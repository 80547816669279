// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-unavailability-list-style__not_available_header--2sUsD {\n  margin-top: 35px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n}\n\n.src-components-unavailability-list-style__not_available_header--2sUsD button {\n  padding: 0;\n}\n\n.src-components-unavailability-list-style__unavailable_team_wrap--2rsg_ {\n  padding-top: 30px;\n}\n\n.src-components-unavailability-list-style__unavailable_team_date--3SUWL {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  -ms-flex-align: center;\n      align-items: center;\n  margin-bottom: 15px;\n}\n\n.src-components-unavailability-list-style__unavailable_team_resaons--1KhNc {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-components-unavailability-list-style__unavailable_team_resaons--1KhNc h4 {\n  font-size: 12px;\n  margin-right: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"not_available_header": "src-components-unavailability-list-style__not_available_header--2sUsD",
	"unavailable_team_wrap": "src-components-unavailability-list-style__unavailable_team_wrap--2rsg_",
	"unavailable_team_date": "src-components-unavailability-list-style__unavailable_team_date--3SUWL",
	"unavailable_team_resaons": "src-components-unavailability-list-style__unavailable_team_resaons--1KhNc"
};
module.exports = exports;
