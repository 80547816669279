import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import PropTypes from 'prop-types'
import { flowRight as compose } from 'lodash'
import Positions from '../../components/positions'
import { fetchWorkplacePositions, updatePositionAlias } from './positions.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import {
  updatePosition,
  resetPosition
} from '../../apollo/apollo-cache-mutation.gql'
import { restrictedUsers } from '../../utils/constant'
import { getDisplayHash, getEmployee } from '../../utils/search-table-methods'
import { searchResultData } from '../../utils/search-string'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updatePositionInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateAlias: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  resetPositionInfo: PropTypes.func,
  getWorkplacePositions: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  history: { push: () => {} },
  updatePositionInfo: () => {},
  updateAlias: () => {},
  getShift: {
    currentShift: {
      workplace: {
        corporation: {
          settings: ''
        }
      },
      manager: {
        id: ''
      }
    }
  },
  resetPositionInfo: () => {},
  getWorkplacePositions: {
    Workplace: {
      positions: []
    }
  }
}

export class PositionsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positions: [],
      positionsData: [],
      isLoading: false,
      isSearch: false,
      searchedPositions: [],
      searchWord: '',
      isSynced: false
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps?.getWorkplacePositions?.loading) {
      return {
        isLoading: true
      }
    }
    if (
      nextProps?.getWorkplacePositions &&
      nextProps?.getWorkplacePositions?.Workplace
    ) {
      const {
        positions: positionsInfo
      } = nextProps.getWorkplacePositions.Workplace
      const {
        currentShift: {
          workplace: {
            corporation: { settings }
          }
        }
      } = nextProps.getShift
      const settingsObj = settings && JSON.parse(settings)
      const syncType = settingsObj && settingsObj.sync_type
      const isSynced = syncType === 'KRONOS-WFD'
      const positions = getEmployee(positionsInfo, isSynced)
      return {
        positions: getDisplayHash(positions, positions.length, isSynced),
        positionsData: positions,
        isLoading: false,
        isSynced
      }
    }
    return null
  }

  onSearchPosition = word => {
    if (word.length === 0) {
      this.setState({ isSearch: false, searchWord: '' })
    } else {
      this.setState({ isSearch: true })
      const { positionsData } = this.state
      const searchedData = searchResultData(positionsData, word)
      this.setState({
        searchedPositions: getDisplayHash(searchedData, searchedData.length),
        isSearch: true,
        searchWord: word
      })
    }
  }

  onEditPosition = positionId => {
    const { searchWord } = this.state
    const { history, getWorkplacePositions, updatePositionInfo } = this.props
    if (positionId) {
      if (getWorkplacePositions.Workplace) {
        const { positions } = getWorkplacePositions.Workplace
        const filteredPosition = positions.find(
          position => position.id === positionId
        )
        const { id, name, color, users } = filteredPosition
        updatePositionInfo({
          variables: {
            id,
            name,
            color,
            users,
            isEdit: true
          }
        }).then(() => {
          history.push({
            pathname: '/positionMember',
            state: { positionId }
          })
        })
      }
    } else if (searchWord !== '') this.onSearchPosition(searchWord)
  }

  onEditAlias = (id, alias) => {
    const { updateAlias, getShift } = this.props
    updateAlias({
      variables: { id, alias },
      update: cache => {
        try {
          const positions = cache.readQuery({
            query: fetchWorkplacePositions,
            variables: {
              input: {
                id: getShift?.currentShift?.workplace?.id
              }
            }
          })
          const filteredPosition = positions?.Workplace?.positions.map(
            position => {
              const pos = position
              if (pos.id === id) pos.alias = alias
              return pos
            }
          )
          cache.writeQuery({
            query: fetchWorkplacePositions,
            variables: {
              input: {
                id: getShift?.currentShift?.workplace?.id
              }
            },
            data: {
              Workplace: {
                Workplace: { positions: filteredPosition },
                __typename: 'Position'
              }
            }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    })
  }

  onAddPosition = () => {
    const { history, resetPositionInfo } = this.props
    resetPositionInfo().then(() => {
      history.push('/namePosition')
    })
  }

  render() {
    const { history, getShift } = this.props
    const {
      positions,
      isLoading,
      isSearch,
      searchedPositions,
      isSynced
    } = this.state
    const {
      currentShift: {
        manager: { id }
      }
    } = getShift
    const isRestricted = restrictedUsers.includes(id)
    const positionsData = isSearch ? searchedPositions : positions

    return (
      <Positions
        isLoading={isLoading}
        positions={positionsData}
        history={history}
        onAddPosition={this.onAddPosition}
        onEditAlias={this.onEditAlias}
        onSearchChange={this.onSearchPosition}
        onDrawerChange={this.onEditPosition}
        isRestricted={isRestricted}
        isSynced={isSynced}
      />
    )
  }
}

export const PositionsContainerApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updatePosition, { name: 'updatePositionInfo' }),
  graphql(updatePositionAlias, { name: 'updateAlias' }),
  graphql(resetPosition, { name: 'resetPositionInfo' }),
  graphql(fetchWorkplacePositions, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.workplace?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplacePositions'
  })
)(PositionsContainer)

PositionsContainer.propTypes = propTypes
PositionsContainer.defaultProps = defaultProps

export default PositionsContainerApollo
