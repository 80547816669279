// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-login-styles__login_form--1gdbv {\n  padding: 0 20px;\n  height: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: start;\n      align-items: flex-start;\n}\n\n.src-components-login-styles__form_container--Prrga {\n  -ms-flex-positive: 1;\n      flex-grow: 1;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: space-evenly;\n      justify-content: space-evenly;\n  width: 100%;\n}\n\n.src-components-login-styles__form_container--Prrga > button {\n  width: 100%;\n  display: -ms-flexbox;\n  display: flex;\n}\n", ""]);
// Exports
exports.locals = {
	"login_form": "src-components-login-styles__login_form--1gdbv",
	"form_container": "src-components-login-styles__form_container--Prrga"
};
module.exports = exports;
