import React, { Component } from 'react'
import { Icon, Spin } from 'antd'
import PropTypes from 'prop-types'
import styles from './style.css'

const antIcon = <Icon type="loading" className={styles.loader} spin />
const antIcon1 = <Icon type="loading" className={styles.leftLoader} spin />

const propTypes = {
  isLeft: PropTypes.bool
}
const defaultProps = {
  isLeft: false
}
export class Loader extends Component {
  render() {
    const { isLeft } = this.props
    return isLeft ? (
      <Spin indicator={antIcon1} />
    ) : (
      <div className={styles.full_page}>
        <span className={styles.spinner}>
          <Spin indicator={antIcon} />
        </span>
      </div>
    )
  }
}

Loader.propTypes = propTypes
Loader.defaultProps = defaultProps
export default Loader
