import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List } from 'syrg-design-kit'
import { Loader } from '../../controls/loader'
import { createAndFlattenListWithCheckboxes } from '../../../utils/user-list-sorting'

const propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired,
  isLoading: PropTypes.bool,
  onSearchChange: PropTypes.func.isRequired,
  onCheckChange: PropTypes.func,
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
  isSynced: PropTypes.bool
}

const defaultProps = {
  isLoading: false,
  onCheckChange: () => {},
  selectedUsers: [],
  isSynced: false
}

export class SearchTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: ''
    }
  }

  onSearchChanged = value => {
    this.setState({ text: value })
    const { onSearchChange } = this.props
    onSearchChange(value)
  }

  render() {
    const {
      data,
      isSynced,
      isLoading,
      onCheckChange,
      selectedUsers
    } = this.props
    const { text } = this.state

    const mappedData = createAndFlattenListWithCheckboxes(
      data,
      selectedUsers,
      isSynced
    )

    return isLoading ? (
      <Loader />
    ) : (
      <List
        withCheckbox
        searchInputValue={text}
        list={mappedData}
        searchInputChange={e => this.onSearchChanged(e.target.value)}
        onCheckboxCheck={onCheckChange}
      />
    )
  }
}

SearchTable.propTypes = propTypes
SearchTable.defaultProps = defaultProps

export default SearchTable
