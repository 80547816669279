import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from 'syrg-design-kit'

const propTypes = {
  notes: PropTypes.string,
  spanishNotes: PropTypes.string,
  onNoteChange: PropTypes.func
}

const defaultProps = {
  notes: '',
  spanishNotes: '',
  onNoteChange: () => {}
}

export class ShiftInstructionsComponent extends Component {
  render() {
    const { notes, spanishNotes, onNoteChange } = this.props
    return (
      <>
        <TextField
          data-id="enNote"
          onChange={e => onNoteChange('en', e.target.value)}
          label="English"
          value={notes}
          placeholder="Type your message here"
          multiline
          rows={5}
          rowsMax={5}
        />
        <TextField
          data-id="esNote"
          onChange={e => onNoteChange('es', e.target.value)}
          label="Spanish"
          value={spanishNotes}
          placeholder="Type your message here"
          multiline
          rows={5}
          rowsMax={5}
        />
      </>
    )
  }
}

ShiftInstructionsComponent.propTypes = propTypes
ShiftInstructionsComponent.defaultProps = defaultProps
export default ShiftInstructionsComponent
