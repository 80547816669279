import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { orderBy, flowRight as compose } from 'lodash'
import moment from 'moment-timezone'
import { graphql, withApollo } from 'react-apollo'
import { message } from 'antd'
import { ShiftRequest } from '../../components/shift-request'
import { getAllShifts, stopCall } from './open-shift.gql'
import { timeCalculationOfShift } from '../../utils/time-formatter'

const propTypes = {
  workplaceId: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onCreateShift: PropTypes.func.isRequired,
  restartPhoneTree: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  isSynced: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getShifts: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  stopCallMutation: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  getShifts: {
    getShiftsByWorkplace: {
      id: '',
      startTime: '',
      endTime: '',
      startDate: '',
      teamMemberRequiredCount: 0,
      assignedUsers: {
        id: ''
      },
      department: {
        id: '',
        name: '',
        alias: ''
      },
      position: {
        id: '',
        name: '',
        color: '',
        alias: ''
      }
    }
  },
  stopCallMutation: () => {}
}

export class OpenShifts extends Component {
  // keeps track of success message when shift fills
  static shiftPhoneTreeUpdate(getShiftsByWorkplace, openShifts, isSynced) {
    if (
      getShiftsByWorkplace.length !== openShifts.length &&
      openShifts.length &&
      openShifts.length > 0
    ) {
      const difference = openShifts.filter(
        x => !getShiftsByWorkplace.includes(x)
      )
      if (!difference[0] || !difference[0].startTime) return null
      const successMessage = `The phone tree for the shift from ${
        difference[0]?.startTime
      } to ${difference[0]?.endTime} on ${difference[0]?.startDate} at  ${
        isSynced
          ? difference[0]?.department?.alias
          : difference[0]?.department?.name
      } has ended.`
      return { changes: true, successMessage }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      workId: '',
      isFetching: false,
      openShifts: []
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let { openShifts, workId } = state
    const { getShifts, workplaceId, timezone, isSynced } = nextProps
    if (workId !== workplaceId && workId !== null) {
      workId = workplaceId
      return { workId, isFetching: true }
    }
    if (getShifts.error !== undefined)
      return { openShifts: [], isFetching: false }
    if (getShifts?.getShiftsByWorkplace) {
      if (openShifts?.length > 0 && openShifts[0].workplaceId === workId) {
        const phonetreeUpdate = OpenShifts.shiftPhoneTreeUpdate(
          getShifts.getShiftsByWorkplace,
          openShifts,
          isSynced
        )
        if (phonetreeUpdate?.changes) {
          message.success(phonetreeUpdate.successMessage, 5)
        }
      }
      if (getShifts.getShiftsByWorkplace !== openShifts) {
        openShifts = getShifts.getShiftsByWorkplace
        if (!openShifts.length) {
          openShifts = []
        }
        openShifts = openShifts.map(shift => {
          const { startTime, endTime, position, department } = shift
          if (!startTime.includes('m')) {
            return {
              ...shift,
              unixStartTime: startTime,
              startDate: moment
                .tz(moment.unix(startTime), timezone)
                .format('MMMM D, YYYY'),
              startTime: moment
                .tz(moment.unix(startTime), timezone)
                .format('h:mma'),
              endTime: moment
                .tz(moment.unix(endTime), timezone)
                .format('h:mma'),
              timeLeft: timeCalculationOfShift(shift.unixStartTime),
              position: {
                ...(position || {}),
                name: isSynced ? position?.alias : position?.name
              },
              department: {
                ...(department || {}),
                name: isSynced ? department?.alias : department?.name
              }
            }
          }
          return shift
        })
        return {
          openShifts: orderBy(openShifts, 'startTime', 'desc'),
          isFetching: false
        }
      }
      openShifts = openShifts.map(shift => {
        return {
          ...shift,
          timeLeft: timeCalculationOfShift(shift.unixStartTime)
        }
      })
      return {
        openShifts,
        isFetching: false
      }
    }
    return null
  }

  onStopCall = shiftId => {
    const { stopCallMutation, workplaceId } = this.props
    stopCallMutation({
      variables: { shiftId },
      update: cache => {
        try {
          const { getShiftsByWorkplace } = cache.readQuery({
            query: getAllShifts,
            variables: { workplaceId }
          })
          const filteredOpenShift = getShiftsByWorkplace.filter(
            shift => shift.id !== shiftId
          )
          cache.writeQuery({
            query: getAllShifts,
            variables: { workplaceId },
            data: { getShiftsByWorkplace: filteredOpenShift }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    })
  }

  render() {
    const { openShifts, isFetching } = this.state
    const {
      history,
      onTabChange,
      restartPhoneTree,
      isSynced,
      onCreateShift
    } = this.props
    return (
      <ShiftRequest
        isLoading={isFetching}
        tabId={0}
        shifts={openShifts}
        restartPhoneTree={restartPhoneTree}
        onTabChange={onTabChange}
        onCreateShift={onCreateShift}
        isSynced={isSynced}
        onCloseRequest={this.onStopCall}
        history={history}
      />
    )
  }
}
export const OpenShiftsContainer = compose(
  withApollo,
  graphql(getAllShifts, {
    skip: ownProps => !ownProps.workplaceId,
    options: ownProps => ({
      variables: {
        workplaceId: ownProps.workplaceId
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 3000
    }),
    name: 'getShifts'
  }),
  graphql(stopCall, { name: 'stopCallMutation' })
)(OpenShifts)

OpenShifts.propTypes = propTypes
OpenShifts.defaultProps = defaultProps

export default OpenShiftsContainer
