// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-shift-wizard-layout-style__request_wrapper--3XXUI {\n  padding: 0 20px;\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.src-components-layouts-shift-wizard-layout-style__request_wrapper--3XXUI h2 {\n  margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"request_wrapper": "src-components-layouts-shift-wizard-layout-style__request_wrapper--3XXUI"
};
module.exports = exports;
