import { Auth, Hub } from 'aws-amplify'
import aws4 from 'aws4'
import { updateManager } from '../apollo/apollo-cache-mutation.gql'
import { getUserByCognitoId } from './authorization-query'

export default class Authentication {
  constructor() {
    this.signingInUser = null
    this.signedInUser = null
    this.selectedCorporationId = null
  }

  getCurrentUser = async () => {
    this.signedInUser = await Auth.currentAuthenticatedUser()
    return this.signedInUser
  }

  getCredentials = async () => {
    const credentials = await Auth.currentCredentials()
    return credentials
  }

  signIn = async (username, password) => {
    let error = null
    let challengeName = null
    try {
      this.signingInUser = await Auth.signIn(username, password)
      challengeName = this.signingInUser?.challengeName
    } catch (err) {
      error = err.message || 'Unexpected error'
      this.signingInUser = null
    }

    return { error, challengeName }
  }

  completeNewPassword = async (user, newPassword) => {
    let error = null
    const challengeName = null
    try {
      return await Auth.completeNewPassword(
        this.signingInUser,
        newPassword,
        this.signingInUser.challengeParam.requiredAttributes
      )
    } catch (err) {
      error = err.message || 'Unexpected error'
    }

    return { error, challengeName }
  }

  confirmSignIn = async (smsCode, client) => {
    let error = null
    try {
      this.signedInUser = await Auth.confirmSignIn(
        this.signingInUser,
        smsCode,
        'SMS_MFA'
      )
      const { username: cognitoId } = this.signedInUser
      await client
        .query({
          query: getUserByCognitoId,
          fetchPolicy: 'no-cache',
          variables: {
            id: cognitoId
          }
        })
        .then(async user => {
          const userDb =
            user.data &&
            user.data.getUserByCognitoId &&
            user.data.getUserByCognitoId
          await client
            .mutate({
              mutation: updateManager,
              fetchPolicy: 'no-cache',
              variables: {
                id: userDb.id,
                firstName: userDb.firstName,
                lastName: userDb.lastName,
                phoneNumber: userDb.phoneNumber
              }
            })
            .then(() => {
              Hub.dispatch(
                'auth',
                { event: 'signIn', data: this.signedInUser },
                'Auth'
              )
            })
            .catch(() => {})
          this.signingInUser = null
        })
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        error = 'User is not confirmed'
      } else {
        error = err.message || 'Unexpected error'
      }
    }
    return { error }
  }

  forgotPassword = async phone => {
    let error = null
    let response = null
    try {
      response = await Auth.forgotPassword(phone)
    } catch (err) {
      error = err
    }
    return { error, response }
  }

  forgotPasswordSubmit = async (phone, code, newPassword) => {
    return Auth.forgotPasswordSubmit(phone, code, newPassword)
      .then(data => {
        return data
      })
      .catch(error => {
        return error
      })
  }

  getLoggedInUserId = async () => {
    const {
      attributes: { sub }
    } = await Auth.currentAuthenticatedUser()
    return sub
  }

  setSelectedCorporationId = id => {
    this.selectedCorporationId = id
  }

  awsGraphqlFetch = async (uri, options) => {
    const sub = await this.getLoggedInUserId()

    const headers = {
      ...options.headers,
      'X-Syrg-User-Id': sub
    }

    if (this.selectedCorporationId) {
      headers['X-Syrg-Corporation-Id'] = this.selectedCorporationId
    }

    const signable = {
      host: process.env.REACT_APP_MWB_BACKEND_HOST || '',
      path: `${process.env.REACT_APP_MWB_BACKEND_PATH || ''}/graphql`,
      method: 'POST',
      service: 'execute-api',
      headers,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
      body: options.body
    }
    const credentials = await this.getCredentials()
    const request = aws4.sign(signable, credentials)
    return fetch(uri, request)
  }

  signOut = async () => {
    Auth.signOut().catch(() => {})
    localStorage.clear()
  }

  globalSignOut = async () => {
    Auth.signOut({ global: true }).catch(() => {})
  }

  changePassword = async (pass, newPass) => {
    const response = null
    const error = null
    return Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, pass, newPass)
      })
      .then(data => {
        return { response: data, error }
      })
      .catch(err => {
        return { response, error: err.message || 'Unexpected error' }
      })
  }
}
