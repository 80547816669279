import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose, flatMap } from 'lodash'
import { getDisplayHash, getEmployee } from '../../utils/search-table-methods'
import { TeamMemberDepartmentComponent } from '../../components/team-member-department'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { updateMemberDepartment } from '../../apollo/apollo-cache-mutation.gql'
import {
  createTeamMember,
  fetchDepartments
} from './team-member-department.gql'
import { fetchWorkplaceMembers } from '../team-member-table/team-member-table.gql'
import { updateCorporationUserMutation } from '../update-team-member/update-team-member.gql'
import { fetchMemberInfo } from '../employee-details/employee-details.gql'

const propTypes = {
  onDelete: PropTypes.func,
  updateCorporationUser: PropTypes.func,
  teamMemberInfo: PropTypes.shape({
    memberId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    preferredLanguage: PropTypes.string,
    page: PropTypes.number,
    phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    positions: PropTypes.array,
    hireDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    primaryWorkplace: PropTypes.string,
    primaryPosition: PropTypes.string,
    employeeId: PropTypes.string,
    isTemp: PropTypes.bool,
    excludedLocation: PropTypes.array,
    isEdit: PropTypes.bool
  }).isRequired,
  createMember: PropTypes.func,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getDepartmentMembers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateMemberLocations: PropTypes.func,
  onCancel: PropTypes.func
}

const defaultProps = {
  onDelete: () => {},
  createMember: () => {},
  getShift: {
    currentShift: {
      workplace: { id: '', corporation: { id: '' } }
    }
  },
  getDepartmentMembers: () => {},
  updateMemberLocations: () => {},
  onCancel: () => {},
  updateCorporationUser: () => {}
}

export class TeamMemberDepartment extends Component {
  constructor(props) {
    super(props)
    const { teamMemberInfo: { excludedLocation = [] } = {} } = props
    this.state = {
      isLoading: false,
      isSearch: false,
      teamMembers: [],
      searchedMembers: [],
      selectedDepts: excludedLocation,
      teamLoading: false,
      error: false,
      isSelectAll: !excludedLocation.length
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { teamMemberData } = state
    if (nextProps?.getDepartmentMembers?.loading)
      return {
        isLoading: true
      }
    if (
      nextProps.getDepartmentMembers &&
      !nextProps.getDepartmentMembers.loading &&
      nextProps.getDepartmentMembers.getDepartmentsByCorporationId
    ) {
      const { getDepartmentsByCorporationId } = nextProps.getDepartmentMembers
      if (teamMemberData !== getDepartmentsByCorporationId) {
        const teamMembers = getEmployee(getDepartmentsByCorporationId)
        return {
          isLoading: false,
          teamMemberData: teamMembers,
          teamMembers: getDisplayHash(teamMembers, teamMembers.length)
        }
      }
    }
    if (
      state.isSearch &&
      nextProps.getShift?.currentShift?.workplace?.id !== state.workplaceId
    ) {
      return {
        isSearch: !state.isSearch,
        workplaceId: nextProps.getShift.currentShift.workplace.id
      }
    }
    return null
  }

  onCheckChange = userID => {
    const { selectedDepts } = this.state
    const foundIndex = selectedDepts.findIndex(id => id === userID)
    if (selectedDepts.length > 0) {
      this.setState({
        error: false
      })
    }
    if (foundIndex >= 0) {
      selectedDepts.splice(foundIndex, 1)
    } else if (foundIndex === -1) {
      selectedDepts.push(userID)
    }
    this.setState({
      selectedDepts,
      isSelectAll: !selectedDepts.length
    })
  }

  onSelectAll = val => {
    const { teamMemberData } = this.state
    this.setState({ isSelectAll: val })
    if (val) this.setState({ selectedDepts: [] })
    else
      this.setState({ selectedDepts: flatMap(teamMemberData).map(b => b.id) })
  }

  addTeamMembers = () => {
    const { selectedDepts, teamMembers } = this.state

    const { onDelete, createMember, teamMemberInfo, getShift } = this.props
    const {
      workplace: {
        id: workplaceId,
        corporation: { id: corporationId }
      }
    } = getShift.currentShift
    if (selectedDepts.length === flatMap(teamMembers).map(b => b.id).length) {
      Snackbar(
        'Please select at least one location before continuing.',
        'error',
        3000
      )
      this.setState({
        error: true
      })
    } else {
      let plainPhoneNumber = teamMemberInfo.phoneNumber
      if (plainPhoneNumber.toString().length === 10)
        plainPhoneNumber = Number(1).toString() + plainPhoneNumber
      createMember({
        variables: {
          input: {
            corporationId,
            firstName: teamMemberInfo.firstName,
            lastName: teamMemberInfo.lastName,
            preferredLanguage: teamMemberInfo.preferredLanguage,
            phoneNumber: Number(plainPhoneNumber),
            positions: teamMemberInfo.positions,
            primaryWorkplace: teamMemberInfo.primaryWorkplace,
            hireDate: teamMemberInfo.hireDate,
            excludedDepartments: selectedDepts,
            employeeId: teamMemberInfo.employeeId,
            primaryPosition: teamMemberInfo.primaryPosition,
            isTemp: teamMemberInfo.isTemp
          }
        },
        update: (
          cache,
          { data: { createTeamMemberInCorporation: newMember } }
        ) => {
          const MemberData = {
            id: newMember?.id,
            firstName: newMember?.firstName,
            lastName: newMember?.lastName,
            __typename: 'User'
          }
          try {
            const {
              Workplace: { employees }
            } = cache.readQuery({
              query: fetchWorkplaceMembers,
              variables: { input: { id: workplaceId } }
            })
            let newMembers = employees
            if (!newMembers) {
              newMembers = []
            }
            if (
              newMembers.filter(member => member.id === newMember.id).length ===
              0
            ) {
              newMembers.push(MemberData)
            }
            cache.writeQuery({
              query: fetchWorkplaceMembers,
              variables: { input: { id: workplaceId } },
              data: {
                Workplace: { employees: newMembers, __typename: 'Workplace' }
              }
            })
          } catch (error) {
            console.info('error', error)
          }
        }
      }).then(data => {
        if (data?.data?.createTeamMemberInCorporation?.message)
          Snackbar(
            data.data.createTeamMemberInCorporation.message,
            'error',
            3000
          )
        else {
          Snackbar('Team Member Saved', 'success', 2000)
          onDelete()
        }
      })
    }
  }

  onBackClick = () => {
    const { updateMemberLocations } = this.props
    const { selectedDepts } = this.state
    updateMemberLocations({
      variables: {
        excludedLocation: selectedDepts
      }
    })
  }

  onUpdate = () => {
    const { selectedDepts, teamMembers } = this.state
    if (selectedDepts.length === flatMap(teamMembers).map(b => b.id).length) {
      Snackbar(
        'Please select at least one location before continuing.',
        'error',
        3000
      )
      this.setState({
        error: true
      })
    } else {
      const {
        onCancel,
        getShift,
        updateCorporationUser,
        teamMemberInfo
      } = this.props
      const {
        workplace: {
          corporation: { id: corporationId }
        }
      } = getShift?.currentShift
      updateCorporationUser({
        variables: {
          input: {
            corporationId,
            userId: teamMemberInfo?.memberId,
            hireDate: teamMemberInfo?.hireDate,
            employeeId:
              teamMemberInfo?.employeeId === null
                ? ''
                : teamMemberInfo?.employeeId.trim(),
            isTemp: teamMemberInfo?.isTemp,
            primaryWorkplace: teamMemberInfo?.primaryWorkplace,
            excludedDepartments: selectedDepts
          }
        },
        update: (cache, { data: { UpdateCorporationUser: user } }) => {
          try {
            const corporationUser = cache.readQuery({
              query: fetchMemberInfo,
              variables: { corporationId, userId: teamMemberInfo?.memberId }
            })
            corporationUser.CorporationUser.departments =
              user.corporationUser.departments
            cache.writeQuery({
              query: fetchMemberInfo,
              variables: { corporationId, userId: teamMemberInfo?.memberId },
              data: { CorporationUser: corporationUser.CorporationUser }
            })
          } catch (error) {
            console.info('error', error)
          }
        }
      }).then(() => {
        Snackbar('Successfully updated team member locations', 'success', 2000)
        onCancel()
      })
    }
  }

  render() {
    const { onCancel, teamMemberInfo } = this.props
    const { isEdit } = teamMemberInfo
    const {
      isSearch,
      searchedMembers,
      teamMembers,
      selectedDepts,
      isLoading,
      teamLoading,
      error,
      isSelectAll,
      teamMemberData
    } = this.state
    const filterTeamMember = isSearch ? searchedMembers : teamMembers
    return (
      <TeamMemberDepartmentComponent
        data={filterTeamMember}
        onNextClick={isEdit ? this.onUpdate : this.addTeamMembers}
        onCheckChange={this.onCheckChange}
        selectedDepts={selectedDepts}
        isLoading={isLoading}
        onBackClick={isEdit ? onCancel : this.onBackClick}
        teamLoading={teamLoading}
        error={error}
        isSelectAll={isSelectAll}
        onSelectAll={this.onSelectAll}
        isEdit={isEdit}
        empName={`${teamMemberInfo?.firstName} ${teamMemberInfo?.lastName}`}
        isIndeterminate={
          selectedDepts.length !== 0 &&
          selectedDepts.length !== teamMemberData.length
        }
      />
    )
  }
}
export const TeamMemberDepartmentApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateCorporationUserMutation, { name: 'updateCorporationUser' }),
  graphql(fetchDepartments, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.corporation.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          corporationId: getShift?.currentShift?.workplace?.corporation?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getDepartmentMembers'
  }),
  graphql(createTeamMember, { name: 'createMember' }),
  graphql(updateMemberDepartment, { name: 'updateMemberLocations' })
)(TeamMemberDepartment)

TeamMemberDepartment.propTypes = propTypes
TeamMemberDepartment.defaultProps = defaultProps

export default TeamMemberDepartmentApollo
