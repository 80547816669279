import gql from 'graphql-tag'

export const getAllShifts = gql`
  query getShiftsByWorkplace($workplaceId: ID!) {
    getShiftsByWorkplace(workplaceId: $workplaceId, onlyOpen: true) {
      id
      startTime
      endTime
      workplaceId
      position {
        id
        name
        color
        alias
      }
      department {
        id
        name
        message
        alias
      }
      assignedUsers {
        id
        firstName
        lastName
      }
      teamMemberRequiredCount
      latestShiftPoll {
        shiftDetails {
          timeBetweenCalls
        }
        createdAt
      }
    }
  }
`

export const stopCall = gql`
  mutation stopCall($shiftId: ID!) {
    stopCall(shiftId: $shiftId)
  }
`

export const getMembersCallList = gql`
  query Shift($id: ID!) {
    Shift(id: $id) {
      assignedUsers {
        id
        firstName
        lastName
      }
      userCall {
        id
        firstName
        lastName
        ranking
        imageUrl
        status
        userResponse
        awardedAt
      }
    }
  }
`

export default { getAllShifts, stopCall }
