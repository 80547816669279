export const checkCharacterOccurrence = (searchString, stringToSearch) => {
  return searchString
    .trim()
    .toLowerCase()
    .includes(
      stringToSearch
        .trim()
        .toString()
        .toLowerCase()
    )
}

export const searchResultData = (
  searchGroup,
  stringToSearch,
  multiple,
  isSynced
) => {
  const searchedData = []
  if (multiple) {
    if (searchGroup) {
      searchGroup.forEach(mapped => {
        const name = mapped?.name?.toLowerCase() || ''
        let alias = ''
        if (isSynced) {
          alias = mapped?.alias?.toLowerCase()
        }
        const street =
          (mapped.addressJson?.includes('street') &&
            JSON.parse(mapped.addressJson).street.toLowerCase()) ||
          ''
        const city =
          (mapped.addressJson?.includes('city') &&
            JSON.parse(mapped.addressJson).city.toLowerCase()) ||
          ''
        const nameFilter = checkCharacterOccurrence(name, stringToSearch)
        const cityFilter = checkCharacterOccurrence(city, stringToSearch)
        const streetFilter = checkCharacterOccurrence(street, stringToSearch)
        const aliasFilter = checkCharacterOccurrence(alias, stringToSearch)
        if (nameFilter || streetFilter || cityFilter || aliasFilter) {
          searchedData.push(mapped)
        }
      })
    }
  } else if (searchGroup?.length > 0) {
    searchGroup.forEach(mapped => {
      const name = mapped.name?.toLowerCase()
      const toFilter = checkCharacterOccurrence(name, stringToSearch)
      if (toFilter) {
        searchedData.push(mapped)
      }
    })
  }
  return searchedData
}

export default searchResultData
