import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Typography } from 'syrg-design-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import styles from './style.css'

const propTypes = {
  tabId: PropTypes.number.isRequired,
  restartPhoneTree: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startDate: PropTypes.string,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      teamMemberRequiredCount: PropTypes.number.isRequired,
      replacedUsers: PropTypes.string,
      timeLeft: PropTypes.string,
      lastPhoneStart: PropTypes.string,
      position: PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string,
        alias: PropTypes.string
      }),
      department: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        message: PropTypes.string,
        alias: PropTypes.string
      }),
      assignedUsers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  isSynced: PropTypes.bool.isRequired,
  onCloseRequest: PropTypes.func
}

const defaultProps = {
  onCloseRequest: () => {}
}

export class ShiftCardComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isStopRequest: false,
      shiftId: ''
    }
  }

  onCloseRequest = shiftId => {
    const { isStopRequest } = this.state
    this.setState({ isStopRequest: !isStopRequest, shiftId })
  }

  onCloseRequestCall = () => {
    const { shiftId } = this.state
    const { onCloseRequest } = this.props
    this.setState({ isStopRequest: false }, () => {
      onCloseRequest(shiftId)
    })
  }

  render() {
    const { shifts, restartPhoneTree, tabId, isSynced } = this.props
    const { isStopRequest } = this.state
    return (
      <>
        {shifts?.map(shift => {
          const isRestart =
            shift.teamMemberRequiredCount - shift?.assignedUsers?.length > 0
          return (
            <div key={shift.id} className={styles.card}>
              <div className={styles.cardText}>
                <Typography variant="h3">
                  {isSynced
                    ? shift?.department?.alias
                    : shift?.department?.name}
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  <FontAwesomeIcon icon={faUser} />
                  {shift?.assignedUsers?.length || 0}/
                  {shift.teamMemberRequiredCount}
                </Typography>
              </div>

              <div className={styles.shift_time}>
                <div className={styles.shift_time_info}>
                  <Typography variant="h4" color="textSecondary">
                    {shift.startDate}
                  </Typography>
                  <Typography variant="body2">
                    {shift.startTime} – {shift.endTime}
                  </Typography>
                </div>
                <div className={styles.shift_position}>
                  <Typography variant="h4">
                    {isSynced ? shift?.position?.alias : shift?.position?.name}
                  </Typography>

                  {tabId !== 0 && (tabId === 2 || !isRestart) && (
                    <Button
                      size="small"
                      data-id="past_view_detail"
                      className={styles.past_view_details}
                      onClick={() => restartPhoneTree(shift, false, false)}
                    >
                      View Details
                    </Button>
                  )}
                  {tabId === 0 && (
                    <Typography variant="body2">
                      {shift.timeLeft}{' '}
                      <FontAwesomeIcon icon={faHourglassHalf} />
                    </Typography>
                  )}
                </div>
              </div>
              {tabId !== 2 && (
                <>
                  <div className={styles.open_btn}>
                    {tabId === 0 && (
                      <>
                        <Button
                          size="small"
                          data-id="cancel-request"
                          color="secondary"
                          width="auto"
                          onClick={() => this.onCloseRequest(shift.id)}
                        >
                          Cancel Request
                        </Button>
                        <Button
                          size="small"
                          data-id="open-detail"
                          onClick={() => restartPhoneTree(shift, false, true)}
                        >
                          View Details
                        </Button>
                      </>
                    )}
                  </div>
                  <div className={styles.restart_button}>
                    {tabId === 1 && isRestart && (
                      <>
                        <Button
                          data-id="restart_request"
                          onClick={() => restartPhoneTree(shift, true, false)}
                          width="auto"
                          size="small"
                        >
                          Restart Request
                        </Button>
                        <Button
                          size="small"
                          data-id="upcoming-detail"
                          onClick={() => restartPhoneTree(shift, false, false)}
                        >
                          View Details
                        </Button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          )
        })}
        <Dialog
          open={isStopRequest}
          onClose={this.onCloseRequest}
          title="Cancel Shift Request?"
          okText="Cancel Request"
          cancelText="Nevermind"
          onOk={this.onCloseRequestCall}
          onCancel={this.onCloseRequest}
        >
          <Typography variant="body1">
            Canceling this request will stop outgoing phone calls. You can view
            this shift in the `Upcoming Shifts` tab.
          </Typography>
        </Dialog>
      </>
    )
  }
}

ShiftCardComponent.propTypes = propTypes
ShiftCardComponent.defaultProps = defaultProps

export default ShiftCardComponent
