import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { cloneDeep, flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { removeCallList } from '../../apollo/apollo-cache-query.gql'
import { ImageRow } from '../../components/theme/image-row'

const propTypes = {
  getRemovedUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  allUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

const defaultProps = {
  getRemovedUsers: {},
  allUsers: []
}

export class ImageRowSummaryContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allListUsers: []
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getRemovedUsers, allUsers } = nextProps
    if (getRemovedUsers?.recipientListDrawer) {
      const { users } = getRemovedUsers.recipientListDrawer
      let { allListUsers } = prevState
      if (allListUsers !== allUsers) {
        allListUsers = allUsers
      }
      allListUsers = allListUsers?.filter(x => !x.unavailable)
      if (users.length > 0) {
        users.forEach(removedMember => {
          const index = allListUsers?.findIndex(
            member => member.userId === removedMember.removedId
          )
          if (index !== -1) cloneDeep(allListUsers?.splice(index, 1))
        })
        return { allListUsers }
      }
      return { allListUsers }
    }
    return null
  }

  render() {
    const { allListUsers } = this.state
    const leftUser = allListUsers?.length - 5
    const displayUsers = allListUsers?.slice(0, 5)
    return <ImageRow displayUsers={displayUsers} leftUser={leftUser} />
  }
}

export const ImageRowSummary = compose(
  withApollo,
  graphql(removeCallList, { name: 'getRemovedUsers' })
)(ImageRowSummaryContainer)

ImageRowSummaryContainer.propTypes = propTypes
ImageRowSummaryContainer.defaultProps = defaultProps

export default ImageRowSummary
