// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-manager-details-style__manager_detail--3BAoZ {\n  padding: 40px 20px 60px;\n  width: 100%;\n}\n\n.src-components-manager-details-style__manager_detail_loading--gH7bc {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n      -ms-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  font-size: 20px;\n  color: #2a53ba;\n}\n\n.src-components-manager-details-style__manager_detail_info--DTX6T {\n  margin-bottom: 20px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n}\n\n.src-components-manager-details-style__manager_detail--3BAoZ h3 {\n  margin-bottom: 30px;\n}\n\n.src-components-manager-details-style__manager_detail--3BAoZ h4 {\n  margin-bottom: 10px;\n}\n\n.src-components-manager-details-style__manager_location--25HRP h4 {\n  margin-bottom: 10px;\n}\n\n.src-components-manager-details-style__manager_location--25HRP {\n  margin-top: 20px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n}\n\n.src-components-manager-details-style__manager_location--25HRP p {\n  margin-bottom: 5px;\n}\n\n.src-components-manager-details-style__manager_detail_btn--1xaap {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  margin-top: 40px;\n  margin-bottom: 20px;\n}\n\n.src-components-manager-details-style__manager_detail_btn--1xaap button {\n  padding: 0;\n  max-width: 48%;\n}\n", ""]);
// Exports
exports.locals = {
	"manager_detail": "src-components-manager-details-style__manager_detail--3BAoZ",
	"manager_detail_loading": "src-components-manager-details-style__manager_detail_loading--gH7bc",
	"manager_detail_info": "src-components-manager-details-style__manager_detail_info--DTX6T",
	"manager_location": "src-components-manager-details-style__manager_location--25HRP",
	"manager_detail_btn": "src-components-manager-details-style__manager_detail_btn--1xaap"
};
module.exports = exports;
