export const unavailable = {
  Mutation: {
    updateUnavailable: (
      _,
      { id, startDate, endDate, reason, corporationUserId },
      { cache }
    ) => {
      const data = {
        unavailable: {
          __typename: 'Unavailable',
          id,
          startDate,
          endDate,
          reason,
          corporationUserId
        }
      }
      cache.writeData({ data })
      return null
    },
    clearUnavailable: (_, variables, { cache }) => {
      const data = {
        unavailable: {
          __typename: 'Unavailable',
          id: '',
          startDate: '',
          endDate: '',
          reason: '',
          corporationUserId: ''
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

export const defaultUnavailable = {
  unavailable: {
    __typename: 'Unavailable',
    id: '',
    startDate: '',
    endDate: '',
    reason: '',
    corporationUserId: ''
  }
}
