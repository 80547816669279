import gql from 'graphql-tag'

export const updateCurrentDate = gql`
  mutation updateShiftStartDate($startDate: String!) {
    updateShiftStartDate(startDate: $startDate) @client
  }
`

export const updateCurrentTime = gql`
  mutation updateShiftTime($startTime: String!, $endTime: String!) {
    updateShiftTime(startTime: $startTime, endTime: $endTime) @client
  }
`

export const updateShiftEditing = gql`
  mutation updateShiftEditing {
    updateShiftEditing @client
  }
`

export const updateReplaceUserString = gql`
  mutation updateReplacedUsers(
    $replacedUsers: String!
    $callOutUserId: String!
  ) {
    updateReplacedUsers(
      replacedUsers: $replacedUsers
      callOutUserId: $callOutUserId
    ) @client
  }
`

export const updateShiftPage = gql`
  mutation updateCurrentPage($currentPage: Int!) {
    updateCurrentPage(currentPage: $currentPage) @client
  }
`

export const updateLocationEditing = gql`
  mutation updateLocationEditing {
    updateLocationEditing @client
  }
`

export const updateMemberEditing = gql`
  mutation updateMemberEditing {
    updateMemberEditing @client
  }
`

export const updateTabId = gql`
  mutation updateTabId($tabId: Int!) {
    updateTabId(tabId: $tabId) @client
  }
`

export const updateCurrentWorkplace = gql`
  mutation updateShiftWorkplace(
    $id: id!
    $name: String!
    $phone: String!
    $timezone: String!
    $corporationId: String!
    $corporationName: String!
    $settings: String
  ) {
    updateShiftWorkplace(
      id: $id
      name: $name
      phone: $phone
      timezone: $timezone
      corporationId: $corporationId
      corporationName: $corporationName
      settings: $settings
    ) @client
  }
`

export const updateCurrentPosition = gql`
  mutation updateShiftPosition(
    $id: String!
    $name: String!
    $color: String!
    $alias: String
  ) {
    updateShiftPosition(id: $id, name: $name, color: $color, alias: $alias)
      @client
  }
`

export const updateManager = gql`
  mutation updateShiftManager(
    $id: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: Int!
  ) {
    updateShiftManager(
      id: $id
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) @client
  }
`

export const updateLocation = gql`
  mutation updateLocation(
    $id: String!
    $name: String!
    $message: String
    $alias: String
  ) {
    updateLocation(id: $id, name: $name, message: $message, alias: $alias)
      @client
  }
`

export const resetProgress = gql`
  mutation resetShiftProgress {
    resetShiftProgress @client
  }
`

export const resetNewTeamMember = gql`
  mutation resetNewTeamMember {
    resetNewTeamMember @client
  }
`

export const updateShift = gql`
  mutation updateCurrentShift(
    $shiftId: Id!
    $position: position!
    $location: location!
    $countUser: Number!
    $startDate: String!
    $startTime: String!
    $endTime: String!
    $restartPhoneTree: boolean
    $assignedUserCount: Number!
    $minBetweenCall: String
    $assignedUsers: Array
    $lastPhoneStart: String
    $replacedUsers: String
  ) {
    updateCurrentShift(
      shiftId: $shiftId
      position: $position
      location: $location
      countUser: $countUser
      startDate: $startDate
      startTime: $startTime
      endTime: $endTime
      restartPhoneTree: $restartPhoneTree
      assignedUserCount: $assignedUserCount
      minBetweenCall: $minBetweenCall
      assignedUsers: $assignedUsers
      lastPhoneStart: $lastPhoneStart
      replacedUsers: $replacedUsers
    ) @client
  }
`

export const clearRemoveCallList = gql`
  mutation clearRemoveCallList {
    clearRemoveCallList @client
  }
`

export const clearUnavailable = gql`
  mutation clearUnavailable {
    clearUnavailable @client
  }
`

export const updateUnavailable = gql`
  mutation updateUnavailable(
    $id: String
    $startDate: String
    $endDate: String
    $reason: String
    $corporationUserId: String
  ) {
    updateUnavailable(
      id: $id
      startDate: $startDate
      endDate: $endDate
      reason: $reason
      corporationUserId: $corporationUserId
    ) @client
  }
`

export const updateEmployeeCount = gql`
  mutation updateEmployeeCount($employeeCount: Number!) {
    updateEmployeeCount(employeeCount: $employeeCount) @client
  }
`

export const removedUserCallList = gql`
  mutation removeCallList(
    $removedId: String!
    $ranking: Number!
    $reason: String!
  ) {
    removeCallList(removedId: $removedId, ranking: $ranking, reason: $reason)
      @client
  }
`

export const removeUserfromList = gql`
  mutation removeUserfromRemovedList($removedId: String!) {
    removeUserfromRemovedList(removedId: $removedId) @client
  }
`

export const updateErrorStatus = gql`
  mutation updateError($isError: boolean!, $errorMessage: String!) {
    updateError(isError: $isError, errorMessage: $errorMessage) @client
  }
`

export const updatePositionName = gql`
  mutation updatePositionName($name: String!) {
    updatePositionName(name: $name) @client
  }
`

export const updatePositionMembers = gql`
  mutation updatePositionMembers($selectedUsers: Array!) {
    updatePositionMembers(selectedUsers: $selectedUsers) @client
  }
`

export const updatePosition = gql`
  mutation updatePosition(
    $id: String!
    $name: String!
    $color: String!
    $users: array
    $isEdit: boolean
  ) {
    updatePosition(
      id: $id
      name: $name
      color: $color
      selectedUsers: $users
      isEdit: $isEdit
    ) @client
  }
`

export const resetPosition = gql`
  mutation resetPosition {
    resetPosition @client
  }
`

export const addLocationData = gql`
  mutation addLocation(
    $name: String!
    $street: String!
    $city: String!
    $zipcode: String!
    $state: String!
    $page: Number!
  ) {
    addLocation(
      name: $name
      street: $street
      city: $city
      zipcode: $zipcode
      state: $state
      page: $page
    ) @client
  }
`
export const addSupervisorData = gql`
  mutation addSupervisor(
    $supervisorId: String!
    $primaryArea: String!
    $message: String!
    $page: Number!
  ) {
    addSupervisor(
      supervisorId: $supervisorId
      primaryArea: $primaryArea
      message: $message
      page: $page
    ) @client
  }
`
export const clearLocation = gql`
  mutation clearLocation {
    clearLocation @client
  }
`

export const createTeamMember = gql`
  mutation newTeamMember(
    $page: Number!
    $firstName: String
    $lastName: String
    $phoneNumber: Number
    $hireDate: Number
    $preferredLanguage: String
    $employeeId: String
    $isTemp: boolean
  ) {
    newTeamMember(
      page: $page
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      hireDate: $hireDate
      preferredLanguage: $preferredLanguage
      employeeId: $employeeId
      isTemp: $isTemp
    ) @client
  }
`

export const updatePrimaryPosition = gql`
  mutation updatePrimaryPosition($primaryPosition: String!) {
    updatePrimaryPosition(primaryPosition: $primaryPosition) @client
  }
`

export const updateWorkplaceId = gql`
  mutation updateWorkplaceId($workplaceId: String!) {
    updateWorkplaceId(workplaceId: $workplaceId) @client
  }
`
export const updatePrimaryWorkplace = gql`
  mutation updatePrimaryWorkplace($primaryWorkplace: String!) {
    updatePrimaryWorkplace(primaryWorkplace: $primaryWorkplace) @client
  }
`

export const teamPosition = gql`
  mutation teamPosition($positions: Array!) {
    teamPosition(positions: $positions) @client
  }
`

export const updateMemberDepartment = gql`
  mutation updateMemberDepartment($excludedLocation: Array!) {
    updateMemberDepartment(excludedLocation: $excludedLocation) @client
  }
`

export const updateTeamPage = gql`
  mutation updateTeamPage($page: String!) {
    updateTeamPage(page: $page) @client
  }
`

export const updateTeamId = gql`
  mutation updateTeamId($memberId: String!) {
    updateTeamId(memberId: $memberId) @client
  }
`

export const updateEditManagerId = gql`
  mutation updateEditManagerId($managerId: String!) {
    updateEditManagerId(managerId: $managerId) @client
  }
`

export const updateTeamDepartment = gql`
  mutation updateTeamDepartment(
    $firstName: String
    $lastName: String
    $hireDate: String
    $primaryWorkplace: String
    $employeeId: String
    $isTemp: boolean
    $excludedLocation: Array
  ) {
    updateTeamDepartment(
      firstName: $firstName
      lastName: $lastName
      hireDate: $hireDate
      primaryWorkplace: $primaryWorkplace
      employeeId: $employeeId
      isTemp: $isTemp
      excludedLocation: $excludedLocation
    ) @client
  }
`

export const updateNotes = gql`
  mutation updateNotes($notes: String!, $noteChanged: boolean!) {
    updateNotes(notes: $notes, noteChanged: $noteChanged) @client
  }
`

export const updateSpanishNotes = gql`
  mutation updateSpanishNotes(
    $spanishNotes: String!
    $spanNoteChanged: boolean!
  ) {
    updateSpanishNotes(
      spanishNotes: $spanishNotes
      spanNoteChanged: $spanNoteChanged
    ) @client
  }
`

export const updateMinBetweenCall = gql`
  mutation updateMinBetweenCall($minBetweenCall: String!) {
    updateMinBetweenCall(minBetweenCall: $minBetweenCall) @client
  }
`

export const updateNotifyManagers = gql`
  mutation updateNotifyManagers(
    $notifyManagers: array
    $notifyManagerChanged: boolean!
  ) {
    updateNotifyManagers(
      notifyManagers: $notifyManagers
      notifyManagerChanged: $notifyManagerChanged
    ) @client
  }
`

export const updateLocationId = gql`
  mutation updateLocationId($locationId: String!) {
    updateLocationId(locationId: $locationId) @client
  }
`
