// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-team-wizard-layout-style__team_main_header--2UpLV h1 {\n  padding: 30px 0 40px 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"team_main_header": "src-components-layouts-team-wizard-layout-style__team_main_header--2UpLV"
};
module.exports = exports;
