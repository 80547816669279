import gql from 'graphql-tag'

export const recipientListDrawer = {
  Mutation: {
    clearRemoveCallList: (_, variables, { cache }) => {
      const data = {
        recipientListDrawer: {
          users: [],
          __typename: 'recipientListDrawer'
        }
      }
      cache.writeData({ data })
      return data
    },
    removeCallList: (_, { removedId, ranking, reason }, { cache }) => {
      const query = gql`
        query recipientListDrawer {
          recipientListDrawer @client {
            users {
              removedId
              reason
              ranking
            }
          }
        }
      `
      const previous = cache.readQuery({ query })
      const newUser = {
        removedId,
        ranking,
        reason,
        __typename: 'RemoveCallList'
      }
      const data = {
        recipientListDrawer: {
          users: previous.recipientListDrawer.users.concat([newUser]),
          __typename: 'recipientListDrawer'
        }
      }
      cache.writeData({ data })
      return data
    },
    removeUserfromRemovedList: (_, { removedId }, { cache }) => {
      const query = gql`
        query recipientListDrawer {
          recipientListDrawer @client {
            users {
              removedId
              reason
            }
          }
        }
      `
      const previous = cache.readQuery({ query })
      const removedList = previous.recipientListDrawer.users
      const removedUsers = removedList.filter(
        user => user.removedId !== removedId
      )
      const data = {
        recipientListDrawer: {
          users: removedUsers,
          __typename: 'recipientListDrawer'
        }
      }
      cache.writeData({ data })
      return data
    }
  }
}

export const defaultUsers = {
  recipientListDrawer: {
    users: [],
    __typename: 'recipientListDrawer'
  }
}
