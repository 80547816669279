import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Typography,
  TextField,
  DatePicker,
  DropdownMenu,
  MenuItem,
  Switch
} from 'syrg-design-kit'
import { FooterComponent } from '../layouts/footer-navigation'
import FooterButton from '../layouts/footer-button'
import style from './style.css'
import { FlexBody } from '../layouts/flex-body'
import { PositionsDropDownContainer } from '../../containers/positions-dropdown'
import WorkplaceDropdownContainer from '../../containers/workplace-dropdown'

const propTypes = {
  onTextFieldChange: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDateChanged: PropTypes.func.isRequired,
  hireDate: PropTypes.objectOf(PropTypes.any),
  onNextClick: PropTypes.func.isRequired,
  workplaceId: PropTypes.string,
  preferredLanguage: PropTypes.string,
  employeeId: PropTypes.string,
  isTemp: PropTypes.bool,
  isEdit: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    validNumber: PropTypes.bool
  }),
  validate: PropTypes.func,
  seniority: PropTypes.number,
  onCancel: PropTypes.func
}

const defaultProps = {
  hireDate: moment(),
  phoneNumber: '',
  firstName: '',
  lastName: '',
  preferredLanguage: 'ENGLISH',
  employeeId: '',
  isTemp: false,
  error: {},
  validate: () => {},
  isEdit: false,
  children: <></>,
  seniority: 0,
  workplaceId: '',
  onCancel: () => {}
}

export class TeamMemberFormComponent extends Component {
  render() {
    const {
      onTextFieldChange,
      phoneNumber,
      lastName,
      onDateChanged,
      hireDate,
      onNextClick,
      preferredLanguage,
      employeeId,
      isTemp,
      firstName,
      error,
      validate,
      isEdit,
      children,
      seniority,
      onCancel,
      workplaceId
    } = this.props

    return (
      <>
        <FlexBody>
          <div className={style.team_data_wrapper}>
            {isEdit && (
              <div className={style.emp_header}>
                <Typography variant="h1">Employee Details</Typography>
              </div>
            )}
            {!isEdit && (
              <Typography variant="h4" color="primary">
                Personal Info
              </Typography>
            )}
            <div className={style.text_field_wrapper}>
              <TextField
                label="First Name"
                data-id="firstName"
                placeholder="First Name"
                onChange={e => onTextFieldChange(e.target.value, 'firstName')}
                value={firstName}
                required
                fullWidth
                requiredText="Enter employee's first name"
                forceShowError={error.firstName}
                error={error.firstName}
              />
            </div>
            <div className={style.text_field_wrapper}>
              <TextField
                label="Last Name"
                data-id="lastName"
                placeholder="Last Name"
                onChange={e => onTextFieldChange(e.target.value, 'lastName')}
                value={lastName}
                required
                fullWidth
                requiredText="Enter employee's last name"
                forceShowError={error.lastName}
                error={error.lastName}
              />
            </div>
            <div className={style.text_field_wrapper}>
              <TextField
                label="Phone"
                type="phone"
                data-id="phoneNumber"
                placeholder="Phone Number"
                id="my-input-id"
                validator={validate}
                onChange={e => onTextFieldChange(e.target.value, 'phoneNumber')}
                value={phoneNumber}
                required
                fullWidth
                requiredText="Enter employee's phone number"
                forceShowError={error.validNumber}
                error={error.validNumber}
              />
            </div>
            <Typography variant="h4" color="primary">
              {!isEdit && 'Employee'} Details
            </Typography>
            {isEdit && (
              <div className={style.text_field_wrapper}>
                <TextField
                  data-id="seniority"
                  value={seniority}
                  type="number"
                  label="Seniority Rank"
                  fullWidth
                />
              </div>
            )}
            <div className={style.text_field_wrapper}>
              <TextField
                label="EMPLOYEE ID - optional"
                data-id="employeeId"
                placeholder="Employee Id"
                onChange={e => onTextFieldChange(e.target.value, 'employeeId')}
                value={employeeId}
                fullWidth
                hintText="Unique identifier used within your company"
              />
            </div>
            <div className={style.team_data_hire_date}>
              <DatePicker
                label="Hire Date"
                data-id="datePicker"
                onChange={onDateChanged}
                selectedDate={hireDate.format('YYYY-MM-DD')}
                minDate={moment()
                  .subtract(60, 'year')
                  .format('YYYY-MM-DD')}
                maxDate={moment().format('YYYY-MM-DD')}
              />
            </div>
            <div className={style.team_dept_info}>
              <DropdownMenu
                label="Preferred Language"
                data-id="preferredLanguage"
                value={preferredLanguage}
                onChange={e =>
                  onTextFieldChange(e.target.value, 'preferredLanguage')
                }
                width={100}
              >
                <MenuItem value="ENGLISH">English</MenuItem>
                <MenuItem value="SPANISH">Spanish</MenuItem>
              </DropdownMenu>
            </div>

            {!isEdit && (
              <Typography variant="h4" color="primary">
                Position Info
              </Typography>
            )}
            <div
              className={isEdit ? style.team_area_info : style.team_dept_info}
            >
              <WorkplaceDropdownContainer
                label="Primary Area"
                helperText="Employee's main location"
                defaultValue=""
              />
            </div>
            {!isEdit && (
              <div className={style.team_area_info}>
                <PositionsDropDownContainer
                  label="Primary Position - optional"
                  workplaceId={workplaceId}
                  defaultValue="None"
                />
              </div>
            )}
            <div className={style.add_team_isTemp}>
              <Switch
                data-id="isTemp"
                checked={isTemp}
                value={isTemp}
                onChange={e => onTextFieldChange(e.target.checked, 'isTemp')}
              />
              <div className={style.temp_wrap}>
                <Typography variant="h4">Temp Worker</Typography>
                {!isEdit && (
                  <Typography variant="caption">
                    Associated with a temp agency. Can be contacted regarding
                    shifts within relevant workplaces.
                  </Typography>
                )}
              </div>
            </div>
            {children}
          </div>
        </FlexBody>
        {!isEdit ? (
          <FooterButton
            buttonInfo="All fields required unless otherwise noted"
            onClick={onNextClick}
          >
            Next
          </FooterButton>
        ) : (
          <FooterComponent
            backText="Cancel"
            nextText="Save Changes"
            onBackClick={onCancel}
            onNextClick={onNextClick}
          />
        )}
      </>
    )
  }
}

TeamMemberFormComponent.propTypes = propTypes
TeamMemberFormComponent.defaultProps = defaultProps

export default TeamMemberFormComponent
