import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, TextField, Dialog } from 'syrg-design-kit'
import { Header } from '../theme'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import { FooterComponent } from '../layouts/footer-navigation'
import { CallListRow } from '../layouts/call-list-row'
import styles from '../call-list/style.css'

const propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      name: PropTypes.string,
      unavailable: PropTypes.bool,
      ranking: PropTypes.number,
      overtimeReasons: PropTypes.array
    })
  ),
  selectedUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  removedMembers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onCancelClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onReasonChange: PropTypes.func.isRequired,
  txtReason: PropTypes.string.isRequired,
  onReasonSubmit: PropTypes.func.isRequired,
  onReasonCancel: PropTypes.func.isRequired,
  isOpenReasonModal: PropTypes.bool.isRequired,
  errorReason: PropTypes.bool.isRequired
}

const defaultProps = {
  users: [],
  removedMembers: [],
  selectedUsers: []
}

export class CallListEditComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUsers: [...props.selectedUsers]
    }
  }

  onCheckChange = userID => {
    const { selectedUsers } = this.state
    const selectedUsersCopy = [...selectedUsers]
    const foundIndex = selectedUsersCopy.findIndex(userId => userId === userID)
    if (foundIndex >= 0) {
      selectedUsersCopy.splice(foundIndex, 1)
    } else if (foundIndex === -1) {
      selectedUsersCopy.push(userID)
    }
    this.setState({ selectedUsers: selectedUsersCopy })
  }

  render() {
    const { selectedUsers } = this.state
    const {
      users,
      onCancelClick,
      onRemoveClick,
      isOpenReasonModal,
      onReasonChange,
      txtReason,
      onReasonSubmit,
      onReasonCancel,
      errorReason,
      removedMembers
    } = this.props

    return (
      <PageLayout>
        <Header leftText={false} />
        <div className={styles.call_list_head}>
          <Typography variant="h2">Call List</Typography>
          <Typography variant="body2">
            List of employees being contacted to fill the shift, in order.
            Uncheck employees to prevent them from being contacted.
          </Typography>
        </div>
        <FlexBody>
          <div className={styles.call_list_members}>
            {users.length <= 0 ? (
              <Typography variant="body1">No Records</Typography>
            ) : (
              users?.map(user => (
                <CallListRow
                  key={user.userId}
                  userId={user.userId}
                  name={user.name}
                  unavailable={user.unavailable}
                  removed={removedMembers.includes(user.userId)}
                  ranking={user.ranking}
                  overtime={user?.overtimeReasons?.length > 0}
                  isEdit
                  onChange={this.onCheckChange}
                  isChecked={selectedUsers.includes(user.userId)}
                />
              ))
            )}
          </div>
        </FlexBody>

        <FooterComponent
          nextText="Save Changes"
          backText="Cancel"
          onNextClick={() => onRemoveClick(selectedUsers)}
          onBackClick={onCancelClick}
        />
        <Dialog
          open={isOpenReasonModal}
          onClose={onReasonCancel}
          title="Remove Employees?"
          okText="Submit"
          onOk={() => onReasonSubmit(selectedUsers)}
          onCancel={onReasonCancel}
        >
          <Typography variant="body1">
            This will remove employees from the call list. A reason for removal
            is required.
          </Typography>
          <TextField
            data-id="reasonTxt"
            label="Reason for Removal"
            placeholder="Please enter a reason for removal"
            required
            fullWidth
            onChange={e => onReasonChange(e.target.value)}
            value={txtReason}
            rows={4}
            rowsMax={4}
            requiredText="Enter a reason"
            forceShowError={errorReason}
            error={errorReason}
            multiline
          />
        </Dialog>
      </PageLayout>
    )
  }
}

CallListEditComponent.propTypes = propTypes
CallListEditComponent.defaultProps = defaultProps

export default CallListEditComponent
