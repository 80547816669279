import React from 'react'
import PropTypes from 'prop-types'
import { DropdownMenu, MenuItem, TextField, Typography } from 'syrg-design-kit'
import { FooterComponent } from '../layouts/footer-navigation'
import { FlexBody } from '../layouts/flex-body'
import styles from './style.css'
import { usStates } from '../../utils/constant'
import { Header } from '../theme'
import { PageLayout } from '../layouts/page-layout'
import { Loader } from '../controls/loader'

const propTypes = {
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    locationName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    supervisorId: PropTypes.string,
    locationMessage: PropTypes.string
  }),
  error: PropTypes.shape({
    name: PropTypes.bool,
    street: PropTypes.bool,
    city: PropTypes.bool,
    postalCode: PropTypes.bool,
    state: PropTypes.bool,
    supervisorId: PropTypes.bool
  }),
  supervisors: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
  onChange: PropTypes.func,
  onLocationEdit: PropTypes.func,
  onSave: PropTypes.func,
  children: PropTypes.node
}

const defaultProps = {
  isLoading: false,
  location: {},
  error: {},
  supervisors: [],
  onChange: () => {},
  onLocationEdit: () => {},
  onSave: () => {},
  children: <></>
}

const UpdateLocationComponent = props => {
  const {
    isLoading,
    onChange,
    location,
    error,
    supervisors,
    onLocationEdit,
    onSave,
    children
  } = props
  return (
    <>
      <PageLayout>
        <Header leftText={false} />
        {isLoading ? (
          <Loader />
        ) : (
          <FlexBody>
            <div className={styles.location_wrap}>
              <div className={styles.location_content}>
                <Typography variant="h1">Location Details</Typography>
                <TextField
                  label="Location Name"
                  onChange={e => {
                    onChange(e.target.value, 'locationName')
                  }}
                  required
                  requiredText="Enter location name"
                  forceShowError={error.name}
                  error={error.name}
                  placeholder="Location Name"
                  value={location.locationName}
                  fullWidth
                />
                <TextField
                  label="Street Address"
                  onChange={e => {
                    onChange(e.target.value, 'street')
                  }}
                  placeholder="Street Address"
                  required
                  forceShowError={error.street}
                  requiredText="Enter street address"
                  error={error.street}
                  value={location.street}
                  fullWidth
                />
                <TextField
                  label="City"
                  onChange={e => {
                    onChange(e.target.value, 'city')
                  }}
                  placeholder="Boston"
                  required
                  forceShowError={error.city}
                  requiredText="Enter city"
                  error={error.city}
                  value={location.city}
                  fullWidth
                />
                <div className={styles.location_content_state}>
                  <TextField
                    label="Zip Code"
                    onChange={e => {
                      onChange(e.target.value, 'postalCode')
                    }}
                    type="number"
                    placeholder="12345"
                    value={location.postalCode}
                    required
                    forceShowError={error.postalCode}
                    requiredText="Enter zip code"
                    error={error.postalCode}
                  />
                  <DropdownMenu
                    label="State"
                    width={100}
                    value={location.state || 'Select a state'}
                    onChange={e => {
                      onChange(e.target.value, 'state')
                    }}
                    required
                    helperText={error.state ? 'Select a state' : ''}
                    error={error.state}
                  >
                    <MenuItem key={0} value="Select a state">
                      Select a state
                    </MenuItem>
                    {usStates.map(value => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </DropdownMenu>
                </div>
                <DropdownMenu
                  label="Supervisor"
                  width={100}
                  onChange={e => {
                    onChange(e.target.value, 'supervisorId')
                  }}
                  helperText={
                    error.supervisorId
                      ? 'Select a supervisor for the location'
                      : ''
                  }
                  error={error.supervisorId}
                  value={location.supervisorId || 'Select supervisor'}
                >
                  <MenuItem disabled key={0} value="Select supervisor">
                    Select supervisor
                  </MenuItem>
                  {supervisors.map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.firstName} {value.lastName}
                    </MenuItem>
                  ))}
                </DropdownMenu>
                <div className={styles.block}>
                  <TextField
                    label="Location Message - optional"
                    onChange={e => {
                      onChange(e.target.value, 'locationMessage')
                    }}
                    placeholder="Special instruction for location"
                    value={location.locationMessage || ''}
                    hintText="Include any special instructions team members will need to work at
              this location."
                    rows={4}
                    fullWidth
                    rowsMax={4}
                  />
                </div>
              </div>
              {children}
            </div>
          </FlexBody>
        )}
        <FooterComponent
          onNextClick={onSave}
          onBackClick={onLocationEdit}
          nextText="Save Changes"
          backText="Cancel"
        />
      </PageLayout>
    </>
  )
}
UpdateLocationComponent.propTypes = propTypes
UpdateLocationComponent.defaultProps = defaultProps
export default UpdateLocationComponent
