import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHourglassHalf,
  faUser,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'
import { Typography, Link as LinkButton } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import style from './style.css'
import { FooterComponent } from '../layouts/footer-navigation'
import { ImageRowSummary } from '../../containers/image-row-summary'
import { AdvanceOptionsContainer } from '../../containers/advance-options'
import CallListCont from '../../containers/call-list'
import { Loader } from '../controls/loader'
import { Header } from '../theme'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'

const propTypes = {
  positionName: PropTypes.string.isRequired,
  deptName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  timeLeft: PropTypes.string.isRequired,
  employeeCount: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  restartPhoneTree: PropTypes.bool,
  onRestartRequest: PropTypes.func,
  shouldUserRemoved: PropTypes.func,
  allCallListUsers: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string,
      ranking: PropTypes.number,
      unavailable: PropTypes.bool,
      imageUrl: PropTypes.string,
      overtimeReasons: PropTypes.array
    })
  ),
  onStartRequest: PropTypes.func,
  isRequestStarted: PropTypes.bool,
  loading: PropTypes.bool
}

const defaultProps = {
  restartPhoneTree: false,
  allCallListUsers: [],
  shouldUserRemoved: () => {},
  onStartRequest: () => {},
  onRestartRequest: () => {},
  isRequestStarted: false,
  loading: false
}

export class ShiftSummaryComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDropdownModalOpen: false,
      isAdvanceOpen: false
    }
  }

  showDropdownModalOpen = () => {
    this.setState(prevState => ({
      isDropdownModalOpen: !prevState.isDropdownModalOpen
    }))
  }

  showAdvanceOption = () => {
    const { isAdvanceOpen } = this.state
    this.setState({ isAdvanceOpen: !isAdvanceOpen })
  }

  render() {
    const { isDropdownModalOpen, isAdvanceOpen } = this.state
    const {
      allCallListUsers,
      onStartRequest,
      restartPhoneTree,
      onRestartRequest,
      shouldUserRemoved,
      isRequestStarted,
      loading,
      positionName,
      deptName,
      startDate,
      startTime,
      endTime,
      employeeCount,
      onBack,
      onDelete,
      timeLeft
    } = this.props
    if (isRequestStarted) return <Loader />
    if (isDropdownModalOpen)
      return (
        <CallListCont
          recipients={allCallListUsers}
          shouldUserRemoved={shouldUserRemoved}
          restartPhoneTree={restartPhoneTree}
          showDropdownModalOpen={this.showDropdownModalOpen}
        />
      )

    const usersLoaded = allCallListUsers?.length > 0
    return (
      <PageLayout>
        <Header
          isClose
          title="Discard Shift Request?"
          onDelete={onDelete}
          message="Discarding this request will also delete all of the information that you have entered so far."
        />
        <FlexBody>
          <div className={style.restart_shift_detail}>
            <div className={style.restart_shift_info}>
              <Typography variant="h2">
                {restartPhoneTree
                  ? 'Restart Shift Request'
                  : 'Request New Shift'}
              </Typography>
              <Typography variant="body2">
                Confirm shift details
                {restartPhoneTree &&
                  '. Employees who declined the shift will not be contacted.'}
              </Typography>
            </div>
            <hr />
            {/* Isn't this shift card the some one we use on the Shift Requests screen? TODO: Move this to an own component */}
            <div className={style.restart_card}>
              <div className={style.shift_detail}>
                <Typography variant="h3">{deptName}</Typography>
                <Typography variant="h3" color="textSecondary">
                  <FontAwesomeIcon icon={faUser} /> {employeeCount}
                </Typography>
              </div>
              <div className={style.position_name}>
                <Typography variant="h4">{positionName}</Typography>
              </div>
              <Typography variant="h4" color="textSecondary">
                {startDate}
              </Typography>
              <div className={style.shift_time}>
                <Typography variant="body2">
                  {startTime} – {endTime}{' '}
                </Typography>
                <Typography variant="body2">
                  {timeLeft}
                  <FontAwesomeIcon icon={faHourglassHalf} />
                </Typography>
              </div>
            </div>
            <hr />
            <div className={style.contact_view}>
              <div className={style.employee_contact}>
                <Typography variant="h4" color="primary">
                  Employees to Contact
                </Typography>
                <LinkButton onClick={this.showDropdownModalOpen} isButton>
                  View
                </LinkButton>
              </div>
              <Typography variant="caption">
                The ordered list of workers that are going to be contacted
                1-by-1. View the list to edit who will be called.
                {restartPhoneTree &&
                  ' Employees who declined the shift will not be contacted.'}
              </Typography>
            </div>

            {loading ? (
              <div className={style.call_list_loader}>
                <Loader isLeft />
              </div>
            ) : (
              <div
                className={style.profile_pictures}
                role="presentation"
                data-id="imageRowEdit"
                onClick={this.showDropdownModalOpen}
              >
                <ImageRowSummary allUsers={allCallListUsers} />
              </div>
            )}
            <div
              className={style.advance_option}
              role="presentation"
              data-id="advanceEdit"
              onClick={this.showAdvanceOption}
            >
              <Typography variant="h3">Advanced Options</Typography>
              <FontAwesomeIcon
                icon={isAdvanceOpen ? faChevronUp : faChevronDown}
              />{' '}
            </div>
            <AdvanceOptionsContainer
              allUsers={allCallListUsers}
              isOpen={isAdvanceOpen}
            />
          </div>
        </FlexBody>
        <FooterComponent
          id={restartPhoneTree ? 'restart_request' : 'start_request'}
          backText="Back"
          nextText="Start Request"
          onBackClick={onBack}
          onNextClick={restartPhoneTree ? onRestartRequest : onStartRequest}
          isNextDisabled={!usersLoaded}
        />
      </PageLayout>
    )
  }
}

ShiftSummaryComponent.propTypes = propTypes
ShiftSummaryComponent.defaultProps = defaultProps

export default ShiftSummaryComponent
