import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { getTeamMember } from '../../apollo/apollo-cache-query.gql'
import { resetNewTeamMember } from '../../apollo/apollo-cache-mutation.gql'
import { TeamWizardComponent } from '../../components/layouts/team-wizard-layout'

const propTypes = {
  getTeamMemberInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  resetTeamMember: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  getTeamMemberInfo: { teamMember: { page: 1 } },
  resetTeamMember: () => {},
  history: { push: () => {} }
}

export class TeamWizard extends Component {
  onDeleteNewTeamMember = () => {
    const { history, resetTeamMember } = this.props
    resetTeamMember()
    history.push('/teamMembers')
  }

  onCancel = () => {
    const { history } = this.props
    history.push('/employeeDetail')
  }

  render() {
    const { getTeamMemberInfo } = this.props
    if (getTeamMemberInfo.error || getTeamMemberInfo.loading) {
      return <div data-id="emptyDiv" />
    }
    return (
      <TeamWizardComponent
        onDelete={this.onDeleteNewTeamMember}
        onCancel={this.onCancel}
        teamMemberInfo={getTeamMemberInfo?.teamMember}
      />
    )
  }
}
export const TeamWizardApollo = compose(
  withApollo,
  graphql(getTeamMember, { name: 'getTeamMemberInfo' }),
  graphql(resetNewTeamMember, { name: 'resetTeamMember' })
)(TeamWizard)

TeamWizard.propTypes = propTypes
TeamWizard.defaultProps = defaultProps

export default TeamWizardApollo
