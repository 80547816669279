import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { resetProgress } from '../../apollo/apollo-cache-mutation.gql'
import { ShiftWizardComponent } from '../../components/layouts/shift-wizard-layout'

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  resetShiftProgress: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}

const defaultProps = {
  getShift: {
    currentShift: {
      currentPage: 1,
      workplace: { id: '' },
      location: { id: '' }
    }
  }
}

export class ShiftWizard extends Component {
  onDeleteNewShift = () => {
    const { history, resetShiftProgress } = this.props
    resetShiftProgress()
    history.push('/')
  }

  render() {
    const { getShift, history } = this.props
    if (getShift.error || getShift.loading) {
      return <div />
    }
    const {
      startedEditing,
      currentPage,
      workplace: {
        id: workplaceId,
        corporation: { settings }
      },
      location: { id: locationId }
    } = getShift.currentShift
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    return (
      <ShiftWizardComponent
        workplaceId={workplaceId}
        locationId={locationId}
        history={history}
        currentStep={currentPage}
        onDelete={this.onDeleteNewShift}
        isSynced={syncType === 'KRONOS-WFD'}
        startedEditing={startedEditing}
      />
    )
  }
}
export const ShiftWizardApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(resetProgress, { name: 'resetShiftProgress' })
)(ShiftWizard)

ShiftWizard.propTypes = propTypes
ShiftWizard.defaultProps = defaultProps

export default ShiftWizardApollo
