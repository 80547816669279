import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import style from './style.css'

const ValidationText = props => {
  const { textInfo } = props
  return (
    <div className={style.text_wrapper}>
      <Typography variant="h6">{textInfo}</Typography>
    </div>
  )
}

ValidationText.propTypes = {
  textInfo: PropTypes.string.isRequired
}

export default ValidationText
