export const phoneFormatter = val => {
  const phone = `${val}`
  const phoneLength = phone.length
  if (phoneLength === 10) {
    const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    return !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? `-${x[3]}` : ''}`
  }
  if (phoneLength === 11) {
    const x = phone
      .replace(/\D/g, '')
      .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/)
    return `${x[1]}-${x[2]}-${x[3]}-${x[4]}`
  }
  return phone
}
export default phoneFormatter
