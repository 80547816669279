// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body {\n  font-family: 'Lato', sans-serif;\n\n  /* fixed position fixes body scrolling in mobile */\n  position: fixed;\n\n  /* min height fixes keyboard issues on android */\n  min-height: 450px;\n}\n\n.src-app__login_wrapper--2oMGR {\n  background-color: #fff;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  position: absolute;\n}\n", ""]);
// Exports
exports.locals = {
	"login_wrapper": "src-app__login_wrapper--2oMGR"
};
module.exports = exports;
