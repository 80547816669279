// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-forgot-password-style__forgot_pass_form--QAO8u {\n  padding: 0 20px;\n}\n\n.src-components-forgot-password-style__forgot_pass_form--QAO8u h1 {\n  margin-bottom: 20px;\n}\n\n.src-components-forgot-password-style__forgot_pass_form--QAO8u p {\n  margin-top: 20px;\n}\n\n.src-components-forgot-password-style__forgot_pass_form--QAO8u > div {\n  margin: 20px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"forgot_pass_form": "src-components-forgot-password-style__forgot_pass_form--QAO8u"
};
module.exports = exports;
