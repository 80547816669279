// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-enter-password-styles__enter_pass_form--1ySJR {\n  padding: 50px 20px;\n}\n\n.src-components-enter-password-styles__enter_pass_form--1ySJR h1 {\n  margin-bottom: 30px;\n}\n\n.src-components-enter-password-styles__enter_pass_form--1ySJR p {\n  line-height: 1.14;\n}\n\n.src-components-enter-password-styles__enter_pass_form--1ySJR .src-components-enter-password-styles__pin_code--2-qMO p {\n  margin-bottom: 10px;\n}\n\n.src-components-enter-password-styles__enter_pass_form--1ySJR > div {\n  margin-top: 34px;\n}\n", ""]);
// Exports
exports.locals = {
	"enter_pass_form": "src-components-enter-password-styles__enter_pass_form--1ySJR",
	"pin_code": "src-components-enter-password-styles__pin_code--2-qMO"
};
module.exports = exports;
