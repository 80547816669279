// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-notify-managers-modal-style__managers_list--3Itrc {\n  max-height: calc(100vh - 300px);\n  padding-top: 20px;\n  overflow: auto;\n  overflow: -moz-scrollbars-none;\n  -ms-overflow-style: none;\n}\n\n.src-components-notify-managers-modal-style__managers_list--3Itrc::-webkit-scrollbar { width: 0; }\n\n.src-components-notify-managers-modal-style__managers_list_content--1IY8f {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  border-bottom: 1px solid #f0f1f2;\n}\n\n.src-components-notify-managers-modal-style__managers_list_content--1IY8f h3 {\n  font-weight: normal;\n}\n", ""]);
// Exports
exports.locals = {
	"managers_list": "src-components-notify-managers-modal-style__managers_list--3Itrc",
	"managers_list_content": "src-components-notify-managers-modal-style__managers_list_content--1IY8f"
};
module.exports = exports;
