import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flatMap, flowRight as compose } from 'lodash'
import { Snackbar } from 'syrg-design-kit'
import { EmployeeDetailsComponent } from '../../components/employee-details'
import { fetchMemberInfo, deleteTeamMember } from './employee-details.gql'
import {
  getCurrentShift,
  getTeamMember
} from '../../apollo/apollo-cache-query.gql'
import {
  updateTeamDepartment,
  resetNewTeamMember
} from '../../apollo/apollo-cache-mutation.gql'
import { restrictedUsers } from '../../utils/constant'
import { fetchWorkplaceMembers } from '../team-member-table/team-member-table.gql'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  resetTeamMember: PropTypes.func,
  getMemberInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  deleteTeam: PropTypes.func,
  updateTeamDept: PropTypes.func,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getTeam: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  deleteTeam: () => {},
  updateTeamDept: () => {},
  resetTeamMember: () => {},
  history: { push: () => {} },
  getShift: {
    currentShift: {
      manager: {
        id: ''
      },
      workplace: {
        corporation: {
          id: '',
          settings: ''
        }
      }
    }
  },
  getTeam: { teamMember: { memberId: '' } },
  getMemberInfo: {}
}

export class EmployeeDetails extends Component {
  onMemberDelete = () => {
    const { deleteTeam, getShift, getTeam, getMemberInfo } = this.props
    const { workplace } = getShift.currentShift
    const { memberId } = getTeam?.teamMember
    const { CorporationUser } = getMemberInfo
    const {
      corporation: { id: corporationId }
    } = workplace
    deleteTeam({
      variables: {
        input: {
          userId: memberId,
          corporationId
        }
      },
      update: cache => {
        try {
          const members = cache.readQuery({
            query: fetchWorkplaceMembers,
            variables: { input: { id: workplace.id } }
          })
          const filteredEmployee = members.Workplace.employees.filter(
            emp => emp.id !== CorporationUser?.id
          )
          cache.writeQuery({
            query: fetchWorkplaceMembers,
            variables: { input: { id: workplace.id } },
            data: {
              Workplace: {
                employees: filteredEmployee,
                __typename: 'Workplace'
              }
            }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(() => {
      Snackbar('Employee deleted successfully', 'success', 3000)
      this.onBack()
    })
  }

  onBack = () => {
    const { history, resetTeamMember } = this.props
    resetTeamMember().then(() => {
      history.push('/teamMembers')
    })
  }

  onLocationEdit = () => {
    const { updateTeamDept, getMemberInfo, history } = this.props
    const { CorporationUser } = getMemberInfo
    updateTeamDept({
      variables: {
        firstName: CorporationUser?.user?.firstName,
        lastName: CorporationUser?.user?.lastName,
        hireDate: CorporationUser?.hireDate,
        primaryWorkplace: CorporationUser?.primaryWorkplace?.id,
        employeeId:
          CorporationUser?.employeeId === null
            ? ''
            : CorporationUser?.employeeId,
        isTemp: CorporationUser?.isTemp,
        excludedLocation: flatMap(CorporationUser?.excludedDepartments).map(
          b => b.id
        )
      }
    }).then(() => history.push('/memberLocations'))
  }

  render() {
    const { getMemberInfo, getShift, getTeam, history } = this.props
    if (getTeam.loading || getMemberInfo.loading || getMemberInfo.errors) {
      return <EmployeeDetailsComponent loading />
    }

    const { CorporationUser } = getMemberInfo
    const {
      workplace: {
        corporation: { settings }
      },
      manager: { id }
    } = getShift?.currentShift
    const { memberId } = getTeam?.teamMember
    const isRestrictedUser = restrictedUsers.includes(id)
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    const isRestricted = syncType === 'KRONOS-WFD'
    return (
      <EmployeeDetailsComponent
        memberInfo={CorporationUser}
        corporationUserId={memberId}
        isRestricted={isRestricted || isRestrictedUser}
        onMemberDelete={this.onMemberDelete}
        onBack={this.onBack}
        onLocationEdit={this.onLocationEdit}
        history={history}
      />
    )
  }
}
export const EmployeeDetailsApollo = compose(
  withApollo,
  graphql(getTeamMember, { name: 'getTeam' }),
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(fetchMemberInfo, {
    skip: ({ getShift, getTeam }) =>
      !getShift.currentShift ||
      !getShift.currentShift.workplace.corporation.id ||
      !getTeam.teamMember ||
      !getTeam.teamMember.memberId,
    options: ({ getShift, getTeam }) => ({
      variables: {
        corporationId: getShift?.currentShift?.workplace?.corporation?.id,
        userId: getTeam?.teamMember?.memberId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getMemberInfo'
  }),
  graphql(deleteTeamMember, { name: 'deleteTeam' }),
  graphql(updateTeamDepartment, { name: 'updateTeamDept' }),
  graphql(resetNewTeamMember, { name: 'resetTeamMember' })
)(EmployeeDetails)

EmployeeDetails.propTypes = propTypes
EmployeeDetails.defaultProps = defaultProps

export default EmployeeDetailsApollo
