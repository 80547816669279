import React, { Component } from 'react'
import { Icon, Spin } from 'antd'
import PropTypes from 'prop-types'
import { Tab, Tabs, Typography } from 'syrg-design-kit'
import { ShiftCard } from '../theme'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'
import styles from './style.css'
import { Loader } from '../controls/loader'
import FooterButton from '../layouts/footer-button'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'

const antIcon = <Icon type="loading" spin />

const propTypes = {
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  tabId: PropTypes.number.isRequired,
  restartPhoneTree: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startDate: PropTypes.string,
      startTime: PropTypes.string.isRequired,
      replacedUsers: PropTypes.string,
      endTime: PropTypes.string.isRequired,
      lastPhoneStart: PropTypes.string,
      teamMemberRequiredCount: PropTypes.number.isRequired,
      position: PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string,
        alias: PropTypes.string
      }),
      department: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        message: PropTypes.string,
        alias: PropTypes.string
      }).isRequired,
      assignedUsers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired
        })
      )
    })
  ),
  onShiftScroll: PropTypes.func,
  onTabChange: PropTypes.func,
  onCreateShift: PropTypes.func,
  isSynced: PropTypes.bool,
  onCloseRequest: PropTypes.func
}

const defaultProps = {
  shifts: [],
  isFetching: false,
  isLoading: false,
  onShiftScroll: () => {},
  onTabChange: () => {},
  onCreateShift: () => {},
  isSynced: false,
  onCloseRequest: () => {}
}

export class ShiftRequest extends Component {
  render() {
    const {
      shifts,
      tabId,
      isFetching,
      onTabChange,
      onShiftScroll,
      history,
      restartPhoneTree,
      onCreateShift,
      isLoading,
      isSynced,
      onCloseRequest
    } = this.props
    const tabZeroEmpty = tabId === 0 && shifts.length === 0
    const tabOneEmpty = tabId === 1 && shifts.length === 0
    const tabTwoEmpty = tabId === 2 && shifts.length === 0

    const header = (
      <>
        <SelectWorkplaceHeader history={history} />
        <div className={styles.shift_wrap}>
          <Typography variant="h1">Shift Requests</Typography>
          <Typography variant="body2">
            {tabId === 0 && 'Your currently active shift phone calls.'}
            {tabId === 1 && 'Your upcoming scheduled shifts.'}
            {tabId === 2 && 'Your completed past shifts.'}
          </Typography>
        </div>
        <Tabs
          data-id="shiftTab"
          value={tabId}
          fullWidth
          onChange={(e, key) => onTabChange(key)}
        >
          <Tab label="In Progress" />
          <Tab label="Upcoming Shifts" />
          <Tab label="Past Shifts" />
        </Tabs>
      </>
    )
    const footer = (
      <FooterButton data-id="fillShift" onClick={onCreateShift}>
        Fill a Shift
      </FooterButton>
    )
    const shiftCards = (
      <ShiftCard
        shifts={shifts}
        tabId={tabId}
        restartPhoneTree={restartPhoneTree}
        isSynced={isSynced}
        onCloseRequest={onCloseRequest}
      />
    )

    if (tabId === 2 && shifts?.length && !isLoading)
      return (
        <>
          {header}
          <div className={styles.history_content} onScroll={onShiftScroll}>
            {shiftCards}
            {isFetching && (
              <Spin indicator={antIcon} className={styles.loader} />
            )}
          </div>
          {footer}
        </>
      )

    return (
      <PageLayout>
        {header}
        <FlexBody>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {tabZeroEmpty && (
                <>
                  <div className={styles.noshift}>
                    <Typography variant="body2">
                      <b>No Shifts In Progress</b>
                    </Typography>
                  </div>
                </>
              )}
              {tabOneEmpty && (
                <>
                  <div className={styles.noshift}>
                    <Typography variant="body2">
                      <b>No Upcoming Shifts</b>
                    </Typography>
                  </div>
                </>
              )}
              {tabTwoEmpty && !isFetching && !isLoading && (
                <div className={styles.noshift}>
                  <Typography variant="body2">
                    <b>No Past Shifts</b>
                  </Typography>
                </div>
              )}
              {shifts?.length ? shiftCards : ''}
            </>
          )}
        </FlexBody>
        {footer}
      </PageLayout>
    )
  }
}

ShiftRequest.propTypes = propTypes
ShiftRequest.defaultProps = defaultProps

export default ShiftRequest
