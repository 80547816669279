import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import { graphql, withApollo } from 'react-apollo'
import _, { flowRight as compose } from 'lodash'
import { PositionMembersComponent } from '../../components/position-members'
import { fetchWorkplacePositions } from '../positions/positions.gql'
import {
  getCurrentShift,
  getPosition
} from '../../apollo/apollo-cache-query.gql'
import {
  createPositionInCorporation,
  deletePositionMutation,
  updatePositionUsersMutation,
  getPositionMembers
} from './position-member.gql'
import {
  resetPosition,
  updatePositionMembers
} from '../../apollo/apollo-cache-mutation.gql'
import { restrictedUsers } from '../../utils/constant'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  getPositions: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  getPositionMember: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  resetPositionInfo: PropTypes.func,
  createPositionInCorporationMutation: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  updatePositionUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  deletePosition: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updatePositionMember: PropTypes.func
}

const defaultProps = {
  createPositionInCorporationMutation: () => {},
  history: { push: () => {} },
  resetPositionInfo: () => {},
  updatePositionUsers: () => {},
  deletePosition: () => {},
  updatePositionMember: () => {},
  getPositionMember: {}
}

export class PositionMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      color: _.random(6).toString(),
      loading: false,
      positionName: '',
      selectedUsers: [],
      initialized: false
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { getPositionMember, getPositions } = nextProps

    if (
      !getPositions?.newPosition?.isEdit &&
      getPositions?.newPosition?.selectedUsers
    ) {
      return {
        selectedUsers: getPositions.newPosition.selectedUsers
      }
    }
    if (getPositionMember?.Position?.users?.length > 0) {
      const initialUserIds = getPositionMember.Position.users.map(
        user => user.id
      )
      return state.selectedUsers.length === 0 && !state.initialized
        ? {
            selectedUsers: initialUserIds,
            initialized: true
          }
        : {
            selectedUsers: state.selectedUsers
          }
    }
    return {
      positionName: getPositions?.newPosition?.name || ''
    }
  }

  onResetPosition = () => {
    const { loading } = this.state
    const { resetPositionInfo, history } = this.props
    if (loading) this.setState({ loading: false })
    resetPositionInfo()
    history.push('/positions')
  }

  onCreatePosition = selectedUsers => {
    const {
      getShift,
      createPositionInCorporationMutation,
      getPositions
    } = this.props
    const {
      workplace: {
        corporation: { id }
      }
    } = getShift.currentShift
    const { workplace } = getShift.currentShift
    const { name } = getPositions.newPosition
    if (selectedUsers.length === 0) {
      Snackbar(
        'You must select at least one team member to add to this position.',
        'error'
      )
    } else if (name.length === 0) {
      Snackbar('You must give position name.', 'error')
    } else {
      const { color } = this.state
      this.setState({ loading: true })
      createPositionInCorporationMutation({
        variables: {
          input: {
            corporationId: id,
            name: name.trim(),
            alias: '',
            color,
            userIds: selectedUsers
          }
        },
        update: (
          cache,
          { data: { createPositionInCorporation: newPosition } }
        ) => {
          const users = []
          selectedUsers.forEach(user => {
            users.push({ id: user })
          })
          const positionData = {
            id: newPosition && newPosition[0].id,
            name,
            color,
            users,
            __typename: 'Position'
          }
          try {
            const { Workplace } = cache.readQuery({
              query: fetchWorkplacePositions,
              variables: {
                input: { id: workplace.id }
              }
            })
            let newPositions = Workplace.positions
            if (!newPositions) {
              newPositions = []
            }
            newPositions.push(positionData)
            cache.writeQuery({
              query: fetchWorkplacePositions,
              variables: { id: workplace.id },
              data: {
                Workplace: { positions: newPositions }
              }
            })
          } catch (error) {
            console.info('error', error)
          }
        }
      }).then(() => {
        Snackbar('You have successfully added a new position')
        this.onResetPosition()
      })
    }
  }

  onDelete = () => {
    const { deletePosition, getPositions, getShift } = this.props
    const { isEdit, name: positionName } = getPositions.newPosition
    if (isEdit) {
      const {
        workplace: {
          id: workplaceId,
          corporation: { id: corporationId }
        }
      } = getShift.currentShift
      deletePosition({
        variables: {
          input: {
            corporationId,
            positionName
          }
        },
        update: cache => {
          try {
            const positions = cache.readQuery({
              query: fetchWorkplacePositions,
              variables: { input: { id: workplaceId } }
            })
            const filteredPosition = positions.Workplace.positions.filter(
              position => position.name !== positionName
            )
            cache.writeQuery({
              query: fetchWorkplacePositions,
              variables: { input: { id: workplaceId } },
              data: {
                Workplace: {
                  positions: filteredPosition,
                  __typename: 'Workplace'
                }
              }
            })
          } catch (error) {
            console.info('error', error)
          }
        }
      })
    }
    Snackbar('Position deleted successfully', 'success')
    this.onResetPosition()
  }

  onUpdatePosition = users => {
    if (users.length === 0) {
      Snackbar(
        'You must select at least one team member to update this position.',
        'error'
      )
    } else {
      const { getShift, updatePositionUsers, getPositions } = this.props
      const {
        workplace: {
          corporation: { id: corporationId }
        }
      } = getShift.currentShift
      const { name: positionName } = getPositions.newPosition
      updatePositionUsers({
        variables: {
          input: {
            corporationId,
            positionName,
            users
          }
        }
      })
      this.onResetPosition()
    }
  }

  onBackClick = () => {
    const {
      updatePositionMember,
      history,
      getPositions: {
        newPosition: { isEdit }
      }
    } = this.props
    const { selectedUsers } = this.state
    if (isEdit) {
      history.push('/positions')
    } else {
      updatePositionMember({
        variables: {
          selectedUsers
        }
      })
      history.push('/namePosition')
    }
  }

  onClose = () => {
    const { history } = this.props
    history.push('/positions')
  }

  updateSelectedUsers = selectedUsers => this.setState({ selectedUsers })

  render() {
    const { getPositions, getShift } = this.props
    const { isEdit } = getPositions.newPosition
    const {
      currentShift: {
        manager: { id }
      }
    } = getShift
    const { loading, positionName, selectedUsers } = this.state
    const isRestricted = restrictedUsers.includes(id)
    return (
      <PositionMembersComponent
        onSubmit={isEdit ? this.onUpdatePosition : this.onCreatePosition}
        onDeleteClick={this.onDelete}
        isEdit={isEdit}
        onBackClick={this.onBackClick}
        onClose={this.onClose}
        loading={loading}
        positionName={positionName}
        selectedUsers={selectedUsers}
        updateSelectedUsers={this.updateSelectedUsers}
        isRestricted={isRestricted}
      />
    )
  }
}
export const PositionMemberApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(getPosition, { name: 'getPositions' }),
  graphql(resetPosition, { name: 'resetPositionInfo' }),
  graphql(updatePositionUsersMutation, {
    name: 'updatePositionUsers'
  }),
  graphql(deletePositionMutation, {
    name: 'deletePosition'
  }),
  graphql(createPositionInCorporation, {
    name: 'createPositionInCorporationMutation'
  }),
  graphql(getPositionMembers, {
    skip: props => !props.location || !props.location.state,
    options: ownProps => ({
      variables: {
        id: ownProps?.location?.state?.positionId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getPositionMember'
  }),
  graphql(updatePositionMembers, { name: 'updatePositionMember' })
)(PositionMember)

PositionMember.propTypes = propTypes
PositionMember.defaultProps = defaultProps

export default PositionMemberApollo
