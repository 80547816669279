import React, { Component } from 'react'
import _, { flowRight as compose } from 'lodash'
import { graphql, withApollo } from 'react-apollo'
import PropTypes from 'prop-types'
import TeamMemberTable from '../../components/team-member-table'
import { fetchWorkplaceMembers } from './team-member-table.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { updateTeamId } from '../../apollo/apollo-cache-mutation.gql'
import { searchResultData } from '../../utils/search-string'
import { getEmployee, getDisplayHash } from '../../utils/search-table-methods'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isCheckBoxes: PropTypes.bool,
  onSubmit: PropTypes.func,
  updateMemberId: PropTypes.func,
  submitButtonText: PropTypes.string,
  onBackClick: PropTypes.func,
  loading: PropTypes.bool,
  getWorkplaceMembers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isSearchFixed: PropTypes.bool,
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
  updateSelectedUsers: PropTypes.func
}

const defaultProps = {
  history: { push: () => {} },
  getShift: {
    currentShift: {
      workplace: {
        corporation: {
          id: ''
        }
      }
    }
  },
  isCheckBoxes: false,
  onSubmit: () => {},
  updateMemberId: () => {},
  submitButtonText: '',
  onBackClick: () => {},
  loading: false,
  getWorkplaceMembers: {},
  isSearchFixed: true,
  updateSelectedUsers: () => {},
  selectedUsers: []
}

export class TeamMemberTableContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      isLoading: false,
      teamMembers: [],
      searchedMembers: [],
      searchWord: ''
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { teamMemberData } = state
    if (nextProps?.getWorkplaceMembers?.loading) {
      return {
        isLoading: true
      }
    }
    if (
      nextProps.getWorkplaceMembers &&
      !nextProps.getWorkplaceMembers.loading &&
      nextProps.getWorkplaceMembers.Workplace
    ) {
      const {
        Workplace: { employees }
      } = nextProps.getWorkplaceMembers
      const teamMembers = getEmployee(employees)

      if (!_.isEqual(teamMemberData, teamMembers)) {
        return {
          isLoading: false,
          teamMemberData: teamMembers,
          teamMembers: getDisplayHash(teamMembers, teamMembers.length)
        }
      }
      return {
        teamMembers: getDisplayHash(teamMembers, teamMembers.length),
        isLoading: false
      }
    }
    if (
      state.isSearch &&
      nextProps?.getShift?.currentShift?.workplace?.id !== state.workplaceId
    ) {
      return {
        isLoading: false,
        isSearch: !state.isSearch,
        workplaceId: nextProps.getShift.currentShift.workplace.id
      }
    }
    return {
      isLoading: false
    }
  }

  onSearchMember = word => {
    if (word.length === 0) {
      this.setState({ isSearch: false, searchWord: '' })
    } else {
      const { teamMemberData } = this.state
      const searchedData = searchResultData(teamMemberData, word)
      this.setState({
        searchedMembers: getDisplayHash(searchedData, searchedData.length),
        isSearch: true,
        searchWord: word
      })
    }
  }

  onMemberInfoDrawerChange = memberId => {
    const { searchWord } = this.state
    const { history, updateMemberId } = this.props
    if (memberId) {
      updateMemberId({
        variables: {
          memberId
        }
      }).then(() => {
        history.push('/employeeDetail')
      })
    } else if (searchWord !== '') this.onSearchMember(searchWord)
  }

  onCheckChange = userID => {
    const { selectedUsers, updateSelectedUsers } = this.props
    const foundIndex = selectedUsers.findIndex(id => id === userID)
    if (foundIndex >= 0) {
      selectedUsers.splice(foundIndex, 1)
    } else if (foundIndex === -1) {
      selectedUsers.push(userID)
    }
    updateSelectedUsers(selectedUsers)
  }

  onSubmit = () => {
    const { onSubmit, selectedUsers } = this.props
    onSubmit(selectedUsers)
  }

  onBackClick = () => {
    const { onBackClick, selectedUsers } = this.props
    onBackClick(selectedUsers)
  }

  render() {
    const {
      isCheckBoxes,
      submitButtonText,
      loading,
      isSearchFixed,
      selectedUsers
    } = this.props
    const { isLoading, isSearch, searchedMembers, teamMembers } = this.state
    const teamMemberData = isSearch ? searchedMembers : teamMembers
    return (
      <TeamMemberTable
        isLoading={isLoading}
        data={teamMemberData}
        onSearchChange={this.onSearchMember}
        onDrawerChange={this.onMemberInfoDrawerChange}
        isCheckBoxes={isCheckBoxes}
        onCheckChange={this.onCheckChange}
        selectedUsers={selectedUsers}
        onSubmit={this.onSubmit}
        submitButtonText={submitButtonText}
        onBackClick={this.onBackClick}
        loading={loading}
        isSearchFixed={isSearchFixed}
      />
    )
  }
}

export const TeamMemberTableContainerApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateTeamId, { name: 'updateMemberId' }),
  graphql(fetchWorkplaceMembers, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.workplace?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplaceMembers'
  })
)(TeamMemberTableContainer)

TeamMemberTableContainer.propTypes = propTypes
TeamMemberTableContainer.defaultProps = defaultProps

export default TeamMemberTableContainerApollo
