import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { withClientState } from 'apollo-link-state'
import { persistCache } from 'apollo-cache-persist'
import displayError from './apollo-display-error'
import { resolvers, defaults } from './resolvers'
import authentication from '../authentication'
// the network link below will be switched to a mock source by webpack
// if running in mock mode, so be cautious when changing this
// see webpack.config.dev.js line 264
import networkLink from './network-link'

let client = null

const getApolloClient = () => client
const cache = new InMemoryCache()

const stateLink = withClientState({
  cache,
  resolvers,
  defaults
})

persistCache({
  cache,
  storage: window.localStorage
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors[0]?.message) {
    if (graphQLErrors[0].message === 'RETRY') {
      displayError(
        client,
        'We had a problem with your request. Please try again soon.'
      )
    } else {
      displayError(
        client,
        'We had a problem with your request. Try again later.'
      )
    }
  }
  if (networkError) {
    if (networkError === 'not authenticated') {
      authentication.signOut().then(() => {
        window.location.reload()
      })
    }
    displayError(
      client,
      "Network Error: We're having trouble connecting to the network"
    )
  }
})

let retryLink = new RetryLink({
  attempts: {
    max: 2
  }
})

retryLink = retryLink.concat(errorLink)

client = new ApolloClient({
  link: ApolloLink.from([retryLink, stateLink, networkLink]),
  cache
})

export default getApolloClient
