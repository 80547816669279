export const shiftRequests = {
  Mutation: {
    updateTabId: (_, { tabId }, { cache }) => {
      const data = {
        shiftRequest: {
          tabId,
          __typename: 'shiftRequest'
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

export const defaultShiftRequests = {
  shiftRequest: {
    __typename: 'shiftRequest',
    tabId: 0
  }
}
