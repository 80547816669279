export const getEmployee = (list, isSynced) => {
  if (list.length > 0) {
    if (isSynced) {
      return list.map(member => ({
        id: member.id,
        name:
          member.name || `${member.firstName || ''} ${member.lastName || ''}`,
        alias: member.alias
      }))
    }
    return list.map(member => ({
      id: member.id,
      name: member.name || `${member.firstName || ''} ${member.lastName || ''}`
    }))
  }
  return []
}

export const getDisplayHash = (list, count, isSynced) => {
  const lHash = {}
  if (count >= 5 || (count === 0 && list.length >= 5)) {
    list.forEach(m => {
      let lastInitial = m.name ? m.name[0].toUpperCase() : '--'
      if (isSynced) lastInitial = m.alias ? m.alias[0].toUpperCase() : '--'
      lHash[lastInitial] = lHash[lastInitial]
        ? lHash[lastInitial].concat(m)
        : [m]
    })
  } else {
    lHash.A = list
  }
  return lHash
}

export default { getEmployee, getDisplayHash }
