export const editManager = {
  Mutation: {
    updateEditManagerId: (_, { managerId }, { cache }) => {
      const data = {
        editManager: {
          managerId,
          __typename: 'editManager'
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

export const defaultEditManager = {
  editManager: {
    __typename: 'editManager',
    managerId: ''
  }
}
