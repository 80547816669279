import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, Typography } from 'syrg-design-kit'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'
import style from './styles.css'
import FooterButton from '../layouts/footer-button'
import { FlexBody } from '../layouts/flex-body'
import { PageLayout } from '../layouts/page-layout'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  onSetPassword: PropTypes.func,
  pass: PropTypes.string,
  newPass: PropTypes.string,
  confirmPass: PropTypes.string,
  passError: PropTypes.string,
  newPassError: PropTypes.string,
  confirmPassError: PropTypes.string,
  onChange: PropTypes.func
}

const defaultProps = {
  history: { push: () => {} },
  onSetPassword: () => {},
  onChange: () => {},
  pass: '',
  newPass: '',
  confirmPass: '',
  passError: '',
  newPassError: '',
  confirmPassError: ''
}
class ChangePasswordComponent extends Component {
  render() {
    const {
      onSetPassword,
      history,
      pass,
      newPass,
      confirmPass,
      onChange,
      passError,
      newPassError,
      confirmPassError
    } = this.props
    return (
      <PageLayout>
        <SelectWorkplaceHeader isWorkplaceDropdown={false} history={history} />
        <FlexBody>
          <div className={style.change_password}>
            <Typography variant="h1">Update Password</Typography>
            <Typography variant="body1">
              To change your password, enter your current password, then your
              new password.
            </Typography>
            <Typography variant="body1">
              Passwords must include an uppercase letter and lowercase letter,
              special character, and a number.
            </Typography>
            <div className={style.mypass_change}>
              <TextField
                type="password"
                name="currPassWord"
                label="Current Password"
                fullWidth
                id="change_pass"
                onChange={e => {
                  onChange('pass', e.target.value)
                }}
                value={pass}
                placeholder="Enter Password"
                required
                requiredText="Enter Your Current Password"
                errorText={passError}
              />
              <TextField
                label="New Password"
                name="newPassWord"
                fullWidth
                id="change_new_pass"
                type="password"
                onChange={e => {
                  onChange('newPass', e.target.value)
                }}
                value={newPass}
                placeholder="Enter Password"
                required
                requiredText="Enter Your New Password"
                errorText={newPassError}
              />
              <TextField
                label="Confirm New Password"
                fullWidth
                name="confirmPassWord"
                id="change_confirm_pass"
                type="password"
                onChange={e => {
                  onChange('confirmPass', e.target.value)
                }}
                value={confirmPass}
                placeholder="Enter Password"
                required
                requiredText="Enter Your Confirm Password"
                errorText={confirmPassError}
              />
            </div>
          </div>
        </FlexBody>
        <FooterButton onClick={onSetPassword} buttonInfo="All fields required">
          Reset password
        </FooterButton>
      </PageLayout>
    )
  }
}

ChangePasswordComponent.propTypes = propTypes
ChangePasswordComponent.defaultProps = defaultProps

export default ChangePasswordComponent
