// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-shift-request-style__history_content--1eRW7 {\n  height: calc(100vh - 290px);\n  overflow: auto;\n  -webkit-overflow-scrolling: touch;\n}\n\n.src-components-shift-request-style__history_content--1eRW7 .src-components-shift-request-style__loader--1TLv6 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n}\n\n.src-components-shift-request-style__shift_wrap--m-mKr {\n  padding: 15px 20px 10px 20px;\n}\n\n.src-components-shift-request-style__shift_wrap--m-mKr p {\n  margin-top: 10px;\n}\n\n.src-components-shift-request-style__history_content--1eRW7 .src-components-shift-request-style__loader--1TLv6 i {\n  font-size: 24px;\n  color: #2a53ba;\n}\n\n.src-components-shift-request-style__noshift--3Zwys {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  margin-top: 40px;\n}\n\n@media only screen and (max-width: 360px) {\n  .src-components-shift-request-style__shift_history--2hXVV {\n    padding: 15px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"history_content": "src-components-shift-request-style__history_content--1eRW7",
	"loader": "src-components-shift-request-style__loader--1TLv6",
	"shift_wrap": "src-components-shift-request-style__shift_wrap--m-mKr",
	"noshift": "src-components-shift-request-style__noshift--3Zwys",
	"shift_history": "src-components-shift-request-style__shift_history--2hXVV"
};
module.exports = exports;
