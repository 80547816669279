import React, { Component } from 'react'
import { Snackbar } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { ManagerFormComponent } from '../../components/manager-form'
import { createManager, updateManager } from './manager-form.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { getSupervisor } from '../location-details/location-info.gql'
import { fetchManager } from '../manager-details/manager-details.gql'

const propTypes = {
  createNewManager: PropTypes.func,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  supervisorId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  updateUser: PropTypes.func
}

const defaultProps = {
  createNewManager: () => {},
  getShift: {
    currentShift: {
      workplace: { corporation: { id: '' } }
    }
  },
  supervisorId: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  isEdit: false,
  onCancel: () => {},
  children: <></>,
  updateUser: () => {},
  history: { goBack: () => {} }
}

export class ManagerForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: props.firstName || '',
      lastName: props.lastName || '',
      phoneNumber: props.phoneNumber || '',
      isLoading: false,
      error: {
        firstName: false,
        lastName: false,
        validNumber: false
      }
    }
  }

  onChange = (value, key) => {
    if (key === 'firstName' || key === 'lastName') {
      this.setState({ [key]: value.trim() })
    } else {
      this.setState({ [key]: value })
    }
  }

  validate = () => {
    const { phoneNumber } = this.state
    let err = false
    let errorText = ''
    const value = String(phoneNumber).replace(/\D/g, '')
    if (!value || value.length < 10) {
      err = true
      errorText = "Enter manager's phone number"
    }
    return { isError: err, errorText }
  }

  onUpdateManager = (firstName, lastName, phoneNumber, userId) => {
    const { updateUser, onCancel } = this.props
    updateUser({
      variables: {
        input: {
          firstName,
          lastName,
          phoneNumber,
          userId
        }
      },
      update: (
        cache,
        {
          data: {
            updateManager: { message, user: userInfo }
          }
        }
      ) => {
        try {
          if (!message) {
            const memberInfo = cache.readQuery({
              query: fetchManager,
              variables: { input: { id: userId } }
            })
            cache.writeQuery({
              query: fetchManager,
              variables: { input: { id: userId } },
              data: { User: { ...memberInfo.User, ...userInfo } }
            })
          }
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(data => {
      this.setState({ isLoading: false })
      if (data?.data?.updateManager?.message) {
        Snackbar(data.data.updateManager.message, 'error', 3000)
      } else {
        Snackbar('Successfully updated manager information!', 'success', 2000)
        onCancel()
      }
    })
  }

  onCreateManager = (corporationId, firstName, lastName, phoneNumber) => {
    const { createNewManager } = this.props
    createNewManager({
      variables: {
        input: {
          corporationId,
          firstName,
          lastName,
          phoneNumber
        }
      },
      update: (
        cache,
        { data: { createManagerInCorporation: newSupervisor } }
      ) => {
        const managerData = {
          id: newSupervisor?.user?.id,
          firstName: newSupervisor?.user?.firstName,
          lastName: newSupervisor?.user?.lastName,
          phoneNumber: newSupervisor?.user?.phoneNumber,
          __typename: 'User'
        }
        try {
          const { getManagersByCorporationId } = cache.readQuery({
            query: getSupervisor,
            variables: { corporationId }
          })
          getManagersByCorporationId.push(managerData)
          cache.writeQuery({
            query: getSupervisor,
            variables: { corporationId },
            data: { getManagersByCorporationId }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(data => {
      this.setState({ isLoading: false })
      if (data?.data?.createManagerInCorporation?.message)
        Snackbar(data.data.createManagerInCorporation.message, 'error', 3000)
      else {
        this.onDeleteManager()
        Snackbar('Manager Saved', 'success', 2000)
      }
    })
  }

  onNextClick = () => {
    const { firstName, lastName } = this.state
    let { phoneNumber } = this.state
    const { getShift, supervisorId, isEdit } = this.props
    const {
      workplace: {
        corporation: { id: corporationId }
      }
    } = getShift.currentShift
    const convertedPhoneNumber = String(phoneNumber).replace(/\D/g, '')
    const isFirstNameEmpty = firstName === ''
    const isLastNameEmpty = lastName === ''
    const isPhoneNumberValid =
      phoneNumber === '' || convertedPhoneNumber.length < 10

    if (isFirstNameEmpty || isLastNameEmpty || isPhoneNumberValid) {
      this.setState({
        error: {
          firstName: isFirstNameEmpty,
          lastName: isLastNameEmpty,
          validNumber: isPhoneNumberValid
        }
      })
      Snackbar(`Please enter manager's name before continuing.`, 'error', 2000)
    } else if (this.errorCheck()) {
      phoneNumber = Number(convertedPhoneNumber)

      this.setState({ isLoading: true })
      if (String(phoneNumber).length === 10) phoneNumber = `1${phoneNumber}`
      if (isEdit) {
        this.onUpdateManager(
          firstName,
          lastName,
          Number(phoneNumber),
          supervisorId
        )
      } else {
        this.onCreateManager(
          corporationId,
          firstName,
          lastName,
          Number(phoneNumber)
        )
      }
    }
  }

  errorCheck = () => {
    const { firstName, lastName, phoneNumber } = this.state
    const num = String(phoneNumber).replace(/\D/g, '')
    if (
      !/^[a-zA-Z][a-zA-Z\s-]{0,30}$/.test(firstName) ||
      firstName.trim().length === 0
    ) {
      Snackbar('Must enter valid first name', 'error', 3000)
      return false
    }
    if (
      !/^[a-zA-Z][a-zA-Z\s-]{0,30}$/.test(lastName) ||
      lastName.trim().length === 0
    ) {
      Snackbar('Must enter valid last name', 'error', 3)
      return false
    }
    if (String(num).length < 10) {
      this.setState({
        error: {
          validNumber: true
        }
      })
      Snackbar('Must enter valid phone number', 'error', 3000)
      return false
    }
    return true
  }

  onDeleteManager = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { isEdit, onCancel, children } = this.props
    const { firstName, lastName, phoneNumber, isLoading, error } = this.state
    return (
      <ManagerFormComponent
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        onNextClick={this.onNextClick}
        onChange={this.onChange}
        onDelete={isEdit ? onCancel : this.onDeleteManager}
        isLoading={isLoading}
        error={error}
        isEdit={isEdit}
        validate={this.validate}
      >
        {children}
      </ManagerFormComponent>
    )
  }
}

export const ManagerFormApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(createManager, { name: 'createNewManager' }),
  graphql(updateManager, { name: 'updateUser' })
)(ManagerForm)

ManagerForm.propTypes = propTypes
ManagerForm.defaultProps = defaultProps
export default ManagerFormApollo
