import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { Dialog, Checkbox, Typography } from 'syrg-design-kit'
import styles from './style.css'

const propTypes = {
  showManagersModal: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  notifyManagers: PropTypes.arrayOf(PropTypes.number),
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.number
    })
  )
}

const defaultProps = {
  notifyManagers: [],
  managers: []
}

export class NotifyManagersModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedManagers: cloneDeep(props.notifyManagers) }
  }

  onCheckChange = phoneNumber => {
    const { selectedManagers } = this.state
    const foundIndex = selectedManagers.findIndex(
      phone => phone === phoneNumber
    )
    if (foundIndex >= 0) selectedManagers.splice(foundIndex, 1)
    else if (foundIndex === -1) selectedManagers.push(phoneNumber)
    this.setState({ selectedManagers })
  }

  render() {
    const { showManagersModal, onDoneClick, managers } = this.props
    const { selectedManagers } = this.state
    return (
      <Dialog
        open
        onClose={showManagersModal}
        onCancel={showManagersModal}
        title="Select managers"
        onOk={() => onDoneClick(selectedManagers)}
        okText="Done"
      >
        <Typography variant="body2">
          The selected managers get texted about this shift request.
        </Typography>
        <div className={styles.managers_list}>
          {managers?.map(v => (
            <div
              className={styles.managers_list_content}
              key={v.phoneNumber}
              onClick={() => this.onCheckChange(v.phoneNumber)}
              role="presentation"
            >
              <Checkbox checked={selectedManagers.includes(v.phoneNumber)} />
              <Typography variant="h3">
                {v.firstName} {v.lastName}
              </Typography>
            </div>
          ))}
        </div>
      </Dialog>
    )
  }
}

NotifyManagersModalComponent.propTypes = propTypes
NotifyManagersModalComponent.defaultProps = defaultProps

export default NotifyManagersModalComponent
