import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import ChangePasswordComponent from '../../components/change-password'
import authentication from '../../authentication'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  history: { push: () => {} }
}

export class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pass: '',
      newPass: '',
      confirmPass: '',
      passError: '',
      newPassError: '',
      confirmPassError: ''
    }
  }

  onChange = (key, value) => {
    this.setState({ [key]: value })
    switch (key) {
      case 'pass':
        this.setState({ passError: '' })
        break
      case 'newPass':
        this.setState({ newPassError: '', confirmPassError: '' })
        break
      case 'confirmPass':
        this.setState({ confirmPassError: '' })
        break
      default:
        break
    }
  }

  newPassValidation = () => {
    const { pass, newPass } = this.state
    if (newPass.length < 8) return 'Password should be at least 8 characters.'
    if (pass === newPass)
      return 'New password can not be the same as previous password.'
    if (
      newPass.length >= 8 &&
      (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(newPass) ||
        newPass.trim().length === 0)
    )
      return 'Password too weak. Please include a number, a lower and upper case letter, and a special character. Password should be at least 8 characters.'
    return ''
  }

  onSetPassword = async () => {
    const { pass, newPass, confirmPass } = this.state
    const { history } = this.props
    const validation = this.newPassValidation()
    if (pass === '') {
      Snackbar('Please enter your current password.', 'error', 3000)
      this.setState({ passError: 'Please enter your current password.' })
    } else if (newPass === '') {
      Snackbar('Please enter your new password.', 'error', 3000)
      this.setState({ newPassError: 'Please enter your new password.' })
    } else if (confirmPass === '') {
      Snackbar('Please enter confirm password.', 'error', 3000)
      this.setState({ confirmPassError: 'Please enter confirm password.' })
    } else if (validation !== '') {
      this.setState({
        newPassError: validation
      })
      Snackbar(validation, 'error', 3000)
    } else if (confirmPass !== newPass) {
      this.setState({ confirmPassError: 'Confirm password is not matching' })
      Snackbar('Confirm password is not same as new password', 'error', 3000)
    } else {
      const { response, error } = await authentication.changePassword(
        pass,
        newPass
      )
      if (error) {
        if (
          error === 'Incorrect username or password.' ||
          error.includes('validation error')
        ) {
          Snackbar('Wrong password', 'error', 3000)
          this.setState({ passError: 'Wrong password' })
        } else Snackbar(error, 'warning', 3000)
      }
      if (response) {
        history.push('/updatePasswordSuccess')
        Snackbar('Successfully changed password!', 'success', 3000)
      }
    }
  }

  render() {
    const { history } = this.props
    const {
      pass,
      newPass,
      confirmPass,
      confirmPassError,
      passError,
      newPassError
    } = this.state
    return (
      <ChangePasswordComponent
        history={history}
        onSetPassword={this.onSetPassword}
        pass={pass}
        newPass={newPass}
        confirmPass={confirmPass}
        onChange={this.onChange}
        passError={passError}
        newPassError={newPassError}
        confirmPassError={confirmPassError}
      />
    )
  }
}

ChangePassword.propTypes = propTypes
ChangePassword.defaultProps = defaultProps

export default ChangePassword
