import { cloneDeep, flowRight as compose } from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { beacon } from 'quala'
import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Snackbar } from 'syrg-design-kit'
import {
  clearRemoveCallList,
  removedUserCallList,
  resetProgress
} from '../../apollo/apollo-cache-mutation.gql'
import {
  getCurrentShift,
  removeCallList
} from '../../apollo/apollo-cache-query.gql'
import { Loader } from '../../components/controls/loader'
import { ShiftSummaryComponent } from '../../components/shift-summary'
import { callPaceConverter } from '../../utils/call-pace-converter'
import {
  convertToAmPm,
  formattedTime,
  timeCalculationOfShift
} from '../../utils/time-formatter'
import { getAllShifts } from '../open-shifts/open-shift.gql'
import { getFutureShift } from '../upcoming-shifts/upcoming-shifts.gql'
import { createShift, getCallList } from './shift-summary.gql'

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  removedList: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  removeUser: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  createShiftMutation: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  callList: PropTypes.shape({
    loading: PropTypes.bool,
    userToCall: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        unavailable: PropTypes.bool,
        removed: PropTypes.bool,
        reasonRemovedByManager: PropTypes.string,
        ranking: PropTypes.number,
        imageUrl: PropTypes.string,
        overtimeReasons: PropTypes.array
      })
    ),
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    callList: PropTypes.shape({
      shiftId: PropTypes.string,
      runNumber: PropTypes.number,
      userToCall: PropTypes.arrayOf(
        PropTypes.shape({
          userId: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          unavailable: PropTypes.bool,
          removed: PropTypes.bool,
          reasonRemovedByManager: PropTypes.string,
          ranking: PropTypes.number,
          imageUrl: PropTypes.string,
          overtimeReasons: PropTypes.array
        })
      )
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  resetShiftProgress: PropTypes.func.isRequired,
  resetCallList: PropTypes.func.isRequired
}

const defaultProps = {
  createShiftMutation: () => {},
  removedList: {
    recipientListDrawer: {
      users: []
    }
  },
  removeUser: {},
  getShift: {
    currentShift: {
      manager: { id: '' },
      workplace: { id: '' },
      restartPhoneTree: false,
      notes: '',
      minBetweenCall: '1',
      position: { id: '', name: '' },
      location: { id: '', name: '', message: '' },
      startDate: '',
      startTime: '',
      endTime: '',
      callOutUserId: '',
      employeeCount: 0
    }
  },
  callList: {
    loading: false,
    error: [],
    userToCall: [],
    callList: {
      userToCall: []
    }
  },
  history: { push: () => {} }
}

export class ShiftSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allUsers: [],
      isUserRemoved: true,
      isRequestStarted: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { allUsers } = prevState
    const {
      callList: { callList }
    } = nextProps
    if (!nextProps.callList.loading && !nextProps.callList.error) {
      if (callList?.userToCall !== allUsers) {
        allUsers = callList && cloneDeep(callList.userToCall)
        const removedUsers = allUsers.filter(x => x.removed)
        if (removedUsers.length > 0 && prevState.isUserRemoved) {
          ShiftSummary.setRemovedUsers(
            removedUsers,
            nextProps.removedList,
            nextProps.removeUser
          )
        }
        return {
          allUsers
        }
      }
    }
    return null
  }

  static setRemovedUsers = (removedMember, removedList, removeUser) => {
    const { users: removedUsers } = removedList.recipientListDrawer
    removedMember.forEach(user => {
      const index = removedUsers.findIndex(
        item => item.removedId === user.userId
      )
      if (index === -1) {
        removeUser({
          variables: {
            removedId: user.userId,
            ranking: user.ranking,
            reason: user.reasonRemovedByManager
          }
        })
      }
    })
  }

  getRemovedUser = () => {
    const removedUsers = []
    const {
      removedList: {
        recipientListDrawer: { users }
      }
    } = this.props
    users.forEach(user => {
      removedUsers.push({
        userId: user.removedId,
        reasonRemovedByManager: user.reason
      })
    })
    return JSON.stringify(removedUsers)
  }

  shouldUserRemoved = bool => {
    this.setState({ isUserRemoved: bool })
  }

  trackRequestAction = (type, managerId, workplaceId) => {
    const signal = type === 'restart' ? 'Request restarted' : 'Request started'
    beacon.track({
      signal,
      userId: `${managerId}-${workplaceId}`
    })
  }

  onDelete = () => {
    const { resetShiftProgress, resetCallList, history } = this.props
    resetShiftProgress()
    resetCallList()
    history.push('/')
  }

  onBack = () => {
    const { history, getShift } = this.props
    const { restartPhoneTree } = getShift.currentShift
    if (restartPhoneTree) history.push('/shiftDetail')
    else history.push('/newShift')
  }

  onRestartRequest = () => {
    this.setState({ isRequestStarted: true }, () => {
      this.createRequest('restart')
    })
  }

  startRequest = () => {
    this.setState({ isRequestStarted: true }, () => {
      this.createRequest('start')
    })
  }

  createRequest = type => {
    const {
      getShift,
      createShiftMutation,
      history,
      resetShiftProgress,
      resetCallList,
      callList: {
        callList: { shiftId, runNumber }
      }
    } = this.props
    const {
      startTime,
      endTime,
      startDate,
      employeeCount,
      notes,
      spanishNotes,
      minBetweenCall,
      // assignedUserCount,
      manager: { id: requesterId },
      notifyManagers,
      position: {
        id: positionId,
        name: positionName,
        color: positionColor,
        alias: positionAlias
      },
      workplace: { id: workplaceId, timezone, settings },
      location: { id: locationId, name: locationName, alias: locationAlias },
      replacedUsers,
      callOutUserId: callOut
    } = getShift.currentShift
    let callOutUserId = callOut
    if (callOutUserId === '') callOutUserId = null
    const toTimeStamp = (day, time, tz) =>
      moment.tz(`${day} ${time}`, 'MMMM D, YYYY h:mma', tz).unix()
    const startTimeStamp = toTimeStamp(startDate, startTime, timezone)
    let endTimeStamp = toTimeStamp(startDate, endTime, timezone)
    endTimeStamp =
      endTimeStamp < startTimeStamp ? endTimeStamp + 86400 : endTimeStamp
    const notesString = {
      EN: notes,
      ES: spanishNotes
    }
    const settingsObj = settings && JSON.parse(settings)
    const { minBetweenCall: minutes, minSec } = callPaceConverter(
      minBetweenCall,
      settingsObj
    )
    const contactTimeout = minutes * 60 + minSec
    const phonesToNotify = notifyManagers.map(x => String(x))
    createShiftMutation({
      variables: {
        shiftId,
        replacedUsers,
        runNumber,
        removedUsers: this.getRemovedUser(),
        teamMembersRequiredCount: employeeCount,
        requesterId,
        contactTimeout,
        shiftInstruction: notesString,
        phonesToNotify
      },
      update: (
        cache,
        { data: { CreateShiftAndContactEmployees: newShift } }
      ) => {
        const shiftData = {
          id: newShift?.id,
          startTime: startTimeStamp?.toString(),
          endTime: endTimeStamp?.toString(),
          replacedUsers,
          teamMemberRequiredCount: employeeCount || 0,
          position: {
            id: positionId,
            name: positionName,
            color: positionColor,
            alias: positionAlias
          },
          department: {
            id: locationId,
            name: locationName,
            alias: locationAlias
          },
          assignedUsers: newShift?.assignedUsers,
          latestShiftPoll: newShift?.latestShiftPoll,
          workplace: { id: workplaceId },
          __typename: 'Shift'
        }

        try {
          const { getShiftsByWorkplace } = cache.readQuery({
            query: getAllShifts,
            variables: { workplaceId }
          })
          let newShifts = getShiftsByWorkplace
          if (!newShifts) {
            newShifts = []
          }
          newShifts.push(shiftData)
          cache.writeQuery({
            query: getAllShifts,
            variables: { workplaceId },
            data: { getShiftsByWorkplace: newShifts }
          })
        } catch (error) {
          console.info('error', error)
        }
        try {
          const { UpcomingShifts } = cache.readQuery({
            query: getFutureShift,
            variables: { workplaceId }
          })
          let newUpcomingShifts = UpcomingShifts
          if (!newUpcomingShifts) {
            newUpcomingShifts = []
          }
          newUpcomingShifts.push(shiftData)
          cache.writeQuery({
            query: getFutureShift,
            variables: { workplaceId },
            data: { UpcomingShifts: newUpcomingShifts }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    })
      .then(() => {
        if (type === 'restart') Snackbar('Phone tree restarted!', 'success')
        else Snackbar('Shift successfully created!', 'success')
        this.setState({ isRequestStarted: false }, () => {
          resetShiftProgress()
          resetCallList()
          history.push('/')
        })
        this.trackRequestAction(type, requesterId, workplaceId)
      })
      .catch(() => {
        this.setState({ isRequestStarted: false })
      })
  }

  render() {
    const { getShift, callList, removedList } = this.props
    if (
      getShift.loading ||
      !getShift.currentShift ||
      (!callList && callList.userToCall) ||
      removedList.loading
    ) {
      return <Loader />
    }
    const { allUsers, isRequestStarted } = this.state
    const {
      location: { name: deptName },
      position: { name: positionName },
      startDate,
      startTime,
      endTime,
      employeeCount,
      restartPhoneTree
    } = getShift?.currentShift
    const timeLeft = timeCalculationOfShift(
      moment(`${startDate} ${startTime}`, 'MMMM D, YYYY h:mma').unix()
    )
    return (
      <ShiftSummaryComponent
        deptName={deptName}
        positionName={positionName}
        startDate={startDate}
        startTime={convertToAmPm(startTime)}
        endTime={convertToAmPm(endTime)}
        employeeCount={employeeCount}
        timeLeft={timeLeft}
        onBack={this.onBack}
        onDelete={this.onDelete}
        shouldUserRemoved={this.shouldUserRemoved}
        restartPhoneTree={restartPhoneTree}
        allCallListUsers={allUsers}
        closeEditMode={this.closeEditMode}
        onRestartRequest={this.onRestartRequest}
        onStartRequest={this.startRequest}
        isRequestStarted={isRequestStarted}
        loading={callList?.loading}
      />
    )
  }
}

export const ShiftSummaryContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(removeCallList, { name: 'removedList' }),
  graphql(resetProgress, { name: 'resetShiftProgress' }),
  graphql(removedUserCallList, { name: 'removeUser' }),
  graphql(clearRemoveCallList, { name: 'resetCallList' }),
  graphql(getCallList, {
    skip: ({ getShift }) =>
      !getShift.currentShift ||
      !getShift.currentShift.workplace.id ||
      !getShift.currentShift.workplace.corporation ||
      !getShift.currentShift.workplace.timezone ||
      !getShift.currentShift.startTime,
    options: ({ getShift }) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        shiftId: getShift.currentShift.shiftId
          ? getShift.currentShift.shiftId
          : null,
        workplaceId: getShift?.currentShift?.workplace?.id,
        positionId: getShift?.currentShift?.position?.id,
        departmentId: getShift?.currentShift?.location?.id,
        corporationId: getShift?.currentShift?.workplace?.corporation?.id,
        timezone: getShift?.currentShift?.workplace?.timezone,
        startTime: formattedTime(getShift.currentShift, 'start'),
        endTime: formattedTime(getShift.currentShift, 'end'),
        callOutUserId: getShift.currentShift.callOutUserId
      }
    }),
    name: 'callList'
  }),
  graphql(
    createShift,
    { name: 'createShiftMutation' },
    {
      props: ({ mutate }) => ({
        createShift: ({
          shiftId,
          replacedUsers,
          removedUsers,
          runNumber,
          teamMembersRequiredCount,
          requesterId,
          contactTimeout,
          phonesToNotify,
          shiftInstruction
        }) =>
          mutate({
            variables: {
              shiftId,
              replacedUsers,
              removedUsers,
              runNumber,
              requesterId,
              teamMembersRequiredCount,
              contactTimeout,
              phonesToNotify,
              shiftInstruction
            }
          })
      })
    }
  )
)(ShiftSummary)

ShiftSummary.propTypes = propTypes
ShiftSummary.defaultProps = defaultProps

export default withRouter(ShiftSummaryContainer)
