import gql from 'graphql-tag'

export const fetchWorkplaceLocations = gql`
  query getDepartmentsByWorkplaceId($id: ID!) {
    getDepartmentsByWorkplaceId(input: { workplaceId: $id }) {
      id
      name
      alias
      addressJson
    }
  }
`
export default fetchWorkplaceLocations
