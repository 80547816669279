import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import {
  getCurrentShift,
  getPosition
} from '../../apollo/apollo-cache-query.gql'
import NamePositionComponent from '../../components/name-position'
import {
  updatePositionName,
  resetPosition
} from '../../apollo/apollo-cache-mutation.gql'
import {
  renamePositionMutation,
  fetchWorkplacePositions
} from './name-position.gql'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  resetPositionInfo: PropTypes.func,
  getPositions: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  updateNamePosition: PropTypes.func,
  renamePosition: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getWorkplacePositions: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  history: { push: () => {} },
  updateNamePosition: () => {},
  resetPositionInfo: () => {},
  renamePosition: () => {},
  getWorkplacePositions: {
    Workplace: {
      positions: []
    }
  }
}

export class NamePosition extends Component {
  onReset = () => {
    const { history, resetPositionInfo } = this.props
    resetPositionInfo()
    history.push('/positions')
  }

  onChange = value => {
    const { updateNamePosition } = this.props
    if (this.checkValidation(value)) {
      updateNamePosition({
        variables: {
          name: value
        }
      })
    }
  }

  isPositionNameExist = () => {
    const { getWorkplacePositions, getPositions } = this.props
    if (getWorkplacePositions?.Workplace) {
      const { positions } = getWorkplacePositions.Workplace
      const positionName = positions?.map(position => {
        return position.name?.toLowerCase()
      })
      return positionName.includes(
        getPositions.newPosition.name.toLowerCase().trim()
      )
    }
    return false
  }

  onNextClick = () => {
    const {
      getPositions: { newPosition: { name = '' } } = {},
      history
    } = this.props
    if (!name) {
      Snackbar('Please enter position name before continuing', 'error')
    } else if (this.isPositionNameExist()) {
      Snackbar('Position name already exists', 'warning')
    } else {
      history.push('/positionMember')
    }
  }

  onSaveClick = () => {
    const { renamePosition, getPositions, getWorkplacePositions } = this.props
    const { id: positionId, name } = getPositions.newPosition
    const { positions } = getWorkplacePositions.Workplace
    const positionName = positions.find(pos => pos.id === positionId).name
    if (name.trim() === positionName.trim()) {
      this.onReset()
    } else if (
      name.toLowerCase().trim() === positionName.toLowerCase().trim() ||
      !this.isPositionNameExist()
    ) {
      // TODO: renaming position from backend
      console.info(name.trim(), renamePosition)
      this.onReset()
    } else if (
      name.trim() !== positionName.trim() &&
      this.isPositionNameExist()
    ) {
      Snackbar('Position name already exists', 'warning')
    }
  }

  checkValidation = value => {
    const {
      getPositions: { newPosition: { name = '', isEdit = false } } = {}
    } = this.props
    const stringToTrim = isEdit ? name.trim() : value.trim()
    if (!/^[\w\s]{0,}$/.test(stringToTrim.trim())) {
      Snackbar('Special characters are not allowed in position name', 'error')
      return false
    }
    return true
  }

  render() {
    const { history, getPositions } = this.props
    const { name, isEdit } = getPositions?.newPosition
    return (
      <NamePositionComponent
        history={history}
        positionName={name}
        onChange={this.onChange}
        onSubmit={isEdit ? this.onSaveClick : this.onNextClick}
        onReset={this.onReset}
        isEdit={isEdit}
      />
    )
  }
}
export const NamePositionApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(resetPosition, { name: 'resetPositionInfo' }),
  graphql(getPosition, { name: 'getPositions' }),
  graphql(fetchWorkplacePositions, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.workplace?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplacePositions'
  }),
  graphql(updatePositionName, { name: 'updateNamePosition' }),
  graphql(renamePositionMutation, {
    name: 'renamePosition'
  })
)(NamePosition)

NamePosition.propTypes = propTypes
NamePosition.defaultProps = defaultProps

export default NamePositionApollo
