import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, Dialog, Button } from 'syrg-design-kit'
import { PageLayout } from '../layouts/page-layout'
import TeamMemberTable from '../../containers/team-member-table'
import style from './style.css'
import { Header } from '../theme'
import { FlexBody } from '../layouts/flex-body'
import { FooterComponent } from '../layouts/footer-navigation'
import FooterButton from '../layouts/footer-button'

const propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onBackClick: PropTypes.func,
  loading: PropTypes.bool,
  positionName: PropTypes.string.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateSelectedUsers: PropTypes.func.isRequired,
  isRestricted: PropTypes.bool
}

const defaultProps = {
  onBackClick: () => {},
  loading: false,
  isRestricted: false
}

export class PositionMembersComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteModalOpen: false
    }
  }

  onModalClick = () => {
    const { isDeleteModalOpen } = this.state
    this.setState({
      isDeleteModalOpen: !isDeleteModalOpen
    })
  }

  render() {
    const { isDeleteModalOpen } = this.state
    const {
      onDeleteClick,
      onSubmit,
      isEdit,
      onBackClick,
      loading,
      positionName,
      selectedUsers,
      updateSelectedUsers,
      isRestricted
    } = this.props

    return (
      <PageLayout>
        <Header leftText={false} />
        <FlexBody>
          {isEdit && (
            <div className={style.position_info_wrapper}>
              <Typography variant="h2">Position Details</Typography>
              <div className={style.position_info}>
                <Typography variant="h3">{positionName}</Typography>
                {!isRestricted && (
                  <div data-id="delete-position">
                    <Button
                      size="small"
                      onClick={this.onModalClick}
                      color="secondary"
                    >
                      Delete Position
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={style.position_head_text_wrapper}>
            {!isEdit && (
              <Typography variant="h2">Create New Position</Typography>
            )}
            <Typography variant="h4" color="primary">
              Who works this position?
            </Typography>
            {isEdit && (
              <Typography variant="body2">
                Select employees that will be contacted to work this position.
              </Typography>
            )}
          </div>
          <TeamMemberTable
            onSubmit={onSubmit}
            isCheckBoxes
            submitButtonText={isEdit ? 'Save' : 'Create Position'}
            onBackClick={onBackClick}
            loading={loading}
            isSearchFixed={false}
            updateSelectedUsers={updateSelectedUsers}
            selectedUsers={selectedUsers}
          />
        </FlexBody>
        {isRestricted ? (
          <FooterButton onClick={onBackClick}>Back to Positions</FooterButton>
        ) : (
          <FooterComponent
            onBackClick={onBackClick}
            isLoading={loading}
            backText="Cancel"
            onNextClick={() => onSubmit(selectedUsers)}
            nextText="Save Changes"
          >
            {isEdit ? 'Save' : 'Create Position'}
          </FooterComponent>
        )}
        <Dialog
          open={isDeleteModalOpen}
          onCancel={this.onModalClick}
          onOk={onDeleteClick}
          okText="Delete"
          title="Delete Position?"
          onClose={this.onModalClick}
        >
          If you delete this postion there will be no employees assigned to this
          position. This cannot be undone.
        </Dialog>
      </PageLayout>
    )
  }
}

PositionMembersComponent.propTypes = propTypes
PositionMembersComponent.defaultProps = defaultProps

export default PositionMembersComponent
