import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose, sortBy } from 'lodash'
import { Snackbar } from 'syrg-design-kit'
import UpdateLocationComponent from '../../components/update-location'
import {
  fetchLocationInfo,
  updateDepartmentMutation
} from '../location-details/location-info.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { getSupervisor } from '../add-supervisor/add-supervisor.gql'

const propTypes = {
  getSupervisorData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onLocationEdit: PropTypes.func,
  updateDepartment: PropTypes.func,
  children: PropTypes.node,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string,
    alias: PropTypes.string,
    city: PropTypes.string,
    zipcode: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    supervisorId: PropTypes.string,
    message: PropTypes.string,
    isLocationIfoEdit: PropTypes.bool,
    isEditEligible: PropTypes.bool,
    employees: PropTypes.array,
    addressJson: PropTypes.string,
    contactUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.string,
      phoneNumber: PropTypes.number
    })
  })
}

const defaultProps = {
  getSupervisorData: {},
  onLocationEdit: () => {},
  updateDepartment: () => {},
  children: <></>,
  getShift: {},
  location: {}
}

export class UpdateLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.location?.id,
      alias: props.location?.alias,
      locationName: props.location?.name,
      street: JSON.parse(props.location?.addressJson).street,
      city: JSON.parse(props.location?.addressJson).city,
      postalCode: JSON.parse(props.location?.addressJson).postalCode,
      state: JSON.parse(props.location?.addressJson).state,
      supervisorId: props?.location?.contactUser?.id,
      locationMessage: props?.location?.message,
      error: {
        name: false,
        street: false,
        city: false,
        zipcode: false,
        state: false
      }
    }
  }

  onChange = (value, key) => {
    this.setState({
      [key]: value
    })
    if (key === 'state') {
      this.setState({
        error: {
          state: value === 'Select a state'
        }
      })
    }
    if (key === 'supervisorId') {
      this.setState({
        error: {
          state: value === 'Select supervisor'
        }
      })
    }
  }

  onSave = () => {
    const { updateDepartment, onLocationEdit, getShift } = this.props
    const {
      id,
      locationName,
      alias,
      street,
      city,
      state,
      postalCode,
      locationMessage,
      supervisorId
    } = this.state

    updateDepartment({
      variables: {
        input: {
          id,
          name: locationName,
          alias: alias === null ? '' : alias,
          message: locationMessage === null ? '' : locationMessage,
          addressJson: JSON.stringify({
            street,
            city,
            state,
            postalCode
          }),
          contactUserId: supervisorId
        }
      },
      update: cache => {
        try {
          const supervisorssList = cache.readQuery({
            query: getSupervisor,
            variables: {
              corporationId: getShift?.currentShift?.workplace?.corporation?.id
            }
          })
          const supervisorObj = supervisorssList?.getManagersByCorporationId?.find(
            supevisor => supevisor.id === supervisorId
          )
          const department = cache.readQuery({
            query: fetchLocationInfo,
            variables: { id }
          })
          department.Department.name = locationName
          department.Department.alias = alias
          department.Department.message = locationMessage
          department.Department.addressJson = JSON.stringify({
            street,
            city,
            state,
            postalCode
          })
          department.Department.contactUser.id = supervisorObj.id
          department.Department.contactUser.firstName = supervisorObj.firstName
          department.Department.contactUser.lastName = supervisorObj.lastName
          department.Department.contactUser.phoneNumber =
            supervisorObj.phoneNumber
          cache.writeQuery({
            query: fetchLocationInfo,
            variables: { id },
            data: { Department: department.Department }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(() => {
      Snackbar('Location updated successfully', 'success', 3000)
      onLocationEdit()
    })
  }

  render() {
    const {
      error,
      locationName,
      street,
      city,
      postalCode,
      state,
      supervisorId,
      locationMessage
    } = this.state
    const { getSupervisorData, onLocationEdit, children } = this.props
    const supervisors = sortBy(
      getSupervisorData?.getManagersByCorporationId,
      x => x.firstName
    )
    return (
      <UpdateLocationComponent
        location={{
          locationName,
          street,
          city,
          postalCode,
          state,
          supervisorId,
          locationMessage
        }}
        onLocationEdit={onLocationEdit}
        supervisors={supervisors}
        error={error}
        onSave={this.onSave}
        onChange={this.onChange}
        onLocationChange={this.onLocationChange}
      >
        {children}
      </UpdateLocationComponent>
    )
  }
}

const UpdateLocationContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(getSupervisor, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.corporation.id,
    options: ({ getShift }) => ({
      variables: {
        corporationId: getShift?.currentShift?.workplace?.corporation?.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getSupervisorData'
  }),
  graphql(updateDepartmentMutation, { name: 'updateDepartment' })
)(UpdateLocation)

UpdateLocation.propTypes = propTypes
UpdateLocation.defaultProps = defaultProps
export default UpdateLocationContainer
