import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup, TextField } from 'syrg-design-kit'
import styles from './style.css'

const propTypes = {
  onMinChange: PropTypes.func,
  selectedMin: PropTypes.string,
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fastTime: PropTypes.string,
  normalTime: PropTypes.string
}

const defaultProps = {
  onMinChange: () => {},
  selectedMin: 'Fast',
  minutes: '',
  normalTime: '',
  fastTime: ''
}

export class CallPaceComponent extends Component {
  render() {
    const {
      onMinChange,
      selectedMin,
      minutes,
      normalTime,
      fastTime
    } = this.props
    return (
      <div className={styles.callPaceRadio}>
        <RadioGroup
          name="call-pace"
          onChange={(e, val) => onMinChange('selectedMin', val, minutes)}
          value={selectedMin}
          data-id="pace"
        >
          <Radio
            label={`Fast ${fastTime}`}
            value="Fast"
            checked={selectedMin === 'Fast'}
          />
          <Radio
            label={`Normal ${normalTime}`}
            value="Normal"
            checked={selectedMin === 'Normal'}
          />
          <span className={styles.radioDrop}>
            <Radio label="" value="Custom" checked={selectedMin === 'Custom'} />
            <TextField
              data-id="minDuration"
              value={minutes}
              type="number"
              label="Custom"
              onChange={e => onMinChange('minutes', minutes, e.target.value)}
            />
          </span>
        </RadioGroup>
      </div>
    )
  }
}

CallPaceComponent.propTypes = propTypes
CallPaceComponent.defaultProps = defaultProps

export default CallPaceComponent
