import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { sortBy, flowRight as compose } from 'lodash'
import { fetchWorkplace } from '../select-workplace-header/workplace-header.gql'
import {
  getCurrentShift,
  getLocation,
  getTeamMember
} from '../../apollo/apollo-cache-query.gql'
import {
  updateWorkplaceId,
  updatePrimaryWorkplace
} from '../../apollo/apollo-cache-mutation.gql'
import DropDownComponent from '../../components/theme/dropdown'

const propTypes = {
  getWorkplaces: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  getShift: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  updateWorkplace: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  updatePrimaryWorkplaceId: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  getLocationData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onSelectWorkplaceError: PropTypes.func,
  defaultValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  getTeamMemberInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  error: PropTypes.bool
}

const defaultProps = {
  getWorkplaces: {},
  getShift: () => {},
  getLocationData: {},
  updateWorkplace: {},
  updatePrimaryWorkplaceId: {},
  onSelectWorkplaceError: () => {},
  getTeamMemberInfo: { teamMember: { page: 1 } },
  helperText: '',
  error: false
}

export class WorkplaceDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      primaryArea: '',
      primaryAreas: [],
      disableError: false,
      error: {
        primaryArea: false
      }
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      getLocationData,
      getWorkplaces,
      getShift,
      error,
      helperText,
      getTeamMemberInfo
    } = nextProps
    const { teamMember: { primaryWorkplace } = {} } = getTeamMemberInfo
    let disableError = false
    let workPlaceName
    const { currentShift: { workplace } = {} } = getShift
    const { User: { managedWorkplaces } = {} } = getWorkplaces
    const { location: { primaryArea } = {} } = getLocationData
    const areaData = sortBy(managedWorkplaces, x => x.name).filter(
      y => y?.corporation?.id === workplace?.corporation?.id
    )
    const workplaceData = areaData.filter(t => t.id === primaryArea)
    workPlaceName = (workplaceData.length > 0 && workplaceData[0].name) || ''
    if (helperText === "Employee's main location") {
      if (primaryWorkplace) {
        workPlaceName = areaData.filter(t => t.id === primaryWorkplace)
        workPlaceName =
          (workPlaceName.length > 0 && workPlaceName[0].name) || ''
      } else {
        workPlaceName = workplace?.name || ''
      }
      disableError = true
    }
    return {
      primaryArea: workPlaceName || '',
      primaryAreas: areaData,
      error: {
        primaryArea: error
      },
      disableError
    }
  }

  workPlaceChange = (workPlaceId, value, defaultValue) => {
    const {
      updateWorkplace,
      onSelectWorkplaceError,
      updatePrimaryWorkplaceId
    } = this.props
    this.setState({ primaryArea: workPlaceId })
    if (defaultValue) {
      onSelectWorkplaceError(false, value.key)
      updateWorkplace({
        variables: {
          workplaceId: value.key
        }
      })
    }
    if (defaultValue === '') {
      updatePrimaryWorkplaceId({
        variables: {
          primaryWorkplace: value.key
        }
      })
    }
  }

  render() {
    const { error, primaryArea, primaryAreas, disableError } = this.state
    const { defaultValue, label, helperText } = this.props
    return (
      <DropDownComponent
        dataId={primaryArea || defaultValue}
        data={primaryAreas || []}
        onChange={this.workPlaceChange}
        error={error.primaryArea}
        label={label}
        disableError={disableError}
        helperText={helperText}
        defaultValue={defaultValue}
      />
    )
  }
}
const WorkplaceDropdownContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(getTeamMember, { name: 'getTeamMemberInfo' }),
  graphql(updateWorkplaceId, { name: 'updateWorkplace' }),
  graphql(updatePrimaryWorkplace, { name: 'updatePrimaryWorkplaceId' }),
  graphql(getLocation, { name: 'getLocationData' }),
  graphql(fetchWorkplace, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.manager.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.manager.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplaces'
  })
)(WorkplaceDropdown)

WorkplaceDropdown.propTypes = propTypes
WorkplaceDropdown.defaultProps = defaultProps

export default WorkplaceDropdownContainer
