import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Icon, Avatar } from 'antd'
import { Drawer, Link, Dialog, Typography } from 'syrg-design-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faPhone,
  faMapMarkerAlt,
  faUserFriends,
  faUserTag,
  faCog,
  faSignOutAlt,
  faFileAlt,
  faUserTie,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons'
import userPic from '../theme/asset/syrgLogo.png'
import style from './styles.css'
import { Loader } from '../controls/loader'
import FlaggedFeature from '../../containers/flagged-feature'

const propTypes = {
  visible: PropTypes.bool.isRequired,
  onDrawerChange: PropTypes.func.isRequired,
  onRedirectToPage: PropTypes.func.isRequired,
  syncStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  isRestricted: PropTypes.bool,
  onClickSync: PropTypes.func,
  syncLoading: PropTypes.bool,
  syncDate: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  onLogout: PropTypes.func
}

const defaultProps = {
  user: {},
  isLoading: false,
  isRestricted: false,
  syncDate: '',
  syncStatus: '',
  syncLoading: false,
  onClickSync: () => {},
  match: {
    url: '/'
  },
  onLogout: () => {}
}

const pages = [
  { id: 'shifthistory', label: 'My Shifts', icon: faHome, url: '/shifts' },
  { id: 'Callouts', label: 'Call Outs', icon: faPhone, url: '/callouts' },
  {
    id: 'locations',
    label: 'Locations',
    icon: faMapMarkerAlt,
    url: '/locations'
  },
  {
    id: 'teammembers',
    label: 'Employees',
    icon: faUserFriends,
    url: '/teamMembers'
  },
  { id: 'positions', label: 'Positions', icon: faUserTag, url: '/positions' },
  { id: 'managers', label: 'Managers', icon: faUserTie, url: '/managers' },
  { id: 'updates', label: 'Updates', icon: faFileAlt, url: '/updates' },
  {
    id: 'updatepassword',
    label: 'Update Password',
    icon: faCog,
    url: '/updatePassword'
  }
]

export class NavigationDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLogoutOpen: false
    }
  }

  onLogoutClick = () => {
    this.setState(prevState => ({
      isLogoutOpen: !prevState.isLogoutOpen
    }))
  }

  render() {
    const {
      onDrawerChange,
      visible,
      onRedirectToPage,
      user,
      isLoading,
      isRestricted,
      onClickSync,
      syncDate,
      syncLoading,
      syncStatus,
      match,
      onLogout
    } = this.props
    const { isLogoutOpen } = this.state

    let kronosSync = null
    if (isRestricted) {
      if (syncLoading || syncStatus === 'IN_PROGRESS') {
        kronosSync = (
          <div className={style.load_sync}>
            <Loader isLeft />
            <Typography variant="h4">Syncing with Kronos...</Typography>
          </div>
        )
      } else {
        kronosSync = (
          <div
            role="presentation"
            className={style.reload_sync}
            onClick={onClickSync}
          >
            <i>
              <FontAwesomeIcon icon={faSyncAlt} />
            </i>
            <div className={style.sync_labels}>
              <Typography variant="h4">Sync Kronos Now</Typography>
              <Typography variant="caption">Last sync: {syncDate}</Typography>
            </div>
          </div>
        )
      }
    }

    return (
      <Drawer open={visible} onClose={onDrawerChange} data-id="navDrawer">
        <div
          className={`${style.nav_drawer_wrapper} ${
            isRestricted ? style.restricted : ''
          }`}
        >
          <div className={style.scrollable}>
            {isLoading ? (
              <Icon type="loading" className={style.nav_loading} spin />
            ) : (
              <div className={style.navbar_profile}>
                <Avatar src={userPic} />
                <div className={style.header_text}>
                  <Typography variant="h3" color="textSecondary">
                    Hello!
                  </Typography>
                  <Typography variant="h2">
                    {user?.firstName} {user?.lastName}
                  </Typography>
                </div>
              </div>
            )}
            <hr className={style.divider} />
            {pages.map(page => {
              const isUrlMatch =
                match.url === page.url ||
                (match.url === '/' && page.label === 'My Shifts')
              const displayPage = (
                <div
                  id={page.id}
                  role="presentation"
                  key={page.id}
                  onClick={() => onRedirectToPage(page.url)}
                  className={`${style.navigation_item} ${
                    isUrlMatch ? style.active : ''
                  }`}
                >
                  <i>
                    <FontAwesomeIcon icon={page.icon} />
                  </i>
                  <Typography
                    variant="body1"
                    color={isUrlMatch ? 'primary' : 'initial'}
                  >
                    {page.label}
                  </Typography>
                </div>
              )
              return page.id === 'Callouts' ? (
                <FlaggedFeature key={page.id} flag="CALL_OUT_LINE">
                  displayPage
                </FlaggedFeature>
              ) : (
                displayPage
              )
            })}
            <hr className={style.divider} />
            <div
              id="logout"
              role="presentation"
              onClick={this.onLogoutClick}
              className={style.navigation_item}
            >
              <i>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </i>{' '}
              <Typography variant="body1">Log out</Typography>
            </div>
            {kronosSync}
          </div>
          <div className={style.footer}>
            <div className={style.logo} />
            <Link href="https://www.syrghq.com">SYRG Website</Link>
          </div>
          <Dialog
            open={isLogoutOpen}
            onClose={this.onLogoutClick}
            title="Log Out of SYRG?"
            okText="Log Out"
            cancelText="Cancel"
            onOk={onLogout}
            onCancel={this.onLogoutClick}
          >
            Are you sure you want to log out of SYRG?
          </Dialog>
        </div>
      </Drawer>
    )
  }
}

NavigationDrawer.propTypes = propTypes
NavigationDrawer.defaultProps = defaultProps

export default withRouter(NavigationDrawer)
