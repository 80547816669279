import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import {
  updateCurrentPosition,
  updateShiftEditing,
  updatePrimaryPosition
} from '../../apollo/apollo-cache-mutation.gql'
import { workplacePositionsQuery } from '../shift-info/shift-info.gql'
import DropDownComponent from '../../components/theme/dropdown'
import {
  getCurrentShift,
  getTeamMember
} from '../../apollo/apollo-cache-query.gql'

const propTypes = {
  updateShiftPosition: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  updateTeamPosition: PropTypes.func,
  onSelectPositionError: PropTypes.func,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  editShift: PropTypes.func,
  getTeamMemberInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getPositions: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  error: PropTypes.bool,
  isSynced: PropTypes.bool,
  defaultValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string
}

const defaultProps = {
  getPositions: { Workplace: { positions: [] } },
  getTeamMemberInfo: () => {},
  editShift: () => {},
  updateTeamPosition: () => {},
  isSynced: false,
  error: false,
  onSelectPositionError: () => {},
  helperText: ''
}

export class PositionsDropDown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positionId: '',
      error: {
        positionId: props.error
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getPositions, getTeamMemberInfo } = nextProps
    let { positions } = prevState
    let positionId
    if (getPositions?.Workplace) {
      positions = getPositions?.Workplace?.positions
    }
    const { currentShift: { position } = {} } = nextProps.getShift
    const { teamMember: { primaryPosition } = {} } = getTeamMemberInfo
    positionId = position?.id && position.name
    if (primaryPosition) {
      positionId = primaryPosition
    }
    return {
      positionId,
      positions,
      error: {
        positionId: nextProps.error
      }
    }
  }

  onPositionChange = (positionId, value, defaultValue) => {
    const {
      updateShiftPosition,
      onSelectPositionError,
      updateTeamPosition
    } = this.props
    this.setState({ positionId })
    let position
    const {
      getPositions: {
        Workplace: { positions }
      }
    } = this.props
    if (defaultValue === 'Select a position') {
      if (positionId !== 'Select a position') {
        const { editShift } = this.props
        editShift()
        position = positions.find(x => x.id === value.key)
        onSelectPositionError(false)
      } else {
        onSelectPositionError(true)
      }
      updateShiftPosition({
        variables: {
          id: position?.id || '',
          name: position?.name || 'Select a position',
          color: position?.color || '',
          alias: position?.alias || ''
        }
      })
    }
    if (defaultValue === 'None') {
      updateTeamPosition({
        variables: {
          primaryPosition: positionId
        }
      })
    }
  }

  render() {
    const { positions, positionId, error } = this.state
    const { isSynced, defaultValue, label, helperText } = this.props
    return (
      <DropDownComponent
        data={positions || []}
        defaultValue={defaultValue}
        label={label}
        dataId={positionId || defaultValue}
        helperText={helperText}
        onChange={this.onPositionChange}
        error={error.positionId}
        isSynced={isSynced}
      />
    )
  }
}
export const PositionsDropDownContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateCurrentPosition, { name: 'updateShiftPosition' }),
  graphql(updateShiftEditing, { name: 'editShift' }),
  graphql(updatePrimaryPosition, { name: 'updateTeamPosition' }),
  graphql(workplacePositionsQuery, {
    skip: props => !props.workplaceId,
    options: props => ({
      variables: { input: { id: props.workplaceId } },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getPositions'
  }),
  graphql(getTeamMember, { name: 'getTeamMemberInfo' })
)(PositionsDropDown)

PositionsDropDown.propTypes = propTypes
PositionsDropDown.defaultProps = defaultProps

export default PositionsDropDownContainer
