// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-reset-password-success-styles__reset_success--1UiyQ {\n  padding: 0 20px;\n}\n\n.src-components-reset-password-success-styles__reset_success--1UiyQ h1 {\n  margin-bottom: 30px;\n  margin-top: 34px;\n}\n\n.src-components-reset-password-success-styles__reset_success--1UiyQ p {\n  margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"reset_success": "src-components-reset-password-success-styles__reset_success--1UiyQ"
};
module.exports = exports;
