import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CallOutLineComponent } from '../../components/call-out-line'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  history: { push: () => {} }
}

export class CallOutLineContainer extends Component {
  render() {
    const { history } = this.props
    return <CallOutLineComponent history={history} />
  }
}

CallOutLineContainer.propTypes = propTypes
CallOutLineContainer.defaultProps = defaultProps

export default CallOutLineContainer
