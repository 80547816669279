// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-footer-navigation-style__nav_footer_wrapper--1yoCT {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  background-color: #fff;\n}\n\n.src-components-layouts-footer-navigation-style__right_button_width--2eIrq {\n  width: 70%;\n  margin-left: 20px;\n}\n\n.src-components-layouts-footer-navigation-style__left_button_width--vUGPD {\n  width: 30%;\n}\n", ""]);
// Exports
exports.locals = {
	"nav_footer_wrapper": "src-components-layouts-footer-navigation-style__nav_footer_wrapper--1yoCT",
	"right_button_width": "src-components-layouts-footer-navigation-style__right_button_width--2eIrq",
	"left_button_width": "src-components-layouts-footer-navigation-style__left_button_width--vUGPD"
};
module.exports = exports;
