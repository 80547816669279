import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import moment from 'moment-timezone'
import NavigationDrawerComponent from '../../components/navigation-drawer'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { getUserInfo, getLastSync, triggerSync } from './userInfo.gql'
import authentication from '../../authentication'

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  getUser: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getSync: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateSync: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isNavigationDrawer: PropTypes.bool.isRequired,
  onDrawerClick: PropTypes.func.isRequired
}

const defaultProps = {
  getShift: {
    currentShift: {
      workplace: {
        corporation: { id: '', setting: '' }
      }
    }
  },
  history: { push: () => {} },
  updateSync: () => {},
  getUser: {},
  getSync: {}
}

export class NavigationDrawerContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      workplaceName: '',
      user: {},
      syncDate: '',
      updatedSyncDate: '',
      syncLoading: false,
      syncStatus: ''
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { getShift, getUser, getSync } = nextProps
    const tz = moment.tz.guess(true)
    if (getShift?.loading) return { isLoading: true }
    if (getShift?.currentShift?.manager.id === null) return { isLoading: true }
    if (getUser?.loading) return { isLoading: true }
    if (!getShift?.loading && !getUser?.loading && getUser?.User) {
      return {
        isLoading: false,
        workplaceName: getShift?.currentShift?.workplace?.name,
        user: getUser.User,
        syncStatus: getSync?.getLastSync?.status,
        syncDate: getSync?.getLastSync?.endDate
          ? moment
              .tz(moment.unix(getSync?.getLastSync?.endDate), tz)
              .format('L h:mm a')
          : ''
      }
    }
    return null
  }

  redirectToPage = page => {
    const { onDrawerClick } = this.props
    onDrawerClick()
    const { history } = this.props
    history.push(page)
  }

  onClickSync = () => {
    const { updateSync, getShift } = this.props
    const tz = moment.tz.guess(true)
    this.setState({ syncLoading: true })
    updateSync({
      variables: {
        corporationId: getShift?.currentShift?.workplace?.corporation?.id
      }
    }).then(res => {
      this.setState({
        syncLoading: false,
        updatedSyncDate: moment
          .tz(moment.unix(res?.data?.triggerSync?.endDate), tz)
          .format('L h:mm a')
      })
    })
  }

  onLogout = () => {
    authentication.signOut().then(() => {
      window.location.reload()
    })
  }

  render() {
    const { onDrawerClick, isNavigationDrawer, getShift } = this.props
    const settings = getShift?.currentShift?.workplace?.corporation?.settings
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    const isRestricted = syncType === 'KRONOS-WFD'
    const {
      isLoading,
      user,
      workplaceName,
      syncLoading,
      syncDate,
      updatedSyncDate,
      syncStatus
    } = this.state
    return (
      <NavigationDrawerComponent
        workplace={workplaceName}
        user={user}
        visible={isNavigationDrawer}
        onDrawerChange={onDrawerClick}
        onRedirectToPage={this.redirectToPage}
        syncDate={updatedSyncDate === '' ? syncDate : updatedSyncDate}
        syncLoading={syncLoading}
        onClickSync={this.onClickSync}
        isLoading={isLoading}
        isRestricted={isRestricted}
        syncStatus={syncStatus}
        onLogout={this.onLogout}
      />
    )
  }
}

export const NavigationDrawerContainerApp = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(getUserInfo, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.manager.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.manager?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getUser'
  }),
  graphql(getLastSync, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.corporation.id,
    options: ({ getShift }) => ({
      variables: {
        corporationId: getShift?.currentShift?.workplace?.corporation?.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getSync'
  }),
  graphql(triggerSync, { name: 'updateSync' })
)(NavigationDrawerContainer)

NavigationDrawerContainer.propTypes = propTypes
NavigationDrawerContainer.defaultProps = defaultProps

export default NavigationDrawerContainerApp
