// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-call-list-row-style__call_list_row--3MN4b {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  -ms-flex-align: center;\n      align-items: center;\n  margin-bottom: 30px;\n}\n\n.src-components-layouts-call-list-row-style__call_list_row--3MN4b .src-components-layouts-call-list-row-style__row_edit--1Z4_5 {\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.src-components-layouts-call-list-row-style__call_list_row--3MN4b .src-components-layouts-call-list-row-style__row_edit--1Z4_5 p {\n  margin-left: 15px;\n}\n\n.src-components-layouts-call-list-row-style__call_list_row--3MN4b .src-components-layouts-call-list-row-style__row_edit--1Z4_5 .MuiCheckbox-root {\n  padding: 0;\n}\n\n.src-components-layouts-call-list-row-style__call_list_row--3MN4b .src-components-layouts-call-list-row-style__row_edit--1Z4_5 .MuiFormControlLabel-root {\n  margin-left: 0;\n}\n\n.src-components-layouts-call-list-row-style__text_ot--24aR1 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-components-layouts-call-list-row-style__call_list_row--3MN4b .src-components-layouts-call-list-row-style__text_ot--24aR1 h4 {\n  margin-left: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"call_list_row": "src-components-layouts-call-list-row-style__call_list_row--3MN4b",
	"row_edit": "src-components-layouts-call-list-row-style__row_edit--1Z4_5",
	"text_ot": "src-components-layouts-call-list-row-style__text_ot--24aR1"
};
module.exports = exports;
