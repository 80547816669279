import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import {
  Typography,
  DatePicker,
  Button,
  TextField,
  Dialog
} from 'syrg-design-kit'
import style from './style.css'
import { Header } from '../theme'
import { FlexBody } from '../layouts/flex-body'
import { FooterComponent } from '../layouts/footer-navigation'
import { PageLayout } from '../layouts/page-layout'

const propTypes = {
  id: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  reason: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.shape({
    date: PropTypes.bool,
    reason: PropTypes.bool
  })
}

const defaultProps = {
  id: '',
  reason: '',
  onChange: () => {},
  onDelete: () => {},
  error: {}
}

export class UnavailableFormComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteUnavailableModal: false
    }
  }

  unavailableDeleteModal = () => {
    const { isDeleteUnavailableModal } = this.state
    this.setState({
      isDeleteUnavailableModal: !isDeleteUnavailableModal
    })
  }

  onDeleteClick = () => {
    const { onDelete } = this.props
    this.unavailableDeleteModal()
    onDelete()
  }

  render() {
    const { isDeleteUnavailableModal } = this.state
    const {
      id,
      startDate,
      endDate,
      reason,
      onBack,
      onNext,
      onChange,
      error
    } = this.props
    const max = moment()
      .add(50, 'year')
      .format('YYYY-MM-DD')
    const min = moment()
      .subtract(50, 'year')
      .format('YYYY-MM-DD')
    return (
      <PageLayout>
        <Header leftText={false} />
        <FlexBody>
          <div className={style.add_unavailable_info}>
            <Typography variant="h2">
              {!id ? 'Add' : 'Edit'} Unavailability
            </Typography>
            <div className={style.add_unavailable_date}>
              <DatePicker
                label="Start Date"
                data-id="startDate"
                onChange={val => {
                  onChange('startDate', val)
                }}
                maxDate={max}
                minDate={min}
                selectedDate={startDate}
              />
            </div>
            <div className={style.add_unavailable_date}>
              <DatePicker
                label="End Date"
                data-id="endDate"
                onChange={val => {
                  onChange('endDate', val)
                }}
                width="100%"
                maxDate={max}
                minDate={min}
                selectedDate={endDate}
              />
            </div>
            <TextField
              label="Reason"
              data-id="reason"
              placeholder="Reason"
              onChange={e => {
                onChange('reason', e.target.value)
              }}
              value={reason}
              required
              fullWidth
              forceShowError={error.reason}
              hintText="Unique identifier used within your company"
              error={error.reason}
            />
            {id && (
              <Button
                data-id="delete_unavailability"
                size="small"
                width="100%"
                onClick={this.unavailableDeleteModal}
                color="secondary"
              >
                Delete Unavailability
              </Button>
            )}
          </div>
        </FlexBody>
        <FooterComponent
          backText="Cancel"
          nextText="Save Changes"
          buttonInfo="All fields required unless otherwise noted"
          onNextClick={onNext}
          onBackClick={() => onBack('')}
        />
        <Dialog
          open={isDeleteUnavailableModal}
          onClose={this.unavailableDeleteModal}
          title="Delete Unavailability?"
          okText="Delete"
          onOk={this.onDeleteClick}
          onCancel={this.unavailableDeleteModal}
        >
          <Typography variant="body1">
            Deleting unavailability status will allow employee to fill shifts
            during this time again.
          </Typography>
        </Dialog>
      </PageLayout>
    )
  }
}

UnavailableFormComponent.propTypes = propTypes
UnavailableFormComponent.defaultProps = defaultProps

export default UnavailableFormComponent
