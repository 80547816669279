import React, { Component } from 'react'
import { Button, Dialog, Typography } from 'syrg-design-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash'
import { Icon } from 'antd'
import style from './style.css'
import Receiver from '../theme/asset/phone-receiver.gif'
import { Header } from '../theme'
import FooterButton from '../layouts/footer-button'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'

const propTypes = {
  isLoading: PropTypes.bool,
  onCloseRequest: PropTypes.func.isRequired,
  positionName: PropTypes.string.isRequired,
  minBetweenCall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deptName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  timeLeft: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  employeeCount: PropTypes.number.isRequired,
  assignedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    })
  ),
  callList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string,
      ranking: PropTypes.number,
      imageUrl: PropTypes.string,
      status: PropTypes.string,
      userResponse: PropTypes.string,
      awardedAt: PropTypes.string
    })
  ).isRequired,
  onBack: PropTypes.func.isRequired
}

const defaultProps = {
  isLoading: false,
  minBetweenCall: '',
  assignedUsers: []
}

export class OpenShiftDetailComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isStopRequest: false
    }
  }

  onCloseRequest = () => {
    const { isStopRequest } = this.state
    this.setState({ isStopRequest: !isStopRequest })
  }

  onCloseRequestCall = () => {
    const { onCloseRequest } = this.props
    this.setState({ isStopRequest: false }, () => {
      onCloseRequest()
    })
  }

  getStatusText = ({ status }) => {
    switch (status) {
      case 'YES':
        return (
          <Typography variant="body1" color="primary">
            Accepted
          </Typography>
        )
      case 'NO':
        return (
          <Typography variant="body1" color="secondary">
            Declined
          </Typography>
        )
      case 'CONTACTING':
        return <Typography variant="body2">. . .</Typography>
      case 'NO_ANSWER':
        return <Typography variant="body2">No Response</Typography>
      case 'OPTED_OUT':
        return <Typography variant="body2">Opted out</Typography>
      case 'FAILED':
        return <Typography variant="body2">Failed to call</Typography>
      default:
        return null
    }
  }

  render() {
    const { isStopRequest } = this.state
    const {
      timeLeft,
      callList,
      isLoading,
      positionName,
      deptName,
      startDate,
      startTime,
      endTime,
      employeeCount,
      assignedUsers,
      onBack,
      minBetweenCall
    } = this.props
    let sortCall = callList
    if (callList?.length) {
      sortCall = orderBy(callList, ['ranking'], ['asc'])
    }
    const leftUser = employeeCount - assignedUsers?.length

    return (
      <PageLayout>
        <div className={style.open_detail_wrap}>
          <Header onLeftClick={onBack} />
          <FlexBody>
            <div className={style.open_shift_detail}>
              <Typography variant="h1">Shift Request Details</Typography>
              <Typography variant="h3">{deptName}</Typography>
              <Typography variant="h4">Date and Time</Typography>
              <Typography variant="body2">
                <b>{startDate}</b>
              </Typography>
              <Typography variant="body2">
                {startTime} – {endTime}
              </Typography>
              <div className={style.open_detail_info}>
                <Typography variant="h4">Position</Typography>
                <Typography variant="body2">{positionName}</Typography>
              </div>
              <div className={style.open_detail_info}>
                <Typography variant="h4">Time Left</Typography>
                <Typography variant="body2">{timeLeft}</Typography>
              </div>
              <div className={style.open_detail_info}>
                <Typography variant="h4">Call Pace</Typography>
                <Typography variant="body2">{minBetweenCall}</Typography>
              </div>
              <div className={style.cancel_request}>
                <Button
                  size="small"
                  fullWidth
                  data-id="cancel-request"
                  onClick={this.onCloseRequest}
                  color="secondary"
                >
                  Cancel Request
                </Button>
              </div>
              {isLoading || sortCall.length === 0 ? (
                <>
                  <div className={style.open_shift_initializing}>
                    <Typography variant="body1">
                      Initializing Call...
                    </Typography>
                  </div>
                  <Icon type="loading" className={style.open_shift_load} spin />
                </>
              ) : (
                <>
                  <div className={style.accepted_detail}>
                    <div className={style.accepted_detail_header}>
                      <Typography variant="h3">Accepted Shift</Typography>
                      <Typography variant="h3" color="textSecondary">
                        <FontAwesomeIcon icon={faUser} />
                        {assignedUsers?.length}/{employeeCount}
                      </Typography>
                    </div>
                    {assignedUsers?.map(user => (
                      <Typography key={user.id} variant="body2">
                        {user.firstName} {user.lastName}
                      </Typography>
                    ))}
                    {Array.from({ length: leftUser }, (_, k) => (
                      <Typography key={k} variant="body2">
                        <span className={style.free_slot}>–Open–</span>
                      </Typography>
                    ))}
                  </div>
                  <div className={style.accepted_detail_header}>
                    <Typography variant="h3">Contact List</Typography>
                    <Typography variant="h3">Response</Typography>
                  </div>
                  {sortCall.map(item => (
                    <div key={item.id} className={style.list_user}>
                      <div className={style.list_name_detail}>
                        {item.status === 'CONTACTING' ? (
                          <div className={style.call_area}>
                            <img src={Receiver} alt="call" />
                          </div>
                        ) : (
                          <div className={style.phone} />
                        )}
                        <div className={style.list_number}>
                          <Typography variant="body1">
                            <b>{item.ranking}</b>
                          </Typography>
                        </div>
                        <Typography variant="body2">
                          {item.firstName} {item.lastName}
                        </Typography>
                      </div>
                      {this.getStatusText(item)}
                    </div>
                  ))}
                </>
              )}
            </div>
          </FlexBody>
          <FooterButton data-id="back_shift" onClick={onBack}>
            Back to Shifts
          </FooterButton>
          <Dialog
            open={isStopRequest}
            onClose={this.onCloseRequest}
            title="Cancel Shift Request?"
            okText="Cancel Request"
            cancelText="Nevermind"
            onOk={this.onCloseRequestCall}
            onCancel={this.onCloseRequest}
          >
            <Typography variant="body1">
              Canceling this request will stop outgoing phone calls. You can
              view this shift in the `Upcoming Shifts` tab.
            </Typography>
          </Dialog>
        </div>
      </PageLayout>
    )
  }
}

OpenShiftDetailComponent.propTypes = propTypes
OpenShiftDetailComponent.defaultProps = defaultProps

export default OpenShiftDetailComponent
