import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { SearchTable } from '../layouts/search-table-layout'
import SearchableList from '../layouts/searchable-list'

const propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onDrawerChange: PropTypes.func,
  isCheckBoxes: PropTypes.bool,
  onCheckChange: PropTypes.func,
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
  isSearchFixed: PropTypes.bool
}

const defaultProps = {
  onDrawerChange: () => {},
  isCheckBoxes: false,
  onCheckChange: () => {},
  selectedUsers: [],
  isSearchFixed: true
}

class TeamMemberTable extends Component {
  render() {
    const {
      data,
      isLoading,
      onSearchChange,
      onDrawerChange,
      isCheckBoxes,
      onCheckChange,
      selectedUsers,
      isSearchFixed
    } = this.props

    if (isCheckBoxes) {
      return isEmpty(data) && !isLoading ? (
        <div>No Team Members Found</div>
      ) : (
        <SearchTable
          onSearchChange={onSearchChange}
          data={data}
          isLoading={isLoading}
          onCheckChange={onCheckChange}
          selectedUsers={selectedUsers}
          isSearchFixed={isSearchFixed}
        />
      )
    }
    return (
      <SearchableList
        onSearchChange={onSearchChange}
        data={data}
        isLoading={isLoading}
        onRowClick={onDrawerChange}
        searchTextData="Team Members"
      />
    )
  }
}

TeamMemberTable.propTypes = propTypes
TeamMemberTable.defaultProps = defaultProps

export default TeamMemberTable
