import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { ShiftInstructionsComponent } from '../../components/shift-instructions'
import {
  updateNotes,
  updateSpanishNotes
} from '../../apollo/apollo-cache-mutation.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateNote: PropTypes.func,
  updateSpanishNote: PropTypes.func
}

const defaultProps = {
  getShift: {
    currentShift: {
      notes: '',
      spanishNotes: ''
    }
  },
  updateNote: () => {},
  updateSpanishNote: () => {}
}

export class ShiftInstructionsContainer extends Component {
  onNoteChange = (key, notes) => {
    const { updateNote, updateSpanishNote } = this.props
    if (key === 'en') {
      updateNote({
        variables: {
          notes,
          noteChanged: true
        }
      })
    }
    if (key === 'es') {
      updateSpanishNote({
        variables: {
          spanishNotes: notes,
          spanNoteChanged: true
        }
      })
    }
  }

  render() {
    const { getShift } = this.props
    let notes = ''
    let spanishNotes = ''
    if (getShift?.currentShift) {
      notes = getShift?.currentShift?.notes
      spanishNotes = getShift?.currentShift?.spanishNotes
    }
    return (
      <ShiftInstructionsComponent
        notes={notes}
        spanishNotes={spanishNotes}
        onNoteChange={this.onNoteChange}
      />
    )
  }
}

export const ShiftInstructions = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateNotes, { name: 'updateNote' }),
  graphql(updateSpanishNotes, { name: 'updateSpanishNote' })
)(ShiftInstructionsContainer)

ShiftInstructionsContainer.propTypes = propTypes
ShiftInstructionsContainer.defaultProps = defaultProps

export default ShiftInstructions
