// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-updates-style__updates_header--3wy9r h2 {\n  padding: 30px 20px 0;\n}\n\n.src-components-updates-style__updates_header--3wy9r p {\n  padding: 0 20px;\n  margin-top: 19px;\n}\n\n.src-components-updates-style__updates_table--2oxJI {\n  height: auto;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  margin: 20px auto;\n}\n\n.src-components-updates-style__updates_table--2oxJI ul {\n  padding: 0 0 10px 0;\n  border-top: 1px solid #f0f1f2;\n}\n\n.src-components-updates-style__updates_table--2oxJI li {\n  display: -ms-flexbox;\n  display: flex;\n  line-height: 30px;\n  margin: 15px 0;\n  padding: 0 15px 0 20px;\n}\n\n.src-components-updates-style__updates_table--2oxJI ul li h3 {\n  min-width: 125px;\n}\n\n.src-components-updates-style__updates_table--2oxJI ul li p {\n  line-height: 16px;\n  -ms-flex: 1 1;\n      flex: 1 1;\n}\n\n.src-components-updates-style__updates_divider--1h1ee {\n  border: 1px solid #f0f1f2;\n}\n\n.src-components-updates-style__updates_table--2oxJI ul :last-child hr {\n  display: none;\n}\n", ""]);
// Exports
exports.locals = {
	"updates_header": "src-components-updates-style__updates_header--3wy9r",
	"updates_table": "src-components-updates-style__updates_table--2oxJI",
	"updates_divider": "src-components-updates-style__updates_divider--1h1ee"
};
module.exports = exports;
