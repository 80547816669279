import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import style from './style.css'
import { Header } from '../theme'
import { SearchTableHeader } from '../layouts/search-table-header'
import { PageLayout } from '../layouts/page-layout'
import TeamMemberTable from '../../containers/team-member-table'
import { FooterComponent } from '../layouts/footer-navigation'
import { FlexBody } from '../layouts/flex-body'

const propTypes = {
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  updateSelectedUsers: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired
}
const defaultProps = {
  selectedUsers: [],
  onSubmit: () => {},
  name: ''
}
export class EligibleTeamMember extends Component {
  render() {
    const {
      selectedUsers,
      onSubmit,
      name,
      updateSelectedUsers,
      onBackClick
    } = this.props
    const headerComponent = (
      <div className={style.eligible_head_text}>
        <Typography variant="h2">Eligible Employees</Typography>
        <Typography
          variant="h4"
          color="primary"
        >{`Who works at ${name}?`}</Typography>
        <Typography variant="body2">
          Select employees that will be contacted to work at this location.
        </Typography>
      </div>
    )
    return (
      <PageLayout>
        <Header leftText={false} />
        <SearchTableHeader header={headerComponent} isHeaderLayout={false} />
        <FlexBody>
          <TeamMemberTable
            onSubmit={onSubmit}
            isCheckBoxes
            submitButtonText="Save"
            initialSelectedUsers={selectedUsers}
            onBackClick={onBackClick}
            updateSelectedUsers={updateSelectedUsers}
            selectedUsers={selectedUsers}
          />
        </FlexBody>
        <FooterComponent
          onBackClick={onBackClick}
          isLoading={false}
          onNextClick={() => onSubmit(selectedUsers)}
          nextText="Save Changes"
          backText="Cancel"
        >
          Save Changes
        </FooterComponent>
      </PageLayout>
    )
  }
}

EligibleTeamMember.propTypes = propTypes
EligibleTeamMember.defaultProps = defaultProps
export default EligibleTeamMember
