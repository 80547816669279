import React, { Component } from 'react'
import { Snackbar } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flatMap, flowRight as compose, difference } from 'lodash'
import { CallListEditComponent } from '../../components/call-list-edit'
import {
  removedUserCallList,
  removeUserfromList
} from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      name: PropTypes.string,
      unavailable: PropTypes.bool,
      ranking: PropTypes.number,
      overtimeReasons: PropTypes.array
    })
  ).isRequired,
  onEditTableModalOpen: PropTypes.func,
  removedMembers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  removeCallList: PropTypes.func.isRequired,
  restoreUser: PropTypes.func,
  restartPhoneTree: PropTypes.bool,
  shouldUserRemoved: PropTypes.func
}

const defaultProps = {
  restartPhoneTree: false,
  removedMembers: [],
  shouldUserRemoved: () => {},
  onEditTableModalOpen: () => {},
  restoreUser: () => {}
}

export class CallListEdit extends Component {
  constructor(props) {
    super(props)
    const availableUser = props.users?.filter(user => !user.unavailable)
    this.state = {
      isOpenReasonModal: false,
      txtReason: '',
      selectedUsers: difference(
        flatMap(availableUser).map(b => b.userId),
        props.removedMembers
      ),
      errorReason: false
    }
  }

  onReasonChange = txtReason => {
    this.setState({ txtReason })
  }

  onReasonCancel = () => {
    this.setState({ isOpenReasonModal: false, errorReason: false })
  }

  onReasonSubmit = usersSelected => {
    const { removeCallList, onEditTableModalOpen, users } = this.props
    const { txtReason, selectedUsers } = this.state
    const removedUsers = difference(selectedUsers, usersSelected)
    if (txtReason !== '') {
      removedUsers.forEach(user => {
        const userInfo = users.find(x => x.userId === user)
        removeCallList({
          variables: {
            removedId: user,
            ranking: userInfo?.ranking,
            reason: txtReason
          }
        })
      })
      onEditTableModalOpen()
      this.setState({
        isOpenReasonModal: false,
        errorReason: false
      })
    } else {
      this.setState({ errorReason: true })
    }
  }

  onRemoveClick = usersSelected => {
    const { onEditTableModalOpen } = this.props
    const { selectedUsers } = this.state
    const memberRemoved = difference(selectedUsers, usersSelected)
    const memberRestore = difference(usersSelected, selectedUsers)

    if (usersSelected.length <= 0) {
      Snackbar('You must select at least one employee.', 'error', 3000)
    } else if (memberRemoved.length === 0 && memberRestore.length === 0) {
      onEditTableModalOpen()
    } else {
      if (memberRestore.length > 0)
        this.restoreUser(memberRestore, memberRemoved.length === 0)
      if (memberRemoved.length > 0) this.setState({ isOpenReasonModal: true })
    }
  }

  restoreUser = (removedIds, shouldBack) => {
    const {
      restoreUser,
      shouldUserRemoved,
      restartPhoneTree,
      onEditTableModalOpen
    } = this.props
    if (restartPhoneTree) {
      shouldUserRemoved(false)
    }
    removedIds.forEach(id => {
      restoreUser({
        variables: {
          removedId: id
        }
      })
    })
    if (shouldBack) {
      onEditTableModalOpen()
      Snackbar('Successfully restored employees!', 'success', 3000)
    }
  }

  render() {
    const { onEditTableModalOpen, removedMembers, users } = this.props
    const {
      isOpenReasonModal,
      txtReason,
      selectedUsers,
      errorReason
    } = this.state
    return (
      <CallListEditComponent
        users={users}
        onCancelClick={onEditTableModalOpen}
        onRemoveClick={this.onRemoveClick}
        isOpenReasonModal={isOpenReasonModal}
        onReasonChange={this.onReasonChange}
        txtReason={txtReason}
        onReasonSubmit={this.onReasonSubmit}
        onReasonCancel={this.onReasonCancel}
        selectedUsers={selectedUsers}
        errorReason={errorReason}
        removedMembers={removedMembers}
      />
    )
  }
}
export const CallListEditApollo = compose(
  withApollo,
  graphql(removedUserCallList, {
    name: 'removeCallList'
  }),
  graphql(removeUserfromList, { name: 'restoreUser' })
)(CallListEdit)

CallListEdit.propTypes = propTypes
CallListEdit.defaultProps = defaultProps

export default CallListEditApollo
