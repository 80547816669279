import { graphql, withApollo } from 'react-apollo'
import React, { Component } from 'react'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { beacon } from 'quala'
import { Snackbar } from 'syrg-design-kit'
import { BaseLayoutComponent } from '../../components/layouts/base'
import {
  getCurrentShift,
  getErrorStatus
} from '../../apollo/apollo-cache-query.gql'
import { updateErrorStatus } from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  children: PropTypes.node,
  getError: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateError: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  children: null,
  getError: { ErrorStatus: {} },
  updateError: {},
  getShift: null
}
export class BaseLayoutCotainer extends Component {
  resetError = () => {
    const { updateError } = this.props
    updateError({
      variables: {
        isError: false,
        errorMessage: ''
      }
    })
  }

  componentDidMount = () => {
    this.resetError()
  }

  render() {
    const { children, getError, getShift } = this.props
    const manager = getShift?.currentShift?.manager
    const workplace = getShift?.currentShift?.workplace
    const corporation = getShift?.currentShift?.workplace?.corporation
    // INITIALIZE HEAP TRACKING
    if (window && window.heap && manager?.id) {
      window.heap.identify(`${manager.id}`)
      window.heap.addUserProperties({
        Name: `${manager.firstName} ${manager.lastName}`,
        Phone: manager?.phoneNumber
      })
    }
    // INITIALIZE QUALA TRACKING
    if (manager?.id && window?.location?.host?.includes('syrg.app')) {
      beacon.identify({
        userId: `${manager.id}-${workplace.id}`,
        companyId: `${corporation.id}-${workplace.id}`,
        traits: {
          phoneNumber: manager?.phoneNumber,
          name: `${manager.firstName} ${manager.lastName}`,
          role: 'Manager'
        },
        companyTraits: {
          name: `${corporation.name} - ${workplace.name}`
        }
      })
    }
    const { isError, errorMessage } = getError.ErrorStatus || {}
    return (
      <>
        {isError && Snackbar(errorMessage, 'error')}
        <BaseLayoutComponent>{children}</BaseLayoutComponent>
      </>
    )
  }
}
const BaseLayout = compose(
  withApollo,
  graphql(getErrorStatus, {
    name: 'getError'
  }),
  graphql(updateErrorStatus, { name: 'updateError' }),
  graphql(getCurrentShift, { name: 'getShift' })
)(BaseLayoutCotainer)

BaseLayoutCotainer.propTypes = propTypes
BaseLayoutCotainer.defaultProps = defaultProps

export default BaseLayout
