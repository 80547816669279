import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { flowRight as compose } from 'lodash'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import OpenShiftsCont from '../open-shifts'
import UpcomingShiftsCont from '../upcoming-shifts'
import PastShiftsCont from '../past-shifts'
import {
  getCurrentShift,
  getShiftRequests
} from '../../apollo/apollo-cache-query.gql'
import {
  updateShift,
  updateTabId
} from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  client: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  updateCurrentShift: PropTypes.func.isRequired,
  updateTab: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}

const defaultProps = {
  client: { cache: { query: () => {} } }
}

export class ShiftRequests extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabId: props?.getShiftRequest?.shiftRequest?.tabId || 0
    }
  }

  onCreateShift = () => {
    const { history } = this.props
    history.push('/newShift')
  }

  onTabChange = tabId => {
    const { updateTab } = this.props
    updateTab({ variables: { tabId } })
    this.setState({ tabId })
  }

  // called from shift card for view shift details
  restartPhoneTree = (currentShift, isRestartRequest, isOpen) => {
    const { tabId } = this.state
    const { getShift, history, updateCurrentShift } = this.props
    const {
      workplace: { timezone }
    } = getShift.currentShift
    const phoneStart = currentShift?.latestShiftPoll?.createdAt
    const isRestart =
      currentShift.teamMemberRequiredCount -
        currentShift?.assignedUsers?.length >
      0
    const minBetweenCall =
      Math.floor(
        currentShift?.latestShiftPoll?.shiftDetails?.timeBetweenCalls / 60
      ) || 0

    updateCurrentShift({
      variables: {
        shiftId: currentShift.id,
        location: currentShift.department,
        position: currentShift.position,
        countUser: currentShift.teamMemberRequiredCount,
        startDate: currentShift.startDate,
        startTime: currentShift.startTime,
        endTime: currentShift.endTime,
        replacedUsers: currentShift.replacedUsers,
        lastPhoneStart: phoneStart
          ? moment
              .tz(moment.unix(phoneStart), timezone)
              .format('MMMM D, YYYY h:mma')
          : '',
        restartPhoneTree: tabId === 1 && isRestart,
        assignedUsers: currentShift?.assignedUsers,
        assignedUserCount: currentShift?.assignedUsers?.length,
        minBetweenCall
      }
    }).then(() => {
      if (isOpen) history.push('/openShiftDetail')
      else if (isRestartRequest) history.push('/summary')
      else history.push('/shiftDetail')
    })
  }

  render() {
    const { tabId } = this.state
    const { getShift, client, history } = this.props
    const {
      workplace: {
        id: workplaceId,
        timezone,
        corporation: { settings }
      }
    } = getShift.currentShift
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type

    const props = {
      client,
      workplaceId,
      timezone,
      history,
      isSynced: syncType === 'KRONOS-WFD',
      onTabChange: this.onTabChange,
      onCreateShift: this.onCreateShift,
      restartPhoneTree: this.restartPhoneTree
    }
    if (tabId === 0) return <OpenShiftsCont {...props} />
    if (tabId === 1) return <UpcomingShiftsCont {...props} />
    return <PastShiftsCont {...props} />
  }
}

export const ShiftRequestsContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(getShiftRequests, { name: 'getShiftRequest' }),
  graphql(updateShift, { name: 'updateCurrentShift' }),
  graphql(updateTabId, { name: 'updateTab' })
)(ShiftRequests)

ShiftRequests.propTypes = propTypes
ShiftRequests.defaultProps = defaultProps

export default ShiftRequestsContainer
