import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ShiftWizardApollo } from '../containers/shift-wizard'
import Login from '../containers/login'
import { ShiftSummaryContainer } from '../containers/shift-summary'
import { CallOutLineContainer } from '../containers/call-out-line'
import { ShiftRequestsContainer } from '../containers/shift-requests'
import LocationTable from '../containers/location-table'
import TeamMemberCont from '../containers/team-members'
import { ManagersContainer } from '../containers/managers'
import AddLocationWizardContainer from '../containers/add-location-wizard'
import Positions from '../containers/positions'
import { NamePositionApollo } from '../containers/name-position'
import { PositionMemberApollo } from '../containers/position-member'
import { TeamWizardApollo } from '../containers/team-wizard'
import { UpdatesComponent } from '../components/updates'
import { ChangePassword } from '../containers/change-password'
import OpenShiftDetail from '../containers/open-shift-detail'
import ShiftDetail from '../containers/shift-details'
import { EligibleTeamMemberContainerApollo } from '../containers/eligible-team-member'
import { ManagerFormApollo } from '../containers/manager-form'
import LocationDetailsContainer from '../containers/location-details'
import { EmployeeDetailsApollo } from '../containers/employee-details'
import { UnavailableFormContainer } from '../containers/unavailable-form'
import { ManagerDetailsApollo } from '../containers/manager-details'
import ResetPasswordSuccess from '../components/reset-password-success'

class RootRouter extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={ShiftRequestsContainer} />
        <Route path="/openShiftDetail" component={OpenShiftDetail} />
        <Route path="/shiftDetail" component={ShiftDetail} />
        <Route path="/memberLocations" component={TeamWizardApollo} />
        <Route path="/unavailableForm" component={UnavailableFormContainer} />
        <Route path="/employeeDetail" component={EmployeeDetailsApollo} />
        <Route path="/managerDetail" component={ManagerDetailsApollo} />
        <Route path="/addLocation" component={AddLocationWizardContainer} />
        <Route path="/login" component={Login} />
        <Route path="/summary" component={ShiftSummaryContainer} />
        <Route path="/newShift" component={ShiftWizardApollo} />
        <Route path="/shifts" component={ShiftRequestsContainer} />
        <Route path="/callouts" component={CallOutLineContainer} />
        <Route path="/locations" component={LocationTable} />
        <Route path="/locationDetails" component={LocationDetailsContainer} />
        <Route path="/teamMembers" component={TeamMemberCont} />
        <Route path="/managers" component={ManagersContainer} />
        <Route path="/addManager" component={ManagerFormApollo} />
        <Route path="/addTeamMember" component={TeamWizardApollo} />
        <Route path="/positions" component={Positions} />
        <Route path="/namePosition" component={NamePositionApollo} />
        <Route path="/positionMember" component={PositionMemberApollo} />
        <Route path="/updates" component={UpdatesComponent} />
        <Route path="/updatePassword" component={ChangePassword} />
        <Route path="/updatePasswordSuccess" component={ResetPasswordSuccess} />
        <Route
          path="/eligibleTeamMember"
          component={EligibleTeamMemberContainerApollo}
        />
      </Switch>
    )
  }
}

export default RootRouter
