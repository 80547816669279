import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Snackbar, Dialog, Typography } from 'syrg-design-kit'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import moment from 'moment'
import { shiftAuditJson } from './shift-audit.gql'
import { Loader } from '../../components/controls/loader'

const propTypes = {
  getShiftAudit: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  csvFormat: PropTypes.string.isRequired
}

export class ShiftAudit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isDownloadReportOpen: false
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps?.getShiftAudit?.loading)
      return {
        isLoading: true
      }
    if (nextProps.getShiftAudit && !nextProps.getShiftAudit.loading) {
      if (nextProps.getShiftAudit.error) {
        Snackbar('Unable to fetch audit', 'info')
      }
      return {
        isLoading: false
      }
    }
    return null
  }

  toggleDialogOpen = () => {
    this.setState(prevState => ({
      isDownloadReportOpen: !prevState.isDownloadReportOpen
    }))
  }

  onDownloadAudit = () => {
    const {
      csvFormat,
      getShiftAudit: { shiftAudit }
    } = this.props
    const fileName = `${`shift-audit-${moment(
      csvFormat,
      'MMMM DD, YYYY-h:mma'
    ).format('MM-DD-YYYY-hh-mm-a')}.csv`}`

    const auditData = JSON.parse(shiftAudit.auditJSON)
    if (auditData.length) {
      const shiftHeader = Object.keys(auditData[0])
      const shiftValue = Object.values(auditData[0])
      const userHeader = Object.keys(auditData[1])
      let userDetail = auditData.map(row =>
        userHeader.map(fieldName => JSON.stringify(row[fieldName])).join(',')
      )
      userDetail = userDetail.join('\n')

      const hiddenElement = document.createElement('a')
      document.body.appendChild(hiddenElement)
      const csvString = `${shiftHeader}\n${shiftValue}\n\n${userHeader}${userDetail}`

      if (URL && 'download' in hiddenElement) {
        // for chrome & firefox
        const csvData = new Blob([csvString], { type: 'text/csv' })
        const csvUrl = URL.createObjectURL(csvData)
        hiddenElement.href = csvUrl
      } else {
        // for safari
        hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
          csvString
        )}`
      }
      document.body.appendChild(hiddenElement)
      hiddenElement.target = '_blank'
      hiddenElement.download = fileName
      hiddenElement.click()
      this.toggleDialogOpen()
    } else {
      Snackbar('Shift report unavailable', 'info')
    }
  }

  render() {
    const { isLoading, isDownloadReportOpen } = this.state
    const { getShiftAudit } = this.props
    if (isLoading) return <Loader isLeft />
    if (!getShiftAudit.error && !isLoading) {
      return (
        <>
          <Button
            size="small"
            data-id="downloadAudit"
            onClick={this.toggleDialogOpen}
          >
            View Report
          </Button>
          <Dialog
            open={isDownloadReportOpen}
            onClose={this.toggleDialogOpen}
            title="Download Shift Report?"
            okText="Download Report"
            cancelText="Cancel"
            onOk={this.onDownloadAudit}
            onCancel={this.toggleDialogOpen}
          >
            <Typography variant="body1">
              You will download a CSV file containing information partaining to
              this shift. Would you like to download this report now?
            </Typography>
          </Dialog>
        </>
      )
    }
    return <div />
  }
}
export const ShiftAuditApollo = compose(
  withApollo,
  graphql(shiftAuditJson, {
    options: ownProps => ({
      variables: {
        shiftId: ownProps.shiftId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getShiftAudit'
  })
)(ShiftAudit)

ShiftAudit.propTypes = propTypes

export default ShiftAuditApollo
