import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import {
  isFeatureEnabled,
  getCurrentShiftWithCorporation
} from './flagged-feature.gql'

const FlaggedFeature = ({ flag, children }) => {
  const { data: corporationData } = useQuery(getCurrentShiftWithCorporation)

  const corporationId =
    corporationData?.currentShift?.workplace?.corporation?.id

  const { data: isFeatureAllowedData } = useQuery(isFeatureEnabled, {
    skip: !corporationId,
    variables: { input: { feature: flag, corporationId } },
    fetchPolicy: 'cache-and-network'
  })

  return isFeatureAllowedData?.isFeatureEnabled ? children : null
}

FlaggedFeature.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default FlaggedFeature
