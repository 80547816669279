// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-controls-loader-style__spinner--YHtqn .ant-spin {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  height: 100vh;\n  position: fixed;\n  left: 50%;\n  top: 50%;\n  -webkit-transform: translate(-50%, -50%);\n      -ms-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n\n.src-components-controls-loader-style__full_page--R4QCE {\n  background: white;\n  -ms-flex-positive: 1;\n      flex-grow: 1;\n  overflow: auto;\n  min-height: 0;\n}\n\n.src-components-controls-loader-style__spinner--YHtqn .src-components-controls-loader-style__loader--ydnMz {\n  font-size: 24px;\n  color: #2a53ba;\n}\n\n.src-components-controls-loader-style__leftLoader--2JOeQ {\n  font-size: 24px;\n  color: #2a53ba;\n}\n", ""]);
// Exports
exports.locals = {
	"spinner": "src-components-controls-loader-style__spinner--YHtqn",
	"full_page": "src-components-controls-loader-style__full_page--R4QCE",
	"loader": "src-components-controls-loader-style__loader--ydnMz",
	"leftLoader": "src-components-controls-loader-style__leftLoader--2JOeQ"
};
module.exports = exports;
