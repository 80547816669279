import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import { Button, Dialog, Typography, Link as LinkButton } from 'syrg-design-kit'
import style from './style.css'
import { PageLayout } from '../layouts/page-layout'
import { Header } from '../theme'
import { FlexBody } from '../layouts/flex-body'
import FooterButton from '../layouts/footer-button'
import EligibleMemberSectionComponent from '../eligible-member-section'
import UpdateLocationContainer from '../../containers/update-location'

const propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string,
    alias: PropTypes.string,
    city: PropTypes.string,
    zipcode: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    supervisorId: PropTypes.string,
    message: PropTypes.string,
    isLocationIfoEdit: PropTypes.bool,
    isEditEligible: PropTypes.bool,
    employees: PropTypes.array,
    addressJson: PropTypes.string,
    contactUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.string,
      phoneNumber: PropTypes.number
    })
  }),
  isLoading: PropTypes.bool,
  isSynced: PropTypes.bool,
  isRestricted: PropTypes.bool,
  sortedEligibleMember: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  ),
  onBackClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  gotoEligibleTeamMember: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  location: {},
  isLoading: false,
  isSynced: false,
  isRestricted: false,
  sortedEligibleMember: [],
  onBackClick: () => {},
  onDeleteClick: () => {},
  gotoEligibleTeamMember: () => {},
  history: { push: () => {} }
}

export class LocationDetailsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLocationDeleteModal: false,
      isEditLocation: false
    }
  }

  onLocationEdit = () => {
    this.setState(prevState => ({
      isEditLocation: !prevState.isEditLocation
    }))
  }

  locationDeleteModal = () => {
    this.setState(prevState => ({
      isLocationDeleteModal: !prevState.isLocationDeleteModal
    }))
  }

  onDeleteClick = () => {
    const { onDeleteClick, location } = this.props
    onDeleteClick(location.id)
    this.locationDeleteModal()
  }

  render() {
    const {
      isLoading,
      location,
      onBackClick,
      isSynced,
      isRestricted,
      sortedEligibleMember,
      gotoEligibleTeamMember,
      history
    } = this.props
    const address = !isLoading && JSON.parse(location.addressJson)
    const { isLocationDeleteModal } = this.state
    const eligibleComponent = (
      <>
        <EligibleMemberSectionComponent
          isEdit
          isSynced={isSynced}
          isRestricted={isRestricted}
          onEditLocation={gotoEligibleTeamMember}
          sortedEligibleMember={sortedEligibleMember}
          employees={location.employees}
          isEditEligible={location.isEditEligible}
        />
        {!isSynced && !isRestricted && (
          <div className={style.location_detail_delete_loc}>
            <Button
              size="small"
              width="100%"
              onClick={this.locationDeleteModal}
              data-id="deleteLocation"
              color="secondary"
            >
              Delete Location
            </Button>
          </div>
        )}
      </>
    )
    const { isEditLocation } = this.state
    return (
      <>
        {isEditLocation ? (
          <UpdateLocationContainer
            onLocationEdit={this.onLocationEdit}
            history={history}
            location={location}
          >
            {eligibleComponent}
          </UpdateLocationContainer>
        ) : (
          <PageLayout>
            <Header onLeftClick={onBackClick} />
            {isLoading ? (
              <Icon
                type="loading"
                className={style.location_detail_loading}
                spin
              />
            ) : (
              <FlexBody>
                <div className={style.locations_box}>
                  <div className={style.location_edit_info}>
                    <Typography variant="h1">Location Details</Typography>
                    {!isSynced && !isRestricted && (
                      <LinkButton
                        data-id="btnEditLocation"
                        isButton
                        onClick={this.onLocationEdit}
                      >
                        Edit
                      </LinkButton>
                    )}
                  </div>
                  <>
                    <div className={style.profile_wrap}>
                      <Typography variant="h3">{location.name}</Typography>
                    </div>
                    <Typography variant="h4">Address</Typography>
                    <div className={style.loc_content}>
                      <Typography variant="body2">
                        {address.street || ''}
                      </Typography>
                      <Typography variant="body2">
                        {address.city || ''}, {address.state || ''}
                      </Typography>
                      <Typography variant="body2">
                        {address.postalCode || ''}
                      </Typography>
                      <div className={style.address_text}>
                        <LinkButton
                          href={encodeURI(`http://maps.google.com/maps/?q=${address.street ||
                            ''},${address.city || ''},
                    ${address.state || ''},
                    ${address.postalCode || ''}`)}
                          target="_newtab"
                        >
                          View in Maps
                          <Icon type="select" />
                        </LinkButton>
                      </div>
                    </div>
                    <>
                      <Typography variant="h4">Supervisor</Typography>
                      <div className={style.content_2}>
                        <Typography variant="body2">
                          {`${location?.contactUser?.firstName} ${location?.contactUser?.lastName}`}
                        </Typography>
                      </div>
                    </>
                    <Typography variant="h4">Location Message</Typography>
                    <div className={style.content_2}>
                      <Typography variant="body2">
                        {location.message}
                      </Typography>
                    </div>
                    {eligibleComponent}
                  </>
                </div>
              </FlexBody>
            )}
            <FooterButton data-id="back_shift" onClick={onBackClick}>
              Back to Locations
            </FooterButton>
          </PageLayout>
        )}
        <Dialog
          open={isLocationDeleteModal}
          okText="Delete"
          onClose={this.locationDeleteModal}
          onCancel={this.locationDeleteModal}
          onOk={this.onDeleteClick}
          title="Delete Location?"
        >
          You will no longer be able to create shifts at this location.
        </Dialog>
      </>
    )
  }
}

LocationDetailsComponent.propTypes = propTypes
LocationDetailsComponent.defaultProps = defaultProps

export default LocationDetailsComponent
