import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'syrg-design-kit'
import { isEmpty } from 'lodash'
import style from './style.css'
import { Loader } from '../../controls/loader'

const propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired,
  isLoading: PropTypes.bool,
  onSearchChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  isSynced: PropTypes.bool,
  searchTextData: PropTypes.string
}

const defaultProps = {
  isLoading: false,
  onRowClick: () => {},
  isSynced: false,
  searchTextData: ''
}

const mapAliasToName = element => ({
  ...element,
  name: element.alias
})

const SearchableList = props => {
  const {
    data,
    isLoading,
    onSearchChange,
    onRowClick,
    isSynced,
    searchTextData
  } = props
  const [searchText, setSearchText] = useState('')

  const handleSearchInputChange = value => {
    setSearchText(value)
    onSearchChange(value)
  }

  if (isLoading) return <Loader />
  if (isEmpty(data) && !isLoading)
    return <div className={style.no_data}>No {searchTextData} Found </div>

  const list = Object.keys(data)
    .sort()
    .map(letter => data[letter])
    .reduce((arr, letterData) => [...arr, ...letterData], [])

  return (
    <div className={style.scroll_search_container}>
      <List
        searchInputValue={searchText}
        searchInputChange={e => handleSearchInputChange(e.target.value)}
        list={isSynced ? list.map(mapAliasToName) : list}
        onRowClick={onRowClick}
      />
    </div>
  )
}

SearchableList.propTypes = propTypes
SearchableList.defaultProps = defaultProps

export default SearchableList
