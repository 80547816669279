import moment from 'moment-timezone'

export const teamMember = {
  Mutation: {
    newTeamMember: (
      _,
      {
        page,
        firstName,
        lastName,
        phoneNumber,
        hireDate,
        preferredLanguage,
        employeeId,
        isTemp
      },
      { cache }
    ) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          page,
          firstName,
          lastName,
          phoneNumber,
          hireDate,
          preferredLanguage,
          employeeId,
          isTemp
        }
      }
      cache.writeData({ data })
      return null
    },

    updatePrimaryPosition: (_, { primaryPosition }, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          primaryPosition
        }
      }
      cache.writeData({ data })
      return null
    },

    updatePrimaryWorkplace: (_, { primaryWorkplace }, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          primaryWorkplace
        }
      }
      cache.writeData({ data })
      return null
    },

    teamPosition: (_, { positions }, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          positions
        }
      }
      cache.writeData({ data })
      return null
    },

    updateMemberDepartment: (_, { excludedLocation }, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          excludedLocation,
          page: 2
        }
      }
      cache.writeData({ data })
      return null
    },

    updateTeamPage: (_, { page }, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          page
        }
      }
      cache.writeData({ data })
      return null
    },

    updateTeamId: (_, { memberId }, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          memberId
        }
      }
      cache.writeData({ data })
      return null
    },

    updateTeamDepartment: (
      _,
      {
        firstName,
        lastName,
        hireDate,
        primaryWorkplace,
        employeeId,
        isTemp,
        excludedLocation
      },
      { cache }
    ) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          firstName,
          lastName,
          hireDate,
          primaryWorkplace,
          employeeId,
          isTemp,
          excludedLocation,
          isEdit: true,
          page: 3
        }
      }
      cache.writeData({ data })
      return null
    },

    resetNewTeamMember: (_, variables, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          page: 1,
          startedEditing: false,
          isEdit: false,
          memberId: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          hireDate: moment().unix(),
          positions: [],
          primaryWorkplace: '',
          preferredLanguage: 'ENGLISH',
          primaryPosition: '',
          employeeId: '',
          isTemp: false,
          excludedLocation: []
        }
      }
      cache.writeData({ data })
      return null
    },

    updateMemberEditing: (_, variables, { cache }) => {
      const data = {
        teamMember: {
          __typename: 'TeamMember',
          startedEditing: true
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

export const defaultTeamMember = {
  teamMember: {
    __typename: 'TeamMember',
    page: 1,
    startedEditing: false,
    isEdit: false,
    memberId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    primaryPosition: '',
    employeeId: '',
    hireDate: moment()
      .utc()
      .startOf('day')
      .unix(),
    positions: [],
    primaryWorkplace: '',
    preferredLanguage: 'ENGLISH',
    isTemp: false,
    excludedLocation: []
  }
}
