// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-theme-image-row-style__avt_wrapper--3jr7z {\n  display: -ms-flexbox;\n  display: flex;\n  padding: 0 20px;\n  margin-bottom: 30px;\n  cursor: pointer;\n}\n\n.src-components-theme-image-row-style__avt_wrapper--3jr7z .src-components-theme-image-row-style__avt--3hsCJ {\n  width: 50px;\n  height: 50px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  border-radius: 50%;\n  background: #90959e;\n  margin-left: 21px;\n}\n\n.src-components-theme-image-row-style__avt_wrapper--3jr7z .src-components-theme-image-row-style__avt--3hsCJ:first-child {\n  margin-left: 0;\n}\n\n.src-components-theme-image-row-style__avt_wrapper--3jr7z .src-components-theme-image-row-style__leftAvt--1ZiAo {\n  font-size: 12px;\n  line-height: normal;\n  text-align: center;\n  color: #90959e;\n  background: transparent;\n  border: 1px solid #90959e;\n}\n", ""]);
// Exports
exports.locals = {
	"avt_wrapper": "src-components-theme-image-row-style__avt_wrapper--3jr7z",
	"avt": "src-components-theme-image-row-style__avt--3hsCJ",
	"leftAvt": "src-components-theme-image-row-style__leftAvt--1ZiAo"
};
module.exports = exports;
