// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-confirm-pin-styles__confirm_pin_form--SDcD7 {\n  padding: 0 20px;\n}\n\n.src-components-confirm-pin-styles__confirm_pin_form--SDcD7 h1 {\n  margin-bottom: 20px;\n}\n\n.src-components-confirm-pin-styles__confirm_pin_form--SDcD7 p {\n  margin: 10px 0 20px 0;\n}\n\n.src-components-confirm-pin-styles__confirm_pin_form--SDcD7 > div {\n  margin-top: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"confirm_pin_form": "src-components-confirm-pin-styles__confirm_pin_form--SDcD7"
};
module.exports = exports;
