import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.css'

const propTypes = {
  children: PropTypes.node.isRequired
}

export class PageLayout extends Component {
  render() {
    const { children } = this.props
    return <div className={style.wrap}>{children}</div>
  }
}

PageLayout.propTypes = propTypes

export default PageLayout
