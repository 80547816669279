// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-call-list-style__call_list_head--1XNEp h2 {\n  margin: 30px 0 10px 20px;\n}\n\n.src-components-call-list-style__call_list_head--1XNEp p {\n  margin: 0 20px calc(20px * 2) 20px;\n}\n\n.src-components-call-list-style__call_list_members--10-tY {\n  padding: 0 20px;\n}\n\n.src-components-call-list-style__call_list_members--10-tY .src-components-call-list-style__no_data--3H2By {\n  text-align: center;\n  padding: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"call_list_head": "src-components-call-list-style__call_list_head--1XNEp",
	"call_list_members": "src-components-call-list-style__call_list_members--10-tY",
	"no_data": "src-components-call-list-style__no_data--3H2By"
};
module.exports = exports;
