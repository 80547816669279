import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, Checkbox, List } from 'syrg-design-kit'
import styles from './style.css'
import { FooterComponent } from '../layouts/footer-navigation'
import { Loader } from '../controls/loader'
import ValidationText from '../theme/validation-text'
import { FlexBody } from '../layouts/flex-body'
import { PageLayout } from '../layouts/page-layout'
import { createAndFlattenListWithCheckboxes } from '../../utils/user-list-sorting'

const propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired,
  onNextClick: PropTypes.func.isRequired,
  onCheckChange: PropTypes.func.isRequired,
  selectedDepts: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  onBackClick: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isEdit: PropTypes.bool,
  empName: PropTypes.string,
  isSelectAll: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  onSelectAll: PropTypes.func
}

const defaultProps = {
  selectedDepts: [],
  isLoading: false,
  error: false,
  isEdit: false,
  empName: '',
  isSelectAll: false,
  isIndeterminate: false,
  onSelectAll: () => {}
}

export class TeamMemberDepartmentComponent extends Component {
  render() {
    const {
      data,
      onNextClick,
      selectedDepts,
      onCheckChange,
      isLoading,
      onBackClick,
      error,
      empName,
      isEdit,
      isSelectAll,
      onSelectAll,
      isIndeterminate
    } = this.props

    const mappedData = createAndFlattenListWithCheckboxes(
      data,
      selectedDepts,
      false,
      true
    )

    return (
      <PageLayout>
        <span className={styles.team_member_dept}>
          {isEdit && <Typography variant="h2">Edit Locations</Typography>}
          <Typography variant="h4" color="primary">
            {isEdit
              ? `Locations That ${empName} Can Work`
              : 'Locations That Can Be Worked'}
          </Typography>
        </span>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {isEdit && (
              <div className={styles.select_check}>
                <span className={styles.select_all_text}>Select All</span>
                <Checkbox
                  data-id="selectAll"
                  checked={isSelectAll}
                  indeterminate={isIndeterminate}
                  onChange={(e, val) => onSelectAll(val)}
                />
              </div>
            )}
            <FlexBody>
              <List
                withSearch={false}
                withCheckbox
                list={mappedData}
                onCheckboxCheck={onCheckChange}
              />
            </FlexBody>
          </>
        )}
        {error && (
          <ValidationText textInfo="Must select at least one location" />
        )}
        <FooterComponent
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          buttonInfo="Must select at least one field"
          backText={isEdit ? 'Cancel' : 'Back'}
          nextText={isEdit ? 'Save Changes' : 'Next'}
        />
      </PageLayout>
    )
  }
}

TeamMemberDepartmentComponent.propTypes = propTypes
TeamMemberDepartmentComponent.defaultProps = defaultProps

export default TeamMemberDepartmentComponent
