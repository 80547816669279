import gql from 'graphql-tag'

export const getCallList = gql`
  query callList(
    $workplaceId: ID!
    $positionId: ID!
    $departmentId: ID!
    $corporationId: ID!
    $shiftId: ID
    $callOutUserId: ID
    $timezone: String!
    $startTime: String!
    $endTime: String!
  ) {
    callList(
      workplaceId: $workplaceId
      positionId: $positionId
      departmentId: $departmentId
      corporationId: $corporationId
      shiftId: $shiftId
      callOutUserId: $callOutUserId
      startTime: $startTime
      endTime: $endTime
      timezone: $timezone
    ) {
      shiftId
      runNumber
      userToCall {
        userId
        firstName
        lastName
        unavailable
        removed
        reasonRemovedByManager
        ranking
        imageUrl
        overtimeReasons
      }
    }
  }
`

export const createShift = gql`
  mutation createShiftAndContactEmployees(
    $shiftId: String
    $removedUsers: String
    $replacedUsers: String
    $runNumber: Int
    $teamMembersRequiredCount: Int
    $requesterId: String
    $contactTimeout: Int
    $phonesToNotify: [String]
    $shiftInstruction: ShiftInstructions
  ) {
    CreateShiftAndContactEmployees(
      input: {
        shiftId: $shiftId
        removedUsers: $removedUsers
        replacedUsers: $replacedUsers
        runNumber: $runNumber
        teamMembersRequiredCount: $teamMembersRequiredCount
        requesterId: $requesterId
        contactTimeout: $contactTimeout
        phonesToNotify: $phonesToNotify
        shiftInstruction: $shiftInstruction
      }
    ) {
      id
      startTime
      endTime
      assignedUsers {
        id
        firstName
        lastName
      }
      latestShiftPoll {
        shiftDetails {
          timeBetweenCalls
        }
        createdAt
      }
    }
  }
`

export default { getCallList }
