import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withApollo, graphql } from 'react-apollo'
import moment from 'moment'
import localization from 'moment/locale/es'
import { cloneDeep, flowRight as compose } from 'lodash'
import {
  removeCallList,
  getCurrentShift
} from '../../apollo/apollo-cache-query.gql'
import { AdvanceOptionComponent } from '../../components/advance-options'
import { getSupervisor } from '../add-supervisor/add-supervisor.gql'
import { fetchLocationInfo } from '../location-details/location-info.gql'
import {
  updateNotifyManagers,
  updateNotes,
  updateSpanishNotes
} from '../../apollo/apollo-cache-mutation.gql'
import { advanceTemplate } from '../../utils/advance-template'

moment.locale('en')

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getManagers: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isOpen: PropTypes.bool,
  getRemovedUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getLocationInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateNote: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateSpanishNote: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateManagers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  allUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

const defaultProps = {
  getShift: {
    currentShift: {
      notes: '',
      spanishNotes: '',
      minBetweenCall: ''
    }
  },
  getManagers: {},
  isOpen: false,
  getRemovedUsers: {},
  getLocationInfo: {},
  updateNote: {},
  updateSpanishNote: {},
  updateManagers: {},
  allUsers: []
}

export class AdvanceOptions extends Component {
  static updateAdvance(
    getShift,
    getLocationInfo,
    updateNote,
    updateSpanishNote,
    updateManagers
  ) {
    const {
      noteChanged,
      spanNoteChanged,
      notifyManagerChanged,
      startDate,
      startTime,
      endTime,
      manager: { phoneNumber },
      position: { name: positionName },
      workplace: {
        name: workplaceName,
        corporation: { name: corporationName }
      },
      notifyManagers
    } = getShift.currentShift
    const { Department } = getLocationInfo

    if (!notifyManagerChanged) {
      if (phoneNumber === Department?.contactUser?.phoneNumber)
        updateManagers({
          variables: {
            notifyManagers: [phoneNumber],
            notifyManagerChanged: false
          }
        })
      else
        updateManagers({
          variables: {
            notifyManagers: [phoneNumber, Department?.contactUser?.phoneNumber],
            notifyManagerChanged: false
          }
        })
    } else {
      updateManagers({
        variables: {
          notifyManagers,
          notifyManagerChanged: true
        }
      })
    }

    if (!noteChanged) {
      const startDay = moment(startDate, 'MMMM D, YYYY')
      updateNote({
        variables: {
          notes: advanceTemplate(
            positionName,
            corporationName,
            startDay,
            startTime,
            endTime,
            workplaceName,
            Department
          ),
          noteChanged: false
        }
      })
    }

    if (!spanNoteChanged) {
      const spanishDay = moment(startDate, 'MMMM D, YYYY').locale(
        'es',
        localization
      )
      updateSpanishNote({
        variables: {
          spanishNotes: advanceTemplate(
            positionName,
            corporationName,
            spanishDay,
            startTime,
            endTime,
            workplaceName,
            Department,
            true
          ),
          spanNoteChanged: false
        }
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      allListUsers: []
    }
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const {
      getRemovedUsers,
      allUsers,
      getShift,
      getLocationInfo,
      updateNote,
      updateSpanishNote,
      updateManagers
    } = nextProps
    if (getLocationInfo?.loading || getLocationInfo?.error) {
      return {
        isLoading: true
      }
    }
    if (
      !getLocationInfo?.loading &&
      getLocationInfo?.Department &&
      getRemovedUsers?.recipientListDrawer
    ) {
      const { users } = getRemovedUsers.recipientListDrawer
      let { allListUsers } = nextState
      if (allListUsers !== allUsers) {
        allListUsers = allUsers
      }
      allListUsers = allListUsers?.filter(x => !x.unavailable)
      if (users.length > 0) {
        users.forEach(removedMember => {
          const index = allListUsers?.findIndex(
            member => member.userId === removedMember.removedId
          )
          if (index !== -1) cloneDeep(allListUsers?.splice(index, 1))
        })
        AdvanceOptions.updateAdvance(
          getShift,
          getLocationInfo,
          updateNote,
          updateSpanishNote,
          updateManagers
        )
        return { isLoading: false, allListUsers }
      }
      AdvanceOptions.updateAdvance(
        getShift,
        getLocationInfo,
        updateNote,
        updateSpanishNote,
        updateManagers
      )
      return { isLoading: false, allListUsers }
    }
    return null
  }

  render() {
    const { isLoading, allListUsers } = this.state
    const { getShift, getManagers, isOpen } = this.props
    if (!isOpen) return <></>

    const {
      notes,
      spanishNotes,
      minBetweenCall,
      restartPhoneTree,
      notifyManagers,
      workplace: {
        corporation: { settings }
      }
    } = getShift.currentShift
    return (
      <AdvanceOptionComponent
        isLoading={isLoading}
        notes={notes}
        restartPhoneTree={restartPhoneTree}
        spanishNotes={spanishNotes}
        minBetweenCall={minBetweenCall}
        notifyManagers={notifyManagers}
        managers={getManagers?.getManagersByCorporationId}
        settings={settings}
        callList={allListUsers?.length}
      />
    )
  }
}

export const AdvanceOptionsContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(fetchLocationInfo, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.location.id,
    options: ({ getShift }) => ({
      variables: {
        id: getShift.currentShift.location.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getLocationInfo'
  }),
  graphql(getSupervisor, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.corporation.id,
    options: ({ getShift }) => ({
      variables: {
        corporationId: getShift?.currentShift?.workplace?.corporation?.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getManagers'
  }),
  graphql(updateNotes, { name: 'updateNote' }),
  graphql(updateSpanishNotes, { name: 'updateSpanishNote' }),
  graphql(updateNotifyManagers, { name: 'updateManagers' }),
  graphql(removeCallList, { name: 'getRemovedUsers' })
)(AdvanceOptions)

AdvanceOptions.propTypes = propTypes
AdvanceOptions.defaultProps = defaultProps

export default AdvanceOptionsContainer
