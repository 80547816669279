import gql from 'graphql-tag'

export const fetchWorkplaceMembers = gql`
  query Workplace($input: WorkplaceInput!) {
    Workplace(input: $input) {
      employees {
        id
        firstName
        lastName
      }
    }
  }
`
export default fetchWorkplaceMembers
