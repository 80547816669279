import gql from 'graphql-tag'

export const newPosition = {
  Mutation: {
    updatePositionName: (_, { name }, { cache }) => {
      const query = gql`
        query newPosition {
          newPosition @client {
            id
          }
        }
      `
      const previous = cache.readQuery({ query })
      const data = {
        newPosition: {
          id: previous.newPosition.id,
          name,
          __typename: 'position'
        }
      }
      cache.writeData({ data })
      return data
    },

    resetPosition: (_, variables, { cache }) => {
      const data = {
        newPosition: {
          id: '',
          name: '',
          color: '0',
          selectedUsers: [],
          isEdit: false,
          __typename: 'position'
        }
      }
      cache.writeData({ data })
      return null
    },

    updatePosition: (
      _,
      { id, name, color, selectedUsers, isEdit },
      { cache }
    ) => {
      const data = {
        newPosition: {
          id,
          name,
          color,
          selectedUsers,
          isEdit,
          __typename: 'position'
        }
      }
      cache.writeData({ data })
      return null
    },

    updatePositionMembers: (_, { selectedUsers }, { cache }) => {
      const query = gql`
        query newPosition {
          newPosition @client {
            id
          }
        }
      `
      const previous = cache.readQuery({ query })
      const data = {
        newPosition: {
          id: previous.newPosition.id,
          selectedUsers,
          __typename: 'position'
        }
      }
      cache.writeData({ data })
      return data
    }
  }
}

export const defaultPosition = {
  newPosition: {
    id: '',
    name: '',
    color: '0',
    selectedUsers: [],
    isEdit: false,
    __typename: 'position'
  }
}
