import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { LocationTable } from '../../components/location-table'
import { fetchWorkplaceLocations } from './location-table.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { updateLocationId } from '../../apollo/apollo-cache-mutation.gql'
import { searchResultData } from '../../utils/search-string'
import { restrictedUsers } from '../../utils/constant'
import { getDisplayHash } from '../../utils/search-table-methods'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      locationId: PropTypes.string
    })
  }),
  updateLocId: PropTypes.func,
  getWorkplaceLocations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  updateLocId: () => {},
  history: { push: () => {} },
  location: { pathname: '', state: { locationId: '' } },
  getWorkplaceLocations: {}
}

export class LocationTableContainer extends Component {
  constructor(props) {
    super(props)
    const {
      location: { state: { locationId } = '' }
    } = props
    this.state = {
      isSearch: false,
      locations: [],
      searchedLocations: [],
      isLoading: false,
      locationId: locationId || '',
      isSynced: false,
      isRestricted: false
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      manager: { id },
      workplace: {
        id: workId,
        corporation: { settings }
      }
    } = nextProps?.getShift?.currentShift
    const isRestricted = restrictedUsers.includes(id)
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    const isSynced = syncType === 'KRONOS-WFD'
    if (nextProps?.getWorkplaceLocations?.loading)
      return {
        isLoading: true
      }
    if (
      nextProps.getWorkplaceLocations &&
      !nextProps.getWorkplaceLocations.loading &&
      nextProps.getWorkplaceLocations.getDepartmentsByWorkplaceId
    ) {
      const { getDepartmentsByWorkplaceId } = nextProps.getWorkplaceLocations
      return {
        isLoading: false,
        locationData: getDepartmentsByWorkplaceId,
        isRestricted,
        isSynced,
        locations: getDisplayHash(
          getDepartmentsByWorkplaceId,
          getDepartmentsByWorkplaceId.length,
          isSynced
        )
      }
    }
    if (state.isSearch && workId !== state.workplaceId) {
      return {
        workplaceId: workId
      }
    }
    return null
  }

  onSearchLocation = word => {
    const { isSynced } = this.state
    if (word.trim().length === 0) {
      this.setState({ isSearch: false })
    } else {
      const { locationData } = this.state
      const searchedData = searchResultData(locationData, word, true, isSynced)
      this.setState({
        searchedLocations: getDisplayHash(
          searchedData,
          searchedData.length,
          isSynced
        ),
        isSearch: true
      })
    }
  }

  onLocationClick = locationId => {
    const { updateLocId, history } = this.props
    if (locationId) {
      updateLocId({
        variables: {
          locationId
        }
      }).then(() => {
        history.push('/locationDetails')
      })
    }
  }

  onNewLocation = () => {
    const { history } = this.props
    history.push('/addLocation')
  }

  onLocationUpdate = (locId, locName, isSynced) => {
    const { locationData } = this.state
    locationData.map(location => {
      const loc = location
      if (loc.id === locId) {
        if (isSynced) loc.alias = locName
        else loc.name = locName
      }
      return loc
    })
    this.setState({ locationData })
  }

  render() {
    const { history } = this.props
    const {
      isSearch,
      searchedLocations,
      locations,
      locationId,
      isLoading,
      isRestricted,
      isSynced
    } = this.state
    const locationData = isSearch ? searchedLocations : locations
    return (
      <LocationTable
        data={locationData}
        onSearchChange={this.onSearchLocation}
        onNewLocation={this.onNewLocation}
        onLocationClick={this.onLocationClick}
        onLocationUpdate={this.onLocationUpdate}
        locationId={locationId}
        history={history}
        isLoading={isLoading}
        isRestricted={isRestricted}
        isSynced={isSynced}
      />
    )
  }
}

export const LocationTableContainerApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateLocationId, { name: 'updateLocId' }),
  graphql(fetchWorkplaceLocations, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.id,
    options: ({ getShift }) => ({
      variables: {
        id: getShift?.currentShift?.workplace?.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplaceLocations'
  })
)(LocationTableContainer)

LocationTableContainer.propTypes = propTypes
LocationTableContainer.defaultProps = defaultProps

export default LocationTableContainerApollo
