import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import Login from '../../components/login'
import EnterPassword from '../../components/enter-password'
import ConfirmPIN from '../../components/confirm-pin'
import authentication from '../../authentication'
import ForgotPasswordContainer from '../forgot-password'

const clearState = {
  error: '',
  success: '',
  open: false,
  number: '',
  isLoading: false,
  pin: false,
  password: false,
  reset: false,
  forgotPassword: false
}

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  client: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}

const defaultProps = {
  history: { push: () => {} }
}

export class ConfirmContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      success: '',
      open: false,
      number: '',
      isLoading: false,
      reset: false
    }
  }

  componentDidUpdate() {
    const { error, open, success } = this.state
    if (error && open) {
      Snackbar(error, 'error')
    } else if (success) {
      Snackbar(success, 'success')
    }
  }

  onInitialLogin = async (number, password) => {
    const { isLoading } = this.state
    const pw = password || 'xxxxxx'
    let num = number.toString().replace(/\D/g, '')
    num = `+${num.length < 11 ? '1' : ''}${num}`
    this.setState({ num })
    if (num.length < 10) {
      this.setState({
        pin: false,
        success: '',
        error: 'Please enter at least 10 digits',
        open: true
      })
    } else if (!isLoading) {
      this.setState({ isLoading: true })
      const { error, challengeName } = await authentication.signIn(num, pw)
      // reset password case
      if (error === 'Password reset required for the user') {
        await authentication.forgotPassword(num)
        this.setState({
          ...clearState,
          ...{
            reset: true,
            number
          }
        })
        return
      }

      if (error) {
        if (error.includes('null invocation failed due to configuration.')) {
          this.setError('Password Required.')
        } else {
          this.setError(error)
        }
        return
      }

      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({
          ...clearState,
          ...{
            password: true,
            number
          }
        })
      }
      if (challengeName === 'SMS_MFA') {
        this.setState({
          ...clearState,
          ...{
            pin: true,
            number
          }
        })
      }
    }
  }

  // called when password reset required during login
  updatePassword = async (password, code = null) => {
    const { num, reset } = this.state
    if (reset) {
      this.forgotPasswordReset(num, password, code)
    } else {
      this.completeTempPassword(num, password)
    }
  }

  forgotPasswordReset = async (num, password, code) => {
    const response = await authentication.forgotPasswordSubmit(
      num,
      code,
      password
    )
    if (response && response.message) {
      this.setState({
        error: response.message,
        open: true
      })
      return
    }
    this.setState({
      ...clearState
    })
    Snackbar(
      'Password reset. You can log in with your new password.',
      'success'
    )
  }

  completeTempPassword = async (num, password) => {
    const response = await authentication.completeNewPassword(num, password)
    const { number } = this.state

    if (response && response.challengeName === 'SMS_MFA') {
      this.setState({
        ...clearState,
        ...{
          pin: true,
          number,
          error: response.error,
          open: !!response.error
        }
      })
    }
  }

  setError = error => {
    this.setState({
      success: '',
      error,
      open: true,
      isLoading: false
    })
  }

  onBack = () => {
    this.setState({ ...clearState })
  }

  onNextClick = code => async () => {
    const { client } = this.props
    this.setState({ isLoading: true })
    let { error } = await authentication.confirmSignIn(code, client)
    if (error) {
      error =
        error === 'Confirmation code cannot be empty'
          ? 'Confirmation code cannot be empty'
          : 'Incorrect verification code entered'
      this.setState({
        error,
        open: true,
        isLoading: false
      })
    }
  }

  // TODO: redirect on click
  onForgotPasswordClick = () => {
    this.setState({
      ...clearState,
      ...{
        forgotPassword: true
      }
    })
  }

  onBackFromForgotPassword = message => {
    this.setState({
      ...clearState
    })
    if (message) {
      Snackbar(message, 'success')
    }
  }

  setOpenToFalse = () => {
    const { open } = this.state
    if (open) {
      this.setState({ open: false })
    }
  }

  render() {
    const {
      pin,
      password,
      reset,
      error,
      success,
      number,
      open,
      isLoading,
      forgotPassword
    } = this.state
    if (password || reset) {
      // if password reset required during login
      return (
        <EnterPassword
          reset={reset}
          tempPasswordReset={password}
          enterPassword={this.updatePassword}
          isLoading={isLoading}
          number={number}
          error={error}
          passwordMismatchError={error}
          setPasswordMismatchErrorError={this.setError}
          open={open}
          onBack={this.onBack}
          setOpenToFalse={this.setOpenToFalse}
        />
      )
    }
    if (pin) {
      return (
        <ConfirmPIN
          isLoading={isLoading}
          number={number}
          error={error}
          open={open}
          onBack={this.onBack}
          onSendPinClick={this.onNextClick}
          setOpenToFalse={this.setOpenToFalse}
        />
      )
    }
    // TODO: redirect to forgot password flow
    if (forgotPassword) {
      return <ForgotPasswordContainer onBack={this.onBackFromForgotPassword} />
    }
    return (
      <Login
        error={error}
        success={success}
        onLoginClick={this.onInitialLogin}
        open={open}
        number={number}
        isLoading={isLoading}
        onForgotPasswordClick={this.onForgotPasswordClick}
        setOpenToFalse={this.setOpenToFalse}
      />
    )
  }
}

ConfirmContainer.propTypes = propTypes
ConfirmContainer.defaultProps = defaultProps

export default ConfirmContainer
