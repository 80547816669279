import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import { ShiftScheduleContainer } from '../../../containers/shift-schedule'
import { ShiftInfoContainer } from '../../../containers/shift-info'
import { Header } from '../../theme/header'
import { PageLayout } from '../page-layout'
import styles from './style.css'

const propTypes = {
  currentStep: PropTypes.number.isRequired,
  locationId: PropTypes.string.isRequired,
  workplaceId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isSynced: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  startedEditing: PropTypes.bool.isRequired
}

const defaultProps = {
  isSynced: false
}

export class ShiftWizardComponent extends Component {
  shiftPage = () => {
    const {
      currentStep,
      locationId,
      workplaceId,
      history,
      isSynced
    } = this.props
    if (currentStep === 1) {
      return (
        <ShiftInfoContainer
          workplaceId={workplaceId}
          isSynced={isSynced}
          locationId={locationId}
        />
      )
    }
    return <ShiftScheduleContainer history={history} />
  }

  render() {
    const { onDelete, currentStep, startedEditing } = this.props
    return (
      <PageLayout>
        <Header
          isClose
          showModal={startedEditing}
          title="Discard Shift Request?"
          onDelete={onDelete}
          message="Discarding this request will also delete all of the information that you have entered so far."
        />
        <div className={styles.request_wrapper}>
          <Typography variant="h2">Request New Shift</Typography>
          <Typography variant="body2">
            {currentStep === 1
              ? 'Request for a shift to be filled'
              : 'When the shift needs to be filled'}
          </Typography>
        </div>
        {this.shiftPage()}
      </PageLayout>
    )
  }
}

ShiftWizardComponent.propTypes = propTypes
ShiftWizardComponent.defaultProps = defaultProps

export default ShiftWizardComponent
