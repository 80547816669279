import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import { AddTeamMemberContainer } from '../../../containers/add-team-member'
import { AddMemberPositionContainer } from '../../../containers/add-member-position'
import { TeamMemberDepartmentApollo } from '../../../containers/team-member-department'
import { Header } from '../../theme'
import { PageLayout } from '../page-layout'
import style from './style.css'

const propTypes = {
  teamMemberInfo: PropTypes.shape({
    memberId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    page: PropTypes.number,
    phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    positions: PropTypes.array,
    hireDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    primaryWorkplace: PropTypes.string,
    startedEditing: PropTypes.bool,
    employeeId: PropTypes.string,
    isTemp: PropTypes.bool,
    excludedLocation: PropTypes.array,
    isEdit: PropTypes.bool
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export class TeamWizardComponent extends Component {
  teamMemberPage = () => {
    const { teamMemberInfo, onDelete, onCancel } = this.props
    const { page } = teamMemberInfo
    if (page === 1)
      return <AddTeamMemberContainer teamMemberInfo={teamMemberInfo} />
    if (page === 2)
      return <AddMemberPositionContainer teamMemberInfo={teamMemberInfo} />
    return (
      <TeamMemberDepartmentApollo
        teamMemberInfo={teamMemberInfo}
        onDelete={onDelete}
        onCancel={onCancel}
      />
    )
  }

  render() {
    const { onDelete, teamMemberInfo } = this.props
    const { startedEditing, isEdit } = teamMemberInfo
    return (
      <PageLayout>
        {isEdit ? (
          <Header leftText={false} />
        ) : (
          <Header
            onDelete={onDelete}
            showModal={startedEditing}
            isClose
            title="Delete New Team Member?"
            message="Discarding this team member will delete all of the information you’ve entered so far."
          />
        )}
        {!isEdit && (
          <div className={style.team_main_header}>
            <Typography variant="h1">Add New Employee</Typography>
          </div>
        )}
        {this.teamMemberPage()}
      </PageLayout>
    )
  }
}

TeamWizardComponent.propTypes = propTypes

export default TeamWizardComponent
