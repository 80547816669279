// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-page-layout-style__wrap--1ayTa {\n  -ms-flex-positive: 1;\n      flex-grow: 1;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n\n  /* for Firefox: fixes scrolling */\n\n  /* note: when set at 100vh, breaks footer in mobile view */\n  min-height: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"wrap": "src-components-layouts-page-layout-style__wrap--1ayTa"
};
module.exports = exports;
