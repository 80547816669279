import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextField, Typography } from 'syrg-design-kit'
import { FooterComponent } from '../layouts/footer-navigation'
import Header from '../layouts/login-header'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import styles from './styles.css'

const propTypes = {
  number: PropTypes.string.isRequired,
  onSendPinClick: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  setOpenToFalse: PropTypes.func.isRequired
}

const defaultProps = {
  error: ''
}
class ConfirmPINComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      code: ''
    }
  }

  onChange(code) {
    const { setOpenToFalse } = this.props
    setOpenToFalse()
    this.setState({ code })
  }

  render() {
    const { code } = this.state
    const { onSendPinClick, number, onBack, error, isLoading } = this.props
    return (
      <PageLayout>
        <Header />
        <FlexBody>
          <div className={styles.confirm_pin_form}>
            <Typography variant="h1">Verify Your Number</Typography>
            <Typography variant="body1">{`Please enter the 6 digit code we sent to your phone number: ${number}`}</Typography>
            <TextField
              type="number"
              value={code}
              onChange={e => this.onChange(e.target.value)}
              label="Verification Code"
              required
              placeholder="123456"
              requiredText={error}
              errorText={error}
              forceShowError={error.length > 0}
              fullWidth
            />
          </div>
        </FlexBody>
        <FooterComponent
          onNextClick={onSendPinClick(code)}
          nextText="Log In"
          backText="Back"
          onBackClick={onBack}
          isLoading={isLoading}
          buttonInfo="All fields required"
          isLink
        />
      </PageLayout>
    )
  }
}

ConfirmPINComponent.propTypes = propTypes
ConfirmPINComponent.defaultProps = defaultProps

export default ConfirmPINComponent
