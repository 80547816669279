import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { TeamMembers } from '../../components/team-members'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { restrictedUsers } from '../../utils/constant'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  getShift: {
    currentShift: {
      manager: {
        id: ''
      },
      workplace: {
        corporation: {
          settings: ''
        }
      }
    }
  }
}

export class TeamMemberContainer extends Component {
  render() {
    const { getShift, history } = this.props
    const {
      currentShift: {
        workplace: {
          corporation: { settings }
        },
        manager: { id }
      }
    } = getShift
    const isRestrictedUser = restrictedUsers.includes(id)
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    const isRestricted = syncType === 'KRONOS-WFD'
    return (
      <TeamMembers
        isRestricted={isRestricted || isRestrictedUser}
        history={history}
      />
    )
  }
}

export const TeamMemberContainerApollo = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' })
)(TeamMemberContainer)

TeamMemberContainer.propTypes = propTypes
TeamMemberContainer.defaultProps = defaultProps

export default TeamMemberContainerApollo
