import { flowRight as compose } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { Snackbar } from 'syrg-design-kit'
import {
  updateCurrentDate,
  updateCurrentTime,
  updateShiftPage
} from '../../apollo/apollo-cache-mutation.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import ShiftScheduleComponent from '../../components/shift-schedule'
import { thirtyMinutes } from '../../utils/constant'
import { calculateDiff, calculateDiffInUnix } from '../../utils/time-formatter'

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateShiftStartDate: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  updateShiftTime: PropTypes.func.isRequired
}

const defaultProps = {
  getShift: {
    currentShift: {
      startTime: moment(),
      endTime: moment(),
      progress: 1,
      currentPage: 1
    }
  },
  updateCurrentPage: () => {}
}

export class ShiftSchedule extends Component {
  constructor(props) {
    super(props)
    const {
      getShift: { currentShift }
    } = props
    const isAmPm =
      currentShift?.startTime.includes('am') ||
      currentShift?.startTime.includes('pm')
    const selectedDate = currentShift?.startDate
      ? this.createMomentDate(currentShift?.startDate)
      : moment()
    const startTime = !isAmPm
      ? this.createDateFromHours(currentShift?.startTime, selectedDate)
      : moment()
          .add(1, 'h')
          .startOf('h')
    const endTime = !isAmPm
      ? this.createDateFromHours(currentShift?.endTime, selectedDate)
      : moment()
          .add(5, 'h')
          .startOf('h')
    this.state = {
      selectedDate,
      startTime,
      endTime,
      timeLength: this.getShiftDuration(startTime, endTime)
    }
    this.setShiftTimeInApollo(
      startTime.format('HH:mm'),
      endTime.format('HH:mm'),
      selectedDate.format('MMMM DD, YYYY')
    )
  }

  setShiftTimeInApollo = (startTime, endTime, startDate) => {
    const { updateShiftStartDate, updateShiftTime } = this.props
    if (startTime && endTime && startTime !== endTime) {
      updateShiftTime({
        variables: {
          startTime,
          endTime
        }
      })
    }
    if (startDate && (startTime === null || startTime !== endTime)) {
      updateShiftStartDate({
        variables: {
          startDate
        }
      })
    }
  }

  getShiftDuration = (startTime, endTime) => {
    if (endTime.isAfter(startTime)) {
      const result = calculateDiff(startTime, endTime)
      return result
    }
    const increasedEndTime = moment(endTime).add(1, 'd')
    return calculateDiff(startTime, increasedEndTime)
  }

  createMomentDate = dateString => {
    const [month, day, year] = dateString
      .split(' ')
      .map(datePart => datePart.replace(/[^\w\s]/gi, ''))
    return moment()
      .set('y', parseInt(year, 10))
      .set('month', month)
      .set('date', parseInt(day, 10))
      .set('h', 0)
  }

  createDateFromHours = (time, selectedDate) => {
    if (time) {
      const [hour, minute] = time.split(':')
      return moment(selectedDate)
        .hour(parseInt(hour, 10))
        .minute(minute)
    }
    return moment()
  }

  onBackClick = () => {
    const { updateCurrentPage } = this.props
    updateCurrentPage({
      variables: {
        currentPage: 1
      }
    })
  }

  onDateSelect = selectedDate => {
    const { startTime } = this.state
    const today = moment().startOf('day')
    const selectedTime = moment(startTime).set(
      'date',
      moment(selectedDate).date()
    )
    const momentStartDate = moment(selectedDate).startOf('day')
    if (!selectedTime.isAfter(moment()) || momentStartDate.isBefore(today)) {
      Snackbar("The shift can't start in the past", 'error')
      return
    }
    try {
      if (momentStartDate) {
        this.setShiftTimeInApollo(
          null,
          null,
          momentStartDate.format('MMMM DD, YYYY')
        )
        this.setState({
          selectedDate: moment(momentStartDate).add(1, 'millisecond')
        })
      }
    } catch (error) {
      Snackbar(error, 'error')
    }
  }

  setShiftTimeInState = (startTime, endTime) => {
    return {
      startTime,
      endTime,
      timeLength: this.getShiftDuration(startTime, endTime)
    }
  }

  onTimeChange = (time, type) => {
    this.setState(prevState => {
      const { startTime, endTime, selectedDate } = prevState
      const selectedTime = moment(time).set('date', selectedDate.date())
      const startTimeWithSelectedDate = moment(startTime).set(
        'date',
        selectedDate.date()
      )
      const endTimeWithSelectedDate = moment(endTime).set(
        'date',
        selectedDate.date()
      )
      const timeDifference =
        type === 'startTime'
          ? calculateDiffInUnix(selectedTime, endTimeWithSelectedDate)
          : calculateDiffInUnix(startTimeWithSelectedDate, selectedTime)
      const inThirtyMinutesRange =
        timeDifference > 0 && timeDifference <= thirtyMinutes

      if (inThirtyMinutesRange) {
        const startTimeToUpdateStateWith =
          type === 'startTime' ? selectedTime : startTimeWithSelectedDate
        const correctedEndTime = moment(startTimeToUpdateStateWith).add(30, 'm')
        return this.setShiftTimeInState(
          startTimeToUpdateStateWith,
          correctedEndTime
        )
      }

      const isSame =
        type === 'startTime'
          ? selectedTime.isSame(endTimeWithSelectedDate)
          : selectedTime.isSame(startTimeWithSelectedDate)

      if (isSame) {
        const correctedTime = moment(selectedTime).add(30, 'm')
        return this.setShiftTimeInState(selectedTime, correctedTime)
      }

      if (type === 'startTime') {
        if (selectedTime.isAfter(moment())) {
          return this.setShiftTimeInState(selectedTime, endTimeWithSelectedDate)
        }
        Snackbar('Start time cannot be in the past', 'error')
        return null
      }
      return this.setShiftTimeInState(startTimeWithSelectedDate, selectedTime)
    })
  }

  onNextClick = () => {
    const { history } = this.props
    const { startTime, endTime, selectedDate } = this.state
    if (startTime.isSame(endTime)) {
      Snackbar('Enter a valid start or end time.', 'error')
      return
    }
    this.setShiftTimeInApollo(
      startTime.format('HH:mm'),
      endTime.format('HH:mm'),
      selectedDate.format('MMMM DD, YYYY')
    )
    history.push('/summary')
  }

  render() {
    const { startTime, endTime, timeLength, selectedDate } = this.state
    return (
      <ShiftScheduleComponent
        selectedDate={selectedDate}
        startTime={startTime}
        endTime={endTime}
        timeLength={timeLength}
        onDateSelect={this.onDateSelect}
        onBackClick={this.onBackClick}
        onNextClick={this.onNextClick}
        onTimeChange={this.onTimeChange}
      />
    )
  }
}

export const ShiftScheduleContainer = compose(
  withApollo,
  graphql(getCurrentShift, {
    name: 'getShift'
  }),
  graphql(updateCurrentDate, { name: 'updateShiftStartDate' }),
  graphql(updateShiftPage, { name: 'updateCurrentPage' }),
  graphql(updateCurrentTime, { name: 'updateShiftTime' })
)(ShiftSchedule)

ShiftSchedule.propTypes = propTypes
ShiftSchedule.defaultProps = defaultProps

export default ShiftScheduleContainer
