// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-location-details-style__location_edit_info--3WSFq {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  margin-bottom: 30px;\n}\n\n.src-components-location-details-style__profile_wrap--24pi8 {\n  margin-bottom: 30px;\n}\n\n.src-components-location-details-style__loc_content--3ZYqO {\n  padding: 10px 0 32px 0;\n  font-size: 14px;\n  color: #707682;\n  line-height: 20px;\n}\n\n.src-components-location-details-style__address_text--rr04u {\n  margin-top: 10px;\n}\n\n.src-components-location-details-style__address_text--rr04u i {\n  margin-left: 5px;\n}\n\n.src-components-location-details-style__content_2--3l6T1 {\n  padding: 10px 0 20px 0;\n}\n\n.src-components-location-details-style__locations_box--15iLj {\n  padding: 40px 20px 12px 20px;\n}\n\n.src-components-location-details-style__location_detail_delete_loc--25PnI {\n  margin-top: 65px;\n  margin-bottom: 60px;\n}\n\n.src-components-location-details-style__location_detail_delete_loc--25PnI button {\n  padding: 0;\n}\n\n.src-components-location-details-style__location_detail_loading--GTVwg {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  height: 100vh;\n  font-size: 24px;\n  color: #2a53ba;\n}\n", ""]);
// Exports
exports.locals = {
	"location_edit_info": "src-components-location-details-style__location_edit_info--3WSFq",
	"profile_wrap": "src-components-location-details-style__profile_wrap--24pi8",
	"loc_content": "src-components-location-details-style__loc_content--3ZYqO",
	"address_text": "src-components-location-details-style__address_text--rr04u",
	"content_2": "src-components-location-details-style__content_2--3l6T1",
	"locations_box": "src-components-location-details-style__locations_box--15iLj",
	"location_detail_delete_loc": "src-components-location-details-style__location_detail_delete_loc--25PnI",
	"location_detail_loading": "src-components-location-details-style__location_detail_loading--GTVwg"
};
module.exports = exports;
