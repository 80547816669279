import React, { Component } from 'react'
import moment from 'moment-timezone'
import { Snackbar } from 'syrg-design-kit'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { TeamMemberFormComponent } from '../../components/team-member-form'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import {
  createTeamMember,
  updateMemberEditing
} from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  createNewTeamMember: PropTypes.func,
  updateTeamEditing: PropTypes.func,
  teamMemberInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    page: PropTypes.number,
    phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    positions: PropTypes.array,
    hireDate: PropTypes.number,
    primaryWorkplace: PropTypes.string,
    preferredLanguage: PropTypes.string,
    employeeId: PropTypes.string,
    isTemp: PropTypes.bool
  }).isRequired,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  createNewTeamMember: () => {},
  updateTeamEditing: () => {},
  getShift: {}
}

export class AddTeamMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: props.teamMemberInfo.firstName,
      lastName: props.teamMemberInfo.lastName,
      phoneNumber: props.teamMemberInfo.phoneNumber,
      hireDate: moment(
        moment
          .unix(props.teamMemberInfo.hireDate)
          .utc()
          .format('MM/DD/YYYY'),
        'MM/DD/YYYY'
      ),
      preferredLanguage: props.teamMemberInfo.preferredLanguage || 'English',
      employeeId: props.teamMemberInfo.employeeId,
      isTemp: props.teamMemberInfo.isTemp,
      error: {
        firstName: false,
        lastName: false,
        validNumber: false
      }
    }
  }

  onTextFieldChange = (value, key) => {
    const { employeeId, firstName, lastName, phoneNumber } = this.state
    if (
      employeeId === '' &&
      firstName === '' &&
      lastName === '' &&
      phoneNumber === ''
    ) {
      const { updateTeamEditing } = this.props
      updateTeamEditing()
    }
    if (key === 'employeeId' || key === 'firstName' || key === 'lastName') {
      this.setState({ [key]: value.trim() })
    } else {
      this.setState({ [key]: value })
    }
  }

  validate = () => {
    const { phoneNumber } = this.state
    let err = false
    let errorText = ''
    const value = `${phoneNumber}`.toString().replace(/\D/g, '')
    if (!value || value.length < 10) {
      err = true
      errorText = "Enter employee's phone number"
    }
    return { isError: err, errorText }
  }

  onDateChanged = date => {
    if (moment(date).isBefore(moment()) && moment(date).year() >= 1900) {
      this.setState({ hireDate: moment(date) })
    } else {
      Snackbar('Please check the hire date for accuracy.', 'error', 3000)
    }
  }

  onNextClick = () => {
    const { createNewTeamMember } = this.props
    const {
      firstName,
      lastName,
      hireDate,
      employeeId,
      phoneNumber,
      preferredLanguage,
      isTemp
    } = this.state
    const Phonenumber = `${phoneNumber}`.toString().replace(/\D/g, '')
    const FirstName = firstName === ''
    const LastName = lastName === ''
    const PhoneNumber = phoneNumber === '' || Phonenumber.length < 10
    if (FirstName || LastName || PhoneNumber) {
      this.setState({
        error: {
          firstName: FirstName,
          lastName: LastName,
          validNumber: PhoneNumber
        }
      })
      Snackbar('Please fill in all fields', 'warning', 5000)
    } else if (this.errorCheck()) {
      const hDate = moment(hireDate).format('YYYY-MM-DD')
      const formatHireDate = moment.utc(hDate, 'YYYY-MM-DD').unix()
      createNewTeamMember({
        variables: {
          page: 2,
          firstName,
          lastName,
          phoneNumber: Number(`${phoneNumber}`.toString().replace(/\D/g, '')),
          hireDate: formatHireDate,
          employeeId,
          preferredLanguage,
          isTemp
        }
      })
    }
  }

  errorCheck = () => {
    const { firstName, lastName, phoneNumber } = this.state
    const num = `${phoneNumber}`.toString().replace(/\D/g, '')
    if (
      !/^[a-zA-Z][a-zA-Z\s-]{0,30}$/.test(firstName) ||
      firstName.trim().length === 0
    ) {
      Snackbar('Must enter valid first name', 'error', 3000)
      return false
    }
    if (
      !/^[a-zA-Z][a-zA-Z\s-]{0,30}$/.test(lastName) ||
      lastName.trim().length === 0
    ) {
      Snackbar('Must enter valid last name', 'error', 3000)
      return false
    }
    if (`${num}`.toString().length < 10) {
      this.setState({
        error: {
          validNumber: true
        }
      })
      Snackbar('Must enter valid phone number', 'error', 3000)
      return false
    }
    return true
  }

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      hireDate,
      preferredLanguage,
      employeeId,
      isTemp,
      error
    } = this.state
    const { getShift: { currentShift } = {} } = this.props
    return (
      <TeamMemberFormComponent
        onTextFieldChange={this.onTextFieldChange}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        onDateChanged={this.onDateChanged}
        hireDate={hireDate}
        workplaceId={currentShift?.workplace?.id || ''}
        onNextClick={this.onNextClick}
        employeeId={employeeId}
        preferredLanguage={preferredLanguage}
        isTemp={isTemp}
        error={error}
        validate={this.validate}
      />
    )
  }
}

export const AddTeamMemberContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(createTeamMember, { name: 'createNewTeamMember' }),
  graphql(updateMemberEditing, { name: 'updateTeamEditing' })
)(AddTeamMember)

AddTeamMember.propTypes = propTypes
AddTeamMember.defaultProps = defaultProps

export default AddTeamMemberContainer
