// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-advance-options-style__advance_load--3wij4 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  font-size: 24px;\n  color: #2a53ba;\n}\n\n.src-components-advance-options-style__shift_wrap_info--18u0X {\n  padding: 0 20px;\n}\n\n.src-components-advance-options-style__shift_note--1TxdB {\n  padding-top: 35px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n}\n\n.src-components-advance-options-style__shift_note--1TxdB .src-components-advance-options-style__section_header--ZGM1q {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-components-advance-options-style__shift_note--1TxdB .MuiTypography-caption {\n  margin-bottom: 10px;\n}\n\n.src-components-advance-options-style__shift_note--1TxdB .src-components-advance-options-style__section_header--ZGM1q h4 {\n  -ms-flex: 1 1;\n      flex: 1 1;\n}\n\n.src-components-advance-options-style__instruction_fields--1wg5O {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  width: 100%;\n}\n\n.src-components-advance-options-style__managers_to_notify--13BLN {\n  margin-bottom: 50px;\n}\n\n.src-components-advance-options-style__managers_to_notify--13BLN p {\n  margin-bottom: 8px;\n}\n\n.src-components-advance-options-style__info_icon--2wPR7 {\n  color: #90959e;\n}\n\n.src-components-advance-options-style__edit_icon--3qQP8 {\n  margin-left: 5px;\n}\n\n.src-components-advance-options-style__info_icon--2wPR7 {\n  margin-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"advance_load": "src-components-advance-options-style__advance_load--3wij4",
	"shift_wrap_info": "src-components-advance-options-style__shift_wrap_info--18u0X",
	"shift_note": "src-components-advance-options-style__shift_note--1TxdB",
	"section_header": "src-components-advance-options-style__section_header--ZGM1q",
	"instruction_fields": "src-components-advance-options-style__instruction_fields--1wg5O",
	"managers_to_notify": "src-components-advance-options-style__managers_to_notify--13BLN",
	"info_icon": "src-components-advance-options-style__info_icon--2wPR7",
	"edit_icon": "src-components-advance-options-style__edit_icon--3qQP8"
};
module.exports = exports;
