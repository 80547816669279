import React from 'react'
import SyrgLogo from '../../theme/asset/SYRGlogoBlue.png'
import styles from './style.css'

const Header = () => (
  <div className={styles.logo_container}>
    <img width="135px" height="50px" src={SyrgLogo} alt="syrg logo" />
  </div>
)

export default Header
