import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, TextField } from 'syrg-design-kit'
import { Header } from '../theme'
import FooterButton from '../layouts/footer-button'
import style from './style.css'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import { FooterComponent } from '../layouts/footer-navigation'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onNextClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    validNumber: PropTypes.bool
  }),
  validate: PropTypes.func,
  isEdit: PropTypes.bool,
  children: PropTypes.node
}

const defaultProps = {
  phoneNumber: '',
  lastName: '',
  firstName: '',
  isLoading: false,
  error: {},
  validate: () => {},
  isEdit: false,
  children: <></>
}

export class ManagerFormComponent extends Component {
  render() {
    const {
      onChange,
      phoneNumber,
      lastName,
      firstName,
      onNextClick,
      onDelete,
      isLoading,
      error,
      validate,
      isEdit,
      children
    } = this.props
    const isDataFilled =
      firstName === '' && lastName === '' && phoneNumber === ''

    return (
      <PageLayout>
        {isEdit ? (
          <Header leftText={false} />
        ) : (
          <Header
            isClose
            showModal={!isDataFilled}
            onDelete={onDelete}
            title="Delete New Manager?"
            message="Discarding this manager will delete all of the information you’ve entered so far."
          />
        )}
        <FlexBody>
          <div className={style.manager_form_header}>
            {isEdit && <Typography variant="h1">Manager Details</Typography>}
            {!isEdit && <Typography variant="h2">Add New Manager</Typography>}
            {!isEdit && (
              <Typography variant="body2">
                Adding a new manager will allow them to create shift requests
                and manage employees within SYRG.
              </Typography>
            )}
          </div>
          <div className={style.manager_form_wrapper}>
            <TextField
              label="First Name"
              data-id="firstName"
              placeholder="First Name"
              onChange={e => onChange(e.target.value, 'firstName')}
              value={firstName}
              required
              fullWidth
              requiredText="Enter manager's first name"
              forceShowError={error.firstName}
              error={error.firstName}
            />
            <TextField
              label="Last Name"
              data-id="lastName"
              placeholder="Last Name"
              onChange={e => onChange(e.target.value, 'lastName')}
              value={lastName}
              required
              fullWidth
              requiredText="Enter manager's last name"
              forceShowError={error.lastName}
              error={error.lastName}
            />
            <TextField
              label="Phone"
              type="phone"
              data-id="phoneNumber"
              placeholder="Phone Number"
              id="my-input-id"
              validator={validate}
              onChange={e => onChange(e.target.value, 'phoneNumber')}
              value={phoneNumber}
              required
              fullWidth
              requiredText="Enter manager's phone number"
              forceShowError={error.validNumber}
              error={error.validNumber}
            />
            {children}
          </div>
        </FlexBody>
        {!isEdit ? (
          <FooterButton
            buttonInfo="All fields required"
            data-id="next-btn"
            isLoading={isLoading}
            onClick={onNextClick}
          >
            Next
          </FooterButton>
        ) : (
          <FooterComponent
            backText="Cancel"
            nextText="Save Changes"
            onBackClick={onDelete}
            onNextClick={onNextClick}
          />
        )}
      </PageLayout>
    )
  }
}

ManagerFormComponent.propTypes = propTypes
ManagerFormComponent.defaultProps = defaultProps
export default ManagerFormComponent
