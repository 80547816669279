import React from 'react'
import PropTypes from 'prop-types'
import { DropdownMenu, MenuItem } from 'syrg-design-kit'

const DropDownComponent = props => {
  const {
    dataId,
    onChange,
    data,
    isSynced,
    error,
    helperText,
    defaultValue,
    label,
    disableError
  } = props
  return (
    <DropdownMenu
      label={label}
      data-id="dropdown"
      value={dataId}
      onChange={(e, value) => {
        onChange(e.target.value, value, defaultValue)
      }}
      helperText={disableError || error ? helperText : ''}
      error={disableError ? false : error}
      width={100}
    >
      {defaultValue !== '' && (
        <MenuItem disabled key={0} value={defaultValue}>
          {defaultValue}
        </MenuItem>
      )}
      {data.map(v => (
        <MenuItem key={v.id} name={v.name} value={isSynced ? v.alias : v.name}>
          {isSynced ? v.alias : v.name}
        </MenuItem>
      ))}
    </DropdownMenu>
  )
}

DropDownComponent.propTypes = {
  dataId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      alias: PropTypes.string
    }).isRequired
  ).isRequired,
  isSynced: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disableError: PropTypes.bool
}

DropDownComponent.defaultProps = {
  isSynced: false,
  error: {},
  disableError: false
}

export default DropDownComponent
