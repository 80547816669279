// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-theme-validation-text-style__text_wrapper--i2a0v h6 {\n  color: #d32f2f;\n  font-size: 12px;\n  margin-top: 15px;\n  margin-bottom: 15px;\n  padding: 0 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"text_wrapper": "src-components-theme-validation-text-style__text_wrapper--i2a0v"
};
module.exports = exports;
