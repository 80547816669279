import gql from 'graphql-tag'

export const getUserByCognitoId = gql`
  query getUserByCognitoId($id: ID!) {
    getUserByCognitoId(id: $id) {
      id
      firstName
      lastName
      phoneNumber
    }
  }
`
export default getUserByCognitoId
