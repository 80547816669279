import gql from 'graphql-tag'

export const getLocation = gql`
  query getDepartmentsByWorkplaceId($id: ID!) {
    getDepartmentsByWorkplaceId(input: { workplaceId: $id }) {
      id
      name
      alias
      message
      addressJson
    }
  }
`

export const workplacePositionsQuery = gql`
  query Workplace($input: WorkplaceInput!) {
    Workplace(input: $input) {
      positions {
        id
        name
        alias
        color
      }
    }
  }
`

export default { getLocation, workplacePositionsQuery }
