export const location = {
  Mutation: {
    addLocation: (
      _,
      { name, street, city, zipcode, state, page },
      { cache }
    ) => {
      const data = {
        location: {
          __typename: 'Location',
          name,
          street,
          city,
          zipcode,
          state,
          page
        }
      }
      cache.writeData({ data })
      return null
    },
    addSupervisor: (
      _,
      { supervisorId, primaryArea, message, page },
      { cache }
    ) => {
      const data = {
        location: {
          __typename: 'Location',
          page,
          supervisorId,
          primaryArea,
          message
        }
      }
      cache.writeData({ data })
      return null
    },
    clearLocation: (_, variables, { cache }) => {
      const data = {
        location: {
          __typename: 'Location',
          page: 1,
          startedEditing: false,
          locationId: '',
          name: '',
          street: '',
          city: '',
          zipcode: '',
          state: '',
          supervisorId: '',
          primaryArea: '',
          message: ''
        }
      }
      cache.writeData({ data })
      return null
    },
    updateLocationId: (_, { locationId }, { cache }) => {
      const data = {
        location: {
          __typename: 'Location',
          locationId
        }
      }
      cache.writeData({ data })
      return null
    },
    updateWorkplaceId: (_, { workplaceId }, { cache }) => {
      const data = {
        location: {
          __typename: 'Location',
          primaryArea: workplaceId
        }
      }
      cache.writeData({ data })
      return null
    },
    updateLocationEditing: (_, variables, { cache }) => {
      const data = {
        location: {
          __typename: 'Location',
          startedEditing: true
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

export const defaultLocation = {
  location: {
    __typename: 'Location',
    page: 1,
    startedEditing: false,
    locationId: '',
    name: '',
    street: '',
    city: '',
    zipcode: '',
    state: '',
    supervisorId: '',
    primaryArea: '',
    message: ''
  }
}
