import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, Typography } from 'syrg-design-kit'
import { Header } from '../theme'
import FooterButton from '../layouts/footer-button'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import style from './style.css'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  positionName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired
}

const defaultProps = {}

class NamePositionComponent extends Component {
  render() {
    const { isEdit, positionName, onChange, onSubmit, onReset } = this.props
    return (
      <PageLayout>
        <Header
          isClose
          showModal={positionName !== ''}
          onDelete={onReset}
          title="Discard New Position?"
          message="Discarding this new position will delete all of the information you’ve entered so far."
        />
        <FlexBody>
          <div className={style.create_position}>
            <Typography variant="h2">
              {isEdit ? 'Rename Position' : 'Create New Position'}
            </Typography>
            <Typography variant="body2">
              {isEdit
                ? 'This position is universal across your entire organization.'
                : 'This new position will be universal across your entire organization.'}
            </Typography>
            <TextField
              label="Position Name"
              placeholder="Position Name"
              onChange={e => onChange(e.target.value)}
              value={positionName}
              data-id="position_name"
              fullWidth
            />
          </div>
        </FlexBody>
        <FooterButton onClick={onSubmit} buttonInfo="All Fields Required">
          {isEdit ? 'Save' : 'Next'}
        </FooterButton>
      </PageLayout>
    )
  }
}

NamePositionComponent.propTypes = propTypes
NamePositionComponent.defaultProps = defaultProps

export default NamePositionComponent
