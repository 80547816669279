import gql from 'graphql-tag'

export const renamePositionMutation = gql`
  mutation renamePosition($input: renamePositionInput!) {
    renamePosition(input: $input) {
      positionId
    }
  }
`

export const fetchWorkplacePositions = gql`
  query Workplace($input: WorkplaceInput!) {
    Workplace(input: $input) {
      positions {
        id
        name
      }
    }
  }
`
export default { renamePositionMutation }
