import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { flowRight as compose } from 'lodash'
import { graphql, withApollo } from 'react-apollo'
import { ShiftRequest } from '../../components/shift-request'
import { getFutureShift } from './upcoming-shifts.gql'

const propTypes = {
  onTabChange: PropTypes.func.isRequired,
  onCreateShift: PropTypes.func.isRequired,
  restartPhoneTree: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  isSynced: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getFutureShifts: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  getFutureShifts: { UpcomingShifts: [] }
}

export class UpcomingShifts extends Component {
  processShiftsData = (shiftsQueryData, tz) => {
    if (shiftsQueryData?.length) {
      const rawShifts = shiftsQueryData
      return rawShifts.map(shift => {
        const { startTime, endTime } = shift
        if (!startTime.includes('m')) {
          return {
            ...shift,
            startDate: moment
              .tz(moment.unix(startTime), tz)
              .format('MMMM D, YYYY'),
            startTime: moment.tz(moment.unix(startTime), tz).format('h:mma'),
            endTime: moment.tz(moment.unix(endTime), tz).format('h:mma')
          }
        }
        return shift
      })
    }
    return []
  }

  render() {
    const {
      getFutureShifts,
      timezone,
      onCreateShift,
      onTabChange,
      restartPhoneTree,
      isSynced,
      history
    } = this.props

    const props = {
      tabId: 1,
      restartPhoneTree,
      onTabChange,
      onCreateShift,
      isSynced,
      history
    }
    if (getFutureShifts.loading) {
      return <ShiftRequest isLoading {...props} />
    }
    const shifts = this.processShiftsData(
      getFutureShifts?.UpcomingShifts,
      timezone
    )
    return <ShiftRequest shifts={shifts} {...props} />
  }
}

export const UpcomingShiftsContainer = compose(
  withApollo,
  graphql(getFutureShift, {
    skip: ownProps => !ownProps.workplaceId,
    options: ownProps => ({
      variables: {
        workplaceId: ownProps.workplaceId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getFutureShifts'
  })
)(UpcomingShifts)

UpcomingShifts.propTypes = propTypes
UpcomingShifts.defaultProps = defaultProps

export default UpcomingShiftsContainer
