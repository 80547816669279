import React, { Component } from 'react'
import { Snackbar } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose, orderBy } from 'lodash'
import { LocationDetailsComponent } from '../../components/location-details'
import { fetchLocationInfo, deleteLocation } from './location-info.gql'
import { restrictedUsers } from '../../utils/constant'
import {
  getCurrentShift,
  getLocation
} from '../../apollo/apollo-cache-query.gql'
import { fetchWorkplaceLocations } from '../location-table/location-table.gql'

const propTypes = {
  getLocationInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  deleteLoc: PropTypes.func
}

const defaultProps = {
  getLocationInfo: {},
  getShift: {},
  deleteLoc: () => {},
  history: { push: () => {}, goBack: () => {} }
}

export class LocationDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditEligible: false
    }
  }

  onBackClick = () => {
    const { history } = this.props
    history.goBack()
  }

  onLocationDelete = id => {
    const { deleteLoc, getShift, history } = this.props
    deleteLoc({
      variables: {
        input: {
          id
        }
      },
      update: cache => {
        try {
          const workplaceId = getShift.currentShift.workplace.id
          const locations = cache.readQuery({
            query: fetchWorkplaceLocations,
            variables: { id: workplaceId }
          })
          const filteredLocations = locations.getDepartmentsByWorkplaceId.filter(
            location => location.id !== id
          )
          cache.writeQuery({
            query: fetchWorkplaceLocations,
            variables: { id: workplaceId },
            data: { getDepartmentsByWorkplaceId: filteredLocations }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(() => {
      Snackbar('Location deleted successfully', 'success', 3000)
      history.goBack()
    })
  }

  onCancel = () => {
    this.setState(prevState => ({ isEditEligible: !prevState.isEditEligible }))
  }

  gotoEligibleTeamMember = () => {
    const { history } = this.props
    history.push('/eligibleTeamMember')
  }

  render() {
    const { getLocationInfo, getShift, history } = this.props
    const {
      manager: { id },
      workplace: {
        corporation: { settings }
      }
    } = getShift?.currentShift
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    const isSynced = syncType === 'KRONOS-WFD'
    const isRestricted = restrictedUsers.includes(id)
    const sortedEligibleMember = orderBy(
      getLocationInfo.Department?.employees,
      [
        user => user.firstName?.toLowerCase(),
        user => user.lastName?.toLowerCase()
      ],
      ['asc']
    )
    if (getLocationInfo?.loading) {
      return <LocationDetailsComponent isLoading />
    }
    return (
      <LocationDetailsComponent
        isSynced={isSynced}
        onDeleteClick={this.onLocationDelete}
        isRestricted={isRestricted}
        location={getLocationInfo.Department}
        sortedEligibleMember={sortedEligibleMember}
        onBackClick={this.onBackClick}
        gotoEligibleTeamMember={this.gotoEligibleTeamMember}
        history={history}
      />
    )
  }
}
const LocationDetailsContainer = compose(
  withApollo,
  graphql(getLocation, { name: 'getLocationData' }),
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(deleteLocation, { name: 'deleteLoc' }),
  graphql(fetchLocationInfo, {
    skip: ({ getLocationData }) =>
      !getLocationData.location || !getLocationData.location.locationId,
    options: ({ getLocationData }) => ({
      variables: {
        id: getLocationData?.location?.locationId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getLocationInfo'
  })
)(LocationDetails)

LocationDetails.propTypes = propTypes
LocationDetails.defaultProps = defaultProps

export default LocationDetailsContainer
