import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEqual, flowRight as compose } from 'lodash'
import { graphql, withApollo } from 'react-apollo'
import { Snackbar } from 'syrg-design-kit'
import { EligibleTeamMember } from '../../components/eligible-team-member'
import { fetchLocationInfo } from '../location-details/location-info.gql'
import { fetchWorkplaceMembers } from '../team-member-table/team-member-table.gql'
import { checkCharacterOccurrence } from '../../utils/search-string'
import { updateEligibleMemberMutation } from './eligible-team-member.gql'
import { getEmployee, getDisplayHash } from '../../utils/search-table-methods'
import {
  getCurrentShift,
  getLocation
} from '../../apollo/apollo-cache-query.gql'

const propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired,
  getLocationInfo: PropTypes.shape({
    loading: PropTypes.bool,
    isLoading: PropTypes.bool,
    Department: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      employees: PropTypes.arrayOf(PropTypes.object)
    })
  }),
  updateEligibleMember: PropTypes.func,
  getLocationData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  getShift: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

const defaultProps = {
  getLocationInfo: {},
  updateEligibleMember: () => {},
  getLocationData: {},
  getShift: {}
}

export class EligibleTeamMemberContainer extends Component {
  constructor(props) {
    super(props)
    const {
      getLocationInfo: { Department: { employees = [] } = {} }
    } = props
    const teamMembers = getEmployee(employees)
    this.state = {
      selectedUsers: teamMembers.map(data => data.id),
      isSearch: false,
      searchedMembers: []
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { teamMemberData, isSearch } = prevState
    const {
      getLocationInfo: {
        loading: isLoading,
        Department: { employees = [] } = {}
      }
    } = nextProps
    if (isLoading) return { isLoading }
    if ((employees || []).length) {
      const teamMembers = getEmployee(employees)
      if (!isEqual(teamMemberData, teamMembers)) {
        return {
          isLoading: false,
          teamMemberData: teamMembers,
          teamMembers: getDisplayHash(teamMembers, teamMembers.length)
        }
      }
      return {
        teamMembers: getDisplayHash(teamMembers, teamMembers.length),
        isLoading: false
      }
    }
    if (isSearch) {
      return {
        isLoading: false,
        isSearch: !isSearch
      }
    }
    return {
      isLoading: false
    }
  }

  onCheckChange = userID => {
    const { selectedUsers } = this.state
    const foundIndex = selectedUsers.findIndex(id => id === userID)
    if (foundIndex !== -1) selectedUsers.splice(foundIndex, 1)
    else selectedUsers.push(userID)
    this.setState({ selectedUsers })
  }

  onSearchMember = word => {
    let { isSearch, searchedMembers } = this.state
    isSearch = !!word.length
    if (word.length) {
      const { teamMemberData } = this.state
      searchedMembers = []
      if (teamMemberData) {
        teamMemberData.forEach(mapped => {
          const name = mapped.name?.toLowerCase()
          const toFilter = checkCharacterOccurrence(name, word)
          if (toFilter) {
            searchedMembers.push(mapped)
          }
        })
      }
      searchedMembers = getDisplayHash(searchedMembers, searchedMembers.length)
    }
    this.setState({ isSearch, searchedMembers })
  }

  onCancelClick = () => {
    const { history } = this.props
    history.goBack()
  }

  onSaveClick = selectedUsers => {
    const {
      getLocationData,
      getShift: { currentShift: { workplace: { id = '' } = {} } = {} },
      updateEligibleMember
    } = this.props
    updateEligibleMember({
      variables: {
        input: {
          id: getLocationData?.location?.locationId,
          userIds: selectedUsers
        }
      },
      update: cache => {
        try {
          const locationInfo = cache.readQuery({
            query: fetchLocationInfo,
            variables: { id: getLocationData?.location?.locationId }
          })
          const memberInfo = cache.readQuery({
            query: fetchWorkplaceMembers,
            variables: { input: { id } }
          })
          selectedUsers.forEach(memberId => {
            if (
              !locationInfo.Department.employees.find(
                emp => emp.id === memberId
              )
            ) {
              locationInfo.Department.employees.push(
                memberInfo.Workplace.employees.find(m => m.id === memberId)
              )
            }
          })

          cache.writeQuery({
            query: fetchLocationInfo,
            variables: { id: getLocationData?.location?.locationId },
            data: { Department: locationInfo.Department }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    })
    Snackbar('Eligible employees saved successfully.', 'success', 3000)
    this.onCancelClick()
  }

  updateSelectedUsers = selectedUsers => this.setState({ selectedUsers })

  render() {
    const {
      getLocationInfo: { Department: { name = '' } = {} }
    } = this.props
    const { selectedUsers } = this.state
    return (
      <EligibleTeamMember
        selectedUsers={selectedUsers}
        onSubmit={this.onSaveClick}
        name={name}
        updateSelectedUsers={this.updateSelectedUsers}
        onBackClick={this.onCancelClick}
      />
    )
  }
}

export const EligibleTeamMemberContainerApollo = compose(
  withApollo,
  graphql(getLocation, { name: 'getLocationData' }),
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(fetchLocationInfo, {
    skip: ({ getLocationData }) =>
      !getLocationData.location || !getLocationData.location.locationId,
    options: ({ getLocationData }) => ({
      variables: {
        id: getLocationData?.location?.locationId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getLocationInfo'
  }),
  graphql(updateEligibleMemberMutation, { name: 'updateEligibleMember' })
)(EligibleTeamMemberContainer)

EligibleTeamMemberContainer.propTypes = propTypes
EligibleTeamMemberContainer.defaultProps = defaultProps

export default EligibleTeamMemberContainerApollo
