import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { CallPaceComponent } from '../../components/call-pace'
import { updateMinBetweenCall } from '../../apollo/apollo-cache-mutation.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'

const propTypes = {
  updateMinCall: PropTypes.func,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  updateMinCall: () => {},
  getShift: {
    currentShift: {
      minBetweenCall: 'Fast'
    }
  }
}

export class CallPaceContainer extends Component {
  constructor(props) {
    super(props)
    const { getShift } = props
    let minBetweenCall = ''
    if (getShift?.currentShift)
      minBetweenCall = getShift.currentShift.minBetweenCall
    this.state = {
      selectedMin:
        parseInt(minBetweenCall, 10) >= 1 ? 'Custom' : minBetweenCall,
      minutes: parseInt(minBetweenCall, 10) >= 1 ? minBetweenCall : '5'
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { getShift } = nextProps
    let minBetweenCall = ''
    if (getShift?.currentShift)
      minBetweenCall = getShift.currentShift.minBetweenCall
    const { selectedMin } = state
    if (minBetweenCall !== selectedMin) {
      return {
        selectedMin:
          parseInt(minBetweenCall, 10) >= 1 ? 'Custom' : minBetweenCall,
        minutes: parseInt(minBetweenCall, 10) >= 1 ? minBetweenCall : '5'
      }
    }
    return null
  }

  onMinChange = (key, value, minutes) => {
    const { updateMinCall } = this.props
    if (value === 'Custom' || key === 'minutes') {
      if (minutes > 0)
        updateMinCall({
          variables: {
            minBetweenCall: minutes
          }
        })
    } else if (key === 'selectedMin') {
      updateMinCall({
        variables: {
          minBetweenCall: value
        }
      })
    }
  }

  getTime = () => {
    const { getShift } = this.props
    const settings = getShift?.currentShift?.workplace?.corporation?.settings
    const settingsObj = settings && JSON.parse(settings)
    const fast = settingsObj?.timeBetweenCalls?.fast
    const normal = settingsObj?.timeBetweenCalls?.normal

    const fastMin = fast === undefined ? 1 : Number(fast?.split(':')[1])
    const fastMins = fastMin >= 2 ? 'mins' : 'min'
    const fastSec =
      Number(fast?.split(':')[2]) > 0 ? Number(fast?.split(':')[2]) : 0
    const fastTime =
      fastSec > 0
        ? `(${fastMin} ${fastMins} ${fastSec} sec)`
        : `(${fastMin} ${fastMins})`

    const normalMin = normal === undefined ? 3 : Number(normal?.split(':')[1])
    const normalMins = normalMin >= 2 ? 'mins' : 'min'
    const normalSec =
      Number(fast?.split(':')[2]) > 0 ? Number(fast?.split(':')[2]) : 0
    const normalTime =
      normalSec > 0
        ? `(${normalMin} ${normalMins} ${normalSec} sec)`
        : `(${normalMin} ${normalMins})`
    return { fastTime, normalTime }
  }

  render() {
    const { fastTime, normalTime } = this.getTime()
    const { selectedMin, minutes } = this.state
    return (
      <CallPaceComponent
        fastTime={fastTime}
        normalTime={normalTime}
        onMinChange={this.onMinChange}
        selectedMin={selectedMin}
        minutes={minutes}
      />
    )
  }
}

export const CallPace = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateMinBetweenCall, { name: 'updateMinCall' })
)(CallPaceContainer)

CallPaceContainer.propTypes = propTypes
CallPaceContainer.defaultProps = defaultProps

export default CallPace
