import PropTypes from 'prop-types'
import React from 'react'
import { Link, Typography } from 'syrg-design-kit'
import style from './style.css'

export const Footer = props => {
  const { children, buttonInfo, isLink } = props
  return (
    <div className={style.bottom_wrapper}>
      {(buttonInfo || isLink) && (
        <div className={style.info}>
          {buttonInfo && (
            <Typography variant="caption">
              <i>{buttonInfo}</i>
            </Typography>
          )}
          {isLink && (
            <Link href="https://www.syrghq.com">Visit www.syrghq.com</Link>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  buttonInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  isLink: PropTypes.bool
}

Footer.defaultProps = {
  buttonInfo: false,
  isLink: false
}

export default Footer
