import React, { Component } from 'react'
import moment from 'moment-timezone'
import { Snackbar } from 'syrg-design-kit'
import { graphql, withApollo } from 'react-apollo'
import { sortBy, flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { TeamMemberFormComponent } from '../../components/team-member-form'
import { fetchMemberInfo } from '../employee-details/employee-details.gql'
import { fetchWorkplace } from '../select-workplace-header/workplace-header.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import {
  updateUserMutation,
  updateCorporationUserMutation
} from './update-team-member.gql'

const propTypes = {
  children: PropTypes.node,
  isEdit: PropTypes.bool,
  updateUser: PropTypes.func,
  updateCorporationUser: PropTypes.func,
  teamMemberInfo: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hireDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    primaryWorkplace: PropTypes.string,
    preferredLanguage: PropTypes.string,
    employeeId: PropTypes.string,
    isTemp: PropTypes.bool,
    seniority: PropTypes.number,
    excludedLocation: PropTypes.array
  }).isRequired,
  getWorkplace: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onCancel: PropTypes.func
}

const defaultProps = {
  isEdit: false,
  getWorkplace: {},
  updateUser: () => {},
  getShift: {},
  children: <></>,
  onCancel: () => {},
  updateCorporationUser: () => {}
}

export class UpdateTeamMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: props.teamMemberInfo.firstName,
      lastName: props.teamMemberInfo.lastName,
      phoneNumber: props.teamMemberInfo.phoneNumber,
      hireDate: moment(
        moment
          .unix(props.teamMemberInfo.hireDate)
          .utc()
          .format('MM/DD/YYYY'),
        'MM/DD/YYYY'
      ),
      allWorkplace: [],
      primaryWorkplace: props.teamMemberInfo.primaryWorkplace,
      preferredLanguage: props.teamMemberInfo.preferredLanguage || 'English',
      employeeId: props.teamMemberInfo.employeeId,
      isTemp: props.teamMemberInfo.isTemp,
      error: {
        firstName: false,
        lastName: false,
        validNumber: false
      }
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { getWorkplace, getShift, teamMemberInfo } = nextProps
    const workplaces = getWorkplace?.User?.managedWorkplaces || []
    const corporationId = getShift?.currentShift?.workplace?.corporation?.id
    const managedWorkplaces = workplaces.filter(
      work => work.corporation.id === corporationId
    )
    const sortedWorkplace = sortBy(managedWorkplaces, x => x.name)
    return {
      allWorkplace: sortedWorkplace,
      primaryWorkplace: teamMemberInfo?.primaryWorkplace || ''
    }
  }

  onTextFieldChange = (value, key) => {
    if (key === 'employeeId' || key === 'firstName' || key === 'lastName') {
      this.setState({ [key]: value.trim() })
    } else {
      this.setState({ [key]: value })
    }
  }

  validate = () => {
    const { phoneNumber } = this.state
    let err = false
    let errorText = ''
    const value = `${phoneNumber}`.toString().replace(/\D/g, '')
    if (!value || value.length < 10) {
      err = true
      errorText = "Enter employee's phone number"
    }
    return { isError: err, errorText }
  }

  onDateChanged = date => {
    if (moment(date).isBefore(moment()) && moment(date).year() >= 1900) {
      this.setState({ hireDate: moment(date) })
    } else {
      Snackbar('Please check the hire date for accuracy.', 'error', 3000)
    }
  }

  onUpdate = () => {
    const {
      firstName,
      lastName,
      hireDate,
      phoneNumber,
      primaryWorkplace,
      preferredLanguage,
      isTemp
    } = this.state
    let { employeeId } = this.state
    const Phonenumber = `${phoneNumber}`.toString().replace(/\D/g, '')
    const FirstName = firstName === ''
    const LastName = lastName === ''
    const PhoneNumber = phoneNumber === '' || Phonenumber.length < 10
    if (FirstName || LastName || PhoneNumber) {
      this.setState({
        error: {
          firstName: FirstName,
          lastName: LastName,
          validNumber: PhoneNumber
        }
      })
      Snackbar('Please fill in all fields', 'warning', 5000)
    } else if (this.errorCheck()) {
      const hDate = moment(hireDate).format('YYYY-MM-DD')
      const formatHireDate = moment
        .utc(hDate, 'YYYY-MM-DD')
        .unix()
        .toString()
      const phone = Number(`${phoneNumber}`.toString().replace(/\D/g, ''))
      let plainPhoneNumber = phone
      if (phone.toString().length === 10)
        plainPhoneNumber = Number(1).toString() + phone

      const {
        onCancel,
        getShift,
        updateCorporationUser,
        updateUser,
        teamMemberInfo
      } = this.props
      const { excludedLocation } = teamMemberInfo
      const {
        workplace: {
          corporation: { id: corporationId }
        }
      } = getShift?.currentShift
      if (employeeId === null) {
        employeeId = ''
      }

      updateUser({
        variables: {
          input: {
            firstName,
            lastName,
            phoneNumber: Number(plainPhoneNumber),
            preferredLanguage,
            id: teamMemberInfo?.id
          }
        }
      }).then(data => {
        if (data?.data?.updateUser?.message) {
          Snackbar(data.data.updateUser.message, 'error', 3000)
        } else {
          updateCorporationUser({
            variables: {
              input: {
                corporationId,
                userId: teamMemberInfo.id,
                hireDate: formatHireDate,
                employeeId: employeeId.trim(),
                isTemp,
                primaryWorkplace,
                excludedDepartments: excludedLocation
              }
            },
            update: (cache, { data: { UpdateCorporationUser: user } }) => {
              try {
                const corporationUser = cache.readQuery({
                  query: fetchMemberInfo,
                  variables: { corporationId, userId: teamMemberInfo.id }
                })
                corporationUser.CorporationUser.user.firstName =
                  user.corporationUser.user.firstName
                corporationUser.CorporationUser.user.lastName =
                  user.corporationUser.user.lastName
                corporationUser.CorporationUser.user.phoneNumber =
                  user.corporationUser.user.phoneNumber
                corporationUser.CorporationUser.user.preferredLanguage =
                  user.corporationUser.user.preferredLanguage
                corporationUser.CorporationUser.hireDate =
                  user.corporationUser.hireDate
                corporationUser.CorporationUser.employeeId =
                  user.corporationUser.employeeId
                corporationUser.CorporationUser.isTemp =
                  user.corporationUser.isTemp
                corporationUser.CorporationUser.primaryWorkplace.id =
                  user.corporationUser.primaryWorkplace.id
                cache.writeQuery({
                  query: fetchMemberInfo,
                  variables: { corporationId, userId: teamMemberInfo.id },
                  data: { CorporationUser: corporationUser.CorporationUser }
                })
              } catch (error) {
                console.info('error', error)
              }
            }
          }).then(() => {
            Snackbar(
              'Successfully updated member information!',
              'success',
              2000
            )
            onCancel()
          })
        }
      })
    }
  }

  errorCheck = () => {
    const { firstName, lastName, phoneNumber } = this.state
    const num = `${phoneNumber}`.toString().replace(/\D/g, '')
    if (
      !/^[a-zA-Z][a-zA-Z\s-]{0,30}$/.test(firstName) ||
      firstName.trim().length === 0
    ) {
      Snackbar('Must enter valid first name', 'error', 3000)
      return false
    }
    if (
      !/^[a-zA-Z][a-zA-Z\s-]{0,30}$/.test(lastName) ||
      lastName.trim().length === 0
    ) {
      Snackbar('Must enter valid last name', 'error', 3000)
      return false
    }
    if (`${num}`.toString().length < 10) {
      this.setState({
        error: {
          validNumber: true
        }
      })
      Snackbar('Must enter valid phone number', 'error', 3000)
      return false
    }
    return true
  }

  render() {
    const { isEdit, children, teamMemberInfo, onCancel } = this.props
    const {
      firstName,
      lastName,
      phoneNumber,
      hireDate,
      allWorkplace,
      preferredLanguage,
      employeeId,
      isTemp,
      error
    } = this.state

    return (
      <TeamMemberFormComponent
        onTextFieldChange={this.onTextFieldChange}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        onDateChanged={this.onDateChanged}
        hireDate={hireDate}
        allWorkplace={allWorkplace}
        onNextClick={this.onUpdate}
        employeeId={employeeId || ''}
        preferredLanguage={preferredLanguage}
        isTemp={isTemp}
        error={error}
        validate={this.validate}
        isEdit={isEdit}
        seniority={teamMemberInfo?.seniority || 0}
        onCancel={onCancel}
      >
        {children}
      </TeamMemberFormComponent>
    )
  }
}

export const UpdateTeamMemberContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateCorporationUserMutation, { name: 'updateCorporationUser' }),
  graphql(updateUserMutation, { name: 'updateUser' }),
  graphql(fetchWorkplace, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.manager.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.manager?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplace'
  })
)(UpdateTeamMember)

UpdateTeamMember.propTypes = propTypes
UpdateTeamMember.defaultProps = defaultProps

export default UpdateTeamMemberContainer
