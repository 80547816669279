// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-flex-body-style__wrap--1ACgT {\n  -ms-flex-positive: 1;\n      flex-grow: 1;\n  overflow: auto;\n\n  /* for Firefox */\n  min-height: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"wrap": "src-components-layouts-flex-body-style__wrap--1ACgT"
};
module.exports = exports;
