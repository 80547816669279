// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-position-members-style__position_head_text_wrapper--3b0yc {\n  padding: 0 20px;\n}\n\n.src-components-position-members-style__position_head_text_wrapper--3b0yc h4 {\n  margin-top: 37px;\n}\n\n.src-components-position-members-style__position_head_text_wrapper--3b0yc h2 {\n  margin-top: 30px;\n}\n\n.src-components-position-members-style__position_head_text_wrapper--3b0yc p {\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.src-components-position-members-style__position_info_wrapper--15qfe {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  padding: 0 20px;\n}\n\n.src-components-position-members-style__position_info_wrapper--15qfe h2 {\n  margin-top: 30px;\n}\n\n.src-components-position-members-style__position_info--1882l {\n  margin-top: 30px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-components-position-members-style__position_info--1882l > div button {\n  padding: 0 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"position_head_text_wrapper": "src-components-position-members-style__position_head_text_wrapper--3b0yc",
	"position_info_wrapper": "src-components-position-members-style__position_info_wrapper--15qfe",
	"position_info": "src-components-position-members-style__position_info--1882l"
};
module.exports = exports;
