import gql from 'graphql-tag'

export const fetchMemberInfo = gql`
  query CorporationUser($corporationId: ID!, $userId: ID!) {
    CorporationUser(corporationId: $corporationId, userId: $userId) {
      id
      userId
      seniority
      hireDate
      optedOut
      employeeId
      isTemp
      user {
        id
        firstName
        lastName
        phoneNumber
        preferredLanguage
      }
      primaryWorkplace {
        id
        name
      }
      excludedDepartments {
        id
        name
      }
      departments {
        id
        name
      }
    }
  }
`

export const deleteTeamMember = gql`
  mutation deleteUserCorporation($input: deleteUserCorporationInput!) {
    deleteUserCorporation(input: $input) {
      user {
        id
        firstName
      }
    }
  }
`

export default { deleteTeamMember, fetchMemberInfo }
