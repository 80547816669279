export const usStates = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

export const restrictedUsers = [
  '79f1aea4-856b-4e2b-8c61-0b5336d420ab',
  'e8352a92-ad07-4f75-8647-aa7ce22cd0a0',
  '8b8b3e0a-c91f-46f4-8f02-7b4d90aca214',
  '2418681e-4a4c-4944-8a20-eb004c211355',
  'ebcad778-787e-40e7-b704-fb77c0b82924',
  'd85fdd23-3dbf-490c-8b83-bec097e10d51',
  '5cd45f01-4c64-4e0a-b044-67fc261fffc3',
  '342a93e3-4de5-4a56-91fc-2484a273452c',
  '87a358dc-c728-45a5-a864-8267e9a2f7d2',
  '11e07cc2-ed7e-4b0f-a58f-c6be41b21f65',
  'd7665270-47fc-4689-bf33-cbc3334538f2',
  '1a0904ab-a064-4e61-aa31-e141bfae5368',
  '2710642f-85f5-4327-87b2-1a1a0f96e821',
  'c0060d9f-4169-4f4f-a697-631d4ea2b3ac',
  '9080bde4-fb5a-4fca-92e5-ebd765fa6a5c',
  'f773c562-4781-4e8d-b11f-1c5010f79c2d',
  '851a2adc-4c00-4efe-b969-18cb175af502',
  '887e1f9b-d109-45e1-972f-cff5a9e4cadd',
  '3869b195-5152-4b91-af94-68a1a6ac86bf'
]

export const thirtyMinutes = 1800000

export default usStates
