// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-shift-info-style__request_header--2BXBT {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  padding: 0 20px;\n}\n\n.src-components-shift-info-style__request_header--2BXBT h4 {\n  margin-bottom: 15px;\n}\n\n.src-components-shift-info-style__request_header--2BXBT p {\n  margin: 8px 14px 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"request_header": "src-components-shift-info-style__request_header--2BXBT"
};
module.exports = exports;
