const displayErrorMessage = (client, errorMessage) => {
  client.writeData({
    data: {
      ErrorStatus: {
        __typename: 'ErrorMessage',
        isError: true,
        errorMessage
      }
    }
  })
  setTimeout(function closeError() {
    client.writeData({
      data: {
        ErrorStatus: {
          __typename: 'ErrorMessage',
          isError: false,
          errorMessage: ''
        }
      }
    })
  }, 8000)
}

export default displayErrorMessage
