import gql from 'graphql-tag'

export const shiftAuditJson = gql`
  query shiftAudit($shiftId: ID!) {
    shiftAudit(shiftId: $shiftId) {
      auditJSON
    }
  }
`

export default shiftAuditJson
