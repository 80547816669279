import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PageLayout } from '../layouts/page-layout'
import { SearchTableHeader } from '../layouts/search-table-header'
import TeamMemberTable from '../../containers/team-member-table'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isRestricted: PropTypes.bool
}

const defaultProps = {
  history: { push: () => {} },
  isRestricted: false
}

export class TeamMembers extends Component {
  onAddTeam = () => {
    const { history } = this.props
    history.push('/addTeamMember')
  }

  render() {
    const { history, isRestricted } = this.props
    return (
      <PageLayout>
        <SelectWorkplaceHeader history={history} />
        <SearchTableHeader
          header="Team Members"
          onClick={this.onAddTeam}
          isRestricted={isRestricted}
        />
        <TeamMemberTable history={history} />
      </PageLayout>
    )
  }
}

TeamMembers.propTypes = propTypes
TeamMembers.defaultProps = defaultProps

export default TeamMembers
