import React, { Component } from 'react'
import { ApolloProvider } from 'react-apollo'
import Amplify, { Hub } from 'aws-amplify'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import ReactPersistRoute from 'react-persist-route'
import { beacon } from 'quala'
import { createLocalStorageAdapter } from 'react-persist-route/lib/Adapters/localStorageAdapter'
import { getCurrentShift } from './apollo/apollo-cache-query.gql'
import authentication from './authentication'
import RootRouter from './routes/root-router'
import Layout from './containers/base-layout'
import getApolloClient from './apollo/apollo-client'
import Login from './containers/login'
import styles from './app.css'

const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    mandatorySignIn: true
  }
}

Amplify.configure(amplifyConfig)

class App extends Component {
  constructor(props) {
    super(props)
    Hub.listen('auth', this)
    this.state = {
      client: null,
      loaded: false,
      managerId: null
    }
    this.history = createBrowserHistory()
    this.adapter = createLocalStorageAdapter('appkey', {
      href: 'process.env.PUBLIC_URL' // The fallback url for your app, generally would be the home page.
    })
  }

  async componentDidMount() {
    authentication
      .getCurrentUser()
      .then(() => {
        this.setState({ authState: 'signedIn' })
      })
      .catch(() => {
        this.setState({ authState: 'signIn' })
      })
    const cache = new InMemoryCache()
    try {
      await persistCache({
        cache,
        storage: window.localStorage
      }).then(() => {
        this.setState({
          client: getApolloClient(),
          loaded: true
        })
      })
    } catch (e) {
      //
    }

    this.setState({
      client: getApolloClient(),
      loaded: true
    })
  }

  onHubCapsule = capsule => {
    const { channel, payload } = capsule
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          this.setState({ authState: 'signedIn' })
          break
        case 'signIn_failure':
          this.setState({ authState: 'signIn' })
          break
        default:
          break
      }
    }
  }

  render() {
    beacon.load('cjzn3hqtb000118w9dv1t70tx')
    const { client, loaded, authState, managerId } = this.state
    if (!loaded) {
      return <div />
    }

    client
      .query({
        query: getCurrentShift
      })
      .then(x => {
        const manager = x?.data?.currentShift?.manager?.id
        if (!managerId && manager) {
          this.setState({ managerId: manager })
        }
      })
      .catch(err => {
        console.info(err)
      })

    switch (authState) {
      case 'loading':
        return <div>Loading...</div>
      case 'signIn':
        return (
          <div className={styles.login_wrapper}>
            <Login client={client} />
          </div>
        )
      case 'signedIn':
        if (managerId) {
          return (
            <div>
              <ReactPersistRoute
                history={this.history}
                adapter={this.adapter}
              />
              <ApolloProvider client={client}>
                <Router
                  history={this.history}
                  basename={process.env.PUBLIC_URL}
                >
                  <Layout>
                    <RootRouter />
                  </Layout>
                </Router>
              </ApolloProvider>
            </div>
          )
        }
        return (
          <div className={styles.login_wrapper}>
            <Login client={client} />
          </div>
        )
      default:
        return <div>{authState}</div>
    }
  }
}

export default App
