import { merge } from 'lodash'
import { currentShift, defaultShift } from './shift'
import { recipientListDrawer, defaultUsers } from './users'
import { location, defaultLocation } from './location'
import { newPosition, defaultPosition } from './position'
import { teamMember, defaultTeamMember } from './teammember'
import { error, defaultError } from './error'
import { shiftRequests, defaultShiftRequests } from './shift-requests'
import { unavailable, defaultUnavailable } from './unavailable'
import { editManager, defaultEditManager } from './edit-manager'

export const resolvers = merge(
  currentShift,
  recipientListDrawer,
  location,
  newPosition,
  teamMember,
  error,
  shiftRequests,
  unavailable,
  editManager
)
export const defaults = merge(
  defaultShift,
  defaultUsers,
  defaultError,
  defaultLocation,
  defaultPosition,
  defaultTeamMember,
  defaultShiftRequests,
  defaultUnavailable,
  defaultEditManager
)
