import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import AddLocationContainer from '../../../containers/add-location'
import AddSupervisorContainer from '../../../containers/add-supervisor'
import { Header } from '../../theme'
import { PageLayout } from '../page-layout'
import styles from './style.css'

const propTypes = {
  onDeleteCurrentLocation: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  startedEditing: PropTypes.bool.isRequired
}

export class AddLocationWizardComponent extends Component {
  render() {
    const { onDeleteCurrentLocation, page, startedEditing } = this.props
    return (
      <PageLayout>
        <Header
          isClose
          showModal={startedEditing}
          onDelete={onDeleteCurrentLocation}
          title="Discard New Location?"
          message="Discarding this location will delete all of the information you have entered so far."
        />
        <div className={styles.title_location}>
          <Typography variant="h6">Add a New Location</Typography>
        </div>
        {page === 1 && <AddLocationContainer />}
        {page === 2 && (
          <AddSupervisorContainer
            onDeleteCurrentLocation={onDeleteCurrentLocation}
          />
        )}
      </PageLayout>
    )
  }
}

AddLocationWizardComponent.propTypes = propTypes

export default AddLocationWizardComponent
