import React, { Component } from 'react'
import { Button, Typography } from 'syrg-design-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { Header } from '../theme'
import style from './style.css'
import { ShiftAuditApollo } from '../../containers/shift-audit'
import FooterButton from '../layouts/footer-button'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'

const propTypes = {
  shiftId: PropTypes.string.isRequired,
  positionName: PropTypes.string.isRequired,
  deptName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  phoneStart: PropTypes.string,
  replacedUsers: PropTypes.string,
  tabId: PropTypes.number.isRequired,
  employeeCount: PropTypes.number.isRequired,
  assignedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    })
  ),
  onBack: PropTypes.func.isRequired,
  onRestart: PropTypes.func
}

const defaultProps = {
  phoneStart: '',
  replacedUsers: '',
  assignedUsers: [],
  onRestart: () => {}
}

export class ShiftDetailComponent extends Component {
  render() {
    const {
      onRestart,
      shiftId,
      positionName,
      deptName,
      startDate,
      startTime,
      endTime,
      phoneStart,
      replacedUsers,
      tabId,
      employeeCount,
      assignedUsers,
      onBack
    } = this.props
    const isRestart = employeeCount - assignedUsers?.length > 0
    return (
      <PageLayout>
        <div className={style.shift_detail_wrap}>
          <Header onLeftClick={onBack} />
          <FlexBody>
            <div className={style.shift_detail}>
              <Typography variant="h1">Shift Request Details</Typography>
              <Typography variant="h3">{deptName}</Typography>
              <Typography variant="h4">Date and Time</Typography>
              <Typography variant="body2">
                <b>{startDate}</b>
              </Typography>
              <Typography variant="body2">
                {startTime} – {endTime}
              </Typography>
              <div className={style.shift_detail_info}>
                <Typography variant="h4">Position</Typography>
                <Typography variant="body2">{positionName}</Typography>
              </div>
              <div className={style.shift_detail_info}>
                <Typography variant="h4">Requesting Started</Typography>
                <Typography variant="body2">{phoneStart}</Typography>
              </div>
              <div className={style.shift_detail_info}>
                <Typography variant="h4">Replacing</Typography>
                <Typography variant="body2">{replacedUsers}</Typography>
              </div>
              {tabId === 2 && (
                <div className={style.past_view_report}>
                  <ShiftAuditApollo
                    shiftId={shiftId}
                    csvFormat={`${startDate}-${startTime}`}
                  />
                </div>
              )}
              {tabId === 1 && (
                <div className={style.upcoming_buttons}>
                  <ShiftAuditApollo
                    shiftId={shiftId}
                    csvFormat={`${startDate}-${startTime}`}
                  />
                  {isRestart && (
                    <Button
                      size="small"
                      data-id="restart_request"
                      onClick={onRestart}
                    >
                      Restart Request
                    </Button>
                  )}
                </div>
              )}
              <div className={style.accepted_detail}>
                <div className={style.accepted_detail_header}>
                  <Typography variant="h3">Accepted Shift</Typography>
                  <Typography variant="h3" color="textSecondary">
                    <FontAwesomeIcon icon={faUser} /> {assignedUsers.length}/
                    {employeeCount}
                  </Typography>
                </div>
                {assignedUsers?.map(user => (
                  <Typography key={user.id} variant="body2">
                    {user.firstName} {user.lastName}
                  </Typography>
                ))}
              </div>
            </div>
          </FlexBody>
          <FooterButton data-id="back_shift" onClick={onBack}>
            Back to Shifts
          </FooterButton>
        </div>
      </PageLayout>
    )
  }
}

ShiftDetailComponent.propTypes = propTypes
ShiftDetailComponent.defaultProps = defaultProps

export default ShiftDetailComponent
