// this file will be switched to a mock source by webpack
// if running in mock mode, so be cautious when changing this
// see webpack.config.dev.js line 264

import { createUploadLink } from 'apollo-upload-client'
import authentication from '../authentication'

const uri = `${process.env.REACT_APP_MWB_BACKEND_PROTOCOL || ''}${process.env
  .REACT_APP_MWB_BACKEND_HOST || ''}${process.env.REACT_APP_MWB_BACKEND_PATH ||
  ''}/graphql`

// dev endpoint
// const uri = 'https://mwb-api.dev.getsyrg.com/Prod/graphql'

export default createUploadLink({
  uri,
  fetch: authentication.awsGraphqlFetch
})
