// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-add-location-wizard-style__title_location--3jR-F {\n  padding-left: 20px;\n  padding-top: 30px;\n}\n\n.src-components-layouts-add-location-wizard-style__title_location--3jR-F h6 {\n  font-weight: bold;\n  font-size: 21px;\n  color: #333c47;\n}\n", ""]);
// Exports
exports.locals = {
	"title_location": "src-components-layouts-add-location-wizard-style__title_location--3jR-F"
};
module.exports = exports;
