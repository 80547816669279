import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'
import style from './styles.css'
import FooterButton from '../layouts/footer-button'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  history: { push: () => {} }
}
class ResetPasswordSuccessComponent extends Component {
  render() {
    const { history } = this.props
    return (
      <PageLayout>
        <SelectWorkplaceHeader isWorkplaceDropdown={false} history={history} />
        <FlexBody>
          <div className={style.reset_success}>
            <Typography variant="h1">Password Updated Successfully</Typography>
            <Typography variant="body1">
              Your password has been updated.
            </Typography>
            <Typography variant="body1">
              Navigate to your shifts or use the menu to manage your account.
            </Typography>
          </div>
        </FlexBody>

        <FooterButton
          onClick={() => {
            history.push('/')
          }}
        >
          Go to My Shifts
        </FooterButton>
      </PageLayout>
    )
  }
}

ResetPasswordSuccessComponent.propTypes = propTypes
ResetPasswordSuccessComponent.defaultProps = defaultProps

export default ResetPasswordSuccessComponent
