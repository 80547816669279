export const callPaceConverter = (minBetweenCall, settingsObj) => {
  let minutes = 0
  let secs = 0
  if (minBetweenCall === 'Fast') {
    const fast = settingsObj?.timeBetweenCalls?.fast
    minutes = fast === undefined ? 1 : Number(fast?.split(':')[1])
    secs = Number(fast?.split(':')[2]) > 0 ? Number(fast?.split(':')[2]) : 0
    return { minBetweenCall: minutes, minSec: secs }
  }
  if (minBetweenCall === 'Normal') {
    const normal = settingsObj?.timeBetweenCalls?.normal
    minutes = normal === undefined ? 3 : Number(normal?.split(':')[1])
    secs = Number(normal?.split(':')[2]) > 0 ? Number(normal?.split(':')[2]) : 0
    return { minBetweenCall: minutes, minSec: secs }
  }
  return { minBetweenCall, minSec: secs }
}
export default callPaceConverter
