import gql from 'graphql-tag'

export const getSupervisor = gql`
  query getManagersByCorporationId($corporationId: ID!) {
    getManagersByCorporationId(corporationId: $corporationId) {
      id
      firstName
      lastName
      phoneNumber
    }
  }
`
export default getSupervisor

export const addLocationMutation = gql`
  mutation createDepartment(
    $name: String!
    $workplaceId: ID!
    $contactUserId: ID!
    $addressJson: String!
    $message: String!
    $alias: String!
  ) {
    createDepartment(
      input: {
        name: $name
        workplaceId: $workplaceId
        contactUserId: $contactUserId
        addressJson: $addressJson
        message: $message
        alias: $alias
      }
    ) {
      id
      workplaceId
      name
      addressJson
      message
    }
  }
`
