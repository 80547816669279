// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-searchable-list-style__scroll_search_container--a80V8 {\n  min-height: 0;\n  height: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n}\n\n.src-components-layouts-searchable-list-style__no_data--2C_4w {\n  text-align: center;\n  padding-top: 20px;\n  color: #90959e;\n}\n", ""]);
// Exports
exports.locals = {
	"scroll_search_container": "src-components-layouts-searchable-list-style__scroll_search_container--a80V8",
	"no_data": "src-components-layouts-searchable-list-style__no_data--2C_4w"
};
module.exports = exports;
