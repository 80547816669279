import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import style from './style.css'
import updates from './updates.json'
import { FlexBody } from '../layouts/flex-body'
import { PageLayout } from '../layouts/page-layout'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  history: { push: () => {} }
}

export class UpdatesComponent extends Component {
  render() {
    const { history } = this.props
    return (
      <PageLayout>
        <SelectWorkplaceHeader isWorkplaceDropdown={false} history={history} />
        <FlexBody>
          <div className={style.updates_header}>
            <Typography variant="h2">Software updates</Typography>
            <Typography variant="body2">Recent updates to Syrg</Typography>
          </div>
          <div className={style.updates_table}>
            <ul>
              {updates.map(update => {
                return (
                  <div key={update.description}>
                    <li>
                      <Typography variant="h3">{update.date}</Typography>
                      <Typography variant="body1">
                        {update.description}
                      </Typography>
                    </li>
                    <hr className={style.updates_divider} />
                  </div>
                )
              })}
            </ul>
          </div>
        </FlexBody>
      </PageLayout>
    )
  }
}

UpdatesComponent.propTypes = propTypes
UpdatesComponent.defaultProps = defaultProps

export default UpdatesComponent
