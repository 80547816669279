import gql from 'graphql-tag'

export const createManager = gql`
  mutation createManagerInCorporation(
    $input: createManagerInCorporationInput!
  ) {
    createManagerInCorporation(input: $input) {
      message
      user {
        id
        firstName
        lastName
        phoneNumber
      }
    }
  }
`

export const updateManager = gql`
  mutation updateManager($input: updateManagerInCorporationInput!) {
    updateManager(input: $input) {
      message
      user {
        id
        phoneNumber
        firstName
        lastName
      }
    }
  }
`

export default { createManager }
