import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Typography } from 'syrg-design-kit'
import style from './style.css'

const propTypes = {
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  unavailable: PropTypes.bool.isRequired,
  ranking: PropTypes.number.isRequired,
  overtime: PropTypes.bool.isRequired,
  removed: PropTypes.bool,
  isEdit: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func
}

const defaultProps = {
  removed: false,
  isEdit: false,
  isChecked: false,
  onChange: () => {}
}

export class CallListRow extends Component {
  nameColor = () => {
    const { overtime, unavailable, removed } = this.props
    if (unavailable || removed) return 'textSecondary'
    if (overtime && !unavailable) return 'secondary'
    return 'textPrimary'
  }

  userInfo = () => {
    const { removed, unavailable, overtime, ranking, isEdit } = this.props
    if (!removed && !unavailable)
      return (
        <>
          {overtime && (
            <Typography id="overtime" variant="body1" color="secondary">
              <i>Overtime</i>
            </Typography>
          )}
          <Typography id="ranking" variant="h4">
            {ranking}
          </Typography>
        </>
      )
    if (!removed && unavailable && !overtime)
      return (
        <Typography id="unavailable" variant="body1" color="textSecondary">
          <i>Unavailable</i>
        </Typography>
      )
    if (removed && !isEdit)
      return (
        <Typography id="removed" variant="body1" color="secondary">
          <i>Removed</i>
        </Typography>
      )
    if (removed && isEdit) return <Typography variant="h4">-</Typography>
    if (overtime && isEdit)
      return (
        <>
          <Typography id="overtime" variant="body1" color="secondary">
            <i>Overtime</i>
          </Typography>
          <Typography variant="h4">-</Typography>
        </>
      )
    return (
      <Typography id="overtime" variant="body1" color="secondary">
        <i>Overtime</i>
      </Typography>
    )
  }

  render() {
    const {
      userId,
      name,
      unavailable,
      isEdit,
      isChecked,
      onChange
    } = this.props
    return (
      <div className={style.call_list_row}>
        <div className={style.row_edit}>
          {isEdit && (
            <Checkbox
              data-id="call_check"
              checked={isChecked}
              onChange={unavailable ? () => {} : () => onChange(userId)}
              disabled={unavailable}
            />
          )}
          <Typography variant="body1" color={this.nameColor()}>
            {name}
          </Typography>
        </div>
        <div className={style.text_ot}>{this.userInfo()}</div>
      </div>
    )
  }
}

CallListRow.propTypes = propTypes
CallListRow.defaultProps = defaultProps

export default CallListRow
