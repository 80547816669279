export const error = {
  Mutation: {
    updateError: (_, { isError, errorMessage }, { cache }) => {
      const data = {
        ErrorStatus: {
          isError,
          errorMessage,
          __typename: 'ErrorMessage'
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

export const defaultError = {
  ErrorStatus: {
    __typename: 'ErrorMessage',
    isError: false,
    errorMessage: ''
  }
}
