import React, { Component } from 'react'
import { Snackbar } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { AddLocationComponent } from '../../components/add-location'
import {
  addLocationData,
  updateLocationEditing
} from '../../apollo/apollo-cache-mutation.gql'
import { getLocation } from '../../apollo/apollo-cache-query.gql'

const propTypes = {
  updateLocationEdit: PropTypes.func,
  addLocation: PropTypes.func,
  getLocationData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  addLocation: () => {},
  getLocationData: () => {},
  updateLocationEdit: () => {}
}

export class AddLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locationName: props.getLocationData?.location?.name,
      street: props.getLocationData?.location?.street,
      city: props.getLocationData?.location?.city,
      zipcode: props.getLocationData?.location?.zipcode,
      state: props.getLocationData?.location?.state,
      error: {
        name: false,
        street: false,
        city: false,
        zipcode: false,
        state: false
      }
    }
  }

  onLocationChange = (value, key) => {
    const { locationName, street, city, zipcode, state } = this.state
    if (
      locationName === '' &&
      street === '' &&
      city === '' &&
      state === '' &&
      zipcode === ''
    ) {
      const { updateLocationEdit } = this.props
      updateLocationEdit()
    }
    this.setState({
      [key]: value
    })
    if (key === 'state') {
      this.setState({
        error: {
          state: value === 'Select a state'
        }
      })
    }
  }

  errorCheck = () => {
    const { locationName, zipcode, city, street } = this.state
    if (
      !/^[a-zA-Z0-9]/.test(locationName) ||
      locationName.trim().length === 0
    ) {
      Snackbar('Must enter valid location name', 'error', 3000)
      return false
    }
    if (!/^[a-zA-Z0-9]/.test(street) || street.trim().length === 0) {
      Snackbar('Must enter valid street name', 'error', 3000)
      return false
    }
    if (!/^[a-zA-Z]/.test(city) || city.trim().length === 0) {
      Snackbar('Must enter valid city name', 'error', 3000)
      return false
    }
    if (
      !/^[a-zA-Z0-9]/.test(zipcode) ||
      zipcode.toString().trim().length === 0
    ) {
      Snackbar('Must enter valid zipcode name', 'error', 3000)
      return false
    }
    return true
  }

  nextClick = () => {
    const { addLocation } = this.props
    const { locationName, zipcode, state, city, street } = this.state
    const checkLocation = locationName === ''
    const checkState = state === 'Select a state' || state === ''
    const checkCity = city === ''
    const checkStreet = street === ''
    const checkZipcode = zipcode === ''
    if (
      checkLocation ||
      checkState ||
      checkCity ||
      checkStreet ||
      checkZipcode
    ) {
      this.setState({
        error: {
          name: checkLocation,
          street: checkStreet,
          city: checkCity,
          zipcode: checkZipcode,
          state: checkState
        }
      })
      Snackbar('Please fill in all fields', 'warning', 3000)
    } else if (this.errorCheck()) {
      addLocation({
        variables: {
          name: locationName,
          street,
          city,
          zipcode,
          state,
          page: 2
        }
      })
    }
  }

  render() {
    const { state } = this
    return (
      <AddLocationComponent
        onLocationChange={this.onLocationChange}
        nextClick={this.nextClick}
        location={state}
        error={state.error}
      />
    )
  }
}
const AddLocationContainer = compose(
  withApollo,
  graphql(addLocationData, { name: 'addLocation' }),
  graphql(updateLocationEditing, { name: 'updateLocationEdit' }),
  graphql(getLocation, { name: 'getLocationData' })
)(AddLocation)

AddLocation.propTypes = propTypes
AddLocation.defaultProps = defaultProps

export default AddLocationContainer
