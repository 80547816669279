// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-unavailable-form-style__add_unavailable_info--3BXF6 {\n  padding: 40px 20px 60px;\n}\n\n.src-components-unavailable-form-style__add_unavailable_info--3BXF6 h2 {\n  margin-bottom: 20px;\n}\n\n.src-components-unavailable-form-style__add_unavailable_info--3BXF6 button {\n  padding: 0;\n  margin-top: 40px;\n}\n\n.src-components-unavailable-form-style__add_unavailable_date--108Rg > div > div {\n  margin: 20px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"add_unavailable_info": "src-components-unavailable-form-style__add_unavailable_info--3BXF6",
	"add_unavailable_date": "src-components-unavailable-form-style__add_unavailable_date--108Rg"
};
module.exports = exports;
