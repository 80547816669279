// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-change-password-styles__change_password--2WwIf {\n  padding: 0 20px;\n}\n\n.src-components-change-password-styles__change_password--2WwIf h1 {\n  margin-bottom: 22px;\n  margin-top: 44px;\n}\n\n.src-components-change-password-styles__change_password--2WwIf p {\n  margin-bottom: 10px;\n}\n\n.src-components-change-password-styles__mypass_change--33hGv {\n  margin-top: 42px;\n}\n\n.src-components-change-password-styles__mypass_change--33hGv > div {\n  margin-bottom: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"change_password": "src-components-change-password-styles__change_password--2WwIf",
	"mypass_change": "src-components-change-password-styles__mypass_change--33hGv"
};
module.exports = exports;
