import gql from 'graphql-tag'

export const isFeatureEnabled = gql`
  query isFeatureEnabled($input: IsFeatureEnabledInput!) {
    isFeatureEnabled(input: $input)
  }
`

export const getCurrentShiftWithCorporation = gql`
  query currentShift {
    currentShift @client {
      workplace {
        corporation {
          id
        }
      }
    }
  }
`
