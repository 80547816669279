import { flatMap } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Dialog, TextField, Typography } from 'syrg-design-kit'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'
import { PageLayout } from '../layouts/page-layout'
import { SearchTableHeader } from '../layouts/search-table-header'
import SearchableList from '../layouts/searchable-list'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isLoading: PropTypes.bool,
  positions: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onDrawerChange: PropTypes.func.isRequired,
  onAddPosition: PropTypes.func.isRequired,
  onEditAlias: PropTypes.func,
  isRestricted: PropTypes.bool,
  isSynced: PropTypes.bool
}

const defaultProps = {
  history: { push: () => {} },
  onEditAlias: () => {},
  isLoading: false,
  isRestricted: false,
  isSynced: false
}
class Positions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPositionAliasModalOpen: false,
      positionId: '',
      positionName: '',
      aliasName: ''
    }
  }

  onAliasClick = id => {
    const { positions } = this.props
    const filteredPosition = flatMap(positions).find(pos => pos.id === id)
    this.setState({
      isPositionAliasModalOpen: true,
      positionId: id,
      positionName: filteredPosition.name,
      aliasName: filteredPosition.alias
    })
  }

  onSaveAlias = () => {
    const { onEditAlias } = this.props
    const { aliasName, positionId } = this.state
    this.setState({ isPositionAliasModalOpen: false })
    onEditAlias(positionId, aliasName)
  }

  onAliasModalClose = () => {
    this.setState({ isPositionAliasModalOpen: false })
  }

  onChange = value => {
    this.setState({ aliasName: value })
  }

  render() {
    const {
      positions,
      history,
      onAddPosition,
      isLoading,
      isRestricted,
      isSynced,
      onSearchChange,
      onDrawerChange
    } = this.props
    const { isPositionAliasModalOpen, aliasName, positionName } = this.state
    return (
      <PageLayout>
        <SelectWorkplaceHeader history={history} />
        <SearchTableHeader
          header="Positions"
          onClick={onAddPosition}
          isRestricted={isRestricted || isSynced}
        />
        <SearchableList
          data={positions}
          searchTextData="Positions"
          onSearchChange={onSearchChange}
          onRowClick={isSynced ? this.onAliasClick : onDrawerChange}
          isLoading={isLoading}
          isSynced={isSynced}
        />
        <Dialog
          open={isSynced && isPositionAliasModalOpen}
          onClose={this.onAliasModalClose}
          title="Change Name?"
          okText="Save"
          onOk={this.onSaveAlias}
          onCancel={this.onAliasModalClose}
        >
          <TextField
            label="Alias Name"
            placeholder="Alias Name"
            onChange={e => {
              this.onChange(e.target.value)
            }}
            value={aliasName}
            data-id="inputAlias"
          />
          <Typography type="body1">{positionName}</Typography>
        </Dialog>
      </PageLayout>
    )
  }
}

Positions.propTypes = propTypes
Positions.defaultProps = defaultProps

export default Positions
