import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DatePicker, Typography, TimePicker } from 'syrg-design-kit'
import styles from './style.css'
import { FooterComponent } from '../layouts/footer-navigation'
import { FlexBody } from '../layouts/flex-body'

const propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  onDateSelect: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  timeLength: PropTypes.string,
  startTime: PropTypes.instanceOf(Object),
  endTime: PropTypes.instanceOf(Object)
}

const defaultProps = {
  onTimeChange: () => {},
  timeLength: '',
  startTime: moment(),
  endTime: moment(),
  onNextClick: () => {},
  onBackClick: () => {}
}
class ShiftScheduleComponent extends Component {
  render() {
    const {
      selectedDate,
      onDateSelect,
      onTimeChange,
      startTime,
      endTime,
      onBackClick,
      onNextClick,
      timeLength
    } = this.props
    return (
      <>
        <FlexBody>
          <div className={styles.main_content}>
            <div className={styles.main_dateWrap}>
              <DatePicker
                data-id="datePicker"
                selectedDate={selectedDate}
                minDate={moment().startOf('d')}
                value={selectedDate}
                disablePast
                label="Shift Date"
                onChange={onDateSelect}
              />
            </div>
            <div className={styles.main_dateWrap}>
              <TimePicker
                data-id="startTime"
                label="Start Time"
                selectedTime={startTime}
                onChange={e => onTimeChange(e, 'startTime')}
              />
            </div>
            <div className={styles.main_dateWrap}>
              <TimePicker
                data-id="endTime"
                label="End Time"
                selectedTime={endTime}
                onChange={e => onTimeChange(e, 'endTime')}
              />
            </div>
            <div className={styles.time_length_wrpper}>
              <Typography variant="h3">Shift Length:</Typography>
              <Typography variant="h3">{timeLength}</Typography>
            </div>
          </div>
        </FlexBody>
        <FooterComponent
          buttonInfo="All fields required"
          onBackClick={onBackClick}
          onNextClick={onNextClick}
        />
      </>
    )
  }
}

ShiftScheduleComponent.propTypes = propTypes
ShiftScheduleComponent.defaultProps = defaultProps

export default ShiftScheduleComponent
