import gql from 'graphql-tag'

export const getPastShift = gql`
  query ShiftHistory($workplaceId: ID!, $offset: Int) {
    ShiftHistory(workplaceId: $workplaceId, first: 10, offset: $offset) {
      id
      startTime
      endTime
      teamMemberRequiredCount
      replacedUsers
      position {
        id
        name
        color
        alias
      }
      assignedUsers {
        id
        firstName
        lastName
      }
      department {
        id
        name
        alias
        message
      }
      latestShiftPoll {
        shiftDetails {
          timeBetweenCalls
        }
        createdAt
      }
    }
  }
`

export default { getPastShift }
