// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-add-location-style__location_content--2-0CX {\n  padding: 0 20px;\n}\n\n.src-components-add-location-style__location_content--2-0CX > div {\n  margin-top: 40px;\n  margin-bottom: 0;\n}\n\n.src-components-add-location-style__add_location_state--1_sh3 {\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.src-components-add-location-style__add_location_state--1_sh3 > .MuiTextField-root {\n  margin: 0;\n  margin-right: 10px;\n  -ms-flex: 1 1;\n      flex: 1 1;\n}\n\n.src-components-add-location-style__add_location_state--1_sh3 > div:last-child {\n  min-width: 30%;\n}\n", ""]);
// Exports
exports.locals = {
	"location_content": "src-components-add-location-style__location_content--2-0CX",
	"add_location_state": "src-components-add-location-style__add_location_state--1_sh3"
};
module.exports = exports;
