import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { UnavailabilityListComponent } from '../../components/unavailability-list'
import { getUnavailability } from './unavailability-list.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { updateUnavailable } from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  getUnavailabilityInfo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  corporationUserId: PropTypes.string,
  editUnavailable: PropTypes.func,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
}

const defaultProps = {
  history: { push: () => {} },
  getUnavailabilityInfo: {
    loading: false
  },
  corporationUserId: '',
  editUnavailable: () => {}
}

export class UnavailabilityListContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unavailability: []
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getUnavailabilityInfo } = nextProps
    const { unavailability } = prevState
    if (
      getUnavailabilityInfo &&
      !getUnavailabilityInfo.loading &&
      getUnavailabilityInfo.getCorporationUserUnavailability
    ) {
      const { getCorporationUserUnavailability } = getUnavailabilityInfo
      if (unavailability !== getCorporationUserUnavailability) {
        return {
          unavailability: getCorporationUserUnavailability
        }
      }
    }
    return null
  }

  onUnavailableClick = (id, startDate, endDate, reason, corporationUserId) => {
    const { editUnavailable, history } = this.props
    editUnavailable({
      variables: {
        id,
        startDate,
        endDate,
        reason,
        corporationUserId
      }
    }).then(() => {
      history.push('/unavailableForm')
    })
  }

  render() {
    const { unavailability } = this.state
    const { getShift, corporationUserId } = this.props
    return (
      <UnavailabilityListComponent
        unavailability={unavailability}
        corporationUserId={corporationUserId}
        onUnavailableClick={this.onUnavailableClick}
        timezone={getShift?.currentShift?.workplace?.timezone}
      />
    )
  }
}
export const UnavailabilityListContainerApollo = compose(
  withApollo,
  graphql(getUnavailability, {
    skip: ownProps => !ownProps.corporationUserId,
    options: ownProps => ({
      variables: {
        corporationUserId: ownProps.corporationUserId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getUnavailabilityInfo'
  }),
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateUnavailable, { name: 'editUnavailable' })
)(UnavailabilityListContainer)

UnavailabilityListContainer.propTypes = propTypes
UnavailabilityListContainer.defaultProps = defaultProps

export default UnavailabilityListContainerApollo
