import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextField, Typography } from 'syrg-design-kit'
import { FooterComponent } from '../layouts/footer-navigation'
import Header from '../layouts/login-header'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import styles from './style.css'

const propTypes = {
  number: PropTypes.string,
  onChange: PropTypes.func,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
  error: PropTypes.string
}

const defaultProps = {
  number: '',
  onChange: () => {},
  onBackClick: () => {},
  onNextClick: () => {},
  error: ''
}
class ForgotPassword extends Component {
  render() {
    const { number, onChange, onBackClick, onNextClick, error } = this.props
    return (
      <PageLayout>
        <Header />
        <FlexBody>
          <div className={styles.forgot_pass_form}>
            <Typography variant="h1">Forgot Password</Typography>
            <TextField
              type="phone"
              value={number}
              onChange={e => onChange(e.target.value)}
              label="Phone Number"
              required
              requiredText={error}
              errorText={error}
              forceShowError={error.length > 0}
              fullWidth
            />
            <Typography variant="body1">
              We are going to send a recovery code to your phone. You will need
              this code to reset your password.
            </Typography>
          </div>
        </FlexBody>
        <FooterComponent
          onNextClick={onNextClick}
          nextText="Next"
          backText="Back"
          onBackClick={onBackClick}
          buttonInfo="All fields required"
          isLink
        />
      </PageLayout>
    )
  }
}

ForgotPassword.propTypes = propTypes
ForgotPassword.defaultProps = defaultProps

export default ForgotPassword
