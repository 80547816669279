import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { ShiftDetailComponent } from '../../components/shift-detail'
import {
  getCurrentShift,
  getShiftRequests
} from '../../apollo/apollo-cache-query.gql'
import { resetProgress } from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  getShiftRequest: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  resetShiftProgress: PropTypes.func.isRequired
}

export class ShiftDetails extends Component {
  onBack = () => {
    const { history, resetShiftProgress } = this.props
    resetShiftProgress()
    history.push('/')
  }

  onRestart = () => {
    const { history } = this.props
    history.push('/summary')
  }

  render() {
    const { getShift, getShiftRequest } = this.props
    const { tabId } = getShiftRequest?.shiftRequest
    const {
      shiftId,
      location: { name: deptName },
      position: { name: positionName },
      startDate,
      startTime,
      endTime,
      lastPhoneStart,
      assignedUsers,
      employeeCount,
      replacedUsers
    } = getShift?.currentShift
    return (
      <ShiftDetailComponent
        shiftId={shiftId}
        tabId={tabId}
        deptName={deptName}
        positionName={positionName}
        startDate={startDate}
        startTime={startTime}
        endTime={endTime}
        phoneStart={lastPhoneStart}
        replacedUsers={replacedUsers}
        assignedUsers={assignedUsers}
        employeeCount={employeeCount}
        onBack={this.onBack}
        onRestart={this.onRestart}
      />
    )
  }
}

export const ShiftDetailContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(getShiftRequests, { name: 'getShiftRequest' }),
  graphql(resetProgress, { name: 'resetShiftProgress' })
)(ShiftDetails)

ShiftDetails.propTypes = propTypes

export default ShiftDetailContainer
