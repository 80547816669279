import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Link as LinkButton, Dialog } from 'syrg-design-kit'
import { faInfoCircle, faEdit } from '@fortawesome/free-solid-svg-icons'
import styles from './style.css'
import { NotifyManagersContainer } from '../../containers/notify-managers-modal'
import { ShiftInstructions } from '../../containers/shift-instructions'
import { CallPace } from '../../containers/call-pace'
import { callPaceConverter } from '../../utils/call-pace-converter'

const propTypes = {
  isLoading: PropTypes.bool,
  restartPhoneTree: PropTypes.bool,
  minBetweenCall: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  notifyManagers: PropTypes.arrayOf(PropTypes.number),
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  ),
  settings: PropTypes.string,
  callList: PropTypes.number
}

const defaultProps = {
  isLoading: false,
  restartPhoneTree: false,
  notifyManagers: [],
  managers: [],
  settings: '',
  callList: 0
}
export class AdvanceOptionComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCallDateModalOpen: false,
      isManagersModalOpen: false,
      isCallPaceInfoDialogOpen: false
    }
  }

  showCallDateModal = () => {
    this.setState(prevState => ({
      isCallDateModalOpen: !prevState.isCallDateModalOpen
    }))
  }

  showCallPaceInfoDialog = () => {
    this.setState(prevState => ({
      isCallPaceInfoDialogOpen: !prevState.isCallPaceInfoDialogOpen
    }))
  }

  showManagersModal = () => {
    this.setState(prevState => ({
      isManagersModalOpen: !prevState.isManagersModalOpen
    }))
  }

  fetchEstimatedEndCall = () => {
    const { settings, callList } = this.props
    const { minBetweenCall } = this.props
    const settingsObj = settings && JSON.parse(settings)
    const { minBetweenCall: minutes, minSec } = callPaceConverter(
      minBetweenCall,
      settingsObj
    )
    return moment()
      .add(minutes * callList, 'm')
      .add(minSec * callList, 's')
      .format('MMM Do, h:mma')
  }

  render() {
    const { isManagersModalOpen, isCallPaceInfoDialogOpen } = this.state
    const { isLoading, notifyManagers, managers, restartPhoneTree } = this.props
    const manager = []
    if (notifyManagers?.length) {
      notifyManagers.forEach(mgr => {
        const findManager = managers.find(user => user.phoneNumber === mgr)
        if (findManager) manager.push(findManager)
      })
    }
    const estimatedEndTime = this.fetchEstimatedEndCall()
    return (
      <>
        {isLoading ? (
          <Icon type="loading" className={styles.advance_load} spin />
        ) : (
          <div className={styles.shift_wrap_info}>
            <div className={styles.shift_note}>
              <Typography variant="h4">Shift instructions</Typography>
              <Typography variant="caption">
                Only sent to the workers who accepted the shift
              </Typography>
              <div className={styles.instruction_fields}>
                <ShiftInstructions />
              </div>
            </div>
            <div className={styles.shift_note}>
              <Typography variant="h4">
                Call pace{' '}
                <FontAwesomeIcon
                  className={styles.info_icon}
                  icon={faInfoCircle}
                  data-id="callPaceInfoIcon"
                  onClick={this.showCallPaceInfoDialog}
                />
              </Typography>
              <Typography variant="caption">
                Estimated end of calls: {estimatedEndTime}
              </Typography>
            </div>
            <CallPace />
            <div
              className={`${styles.shift_note} ${styles.managers_to_notify}`}
            >
              <div className={styles.section_header}>
                <Typography variant="h4">Managers to notify</Typography>
                {!restartPhoneTree && (
                  <LinkButton
                    data-id="managersEdit"
                    onClick={this.showManagersModal}
                    isButton
                  >
                    Edit
                    <FontAwesomeIcon
                      className={styles.edit_icon}
                      icon={faEdit}
                    />
                  </LinkButton>
                )}
              </div>
              <Typography variant="caption">
                The selected managers get texted about this shift request
              </Typography>
              {manager?.length ? (
                manager?.map(mgr => (
                  <Typography variant="body1" key={mgr?.phoneNumber}>
                    {mgr?.firstName} {mgr?.lastName}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">
                  No one is selected yet for notification
                </Typography>
              )}
            </div>
            <Dialog
              open={isCallPaceInfoDialogOpen}
              onClose={this.showCallPaceInfoDialog}
              title="Call Pace"
              onOk={this.showCallPaceInfoDialog}
            >
              <Typography variant="body1">
                Call pace defines the wait time between contacting workers. The
                shorter the time, the faster the calls finish. However, eligible
                workers may not have enough time to respond to requests that are
                too short.
              </Typography>
            </Dialog>
          </div>
        )}
        {isManagersModalOpen && (
          <NotifyManagersContainer
            showManagersModal={this.showManagersModal}
            managers={managers}
            notifyManagers={notifyManagers}
          />
        )}
      </>
    )
  }
}

AdvanceOptionComponent.propTypes = propTypes
AdvanceOptionComponent.defaultProps = defaultProps

export default AdvanceOptionComponent
