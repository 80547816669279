import moment from 'moment-timezone'

export const formattedTime = (currentShift, type) => {
  if (currentShift) {
    const start = currentShift?.startTime
    const end = currentShift?.endTime
    const date = currentShift?.startDate
    const tz = currentShift?.workplace?.timezone
    const startTime = moment
      .tz(`${date} ${start}`, 'MMMM D, YYYY h:mma', tz)
      .unix()
    if (type === 'end') {
      let endTime = moment.tz(`${date} ${end}`, 'MMMM D, YYYY h:mma', tz).unix()
      endTime = endTime < startTime ? endTime + 86400 : endTime
      return endTime.toString()
    }
    return startTime.toString()
  }
  return null
}

export const timeCalculationOfShift = unixStartTime => {
  let timeLeft
  if (unixStartTime) {
    const start = moment.unix(unixStartTime)
    const durationHours = moment.duration(start.diff(moment())).asHours()
    if (durationHours < 0) {
      timeLeft = 'Shift has started.'
    } else {
      let day = 0
      if (durationHours >= 24) {
        day = Math.floor(durationHours / 24)
      }
      let hour = Math.floor(durationHours % 24)
      if (day < 1) {
        const durationMinutes = moment
          .duration(start.diff(moment()))
          .asMinutes()
        let minute = Math.ceil(durationMinutes % 60)
        if (minute === 60) {
          minute = 0
          hour += 1
        }
        timeLeft = `${hour}h ${minute}m`
      } else {
        timeLeft = `${day}d ${hour}h`
      }
    }
  }
  return timeLeft
}

export const calculateDiffInUnix = (startTime, endTime) =>
  endTime.diff(startTime)

// Requires moment object arguments
export const calculateDiff = (startTime, endTime) => {
  const difference = calculateDiffInUnix(startTime, endTime)
  return `${Math.floor(moment.duration(difference).asHours())}h ${moment
    .utc(difference)
    .format('mm')}m`
}

// Converts from 24h format to am/pm format
export const convertToAmPm = timeString => {
  const [hour, minute] = timeString.split(':').map(time => parseInt(time, 10))
  return moment()
    .set({ hour, minute })
    .format('LT')
}

export default formattedTime
