// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-team-member-form-style__team_data_wrapper--1Obdy {\n  padding: 0 20px;\n}\n\n.src-components-team-member-form-style__team_data_hire_date--2TCqZ {\n  display: -ms-flexbox;\n  display: flex;\n  margin-top: 15px;\n}\n\n.src-components-team-member-form-style__text_field_wrapper--31_IX {\n  margin-bottom: 20px;\n}\n\n.src-components-team-member-form-style__team_data_hire_date--2TCqZ > div {\n  width: 100%;\n  margin-top: 10px;\n  margin-bottom: 18px;\n}\n\n.src-components-team-member-form-style__team_dept_info--2me8D > div {\n  margin-top: 18px;\n  margin-bottom: 38px;\n}\n\n.src-components-team-member-form-style__team_area_info--3jT8I > div {\n  margin-top: 18px;\n  margin-bottom: 28px;\n}\n\n.src-components-team-member-form-style__add_team_isTemp--1_hi2 {\n  padding: 0 0 10px 0;\n  width: 100%;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.src-components-team-member-form-style__temp_wrap--1nHQd {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: center;\n      align-items: center;\n  margin-top: 8px;\n}\n\n.src-components-team-member-form-style__temp_wrap--1nHQd h4 {\n  margin-bottom: 8px;\n  width: 100%;\n}\n\n.src-components-team-member-form-style__team_data_wrapper--1Obdy .src-components-team-member-form-style__emp_header--__u0f h1 {\n  margin: 40px 0 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"team_data_wrapper": "src-components-team-member-form-style__team_data_wrapper--1Obdy",
	"team_data_hire_date": "src-components-team-member-form-style__team_data_hire_date--2TCqZ",
	"text_field_wrapper": "src-components-team-member-form-style__text_field_wrapper--31_IX",
	"team_dept_info": "src-components-team-member-form-style__team_dept_info--2me8D",
	"team_area_info": "src-components-team-member-form-style__team_area_info--3jT8I",
	"add_team_isTemp": "src-components-team-member-form-style__add_team_isTemp--1_hi2",
	"temp_wrap": "src-components-team-member-form-style__temp_wrap--1nHQd",
	"emp_header": "src-components-team-member-form-style__emp_header--__u0f"
};
module.exports = exports;
