import gql from 'graphql-tag'

export const createPositionInCorporation = gql`
  mutation createPositionInCorporation(
    $input: createPositionInCorporationInput!
  ) {
    createPositionInCorporation(input: $input) {
      id
    }
  }
`

export const updatePositionUsersMutation = gql`
  mutation updateCorporationPositionUsers(
    $input: updateCorporationPositionUsersInput!
  ) {
    updateCorporationPositionUsers(input: $input) {
      positions {
        id
      }
    }
  }
`

export const deletePositionMutation = gql`
  mutation deletePositionsFromCorporation(
    $input: deletePositionsFromCorporationInput!
  ) {
    deletePositionsFromCorporation(input: $input) {
      id
    }
  }
`

export const getPositionMembers = gql`
  query Position($id: ID!) {
    Position(input: { id: $id }) {
      id
      users {
        id
        firstName
      }
    }
  }
`

export default {
  createPositionInCorporation,
  deletePositionMutation,
  updatePositionUsersMutation,
  getPositionMembers
}
