import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { sortBy, flowRight as compose } from 'lodash'
import { updateNotifyManagers } from '../../apollo/apollo-cache-mutation.gql'
import { NotifyManagersModalComponent } from '../../components/notify-managers-modal'

const propTypes = {
  updateManagers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  showManagersModal: PropTypes.func,
  notifyManagers: PropTypes.arrayOf(PropTypes.number),
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  )
}

const defaultProps = {
  updateManagers: () => {},
  showManagersModal: () => {},
  notifyManagers: [],
  managers: []
}

export class NotifyManagers extends Component {
  onDoneClick = notifyManagers => {
    const { showManagersModal, updateManagers } = this.props
    updateManagers({
      variables: { notifyManagers, notifyManagerChanged: true }
    })
    showManagersModal()
  }

  render() {
    const { showManagersModal, notifyManagers, managers } = this.props
    const manager = sortBy(managers, x => x.firstName)
    return (
      <NotifyManagersModalComponent
        showManagersModal={showManagersModal}
        notifyManagers={notifyManagers}
        onDoneClick={this.onDoneClick}
        managers={manager}
      />
    )
  }
}
export const NotifyManagersContainer = compose(
  withApollo,
  graphql(updateNotifyManagers, { name: 'updateManagers' })
)(NotifyManagers)

NotifyManagers.propTypes = propTypes
NotifyManagers.defaultProps = defaultProps

export default NotifyManagersContainer
