import _ from 'lodash'

export const createAndFlattenListWithCheckboxes = (
  data,
  selectedItems,
  isSynced,
  isDepartmentMapping
) => {
  const keys = Object.keys(data).sort()
  return _.flatten(
    keys.map(key => {
      return data[key].map(listRow => ({
        ...listRow,
        checked: isDepartmentMapping
          ? !selectedItems.includes(listRow.id)
          : selectedItems.includes(listRow.id),
        name: isSynced ? listRow.alias : listRow.name
      }))
    })
  ).filter(row => !!row.name)
}

export const createListWithCheckboxes = (data = [], selectedItems) =>
  data
    .map(row => ({
      ...row,
      checked:
        selectedItems.includes(row.name) || selectedItems.includes(row.id)
    }))
    .filter(row => !!row.name)
