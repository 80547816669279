import React, { Component } from 'react'
import { DropdownMenu, MenuItem, TextField } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import styles from './style.css'
import { FooterComponent } from '../layouts/footer-navigation'
import { FlexBody } from '../layouts/flex-body'
import WorkplaceDropdownContainer from '../../containers/workplace-dropdown'

const propTypes = {
  onLocationChange: PropTypes.func.isRequired,
  supervisors: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
  onAddLocationClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  locationData: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    supervisorId: PropTypes.bool,
    primaryArea: PropTypes.bool
  }),
  onSelectWorkplaceError: PropTypes.func
}

const defaultProps = {
  supervisors: [],
  locationData: {},
  error: {},
  isLoading: false,
  onBackClick: () => {},
  onSelectWorkplaceError: () => {}
}

// TODO: loading not availbale on footer-navigation
// TODO: dropdown has placeholder & not <menu> & hintText of message

export class AddSuperVisorComponent extends Component {
  render() {
    const {
      onLocationChange,
      onAddLocationClick,
      supervisors,
      locationData,
      isLoading,
      onBackClick,
      error,
      onSelectWorkplaceError
    } = this.props
    return (
      <>
        <FlexBody>
          <div className={styles.location_content}>
            <DropdownMenu
              label="Supervisor"
              onChange={e => {
                onLocationChange(e.target.value, 'supervisorId')
              }}
              helperText={
                error.supervisorId ? 'Select a supervisor for the location' : ''
              }
              error={error.supervisorId}
              value={locationData.supervisorId || 'Select supervisor'}
              width={100}
            >
              <MenuItem disabled key={0} value="Select supervisor">
                Select supervisor
              </MenuItem>
              {supervisors.map(value => (
                <MenuItem key={value.id} value={value.id}>
                  {value.firstName} {value.lastName}
                </MenuItem>
              ))}
            </DropdownMenu>
            <WorkplaceDropdownContainer
              defaultValue="Select area"
              label="Primary Area"
              helperText="Select a primary area"
              error={error.primaryArea}
              onSelectWorkplaceError={onSelectWorkplaceError}
            />
            <div className={styles.block}>
              <TextField
                label="Location Message - optional"
                onChange={e => {
                  onLocationChange(e.target.value, 'locationMessage')
                }}
                placeholder="Special instruction for location"
                value={locationData.locationMessage}
                hintText="Include any special instructions team members will need to work at
              this location."
                rows={4}
                rowsMax={4}
              />
            </div>
          </div>
        </FlexBody>
        <FooterComponent
          isLoading={isLoading}
          buttonInfo=" All fields required unless otherwise noted"
          onNextClick={onAddLocationClick}
          onBackClick={onBackClick}
          nextText="Add Location"
        />
      </>
    )
  }
}

AddSuperVisorComponent.propTypes = propTypes
AddSuperVisorComponent.defaultProps = defaultProps

export default AddSuperVisorComponent
