import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, List } from 'syrg-design-kit'
import { FooterComponent } from '../layouts/footer-navigation'
import { Loader } from '../controls/loader'
import styles from './style.css'
import { ValidationText } from '../theme'
import { FlexBody } from '../layouts/flex-body'
import { createListWithCheckboxes } from '../../utils/user-list-sorting'
import { PageLayout } from '../layouts/page-layout'

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onNextClick: PropTypes.func.isRequired,
  positions: PropTypes.arrayOf(PropTypes.object),
  onBackClick: PropTypes.func.isRequired,
  onSelectPosition: PropTypes.func.isRequired,
  selectedPositions: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool
}

const defaultProps = {
  positions: [],
  selectedPositions: [],
  error: false
}

export class AddMemberPositionComponent extends Component {
  onselectPositionWithId = id => {
    const { positions, onSelectPosition } = this.props
    const { name } = positions.find(position => position.id === id)

    onSelectPosition(name || '')
  }

  render() {
    const {
      onNextClick,
      positions,
      onBackClick,
      selectedPositions,
      isLoading,
      error
    } = this.props
    const mappedPositions = createListWithCheckboxes(
      positions,
      selectedPositions
    )

    return (
      <PageLayout>
        <span className={styles.add_member_pos}>
          <Typography variant="h4" color="primary">
            Select All Qualified Positions
          </Typography>
        </span>
        <FlexBody>
          {isLoading ? (
            <Loader />
          ) : (
            <List
              withCheckbox
              withSearch={false}
              list={mappedPositions}
              onCheckboxCheck={this.onselectPositionWithId}
            />
          )}
          {error && (
            <ValidationText textInfo="Must select at least one position" />
          )}
        </FlexBody>
        <FooterComponent
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          buttonInfo="Must select at least one field"
        />
      </PageLayout>
    )
  }
}

AddMemberPositionComponent.propTypes = propTypes
AddMemberPositionComponent.defaultProps = defaultProps

export default AddMemberPositionComponent
