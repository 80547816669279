import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose, sortBy } from 'lodash'
import { Snackbar } from 'syrg-design-kit'
import {
  getEditManager,
  getCurrentShift
} from '../../apollo/apollo-cache-query.gql'
import { ManagerDetailsComponent } from '../../components/manager-details'
import { restrictedUsers } from '../../utils/constant'
import {
  deleteManager,
  resetManagerPassword,
  fetchManager
} from './manager-details.gql'
import { getSupervisor } from '../location-details/location-info.gql'

const propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  deleteSupervisor: PropTypes.func,
  resetPassword: PropTypes.func,
  getManagerData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getManagerInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  getShift: {
    currentShift: {
      manager: {
        id: ''
      },
      workplace: {
        corporation: {
          settings: ''
        }
      }
    }
  },
  history: { goBack: () => {} },
  getManagerData: {},
  getManagerInfo: {},
  deleteSupervisor: () => {},
  resetPassword: () => {}
}

export class ManagerDetails extends Component {
  onManagerDelete = () => {
    const { deleteSupervisor, getShift, getManagerInfo } = this.props
    const {
      editManager: { managerId }
    } = getManagerInfo

    const {
      workplace: {
        corporation: { id: corporationId }
      }
    } = getShift.currentShift
    deleteSupervisor({
      variables: {
        input: {
          userId: managerId,
          corporationId
        }
      },
      update: cache => {
        try {
          const members = cache.readQuery({
            query: getSupervisor,
            variables: { corporationId }
          })
          const filteredManagers = members.getManagersByCorporationId.filter(
            mgr => mgr.id !== managerId
          )
          cache.writeQuery({
            query: getSupervisor,
            variables: { corporationId },
            data: { getManagersByCorporationId: filteredManagers }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(() => {
      Snackbar('Manager deleted successfully!', 'success', 2000)
      this.onBack()
    })
  }

  onResetManagerPassword = () => {
    const { getShift, resetPassword, getManagerInfo } = this.props
    const {
      editManager: { managerId }
    } = getManagerInfo

    resetPassword({
      variables: {
        input: {
          userId: getShift?.currentShift?.manager?.id,
          managerId,
          workplaceId: getShift?.currentShift?.workplace?.id
        }
      }
    }).then(data => {
      if (data?.data?.resetManagerPassword?.message) {
        Snackbar(data.data.resetManagerPassword.message, 'error', 2000)
      } else {
        Snackbar('Reset password successfully!', 'success', 2000)
        this.onBack()
      }
    })
  }

  onBack = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { getShift, getManagerData } = this.props
    if (getShift.loading || getManagerData.loading || getManagerData.errors) {
      return <ManagerDetailsComponent loading />
    }
    const { User } = getManagerData
    const {
      workplace: {
        corporation: { settings, id: corporationId }
      },
      manager: { id }
    } = getShift.currentShift
    const filteredWorkplace = User.managedWorkplaces.filter(
      x => corporationId === x.corporation.id
    )
    const workplaces = sortBy(filteredWorkplace, x => x.name)

    const isRestrictedUser = restrictedUsers.includes(id)
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    const isRestricted = syncType === 'KRONOS-WFD'
    return (
      <ManagerDetailsComponent
        managerId={User?.id}
        firstName={User?.firstName}
        lastName={User?.lastName}
        phoneNumber={User?.phoneNumber}
        workplaces={workplaces}
        isRestricted={isRestricted || isRestrictedUser}
        onBack={this.onBack}
        onManagerDelete={this.onManagerDelete}
        onManagerReset={this.onResetManagerPassword}
      />
    )
  }
}

export const ManagerDetailsApollo = compose(
  withApollo,
  graphql(getEditManager, { name: 'getManagerInfo' }),
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(deleteManager, { name: 'deleteSupervisor' }),
  graphql(resetManagerPassword, { name: 'resetPassword' }),
  graphql(fetchManager, {
    skip: ({ getManagerInfo }) => !getManagerInfo.editManager.managerId,
    options: ({ getManagerInfo }) => ({
      variables: {
        input: {
          id: getManagerInfo.editManager.managerId
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getManagerData'
  })
)(ManagerDetails)

ManagerDetails.propTypes = propTypes
ManagerDetails.defaultProps = defaultProps

export default ManagerDetailsApollo
