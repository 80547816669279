import React, { Component } from 'react'
import Authentication from './authentication'

const authentication = new Authentication({})

export const withAuth = WrappedComponent =>
  class extends Component {
    render() {
      return <WrappedComponent auth={authentication} {...this.props} />
    }
  }

export default authentication
