import gql from 'graphql-tag'

export const fetchWorkplacePositions = gql`
  query Workplace($input: WorkplaceInput!) {
    Workplace(input: $input) {
      positions {
        id
        name
        alias
        color
        users {
          id
        }
      }
    }
  }
`

export const updatePositionAlias = gql`
  mutation updatePositionAlias($id: ID!, $alias: String!) {
    updatePositionAlias(id: $id, alias: $alias) {
      id
      name
      alias
      color
    }
  }
`

export default { fetchWorkplacePositions, updatePositionAlias }
