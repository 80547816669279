import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose, orderBy } from 'lodash'
import { message } from 'antd'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import ShiftInfoComponent from '../../components/shift-info'
import {
  updateEmployeeCount,
  updateLocation,
  updateReplaceUserString,
  updateShiftPage,
  updateShiftEditing
} from '../../apollo/apollo-cache-mutation.gql'
import { fetchWorkplaceMembers } from '../team-member-table/team-member-table.gql'
import { getLocation } from './shift-info.gql'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { getEmployee } from '../../utils/search-table-methods'

const propTypes = {
  updateShiftLocation: PropTypes.func.isRequired,
  isSynced: PropTypes.bool,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  updateCurrentPage: PropTypes.func,
  updateReplacementUsers: PropTypes.func,
  getLocations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getWorkplaceMembers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  locationId: PropTypes.string,
  updateShiftEmployee: PropTypes.func,
  editShift: PropTypes.func,
  workplaceId: PropTypes.string
}

const defaultProps = {
  isSynced: false,
  updateCurrentPage: () => {},
  updateReplacementUsers: () => {},
  editShift: () => {},
  getLocations: { getDepartmentsByWorkplaceId: [] },
  getWorkplaceMembers: {},
  locationId: '',
  updateShiftEmployee: () => {},
  workplaceId: ''
}

export class ShiftInfo extends Component {
  constructor(props) {
    super(props)
    const { getShift } = props

    this.state = {
      locations: [],
      selectedId: '',
      replacementString: getShift?.currentShift?.replacedUsers,
      defaultReplacementString: getShift?.currentShift?.replacedUsers,
      teamMembers: [],
      positionId: '',
      error: {
        selectedId: false,
        positionId: false,
        teamMemberCount: false
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getLocations, locationId } = nextProps
    let { locations, selectedId, teamMembers, positionId } = prevState

    /* initializing locations */
    if (
      getLocations?.getDepartmentsByWorkplaceId &&
      getLocations.getDepartmentsByWorkplaceId !== prevState.locations
    ) {
      locations = orderBy(getLocations.getDepartmentsByWorkplaceId, 'name')
      selectedId = locationId
    }
    /* initializing emloyees */
    if (
      !nextProps?.getWorkplaceMembers?.loading &&
      nextProps?.getWorkplaceMembers?.Workplace
    ) {
      const {
        Workplace: { employees }
      } = nextProps.getWorkplaceMembers
      const {
        currentShift: { position }
      } = nextProps.getShift
      positionId = positionId || position?.id
      teamMembers = getEmployee(employees)
    }
    return {
      locations,
      teamMembers,
      positionId,
      selectedId
    }
  }

  onChange = () => {
    const { selectedId, positionId, replacementString } = this.state
    if (selectedId === '' && positionId === '' && replacementString === '') {
      const { editShift } = this.props
      editShift()
    }
  }

  onLocationClick = value => {
    const { locations, selectedId, error } = this.state
    const { updateShiftLocation } = this.props
    let location
    if (value !== 'Select a location') {
      this.onChange()
      location = locations.find(loc => loc.id === value)
      this.setState({
        selectedId: value,
        error: {
          ...error,
          selectedId: false
        }
      })
    } else {
      this.setState({
        selectedId: value,
        error: {
          ...error,
          selectedId: true
        }
      })
    }
    if (value !== selectedId) {
      updateShiftLocation({
        variables: {
          id: value === 'Select a location' ? 'Select a location' : value,
          name: location?.name || 'Select a location',
          message: location?.message || '',
          alias: location?.alias || ''
        }
      })
    }
  }

  onSelectPositionError = value => {
    const { error } = this.state
    this.setState({
      error: {
        ...error,
        positionId: value
      }
    })
  }

  handleChange = (e, value) => {
    this.setState({ replacementString: value || '' }, () => {
      this.onUpdateReplacementString(value)
    })
  }

  onUpdateReplacementString = replacementUserName => {
    const { updateReplacementUsers } = this.props
    const { teamMembers, error } = this.state

    if (replacementUserName !== 'Nobody is being replaced') {
      this.onChange()
      this.setState({
        error: {
          ...error,
          replacementString: false
        }
      })
      const replaced = teamMembers.find(
        x => x.name.toLowerCase() === replacementUserName.toLowerCase()
      )
      updateReplacementUsers({
        variables: {
          replacedUsers: replacementUserName || '',
          callOutUserId: replaced?.id || ''
        }
      })
    } else {
      this.setState({
        error: {
          ...error,
          replacementString: true
        }
      })
      updateReplacementUsers({
        variables: {
          replacedUsers: '',
          callOutUserId: ''
        }
      })
    }
  }

  onCounterClick = action => {
    const { updateShiftEmployee } = this.props
    if (!action.match(/[a-zA-Z!@#$%^&*)(+=._-]/g)) {
      const emp = Number(action)
      if (emp <= 25) {
        updateShiftEmployee({
          variables: {
            employeeCount: emp
          }
        })
      } else {
        Snackbar('Enter only 1-25 employees', 'error', 2000)
      }
    } else {
      updateShiftEmployee({
        variables: {
          employeeCount: 0
        }
      })
    }
  }

  onNextClick = () => {
    const { selectedId, positionId } = this.state
    const { updateCurrentPage, getShift } = this.props
    const teamMemberCount = getShift.currentShift.employeeCount === 0
    if (!selectedId || !positionId || teamMemberCount) {
      this.setState(prevState => ({
        error: {
          selectedId: !prevState.selectedId,
          positionId: !prevState.positionId,
          teamMemberCount
        }
      }))
      message.warning('Please fill in all fields')
    } else {
      updateCurrentPage({
        variables: {
          currentPage: 2
        }
      })
    }
  }

  render() {
    const {
      selectedId,
      locations,
      replacementString,
      teamMembers,
      error,
      defaultReplacementString
    } = this.state
    const { isSynced, getShift, workplaceId } = this.props
    let employeeCount
    if (getShift.currentShift) {
      employeeCount = getShift.currentShift.employeeCount
      if (employeeCount === 0) {
        employeeCount = ''
      }
    }
    return (
      <ShiftInfoComponent
        onLocationClick={this.onLocationClick}
        handleChange={this.handleChange}
        replacementString={replacementString}
        teamMembers={teamMembers}
        onCounterClick={this.onCounterClick}
        selectedId={selectedId}
        locations={locations}
        workplaceId={workplaceId}
        onSelectPositionError={this.onSelectPositionError}
        isSynced={isSynced}
        teamMemberCount={employeeCount}
        onNextClick={this.onNextClick}
        defaultReplacementString={defaultReplacementString}
        error={error}
      />
    )
  }
}
export const ShiftInfoContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateShiftEditing, { name: 'editShift' }),
  graphql(getLocation, {
    skip: props => !props.workplaceId,
    options: props => ({
      variables: {
        id: props.workplaceId
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getLocations'
  }),
  graphql(updateLocation, { name: 'updateShiftLocation' }),
  graphql(updateReplaceUserString, { name: 'updateReplacementUsers' }),
  graphql(fetchWorkplaceMembers, {
    options: ownProps => ({
      variables: {
        input: {
          id: ownProps?.getShift?.currentShift?.workplace?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplaceMembers'
  }),
  graphql(updateEmployeeCount, { name: 'updateShiftEmployee' }),
  graphql(updateShiftPage, { name: 'updateCurrentPage' })
)(ShiftInfo)

ShiftInfo.propTypes = propTypes
ShiftInfo.defaultProps = defaultProps

export default ShiftInfoContainer
