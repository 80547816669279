import React, { Component } from 'react'
import { Button } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import style from './style.css'
import { Footer } from '../footer'

const propTypes = {
  onNextClick: PropTypes.func,
  nextText: PropTypes.string,
  backText: PropTypes.string,
  onBackClick: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  buttonInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  isLink: PropTypes.bool,
  isLoading: PropTypes.bool
}

const defaultProps = {
  onNextClick: () => {},
  nextText: 'Next',
  backText: 'Back',
  onBackClick: () => {},
  isNextDisabled: false,
  buttonInfo: false,
  isLink: false,
  isLoading: false
}

export class FooterComponent extends Component {
  render() {
    const {
      onNextClick,
      nextText,
      backText,
      onBackClick,
      isNextDisabled,
      buttonInfo,
      isLink,
      isLoading
    } = this.props
    return (
      <Footer buttonInfo={buttonInfo} isLink={isLink}>
        <div className={style.nav_footer_wrapper}>
          <div className={style.left_button_width}>
            <Button onClick={onBackClick} id="back" fullWidth>
              {backText}
            </Button>
          </div>
          <div className={style.right_button_width}>
            <Button
              id="next"
              onClick={onNextClick}
              primary
              fullWidth
              height="50px"
              disabled={isNextDisabled}
            >
              {isLoading ? 'Loading...' : nextText}
            </Button>
          </div>
        </div>
      </Footer>
    )
  }
}

FooterComponent.propTypes = propTypes
FooterComponent.defaultProps = defaultProps

export default FooterComponent
