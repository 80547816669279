import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortBy, flowRight as compose } from 'lodash'
import { graphql, withApollo } from 'react-apollo'
import { SelectWorkplaceHeader } from '../../components/theme'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { updateCurrentWorkplace } from '../../apollo/apollo-cache-mutation.gql'
import { fetchWorkplace } from './workplace-header.gql'
import authentication from '../../authentication'

const propTypes = {
  updateWorkplace: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getWorkplace: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isWorkplaceDropdown: PropTypes.bool
}

const defaultProps = {
  getShift: {},
  getWorkplace: {},
  isWorkplaceDropdown: true
}

export class WorkplaceHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allWorkplace: [],
      selectedWorkplace: {}
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let { allWorkplace, selectedWorkplace } = state
    const { getWorkplace, getShift, updateWorkplace } = nextProps
    let workplace = {}

    let managedWorkplaces = getWorkplace?.User?.managedWorkplaces || []
    managedWorkplaces = sortBy(managedWorkplaces, x => x.name)

    if (getShift?.currentShift) workplace = getShift.currentShift.workplace

    if (!workplace.id && managedWorkplaces.length > 0) {
      workplace = managedWorkplaces[0]
      const { id, name, phoneNumber, timezone } = workplace
      const corporationId = workplace?.corporation?.id
      const corporationName = workplace?.corporation?.name
      const settings = workplace?.corporation?.settings
      updateWorkplace({
        variables: {
          id,
          name,
          phone: phoneNumber,
          timezone,
          corporationId,
          corporationName,
          settings
        }
      })
    }

    allWorkplace = managedWorkplaces
    selectedWorkplace = workplace
    return { allWorkplace, selectedWorkplace }
  }

  onChangeWorkplace = key => {
    const { updateWorkplace } = this.props
    const { allWorkplace } = this.state
    const selectedWork = allWorkplace.filter(workplace => workplace.id === key)
    const { id, name, phoneNumber, timezone } = selectedWork[0]
    const corporationId = selectedWork[0]?.corporation?.id
    const corporationName = selectedWork[0]?.corporation?.name
    const settings = selectedWork[0]?.corporation?.settings
    updateWorkplace({
      variables: {
        id,
        name,
        phone: phoneNumber,
        timezone,
        corporationId,
        corporationName,
        settings
      }
    })
  }

  render() {
    const { history, getShift, isWorkplaceDropdown } = this.props
    const { allWorkplace, selectedWorkplace } = this.state
    // TODO: TEMPORARY SOLUTION TO LOGOUT USERS WITHOUT WORKPLACES
    if (getShift.currentShift && !getShift.currentShift.manager.id) {
      authentication.signOut().then(() => {
        window.location.reload()
      })
    }
    return (
      <SelectWorkplaceHeader
        isWorkplaceDropdown={isWorkplaceDropdown}
        allWorkplaces={allWorkplace}
        selectedWorkplace={selectedWorkplace}
        changeWorkPlace={this.onChangeWorkplace}
        history={history}
      />
    )
  }
}

export const WorkplaceHeaderContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(updateCurrentWorkplace, { name: 'updateWorkplace' }),
  graphql(fetchWorkplace, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.manager.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.manager?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getWorkplace'
  })
)(WorkplaceHeader)

WorkplaceHeader.propTypes = propTypes
WorkplaceHeader.defaultProps = defaultProps

export default WorkplaceHeaderContainer
