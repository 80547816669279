import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import { Button, Dialog, Typography, Link as LinkButton } from 'syrg-design-kit'
import { Header } from '../theme'
import style from './style.css'
import { FlexBody } from '../layouts/flex-body'
import { PageLayout } from '../layouts/page-layout'
import FooterButton from '../layouts/footer-button'
import { ManagerFormApollo } from '../../containers/manager-form'

const propTypes = {
  loading: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workplaces: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    )
  ),
  isRestricted: PropTypes.bool,
  managerId: PropTypes.string,
  onManagerDelete: PropTypes.func,
  onManagerReset: PropTypes.func,
  onBack: PropTypes.func
}

const defaultProps = {
  loading: false,
  isRestricted: false,
  managerId: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  workplaces: [],
  onManagerDelete: () => {},
  onManagerReset: () => {},
  onBack: () => {}
}

export class ManagerDetailsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isManagerDeleteModal: false,
      isManagerEdit: false,
      isManagerResetModal: false
    }
  }

  onManagerEdit = () => {
    this.setState(prevState => ({ isManagerEdit: !prevState.isManagerEdit }))
  }

  managerDeleteModal = () => {
    this.setState(prevState => ({
      isManagerDeleteModal: !prevState.isManagerDeleteModal
    }))
  }

  onDeleteClick = () => {
    const { onManagerDelete } = this.props
    this.managerDeleteModal()
    onManagerDelete()
  }

  managerResetModal = () => {
    this.setState(prevState => ({
      isManagerResetModal: !prevState.isManagerResetModal
    }))
  }

  onResetClick = () => {
    const { onManagerReset } = this.props
    this.managerResetModal()
    onManagerReset()
  }

  render() {
    const {
      isManagerDeleteModal,
      isManagerEdit,
      isManagerResetModal
    } = this.state
    const {
      firstName,
      lastName,
      phoneNumber,
      workplaces,
      loading,
      isRestricted,
      onBack,
      managerId
    } = this.props
    const displayInfo = (
      <>
        <div className={style.manager_location}>
          <Typography variant="h4" color="primary">
            {workplaces.length === 0 ? 'No Area Managed' : 'Areas Managed'}
          </Typography>
          {workplaces.length > 0 &&
            workplaces.map(loc => (
              <Typography key={loc.id} variant="body1">
                {loc.name}
              </Typography>
            ))}
        </div>
        {!isRestricted && (
          <div className={style.manager_detail_btn}>
            <Button
              data-id="reset_manager"
              size="small"
              onClick={this.managerResetModal}
            >
              Reset Password
            </Button>
            <Button
              data-id="delete_manager"
              size="small"
              color="secondary"
              onClick={this.managerDeleteModal}
            >
              Delete Manager
            </Button>
          </div>
        )}
      </>
    )

    return (
      <>
        {!isManagerEdit && (
          <PageLayout>
            <Header onLeftClick={onBack} />
            <FlexBody>
              {loading ? (
                <Icon
                  type="loading"
                  spin
                  className={style.manager_detail_loading}
                />
              ) : (
                <div className={style.manager_detail}>
                  <div className={style.manager_detail_info}>
                    <Typography variant="h1">Manager Details</Typography>
                    {!isRestricted && (
                      <LinkButton isButton onClick={this.onManagerEdit}>
                        Edit
                      </LinkButton>
                    )}
                  </div>
                  <Typography variant="h3">
                    {firstName} {lastName}
                  </Typography>
                  <Typography variant="h4">Phone</Typography>
                  <Typography variant="body2">
                    <b>{phoneNumber}</b>
                  </Typography>
                  {displayInfo}
                </div>
              )}
            </FlexBody>
            <FooterButton data-id="back_shift" onClick={onBack}>
              Back to Managers
            </FooterButton>
          </PageLayout>
        )}
        {!loading && isManagerEdit && (
          <ManagerFormApollo
            supervisorId={managerId}
            firstName={firstName}
            lastName={lastName}
            phoneNumber={phoneNumber}
            isEdit
            onCancel={this.onManagerEdit}
          >
            {displayInfo}
          </ManagerFormApollo>
        )}
        <Dialog
          open={isManagerDeleteModal}
          onClose={this.managerDeleteModal}
          title="Delete Manager?"
          okText="Delete"
          onOk={this.onDeleteClick}
          onCancel={this.managerDeleteModal}
        >
          <Typography variant="body1">
            They will no longer be able to manage employees and create new shift
            requests.
          </Typography>
        </Dialog>
        <Dialog
          open={isManagerResetModal}
          onClose={this.managerResetModal}
          title="Reset Manager Password?"
          okText="Reset Password"
          onOk={this.onResetClick}
          onCancel={this.managerResetModal}
        >
          <Typography variant="body1">
            The manager will be prompted to reset their password next time they
            log in.
          </Typography>
        </Dialog>
      </>
    )
  }
}

ManagerDetailsComponent.propTypes = propTypes
ManagerDetailsComponent.defaultProps = defaultProps

export default ManagerDetailsComponent
