// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-name-position-style__create_position--2nAin {\n  padding: 30px 20px;\n}\n\n.src-components-name-position-style__create_position--2nAin p {\n  margin-bottom: 30px;\n}\n\n.src-components-name-position-style__create_position--2nAin h2 {\n  margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"create_position": "src-components-name-position-style__create_position--2nAin"
};
module.exports = exports;
