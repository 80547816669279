// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-footer-button-style__footer_btn_wrapper--3ZuoR {\n  background-color: #fff;\n}\n", ""]);
// Exports
exports.locals = {
	"footer_btn_wrapper": "src-components-layouts-footer-button-style__footer_btn_wrapper--3ZuoR"
};
module.exports = exports;
