// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-team-member-department-style__team_member_dept--3t1Cc h4 {\n  margin-bottom: 15px;\n  padding: 0 20px;\n}\n\n.src-components-team-member-department-style__team_member_dept--3t1Cc h2 {\n  margin: 20px 0 40px 20px;\n}\n\n.src-components-team-member-department-style__select_all_text--2JyWg {\n  margin-right: 10px;\n}\n\n.src-components-team-member-department-style__select_check--3iuJ7 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  padding-right: 35px;\n}\n", ""]);
// Exports
exports.locals = {
	"team_member_dept": "src-components-team-member-department-style__team_member_dept--3t1Cc",
	"select_all_text": "src-components-team-member-department-style__select_all_text--2JyWg",
	"select_check": "src-components-team-member-department-style__select_check--3iuJ7"
};
module.exports = exports;
