// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-logo-header-style__selectHeader--2ZUSz {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  padding: 10px 20px;\n  min-height: 51px;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-components-layouts-logo-header-style__selectHeader--2ZUSz .src-components-layouts-logo-header-style__menuIcon--12jPa {\n  -ms-flex: 1 1;\n      flex: 1 1;\n}\n\n.src-components-layouts-logo-header-style__menuIcon--12jPa .src-components-layouts-logo-header-style__close--2BPDu {\n  cursor: pointer;\n  font-size: 18px;\n  color: #333c47;\n}\n\n.src-components-layouts-logo-header-style__menuIcon--12jPa > div > button {\n  font-size: 14px;\n  padding: 0;\n}\n\n.src-components-layouts-logo-header-style__selectHeader--2ZUSz .src-components-layouts-logo-header-style__cancel--3MMr1 {\n  cursor: pointer;\n  color: #2a53ba;\n  text-decoration: underline;\n}\n\n.src-components-layouts-logo-header-style__selectHeader--2ZUSz .src-components-layouts-logo-header-style__dropHeader--2FZe3 {\n  -ms-flex: 1 1;\n      flex: 1 1;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  -ms-flex-align: center;\n      align-items: center;\n  height: 20px;\n}\n\n.src-components-layouts-logo-header-style__selectHeader--2ZUSz .src-components-layouts-logo-header-style__imgLogo--rmwp1 {\n  -ms-flex: 1 1;\n      flex: 1 1;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"selectHeader": "src-components-layouts-logo-header-style__selectHeader--2ZUSz",
	"menuIcon": "src-components-layouts-logo-header-style__menuIcon--12jPa",
	"close": "src-components-layouts-logo-header-style__close--2BPDu",
	"cancel": "src-components-layouts-logo-header-style__cancel--3MMr1",
	"dropHeader": "src-components-layouts-logo-header-style__dropHeader--2FZe3",
	"imgLogo": "src-components-layouts-logo-header-style__imgLogo--rmwp1"
};
module.exports = exports;
