import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import moment from 'moment-timezone'
import _, { flatMap } from 'lodash'
import {
  Button,
  Dialog,
  Switch,
  Typography,
  Link as LinkButton
} from 'syrg-design-kit'
import { Header } from '../theme'
import style from './style.css'
import { FlexBody } from '../layouts/flex-body'
import { PageLayout } from '../layouts/page-layout'
import FooterButton from '../layouts/footer-button'
import { phoneFormatter } from '../../utils/phone-formatter'
import { UnavailabilityListContainerApollo } from '../../containers/unavailability-list'
import { UpdateTeamMemberContainer } from '../../containers/update-team-member'

const propTypes = {
  loading: PropTypes.bool,
  memberInfo: PropTypes.shape({
    id: PropTypes.string,
    employeeId: PropTypes.string,
    isTemp: PropTypes.bool,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.number,
      optedOut: PropTypes.bool,
      preferredLanguage: PropTypes.string
    }),
    primaryWorkplace: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    excludedDepartments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    seniority: PropTypes.number,
    hireDate: PropTypes.string
  }),
  onMemberDelete: PropTypes.func,
  isRestricted: PropTypes.bool,
  onBack: PropTypes.func,
  onLocationEdit: PropTypes.func,
  corporationUserId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  loading: false,
  history: { push: () => {} },
  memberInfo: {},
  onBack: () => {},
  onLocationEdit: () => {},
  onMemberDelete: () => {},
  isRestricted: false,
  corporationUserId: ''
}

export class EmployeeDetailsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMemberDeleteModal: false,
      isEmployeeEdit: false
    }
  }

  onEmployeeEdit = () => {
    this.setState(prevState => ({ isEmployeeEdit: !prevState.isEmployeeEdit }))
  }

  memberDeleteModal = () => {
    this.setState(prevState => ({
      isMemberDeleteModal: !prevState.isMemberDeleteModal
    }))
  }

  onDeleteClick = () => {
    const { onMemberDelete } = this.props
    this.memberDeleteModal()
    onMemberDelete()
  }

  render() {
    const { isMemberDeleteModal, isEmployeeEdit } = this.state
    const {
      memberInfo,
      loading,
      isRestricted,
      onBack,
      onLocationEdit,
      corporationUserId,
      history
    } = this.props
    let departments = memberInfo?.departments || []
    departments = _.orderBy(departments, ['name'], ['asc'])

    const displayInfo = (
      <>
        <div className={style.emp_detail_loc_header}>
          <Typography variant="h4" color="primary">
            Locations
          </Typography>
          {!isRestricted && (
            <LinkButton isButton onClick={onLocationEdit}>
              Edit
            </LinkButton>
          )}
        </div>
        {departments.length === 0
          ? 'No Location'
          : departments.map(loc => (
              <Typography variant="body1" key={loc.id}>
                {loc.name}
              </Typography>
            ))}
        <UnavailabilityListContainerApollo
          history={history}
          corporationUserId={memberInfo.id}
        />
        {!isRestricted && (
          <div className={style.emp_detail_delete_emp}>
            <Button
              data-id="delete_emp"
              size="small"
              width="100%"
              onClick={this.memberDeleteModal}
              color="secondary"
            >
              Delete Employee
            </Button>
          </div>
        )}
      </>
    )

    return (
      <PageLayout>
        <Header leftText={!isEmployeeEdit} onLeftClick={onBack} />
        {loading && (
          <FlexBody>
            <Icon type="loading" spin className={style.emp_detail_loading} />
          </FlexBody>
        )}

        {!loading && !isEmployeeEdit && (
          <FlexBody>
            <div className={style.emp_detail}>
              <div className={style.emp_detail_header}>
                <Typography variant="h1">Employee Details</Typography>
                {!isRestricted && (
                  <LinkButton isButton onClick={this.onEmployeeEdit}>
                    Edit
                  </LinkButton>
                )}
              </div>
              <Typography variant="h3">
                {memberInfo?.user?.firstName} {memberInfo?.user?.lastName}
              </Typography>
              <div className={style.phone_number_info}>
                <Typography variant="h4">Phone</Typography>
              </div>
              <Typography variant="body2">
                <b>{phoneFormatter(memberInfo?.user?.phoneNumber)}</b>
              </Typography>
              <span className={style.detail}>
                <Typography variant="h4" color="primary">
                  Details
                </Typography>
              </span>
              <div className={style.emp_detail_info}>
                <span>
                  <Typography variant="h4">Seniority Rank:</Typography>
                  <Typography variant="body2">
                    {memberInfo?.seniority}
                  </Typography>
                </span>
                <span>
                  <Typography variant="h4">Primary Area:</Typography>
                  <Typography variant="body2">
                    {memberInfo?.primaryWorkplace?.name}
                  </Typography>
                </span>
                <span>
                  <Typography variant="h4">Hire Date:</Typography>
                  <Typography variant="body2">
                    {memberInfo?.hireDate &&
                      moment
                        .unix(memberInfo.hireDate)
                        .utc()
                        .format('MM/DD/YYYY')}
                  </Typography>
                </span>
                <span>
                  <Typography variant="h4">Preferred Language:</Typography>
                  <Typography variant="body2">
                    {memberInfo?.user?.preferredLanguage}
                  </Typography>
                </span>
                <span>
                  <Typography variant="h4">Employee ID:</Typography>
                  <Typography variant="body2">
                    {memberInfo?.employeeId}
                  </Typography>
                </span>
              </div>
              <div className={style.switch}>
                <Switch
                  checked={memberInfo?.isTemp || false}
                  value={memberInfo?.isTemp || false}
                />
                Temp Worker
              </div>
              {displayInfo}
            </div>
          </FlexBody>
        )}

        {!loading && isEmployeeEdit && (
          <UpdateTeamMemberContainer
            teamMemberInfo={{
              id: corporationUserId,
              firstName: memberInfo?.user?.firstName,
              lastName: memberInfo?.user?.lastName,
              phoneNumber: memberInfo?.user?.phoneNumber,
              hireDate: memberInfo?.hireDate,
              primaryWorkplace: memberInfo?.primaryWorkplace?.id,
              preferredLanguage: memberInfo?.user?.preferredLanguage,
              employeeId: memberInfo?.employeeId,
              isTemp: memberInfo?.isTemp,
              seniority: memberInfo?.seniority,
              excludedLocation: flatMap(memberInfo?.excludedDepartments).map(
                b => b.id
              )
            }}
            isEdit
            onCancel={this.onEmployeeEdit}
          >
            {displayInfo}
          </UpdateTeamMemberContainer>
        )}

        {!isEmployeeEdit && (
          <FooterButton data-id="back_shift" onClick={onBack}>
            Back to Employees
          </FooterButton>
        )}
        <Dialog
          open={isMemberDeleteModal}
          onClose={this.memberDeleteModal}
          title="Delete Employee?"
          okText="Delete"
          onOk={this.onDeleteClick}
          onCancel={this.memberDeleteModal}
        >
          <Typography variant="body1">
            Deleting this employee will remove them from SYRG shift requests.
          </Typography>
        </Dialog>
      </PageLayout>
    )
  }
}

EmployeeDetailsComponent.propTypes = propTypes
EmployeeDetailsComponent.defaultProps = defaultProps

export default EmployeeDetailsComponent
