import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, Link as LinkButton } from 'syrg-design-kit'
import moment from 'moment-timezone'
import style from './style.css'

const propTypes = {
  unavailability: PropTypes.arrayOf(PropTypes.object),
  onUnavailableClick: PropTypes.func,
  timezone: PropTypes.string,
  corporationUserId: PropTypes.string
}

const defaultProps = {
  unavailability: [{}],
  onUnavailableClick: () => {},
  timezone: '',
  corporationUserId: ''
}

export class UnavailabilityListComponent extends Component {
  render() {
    const {
      unavailability,
      onUnavailableClick,
      timezone,
      corporationUserId
    } = this.props
    return (
      <>
        <div className={style.not_available_header}>
          <Typography variant="h4" color="primary">
            Unavailablilty Schedule
          </Typography>
          <span>
            <Button
              data-id="add_unavailability"
              size="small"
              width="157px"
              onClick={() =>
                onUnavailableClick(
                  '',
                  moment().format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD'),
                  '',
                  corporationUserId
                )
              }
            >
              Add Time
            </Button>
          </span>
        </div>
        {unavailability?.map(
          unavailable =>
            unavailable.id && (
              <div
                key={unavailable?.id}
                className={style.unavailable_team_wrap}
              >
                <div className={style.unavailable_team_date}>
                  <Typography variant="h4">
                    {moment
                      .tz(moment.unix(unavailable?.startTime), timezone)
                      .format('MMM D, YYYY')}{' '}
                    {' -  '}
                    {moment
                      .tz(moment.unix(unavailable?.endTime), timezone)
                      .format('MMM D, YYYY')}
                  </Typography>
                  <LinkButton
                    data-id="edit_unavailable"
                    isButton
                    onClick={() =>
                      onUnavailableClick(
                        unavailable?.id,
                        moment
                          .tz(moment.unix(unavailable.startTime), timezone)
                          .format('YYYY-MM-DD'),
                        moment
                          .tz(moment.unix(unavailable.endTime), timezone)
                          .format('YYYY-MM-DD'),
                        unavailable?.reason,
                        corporationUserId
                      )
                    }
                  >
                    Edit
                  </LinkButton>
                </div>
                <div className={style.unavailable_team_resaons}>
                  <Typography variant="h4">Reason:</Typography>
                  <Typography variant="caption">
                    {unavailable?.reason}
                  </Typography>
                </div>
              </div>
            )
        )}
      </>
    )
  }
}

UnavailabilityListComponent.propTypes = propTypes
UnavailabilityListComponent.defaultProps = defaultProps

export default UnavailabilityListComponent
