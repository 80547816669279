// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-base-style__wrap--qcK3j {\n  background-color: #fff;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  position: absolute;\n}\n", ""]);
// Exports
exports.locals = {
	"wrap": "src-components-layouts-base-style__wrap--qcK3j"
};
module.exports = exports;
