// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-eligible-team-member-style__eligible_head_text--1p9RS {\n  margin-bottom: 12px;\n}\n\n.src-components-eligible-team-member-style__eligible_head_text--1p9RS h4 {\n  margin: 40px 0 20px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"eligible_head_text": "src-components-eligible-team-member-style__eligible_head_text--1p9RS"
};
module.exports = exports;
