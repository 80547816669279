import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Snackbar } from 'syrg-design-kit'
import { flowRight as compose } from 'lodash'
import { graphql, withApollo } from 'react-apollo'
import { UnavailableFormComponent } from '../../components/unavailable-form'
import {
  getCurrentShift,
  getUnavailable
} from '../../apollo/apollo-cache-query.gql'
import { clearUnavailable } from '../../apollo/apollo-cache-mutation.gql'
import {
  createUserUnavailability,
  deleteUnavailability,
  getUnavailability,
  updateUnavailabilityMutation
} from '../unavailability-list/unavailability-list.gql'

const propTypes = {
  getUnavailableData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  clearUnavailableData: PropTypes.func,
  createUnavailability: PropTypes.func,
  deleteUserUnavailability: PropTypes.func,
  updateUnavailability: PropTypes.func,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  clearUnavailableData: () => {},
  getUnavailableData: {},
  createUnavailability: () => {},
  deleteUserUnavailability: () => {},
  updateUnavailability: () => {},
  history: { push: () => {} }
}

export class UnavailableForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props?.getUnavailableData?.unavailable?.id,
      startDate: props?.getUnavailableData?.unavailable?.startDate,
      endDate: props?.getUnavailableData?.unavailable?.endDate,
      reason: props?.getUnavailableData?.unavailable?.reason,
      error: {
        date: false,
        reason: false
      }
    }
  }

  createUnavailability = () => {
    const { createUnavailability, getUnavailableData, getShift } = this.props
    const {
      workplace: { timezone }
    } = getShift?.currentShift
    const {
      unavailable: { corporationUserId }
    } = getUnavailableData
    const { startDate, endDate, reason } = this.state
    /* strip timezone */
    const sDate = moment(startDate).format('YYYY-MM-DD')
    const eDate = moment(endDate).format('YYYY-MM-DD')
    /* format to correct timezone and convert to unix */
    const sTime = moment
      .tz(sDate, timezone)
      .startOf('day')
      .unix()
    const eTime = moment
      .tz(eDate, timezone)
      .endOf('day')
      .unix()
    if (reason !== '') {
      if (sTime < eTime) {
        this.setState({ error: { date: false, reason: false } })
        createUnavailability({
          variables: {
            input: {
              corporationUserId,
              reason,
              startTime: `${sTime}`,
              endTime: `${eTime}`
            }
          },
          update: (
            cache,
            { data: { createCorporationUserUnavailability: newUnavailability } }
          ) => {
            try {
              const allUnavailabilities = cache.readQuery({
                query: getUnavailability,
                variables: { corporationUserId }
              })
              let newUnavailabilities = allUnavailabilities
              if (!newUnavailabilities) {
                newUnavailabilities = []
              }
              if (
                newUnavailabilities.getCorporationUserUnavailability.filter(
                  unavilability => unavilability.id === newUnavailability.id
                ).length === 0
              ) {
                newUnavailabilities.getCorporationUserUnavailability.push(
                  newUnavailability
                )
              }
              cache.writeQuery({
                query: getUnavailability,
                variables: { corporationUserId },
                data: {
                  getCorporationUserUnavailability: [
                    ...newUnavailabilities.getCorporationUserUnavailability
                  ]
                }
              })
            } catch (error) {
              console.info('error', error)
            }
          }
        }).then(() => {
          Snackbar('Successfully added unavailability!', 'success', 2000)
          this.onBack()
        })
      } else {
        this.setState({ error: { date: true, reason: false } })
        Snackbar(
          'Start date must be before or the same day as the end date',
          'error'
        )
      }
    } else {
      this.setState({ error: { date: false, reason: true } })
      Snackbar('Please enter a reason for this unavailability', 'error')
    }
  }

  onUpdateUnavailability = () => {
    const { id, startDate, endDate, reason } = this.state
    const { getUnavailableData, updateUnavailability, getShift } = this.props
    const {
      workplace: { timezone }
    } = getShift?.currentShift
    const {
      unavailable: { corporationUserId }
    } = getUnavailableData
    /* strip timezone */
    const sDate = moment(startDate).format('YYYY-MM-DD')
    const eDate = moment(endDate).format('YYYY-MM-DD')
    /* format to correct timezone and convert to unix */
    const sTime = moment
      .tz(sDate, timezone)
      .startOf('day')
      .unix()
    const eTime = moment
      .tz(eDate, timezone)
      .endOf('day')
      .unix()
    if (reason !== '') {
      if (sTime < eTime) {
        updateUnavailability({
          variables: {
            input: {
              id,
              reason,
              startTime: `${sTime}`,
              endTime: `${eTime}`
            }
          },
          update: (
            cache,
            { data: { updateCorporationUserUnavailability: updatedObject } }
          ) => {
            try {
              const allUnavailabilities = cache.readQuery({
                query: getUnavailability,
                variables: { corporationUserId }
              })
              const {
                getCorporationUserUnavailability: unavilabilities
              } = allUnavailabilities
              const removedUpdate = unavilabilities.filter(
                u => u.id !== updatedObject.id
              )
              const updatedUnavailability = removedUpdate.concat(updatedObject)

              cache.writeQuery({
                query: getUnavailability,
                variables: { corporationUserId },
                data: {
                  getCorporationUserUnavailability: [...updatedUnavailability]
                }
              })
            } catch (error) {
              console.info('error', error)
            }
          }
        }).then(() => {
          Snackbar('Successfully updated unavailability!', 'success', 2000)
          this.onBack()
        })
      } else {
        this.setState({ error: { date: true, reason: false } })
        Snackbar(
          'Start date must be before or the same day as the end date',
          'error'
        )
      }
    } else {
      this.setState({ error: { date: false, reason: true } })
      Snackbar('Please enter a reason for this unavailability', 'error')
    }
  }

  removeUnavailableCard = () => {
    const { id } = this.state
    const { deleteUserUnavailability, getUnavailableData } = this.props
    const {
      unavailable: { corporationUserId }
    } = getUnavailableData
    deleteUserUnavailability({
      variables: {
        id
      },
      update: cache => {
        try {
          const allUnavailabilities = cache.readQuery({
            query: getUnavailability,
            variables: { corporationUserId }
          })
          const filteredUnavailabilities = allUnavailabilities.getCorporationUserUnavailability.filter(
            unavailability => unavailability.id !== id
          )
          cache.writeQuery({
            query: getUnavailability,
            variables: { corporationUserId },
            data: {
              getCorporationUserUnavailability: filteredUnavailabilities
            }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(() => {
      Snackbar('Successfully deleted unavailability!', 'success', 2000)
      this.onBack()
    })
  }

  onBack = () => {
    const { history, clearUnavailableData } = this.props
    clearUnavailableData().then(() => {
      history.push('/employeeDetail')
    })
  }

  onEditContent = (key, value) => {
    this.setState({
      [key]:
        key === 'reason' ? value.trim() : moment(value).format('YYYY-MM-DD')
    })
  }

  render() {
    const { id, startDate, endDate, reason, error } = this.state
    return (
      <UnavailableFormComponent
        id={id}
        startDate={startDate}
        endDate={endDate}
        reason={reason}
        onBack={this.onBack}
        onNext={!id ? this.createUnavailability : this.onUpdateUnavailability}
        onDelete={this.removeUnavailableCard}
        onChange={this.onEditContent}
        error={error}
      />
    )
  }
}

export const UnavailableFormContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(clearUnavailable, { name: 'clearUnavailableData' }),
  graphql(getUnavailable, { name: 'getUnavailableData' }),
  graphql(createUserUnavailability, { name: 'createUnavailability' }),
  graphql(deleteUnavailability, { name: 'deleteUserUnavailability' }),
  graphql(updateUnavailabilityMutation, { name: 'updateUnavailability' })
)(UnavailableForm)

UnavailableForm.propTypes = propTypes
UnavailableForm.defaultProps = defaultProps

export default UnavailableFormContainer
