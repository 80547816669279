import gql from 'graphql-tag'

export const fetchManager = gql`
  query User($input: UserInput!) {
    User(input: $input) {
      id
      firstName
      lastName
      phoneNumber
      preferredLanguage
      managedWorkplaces {
        id
        name
        corporation {
          id
        }
      }
    }
  }
`

export const deleteManager = gql`
  mutation deleteManager($input: deleteManagerInput!) {
    deleteManager(input: $input)
  }
`

export const resetManagerPassword = gql`
  mutation resetManagerPassword($input: resetManagerPasswordInput!) {
    resetManagerPassword(input: $input) {
      message
      userId
    }
  }
`

export default fetchManager
