import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Typography } from 'syrg-design-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import style from '../../layouts/logo-header/style.css'
import { LogoHeader } from '../../layouts/logo-header'

const propTypes = {
  isCancel: PropTypes.bool,
  isClose: PropTypes.bool,
  onDelete: PropTypes.func,
  deleteName: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  leftText: PropTypes.bool,
  nextText: PropTypes.string,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  showModal: PropTypes.bool
}

const defaultProps = {
  isClose: false,
  isCancel: false,
  showModal: true,
  leftText: true,
  title: '',
  message: '',
  deleteName: 'Discard',
  nextText: '',
  onDelete: () => {},
  onLeftClick: () => {},
  onRightClick: () => {}
}

export class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteModalOpen: false
    }
  }

  onModalClick = () => {
    const { showModal, onDelete } = this.props
    const { isDeleteModalOpen } = this.state
    if (showModal) this.setState({ isDeleteModalOpen: !isDeleteModalOpen })
    else onDelete()
  }

  getLeftData = () => {
    const { isCancel, isClose, onLeftClick } = this.props
    if (isClose)
      return (
        <FontAwesomeIcon
          onClick={this.onModalClick}
          data-id="closeIcon"
          className={style.close}
          icon={faTimes}
        />
      )
    if (isCancel)
      return (
        <span
          role="presentation"
          data-id="cancel"
          onClick={onLeftClick}
          className={style.cancel}
        >
          {' '}
          Cancel{' '}
        </span>
      )
    return (
      <FontAwesomeIcon
        data-id="close"
        onClick={onLeftClick}
        className={style.close}
        icon={faAngleLeft}
      />
    )
  }

  render() {
    const {
      title,
      message,
      deleteName,
      onDelete,
      nextText,
      onRightClick,
      leftText
    } = this.props
    const { isDeleteModalOpen } = this.state
    return (
      <>
        <LogoHeader
          leftInfo={leftText && this.getLeftData()}
          rightInfo={
            nextText && (
              <span
                data-id="next"
                className={style.cancel}
                role="presentation"
                onClick={onRightClick}
              >
                {nextText}
              </span>
            )
          }
        />
        <Dialog
          open={isDeleteModalOpen}
          onClose={this.onModalClick}
          onCancel={this.onModalClick}
          title={title}
          onOk={onDelete}
          okText={deleteName}
        >
          <Typography variant="body1">{message}</Typography>
        </Dialog>
      </>
    )
  }
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps
export default Header
