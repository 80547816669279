import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { ManagersComponent } from '../../components/managers'
import { getSupervisor } from '../location-details/location-info.gql'
import { searchResultData } from '../../utils/search-string'
import { getDisplayHash, getEmployee } from '../../utils/search-table-methods'
import { restrictedUsers } from '../../utils/constant'
import { updateEditManagerId } from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  getSupervisorData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  editManagerId: PropTypes.func
}

const defaultProps = {
  getShift: {
    currentShift: {
      manager: {
        id: ''
      },
      workplace: {
        corporation: {
          settings: ''
        }
      }
    }
  },
  getSupervisorData: {},
  editManagerId: () => {}
}

export class Managers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      supervisors: [],
      isLoading: false,
      isSearch: false,
      searchedMembers: [],
      searchWord: ''
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps?.getSupervisorData?.loading) {
      return {
        isLoading: true
      }
    }
    if (
      nextProps.getSupervisorData &&
      !nextProps.getSupervisorData.loading &&
      nextProps.getSupervisorData.getManagersByCorporationId
    ) {
      const { getManagersByCorporationId } = nextProps.getSupervisorData
      const teamMembers = getEmployee(getManagersByCorporationId)
      return {
        supervisors: getDisplayHash(teamMembers, teamMembers.length),
        supervisorsData: teamMembers,
        isLoading: false
      }
    }
    return null
  }

  onSearchSupervisor = word => {
    if (word.length === 0) {
      this.setState({ isSearch: false, searchWord: '' })
    } else {
      this.setState({ isSearch: true })
      const { supervisorsData } = this.state
      const searchedData = searchResultData(supervisorsData, word)
      this.setState({
        searchedMembers: getDisplayHash(searchedData, searchedData.length),
        isSearch: true,
        searchWord: word
      })
    }
  }

  onSupervisorDrawerChange = supervisorId => {
    const { searchWord } = this.state
    const { history, editManagerId } = this.props
    if (supervisorId) {
      editManagerId({
        variables: {
          managerId: supervisorId
        }
      }).then(() => {
        history.push('/managerDetail')
      })
    } else if (searchWord !== '') this.onSearchSupervisor(searchWord)
  }

  render() {
    const { supervisors, isLoading, isSearch, searchedMembers } = this.state
    const { history, getShift } = this.props
    const {
      workplace: {
        corporation: { settings }
      },
      manager: { id }
    } = getShift.currentShift
    const supervisorData = isSearch ? searchedMembers : supervisors
    const isRestrictedUser = restrictedUsers.includes(id)
    const settingsObj = settings && JSON.parse(settings)
    const syncType = settingsObj && settingsObj.sync_type
    const isRestricted = syncType === 'KRONOS-WFD'
    return (
      <ManagersComponent
        history={history}
        supervisors={supervisorData}
        isLoading={isLoading}
        onSearchChange={this.onSearchSupervisor}
        onDrawerChange={this.onSupervisorDrawerChange}
        isRestricted={isRestricted || isRestrictedUser}
      />
    )
  }
}

export const ManagersContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(getSupervisor, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.corporation.id,
    options: ({ getShift }) => ({
      variables: {
        corporationId: getShift?.currentShift?.workplace?.corporation?.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getSupervisorData'
  }),
  graphql(updateEditManagerId, { name: 'editManagerId' })
)(Managers)

Managers.propTypes = propTypes
Managers.defaultProps = defaultProps
export default ManagersContainer
