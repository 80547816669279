import gql from 'graphql-tag'

export const getFutureShift = gql`
  query UpcomingShifts($workplaceId: ID!) {
    UpcomingShifts(workplaceId: $workplaceId) {
      id
      startTime
      endTime
      replacedUsers
      teamMemberRequiredCount
      position {
        id
        name
        color
        alias
      }
      department {
        id
        name
        message
        alias
      }
      assignedUsers {
        id
        firstName
        lastName
      }
      latestShiftPoll {
        shiftDetails {
          timeBetweenCalls
        }
        createdAt
      }
    }
  }
`

export default { getFutureShift }
