import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SearchableList from '../layouts/searchable-list'
import { PageLayout } from '../layouts/page-layout'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'
import { SearchTableHeader } from '../layouts/search-table-header'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onLocationClick: PropTypes.func.isRequired,
  onNewLocation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool,
  isSynced: PropTypes.bool
}

const defaultProps = {
  history: { push: () => {} },
  isRestricted: false,
  isSynced: false
}

export class LocationTable extends Component {
  render() {
    const {
      data,
      onSearchChange,
      onLocationClick,
      history,
      onNewLocation,
      isLoading,
      isRestricted,
      isSynced
    } = this.props
    return (
      <PageLayout>
        <SelectWorkplaceHeader history={history} />
        <SearchTableHeader
          header="Locations"
          isRestricted={isRestricted || isSynced}
          onClick={onNewLocation}
        />
        <SearchableList
          data={data}
          onSearchChange={onSearchChange}
          onRowClick={onLocationClick}
          searchTextData="Locations"
          isLoading={isLoading}
          isSynced={isSynced}
        />
      </PageLayout>
    )
  }
}

LocationTable.propTypes = propTypes
LocationTable.defaultProps = defaultProps

export default LocationTable
