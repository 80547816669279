// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-manager-form-style__manager_form_header--3iXu6 h2 {\n  padding: 30px 0 10px 20px;\n}\n\n.src-components-manager-form-style__manager_form_header--3iXu6 p {\n  padding: 0 0 20px 20px;\n}\n\n.src-components-manager-form-style__manager_form_header--3iXu6 h1 {\n  padding: 40px 0 30px 20px;\n}\n\n.src-components-manager-form-style__manager_form_wrapper--2dMig {\n  padding: 0 20px;\n}\n\n.src-components-manager-form-style__manager_form_wrapper--2dMig > div {\n  margin-bottom: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"manager_form_header": "src-components-manager-form-style__manager_form_header--3iXu6",
	"manager_form_wrapper": "src-components-manager-form-style__manager_form_wrapper--2dMig"
};
module.exports = exports;
