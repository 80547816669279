import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PageLayout } from '../layouts/page-layout'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const defaultProps = {
  history: { push: () => {} }
}

export class CallOutLineComponent extends Component {
  render() {
    const { history } = this.props
    return (
      <PageLayout>
        <SelectWorkplaceHeader history={history} />
      </PageLayout>
    )
  }
}

CallOutLineComponent.propTypes = propTypes
CallOutLineComponent.defaultProps = defaultProps

export default CallOutLineComponent
