import gql from 'graphql-tag'

export const updateCorporationUserMutation = gql`
  mutation UpdateCorporationUser($input: updateCorporationUserInput!) {
    UpdateCorporationUser(input: $input) {
      corporationUser {
        id
        userId
        corporationId
        seniority
        hireDate
        optedOut
        employeeId
        isTemp
        user {
          id
          firstName
          lastName
          phoneNumber
          preferredLanguage
        }
        primaryWorkplace {
          id
          name
        }
        excludedDepartments {
          id
          name
        }
        departments {
          id
          name
        }
      }
    }
  }
`

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      message
      user {
        id
        phoneNumber
        firstName
        lastName
        preferredLanguage
      }
    }
  }
`

export default { updateUserMutation, updateCorporationUserMutation }
