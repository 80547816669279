import gql from 'graphql-tag'

export const fetchLocationInfo = gql`
  query Department($id: ID!) {
    Department(input: { id: $id }) {
      id
      name
      message
      addressJson
      alias
      contactUser {
        id
        firstName
        lastName
        phoneNumber
      }
      employees {
        id
        firstName
        lastName
      }
    }
  }
`

export const updateDepartmentMutation = gql`
  mutation updateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      department {
        id
      }
    }
  }
`

export const deleteLocation = gql`
  mutation deleteDepartment($input: DepartmentInput!) {
    deleteDepartment(input: $input) {
      id
      workplaceId
      name
    }
  }
`

export const getSupervisor = gql`
  query getManagersByCorporationId($corporationId: ID!) {
    getManagersByCorporationId(corporationId: $corporationId) {
      id
      firstName
      lastName
    }
  }
`
export default fetchLocationInfo
