import gql from 'graphql-tag'

export const getUserInfo = gql`
  query User($input: UserInput!) {
    User(input: $input) {
      id
      firstName
      lastName
    }
  }
`

export const getLastSync = gql`
  query getLastSync($corporationId: ID!) {
    getLastSync(corporationId: $corporationId) {
      id
      status
      endDate
    }
  }
`

export const triggerSync = gql`
  mutation triggerSync($corporationId: ID!) {
    triggerSync(corporationId: $corporationId) {
      id
      status
      endDate
    }
  }
`

export default { getUserInfo }
