import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'syrg-design-kit'
import App from './app'
import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)
registerServiceWorker()
