import gql from 'graphql-tag'

export const getCurrentShift = gql`
  query currentShift {
    currentShift @client {
      shiftId
      startTime
      endTime
      startedEditing
      initialTime
      startDate
      workplace {
        id
        name
        phone
        timezone
        corporation {
          id
          name
          settings
        }
      }
      position {
        id
        name
        color
        alias
      }
      manager {
        id
        firstName
        lastName
        phoneNumber
      }
      location {
        id
        name
        message
        alias
      }
      employeeCount
      notes
      spanishNotes
      currentPage
      restartPhoneTree
      replacedUsers
      callOutUserId
      assignedUserCount
      minBetweenCall
      initialCallTime
      notifyManagers
      noteChanged
      spanNoteChanged
      notifyManagerChanged
      lastPhoneStart
      assignedUsers {
        id
        firstName
        lastName
      }
    }
  }
`

export const getEditManager = gql`
  query editManager {
    editManager @client {
      managerId
    }
  }
`

export const getTeamMember = gql`
  query teamMember {
    teamMember @client {
      startedEditing
      page
      memberId
      isEdit
      firstName
      lastName
      phoneNumber
      hireDate
      positions
      primaryWorkplace
      preferredLanguage
      primaryPosition
      employeeId
      isTemp
      excludedLocation
    }
  }
`

export const removeCallList = gql`
  query recipientListDrawer {
    recipientListDrawer @client {
      users {
        removedId
        reason
        ranking
      }
    }
  }
`

export const getErrorStatus = gql`
  query ErrorStatus {
    ErrorStatus @client {
      isError
      errorMessage
    }
  }
`

export const getShiftRequests = gql`
  query shiftRequest {
    shiftRequest @client {
      tabId
    }
  }
`

export const getPosition = gql`
  query newPosition {
    newPosition @client {
      id
      name
      color
      selectedUsers
      isEdit
    }
  }
`

export const getLocation = gql`
  query location {
    location @client {
      startedEditing
      name
      street
      city
      zipcode
      state
      page
      supervisorId
      primaryArea
      message
      locationId
    }
  }
`

export const getUnavailable = gql`
  query unavailable {
    unavailable @client {
      id
      startDate
      endDate
      reason
      corporationUserId
    }
  }
`
