import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import ForgotPassword from '../../components/forgot-password'
import EnterPassword from '../../components/enter-password'
import authentication from '../../authentication'

const propTypes = {
  onBack: PropTypes.func
}

const defaultProps = {
  onBack: () => {}
}

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      number: '',
      error: '',
      pin: false,
      isLoading: false,
      open: false,
      passwordMismatchError: ''
    }
  }

  componentDidUpdate() {
    const { error, open, passwordMismatchError } = this.state
    if (open && (error || passwordMismatchError)) {
      Snackbar(error || passwordMismatchError, 'error')
    }
  }

  onChange = e => this.setState({ number: e, open: false })

  onPinBack = () => {
    this.setState({
      pin: false
    })
  }

  setPassWordMissMatchError = passwordMismatchError => {
    this.setState({ passwordMismatchError, open: true })
  }

  onNextClick = async () => {
    const { number } = this.state
    let num = number.toString().replace(/\D/g, '')
    num = `+${num.length < 11 ? '1' : ''}${num}`
    if (num.length < 10) {
      this.setState({
        error: 'Please enter at least 10 digits',
        open: true
      })
    } else {
      const { error, response } = await authentication.forgotPassword(num)
      if (response) {
        this.setState({ pin: true, num, error: '', open: false })
      } else if (error) {
        this.setState({ error: 'User does not exist', open: true })
      }
    }
  }

  updatePassword = async (password, code = null) => {
    const { num } = this.state
    const { onBack } = this.props
    this.setState({ isLoading: true })
    const response = await authentication.forgotPasswordSubmit(
      num,
      code,
      password
    )
    if (response && response.message) {
      this.setState({
        error: response.message,
        open: true,
        isLoading: false
      })
      return
    }
    this.setState({
      pin: false,
      isLoading: false
    })
    onBack('Password changed successfully')
  }

  setOpenToFalse = () => {
    const { open } = this.state
    if (open) {
      this.setState({ open: false })
    }
  }

  render() {
    const {
      number,
      error,
      passwordMismatchError,
      pin,
      open,
      isLoading
    } = this.state
    const { onBack } = this.props
    if (pin) {
      return (
        <EnterPassword
          reset
          enterPassword={this.updatePassword}
          isLoading={isLoading}
          number={number}
          setPasswordMismatchErrorError={this.setPassWordMissMatchError}
          error={error}
          passwordMismatchError={passwordMismatchError}
          open={open}
          onBack={this.onPinBack}
          setOpenToFalse={this.setOpenToFalse}
        />
      )
    }
    return (
      <ForgotPassword
        number={number}
        onChange={this.onChange}
        error={error}
        onBackClick={() => onBack('')}
        onNextClick={this.onNextClick}
        open={open}
      />
    )
  }
}

ForgotPasswordContainer.propTypes = propTypes
ForgotPasswordContainer.defaultProps = defaultProps

export default ForgotPasswordContainer
