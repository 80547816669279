import gql from 'graphql-tag'

export const fetchWorkplace = gql`
  query User($input: UserInput!) {
    User(input: $input) {
      id
      managedWorkplaces {
        id
        name
        phoneNumber
        timezone
        corporation {
          id
          name
          settings
        }
      }
    }
  }
`
export default fetchWorkplace
