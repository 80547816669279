import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'syrg-design-kit'
import styles from './style.css'
import { Header } from '../theme'
import { CallListRow } from '../layouts/call-list-row'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import CallListEditCont from '../../containers/call-list-edit'
import FooterButton from '../layouts/footer-button'

const propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      ranking: PropTypes.number,
      overtimeReasons: PropTypes.array,
      unavailable: PropTypes.bool
    })
  ).isRequired,
  onDropdownModalChange: PropTypes.func,
  removedMembers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isEditTableModalOpen: PropTypes.bool.isRequired,
  onEditTableModalOpen: PropTypes.func,
  restartPhoneTree: PropTypes.bool,
  shouldUserRemoved: PropTypes.func
}

const defaultProps = {
  restartPhoneTree: false,
  removedMembers: [],
  shouldUserRemoved: () => {},
  onEditTableModalOpen: () => {},
  onDropdownModalChange: () => {}
}

export class CallListComponent extends Component {
  render() {
    const {
      members,
      onDropdownModalChange,
      removedMembers,
      isEditTableModalOpen,
      onEditTableModalOpen,
      shouldUserRemoved,
      restartPhoneTree
    } = this.props
    if (isEditTableModalOpen)
      return (
        <CallListEditCont
          onEditTableModalOpen={onEditTableModalOpen}
          users={members}
          removedMembers={removedMembers}
          shouldUserRemoved={shouldUserRemoved}
          restartPhoneTree={restartPhoneTree}
        />
      )
    return (
      <PageLayout>
        <Header
          onLeftClick={onDropdownModalChange}
          nextText="Edit List"
          onRightClick={onEditTableModalOpen}
        />
        <div className={styles.call_list_head}>
          <Typography variant="h2">Call List</Typography>
          <Typography variant="body2">
            List of employees being contacted to fill the shift, in order.
          </Typography>
        </div>
        <FlexBody>
          <div className={styles.call_list_members}>
            {members.length <= 0 ? (
              <div className={styles.no_data}>
                <Typography variant="body1">No Records</Typography>
              </div>
            ) : (
              members?.map(user => (
                <CallListRow
                  key={user.userId}
                  userId={user.userId}
                  name={user.name}
                  unavailable={user.unavailable}
                  removed={removedMembers.includes(user.userId)}
                  ranking={user.ranking}
                  overtime={user?.overtimeReasons?.length > 0}
                />
              ))
            )}
          </div>
        </FlexBody>
        <FooterButton onClick={onDropdownModalChange}>Done</FooterButton>
      </PageLayout>
    )
  }
}

CallListComponent.propTypes = propTypes
CallListComponent.defaultProps = defaultProps

export default CallListComponent
