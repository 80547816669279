import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link as LinkButton, TextField, Typography } from 'syrg-design-kit'
import FooterComponent from '../layouts/footer-button'
import Header from '../layouts/login-header'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import styles from './styles.css'

const propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  error: PropTypes.string,
  number: PropTypes.string.isRequired,
  onForgotPasswordClick: PropTypes.func,
  setOpenToFalse: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const defaultProps = {
  error: '',
  onForgotPasswordClick: () => {}
}

class ConfirmComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      number: props.number,
      password: ''
    }
  }

  onChange(type, e) {
    const { setOpenToFalse } = this.props
    setOpenToFalse()
    this.setState({ [type]: e })
  }

  render() {
    const { onLoginClick, error, isLoading, onForgotPasswordClick } = this.props
    const { number, password } = this.state

    return (
      <PageLayout>
        <Header />
        <FlexBody>
          <div className={styles.login_form}>
            <Typography variant="h1">Log In to SYRG</Typography>
            <div className={styles.form_container}>
              <TextField
                type="phone"
                value={number}
                onChange={e => this.onChange('number', e.target.value)}
                label="Phone Number"
                required
                errorText={error}
                requiredText={error}
                forceShowError={error.length > 0}
                fullWidth
                id="phone"
              />
              <TextField
                type="password"
                value={password}
                onChange={e => this.onChange('password', e.target.value)}
                label="Password"
                fullWidth
                id="password"
                placeholder="Enter password"
              />
            </div>
            <LinkButton onClick={onForgotPasswordClick} isButton>
              Forgot Password
            </LinkButton>
          </div>
        </FlexBody>
        <FooterComponent
          onClick={() => onLoginClick(number, password)}
          buttonInfo="All fields required"
          isLink
          isLoading={isLoading}
        >
          Log In
        </FooterComponent>
      </PageLayout>
    )
  }
}

ConfirmComponent.propTypes = propTypes
ConfirmComponent.defaultProps = defaultProps

export default ConfirmComponent
