// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-shift-schedule-style__main_content--pzoPM {\n  padding: 0 20px;\n}\n\n.src-components-shift-schedule-style__main_dateWrap--1P6fG > div > div {\n  width: 100%;\n}\n\n.src-components-shift-schedule-style__main_dateWrap--1P6fG {\n  margin: 20px 0;\n}\n\n.src-components-shift-schedule-style__time_length_wrpper--3gWuH {\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.src-components-shift-schedule-style__time_length_wrpper--3gWuH :last-child {\n  font-weight: 400;\n  padding-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"main_content": "src-components-shift-schedule-style__main_content--pzoPM",
	"main_dateWrap": "src-components-shift-schedule-style__main_dateWrap--1P6fG",
	"time_length_wrpper": "src-components-shift-schedule-style__time_length_wrpper--3gWuH"
};
module.exports = exports;
