import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import {
  cloneDeep,
  uniqBy,
  flowRight as compose,
  flatMap,
  sortBy
} from 'lodash'
import { CallListComponent } from '../../components/call-list'
import { removeCallList } from '../../apollo/apollo-cache-query.gql'

const propTypes = {
  showDropdownModalOpen: PropTypes.func.isRequired,
  getUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  recipients: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  restartPhoneTree: PropTypes.bool.isRequired,
  shouldUserRemoved: PropTypes.func
}

const defaultProps = {
  shouldUserRemoved: () => {},
  getUsers: { recipientListDrawer: { users: {} } },
  recipients: []
}

export class CallListContainer extends Component {
  static mapMemberNames = list =>
    list.map(member => ({
      userId: member.userId,
      name: `${member.firstName} ${member.lastName}`,
      ranking: member.ranking,
      overtimeReasons: member.overtimeReasons,
      unavailable: member.unavailable
    }))

  constructor(props) {
    super(props)
    this.state = {
      recipients: [],
      members: [],
      removedList: [],
      isEditTableModalOpen: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { members, removedList, recipients } = prevState
    const {
      getUsers: { recipientListDrawer }
    } = nextProps
    if (nextProps.recipients !== recipients) {
      recipients = uniqBy(nextProps.recipients, 'userId')
    }
    if (recipientListDrawer && removedList !== recipientListDrawer.users) {
      removedList = cloneDeep(nextProps.getUsers.recipientListDrawer.users)
    }
    members = CallListContainer.mapMemberNames(recipients)
    return {
      recipients,
      members,
      removedList
    }
  }

  onEditTableModalOpen = () => {
    this.setState(prevState => ({
      isEditTableModalOpen: !prevState.isEditTableModalOpen
    }))
  }

  render() {
    const {
      showDropdownModalOpen,
      restartPhoneTree,
      shouldUserRemoved
    } = this.props
    const { members, isEditTableModalOpen, removedList } = this.state
    const orderedData = sortBy(members, user => user?.ranking)
    return (
      <CallListComponent
        removedMembers={
          removedList?.length ? flatMap(removedList).map(b => b.removedId) : []
        }
        members={orderedData}
        onDropdownModalChange={showDropdownModalOpen}
        isEditTableModalOpen={isEditTableModalOpen}
        onEditTableModalOpen={this.onEditTableModalOpen}
        restartPhoneTree={restartPhoneTree}
        shouldUserRemoved={shouldUserRemoved}
      />
    )
  }
}
const CallListContainerApollo = compose(
  withApollo,
  graphql(removeCallList, { name: 'getUsers' })
)(CallListContainer)

CallListContainer.propTypes = propTypes
CallListContainer.defaultProps = defaultProps

export default CallListContainerApollo
