// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-eligible-member-section-style__eligible_team_member--1JoFZ {\n  padding: 20px 0 0 0;\n  overflow: auto;\n}\n\n.src-components-eligible-member-section-style__eligible_team_member--1JoFZ div {\n  line-height: 1;\n  padding-bottom: 10px;\n}\n\n.src-components-eligible-member-section-style__view_all--2382u h5 {\n  margin-bottom: 10px;\n}\n\n.src-components-eligible-member-section-style__content_head--1U_ZJ {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n}\n", ""]);
// Exports
exports.locals = {
	"eligible_team_member": "src-components-eligible-member-section-style__eligible_team_member--1JoFZ",
	"view_all": "src-components-eligible-member-section-style__view_all--2382u",
	"content_head": "src-components-eligible-member-section-style__content_head--1U_ZJ"
};
module.exports = exports;
