// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-call-pace-style__callPaceRadio--A-Fzg label {\n  margin-right: 0;\n}\n\n.src-components-call-pace-style__callPaceRadio--A-Fzg .src-components-call-pace-style__radioDrop--9qEcN {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"callPaceRadio": "src-components-call-pace-style__callPaceRadio--A-Fzg",
	"radioDrop": "src-components-call-pace-style__radioDrop--9qEcN"
};
module.exports = exports;
