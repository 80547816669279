import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { OpenShiftDetailComponent } from '../../components/open-shift-detail'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import {
  getAllShifts,
  stopCall,
  getMembersCallList
} from '../open-shifts/open-shift.gql'
import { timeCalculationOfShift } from '../../utils/time-formatter'
import { resetProgress } from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  getShift: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  resetShiftProgress: PropTypes.func.isRequired,
  getCallList: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  stopCallMutation: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}

const defaultProps = {
  stopCallMutation: () => {},
  getCallList: {}
}

export class OpenShiftDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      callList: [],
      assignedUsers: []
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let { callList, assignedUsers } = state
    const { getCallList } = nextProps
    if (getCallList?.Shift) {
      if (getCallList.Shift.userCall !== callList) {
        callList = getCallList.Shift.userCall
        return { callList }
      }
      if (getCallList.Shift.assignedUsers !== assignedUsers) {
        assignedUsers = getCallList.Shift.assignedUsers
        return { assignedUsers }
      }
    }
    return null
  }

  onBack = () => {
    const { history, resetShiftProgress } = this.props
    resetShiftProgress()
    history.push('/')
  }

  onStopCall = () => {
    const { stopCallMutation, getShift } = this.props
    const {
      shiftId,
      workplace: { id: workplaceId }
    } = getShift?.currentShift
    stopCallMutation({
      variables: { shiftId },
      update: cache => {
        try {
          const { getShiftsByWorkplace } = cache.readQuery({
            query: getAllShifts,
            variables: { workplaceId }
          })
          const newShifts = getShiftsByWorkplace
          if (newShifts) {
            const index = newShifts.findIndex(shift => shift.id === shiftId)
            newShifts.splice(index, 1)
          }
          cache.writeQuery({
            query: getAllShifts,
            variables: { workplaceId },
            data: { getShiftsByWorkplace: newShifts }
          })
        } catch (error) {
          console.info('error', error)
        }
      }
    }).then(() => {
      this.onBack()
    })
  }

  render() {
    const { callList, assignedUsers } = this.state
    const { getCallList, getShift } = this.props
    const {
      location: { name: deptName },
      position: { name: positionName },
      startDate,
      startTime,
      endTime,
      lastPhoneStart,
      employeeCount,
      replacedUsers,
      minBetweenCall
    } = getShift?.currentShift
    const callPaceMin = Math.floor(minBetweenCall)
    const cpMin = callPaceMin === 1 ? '1 minute' : `${callPaceMin} minutes `
    const callPace = `${cpMin} between calls`

    const timeLeft = timeCalculationOfShift(
      moment(`${startDate} ${startTime}`, 'MMMM D, YYYY h:mma').unix()
    )
    return (
      <OpenShiftDetailComponent
        isLoading={getCallList.loading && callList.length === 0}
        callList={callList}
        deptName={deptName}
        positionName={positionName}
        startDate={startDate}
        startTime={startTime}
        endTime={endTime}
        phoneStart={lastPhoneStart}
        replacedUsers={replacedUsers}
        assignedUsers={assignedUsers}
        employeeCount={employeeCount}
        timeLeft={timeLeft}
        minBetweenCall={callPace}
        onBack={this.onBack}
        onCloseRequest={this.onStopCall}
      />
    )
  }
}

export const OpenShiftDetailContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(resetProgress, { name: 'resetShiftProgress' }),
  graphql(getMembersCallList, {
    options: ownProps => ({
      variables: {
        id: ownProps?.getShift?.currentShift?.shiftId
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 3000
    }),
    name: 'getCallList'
  }),
  graphql(stopCall, { name: 'stopCallMutation' })
)(OpenShiftDetails)

OpenShiftDetails.propTypes = propTypes
OpenShiftDetails.defaultProps = defaultProps
export default OpenShiftDetailContainer
