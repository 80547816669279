import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PageLayout } from '../layouts/page-layout'
import SearchableList from '../layouts/searchable-list'
import SelectWorkplaceHeader from '../../containers/select-workplace-header'
import { SearchTableHeader } from '../layouts/search-table-header'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  supervisors: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onDrawerChange: PropTypes.func.isRequired,
  isRestricted: PropTypes.bool
}

const defaultProps = {
  history: { push: () => {} },
  isRestricted: false
}

export class ManagersComponent extends Component {
  onAddManager = () => {
    const { history } = this.props
    history.push('/addManager')
  }

  render() {
    const {
      history,
      supervisors,
      isLoading,
      onSearchChange,
      onDrawerChange,
      isRestricted
    } = this.props
    return (
      <PageLayout>
        <SelectWorkplaceHeader history={history} />
        <SearchTableHeader
          header="Managers"
          isRestricted={isRestricted}
          onClick={this.onAddManager}
        />
        <SearchableList
          data={supervisors}
          onSearchChange={onSearchChange}
          onRowClick={onDrawerChange}
          searchTextData="Managers"
          isLoading={isLoading}
        />
      </PageLayout>
    )
  }
}

ManagersComponent.propTypes = propTypes
ManagersComponent.defaultProps = defaultProps
export default ManagersComponent
