import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, DropdownMenu, MenuItem } from 'syrg-design-kit'
import styles from './style.css'
import { usStates } from '../../utils/constant'
import FooterButton from '../layouts/footer-button'
import { FlexBody } from '../layouts/flex-body'

const propTypes = {
  onLocationChange: PropTypes.func.isRequired,
  nextClick: PropTypes.func.isRequired,
  location: PropTypes.shape({
    locationName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    zipcode: PropTypes.string,
    state: PropTypes.string
  }),
  error: PropTypes.shape({
    name: PropTypes.bool,
    street: PropTypes.bool,
    city: PropTypes.bool,
    zipcode: PropTypes.bool,
    state: PropTypes.bool
  })
}

const defaultProps = {
  location: {},
  error: {}
}

export class AddLocationComponent extends Component {
  render() {
    const { onLocationChange, nextClick, location, error } = this.props
    return (
      <>
        <FlexBody>
          <div className={styles.location_content}>
            <TextField
              label="Location Name"
              onChange={e => {
                onLocationChange(e.target.value, 'locationName')
              }}
              required
              requiredText="Enter location name"
              forceShowError={error.name}
              error={error.name}
              placeholder="Location Name"
              value={location.locationName}
              fullWidth
            />
            <TextField
              label="Street Address"
              onChange={e => {
                onLocationChange(e.target.value, 'street')
              }}
              placeholder="Street Address"
              required
              forceShowError={error.street}
              requiredText="Enter street address"
              error={error.street}
              value={location.street}
              fullWidth
            />
            <TextField
              label="City"
              onChange={e => {
                onLocationChange(e.target.value, 'city')
              }}
              placeholder="Boston"
              required
              forceShowError={error.city}
              requiredText="Enter city"
              error={error.city}
              value={location.city}
              fullWidth
            />
            <div className={styles.add_location_state}>
              <TextField
                label="Zip Code"
                onChange={e => {
                  onLocationChange(e.target.value, 'zipcode')
                }}
                type="number"
                placeholder="12345"
                value={location.zipcode}
                required
                forceShowError={error.zipcode}
                requiredText="Enter zip code"
                error={error.zipcode}
              />
              <DropdownMenu
                label="State"
                value={location.state || 'Select a state'}
                onChange={e => {
                  onLocationChange(e.target.value, 'state')
                }}
                required
                helperText={error.state ? 'Select a state' : ''}
                error={error.state}
                width={100}
              >
                <MenuItem key={0} value="Select a state">
                  Select a state
                </MenuItem>
                {usStates.map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </DropdownMenu>
            </div>
          </div>
        </FlexBody>
        <FooterButton buttonInfo="All fields required" onClick={nextClick}>
          Next
        </FooterButton>
      </>
    )
  }
}

AddLocationComponent.propTypes = propTypes
AddLocationComponent.defaultProps = defaultProps

export default AddLocationComponent
