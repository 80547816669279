import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.css'
import syrgLogo from '../../theme/asset/syrg-blue-logo.png'

const propTypes = {
  leftInfo: PropTypes.node,
  rightInfo: PropTypes.node
}

const defaultProps = {
  leftInfo: <></>,
  rightInfo: <></>
}

export class LogoHeader extends Component {
  render() {
    const { leftInfo, rightInfo } = this.props
    return (
      <div className={style.selectHeader}>
        <div className={style.menuIcon}>{leftInfo}</div>
        <span className={style.imgLogo}>
          <img src={syrgLogo} alt="syrg-logo" width="58px" height="21px" />
        </span>
        <div className={style.dropHeader}>{rightInfo}</div>
      </div>
    )
  }
}

LogoHeader.propTypes = propTypes
LogoHeader.defaultProps = defaultProps
export default LogoHeader
