import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from 'syrg-design-kit'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { AddMemberPositionComponent } from '../../components/add-member-position'
import { getCurrentShift } from '../../apollo/apollo-cache-query.gql'
import { workplacePositionsQuery } from '../shift-info/shift-info.gql'
import {
  teamPosition,
  updateTeamPage
} from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  updateTeamPosition: PropTypes.func,
  updateCurrentPage: PropTypes.func,
  teamMemberInfo: PropTypes.shape({
    positions: PropTypes.array
  }).isRequired,
  Workplace: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  updateTeamPosition: () => {},
  updateCurrentPage: () => {},
  Workplace: {}
}

export class AddMemberPosition extends Component {
  constructor(props) {
    super(props)
    const { teamMemberInfo: { positions = [], primaryPosition } = {} } = props

    let selectedPositions = [...positions]
    if (!selectedPositions.includes(primaryPosition))
      selectedPositions = [...selectedPositions, primaryPosition]
    if (selectedPositions.length === 1) {
      selectedPositions = selectedPositions[0] ? selectedPositions : []
    }
    this.state = {
      positions: [],
      isLoading: false,
      selectedPositions,
      error: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { isLoading, positions } = prevState
    if (nextProps?.getShift?.currentShift?.workplace?.id === null) {
      isLoading = false
    }
    if (nextProps?.Workplace?.loading) {
      return {
        isLoading: true
      }
    }
    if (nextProps?.Workplace && !nextProps.Workplace.loading) {
      const { Workplace } = nextProps.Workplace
      if (Workplace) {
        const { positions: allPositions } = Workplace
        isLoading = false
        positions = allPositions
      }
    }
    return { isLoading, positions }
  }

  onSelectPosition = positionId => {
    const { selectedPositions } = this.state
    const {
      updateTeamPosition,
      teamMemberInfo: { primaryPosition = '' } = {}
    } = this.props
    const index = selectedPositions.findIndex(pos => pos === positionId)
    if (index >= 0 && primaryPosition !== positionId) {
      selectedPositions.splice(index, 1)
    } else if (!selectedPositions.includes(positionId)) {
      selectedPositions.push(positionId)
    }
    if (selectedPositions.length > 0) {
      this.setState({
        error: false
      })
    }
    this.setState({ selectedPositions }, () => {
      updateTeamPosition({
        variables: {
          positions: selectedPositions
        }
      })
    })
  }

  onNextClick = () => {
    const { updateCurrentPage } = this.props
    const { selectedPositions } = this.state
    if (selectedPositions.length < 1) {
      Snackbar('Please select one or more positions', 'error', 3000)
    } else {
      updateCurrentPage({
        variables: {
          page: 3
        }
      })
    }
  }

  onBackClick = () => {
    const { updateCurrentPage } = this.props
    updateCurrentPage({
      variables: {
        page: 1
      }
    })
  }

  render() {
    const { positions, selectedPositions, isLoading, error } = this.state
    return (
      <AddMemberPositionComponent
        positions={positions}
        isLoading={isLoading}
        onNextClick={this.onNextClick}
        onBackClick={this.onBackClick}
        onSelectPosition={this.onSelectPosition}
        selectedPositions={selectedPositions}
        error={error}
      />
    )
  }
}

export const AddMemberPositionContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(workplacePositionsQuery, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.id,
    options: ({ getShift }) => ({
      variables: {
        input: {
          id: getShift?.currentShift?.workplace?.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'Workplace'
  }),
  graphql(teamPosition, { name: 'updateTeamPosition' }),
  graphql(updateTeamPage, { name: 'updateCurrentPage' })
)(AddMemberPosition)

AddMemberPosition.propTypes = propTypes
AddMemberPosition.defaultProps = defaultProps

export default AddMemberPositionContainer
