import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextField, Typography } from 'syrg-design-kit'
import { FooterComponent } from '../layouts/footer-navigation'
import Header from '../layouts/login-header'
import { PageLayout } from '../layouts/page-layout'
import { FlexBody } from '../layouts/flex-body'
import styles from './styles.css'

const propTypes = {
  enterPassword: PropTypes.func.isRequired,
  setPasswordMismatchErrorError: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  tempPasswordReset: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  setOpenToFalse: PropTypes.func.isRequired,
  passwordMismatchError: PropTypes.string.isRequired
}

const defaultProps = {
  tempPasswordReset: false,
  error: ''
}

class EnterPasswordComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pin: '',
      password: '',
      confirmPassword: ''
    }
  }

  onChange(type, e) {
    const { setOpenToFalse } = this.props
    setOpenToFalse()
    this.setState({ [type]: e })
  }

  onNextClick = () => {
    const {
      setPasswordMismatchErrorError,
      enterPassword,
      tempPasswordReset
    } = this.props
    const { password, confirmPassword, pin } = this.state
    const isRecoveryPinRequired = !tempPasswordReset

    if (!pin.length && isRecoveryPinRequired) {
      setPasswordMismatchErrorError('Verification code cannot be empty')
    } else if (password !== confirmPassword) {
      setPasswordMismatchErrorError('Passwords do not match')
    } else if (password.length < 8) {
      setPasswordMismatchErrorError('Please enter a password over 8 characters')
    } else {
      enterPassword(password, pin)
    }
  }

  render() {
    const { password, confirmPassword, pin } = this.state
    const {
      onBack,
      error,
      isLoading,
      passwordMismatchError,
      tempPasswordReset
    } = this.props

    const isRecoveryPinRequired = !tempPasswordReset

    return (
      <PageLayout>
        <Header />
        <FlexBody>
          <div className={styles.enter_pass_form}>
            <Typography variant="h1">Reset Password</Typography>
            {isRecoveryPinRequired && (
              <span className={styles.pin_code}>
                <Typography variant="body1">
                  You should receive a pincode by text message. Enter it in the
                  first field then create a new password below.
                </Typography>
              </span>
            )}
            <Typography variant="body1">
              Your password must include an uppercase letter and lowercase
              letter, special character and a number.
            </Typography>
            {isRecoveryPinRequired && (
              <TextField
                id="pin"
                type="number"
                value={pin}
                onChange={e => this.onChange('pin', e.target.value)}
                label="Recovery Code"
                required
                fullWidth
                errorText={error}
                requiredText={error}
                forceShowError={!!error}
                placeholder="123456"
              />
            )}
            <TextField
              id="password"
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={e => this.onChange('password', e.target.value)}
              label="Password"
              fullWidth
              forceShowError={!!passwordMismatchError}
            />
            <TextField
              id="confirm_password"
              type="password"
              placeholder="Enter password"
              value={confirmPassword}
              onChange={e => this.onChange('confirmPassword', e.target.value)}
              label="Confirm Password"
              fullWidth
              forceShowError={!!passwordMismatchError}
            />
          </div>
        </FlexBody>
        <FooterComponent
          onNextClick={this.onNextClick}
          onBackClick={onBack}
          nextText="Reset Password"
          backText="Back"
          buttonInfo="All fields required"
          isLink
          isLoading={isLoading}
        />
      </PageLayout>
    )
  }
}

EnterPasswordComponent.propTypes = propTypes
EnterPasswordComponent.defaultProps = defaultProps

export default EnterPasswordComponent
