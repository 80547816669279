import gql from 'graphql-tag'

export const updateEligibleMemberMutation = gql`
  mutation updateDepartmentEmployees($input: updateDepartmentEmployeesInput!) {
    updateDepartmentEmployees(input: $input) {
      department {
        id
        name
        message
        addressJson
        contactUser {
          id
          firstName
          lastName
          phoneNumber
        }
        employees {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export default updateEligibleMemberMutation
