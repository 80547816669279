import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  DropdownMenu,
  MenuItem,
  TextField,
  AutoComplete
} from 'syrg-design-kit'
import styles from './style.css'
import { FlexBody } from '../layouts/flex-body'
import FooterButton from '../layouts/footer-button'
import { PositionsDropDownContainer } from '../../containers/positions-dropdown'

const propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
      message: PropTypes.string,
      name: PropTypes.string.isRequired,
      addressJson: PropTypes.string,
      alias: PropTypes.string
    })
  ).isRequired,
  onLocationClick: PropTypes.func.isRequired,
  onSelectPositionError: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  defaultReplacementString: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  onNextClick: PropTypes.func.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.object),
  onCounterClick: PropTypes.func.isRequired,
  teamMemberCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSynced: PropTypes.bool,
  error: PropTypes.shape({
    selectedId: PropTypes.bool,
    positionId: PropTypes.bool,
    teamMemberCount: PropTypes.bool
  }),
  workplaceId: PropTypes.string.isRequired
}

const defaultProps = {
  defaultReplacementString: '',
  teamMembers: [],
  isSynced: false,
  error: {},
  teamMemberCount: 1
}

class ShiftInfo extends Component {
  render() {
    const {
      handleChange,
      teamMembers,
      locations,
      onLocationClick,
      selectedId,
      isSynced,
      teamMemberCount,
      onCounterClick,
      onNextClick,
      error,
      defaultReplacementString,
      workplaceId,
      onSelectPositionError
    } = this.props
    return (
      <>
        <FlexBody>
          <div className={styles.request_header}>
            <Typography variant="h4" color="primary">
              Shift Information
            </Typography>
            <DropdownMenu
              label="Location"
              data-id="location_dropdown"
              value={selectedId || 'Select a location'}
              onChange={e => onLocationClick(e.target.value)}
              selected={selectedId}
              helperText={error.selectedId ? 'Select a location' : ''}
              error={error.selectedId}
              width={100}
            >
              <MenuItem disabled key={0} value="Select a location">
                Select a location
              </MenuItem>
              {locations?.length &&
                locations.map(value => (
                  <MenuItem key={value.id} name={value.name} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
            </DropdownMenu>
            <PositionsDropDownContainer
              label="Shift Position"
              workplaceId={workplaceId}
              error={error.positionId}
              onSelectPositionError={onSelectPositionError}
              isSynced={isSynced}
              defaultValue="Select a position"
              helperText="Select a position"
            />
            <Typography variant="h4" color="primary">
              Employees
            </Typography>
            <AutoComplete
              data-id="autoComplete"
              label="Employee Being Replaced"
              options={teamMembers}
              defaultValue={{ name: defaultReplacementString }}
              onInputChange={(e, value) => handleChange(e, value)}
              placeholder="Nobody is being replaced"
            />
            <TextField
              label="Employees Needed"
              data-id="textField"
              type="number"
              onChange={e => onCounterClick(e.target.value)}
              value={teamMemberCount}
              hintText="How many employees do you need?"
              required
              requiredText="Enter Employees Needed"
              forceShowError={error.teamMemberCount}
              error={error.teamMemberCount}
            />
          </div>
        </FlexBody>
        <FooterButton buttonInfo="All fields required" onClick={onNextClick}>
          Next
        </FooterButton>
      </>
    )
  }
}

ShiftInfo.propTypes = propTypes
ShiftInfo.defaultProps = defaultProps

export default ShiftInfo
