import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.css'

const propTypes = {
  leftUser: PropTypes.number,
  displayUsers: PropTypes.arrayOf(PropTypes.object)
}

const defaultProps = {
  leftUser: 0,
  displayUsers: []
}

export class ImageRow extends Component {
  render() {
    const { leftUser, displayUsers } = this.props
    return (
      <div className={style.avt_wrapper}>
        {displayUsers?.map(user => (
          <span key={user.userId} className={style.avt} />
        ))}
        {leftUser > 0 && (
          <span className={`${style.avt} ${style.leftAvt}`}>
            +{leftUser} more
          </span>
        )}
      </div>
    )
  }
}

ImageRow.propTypes = propTypes
ImageRow.defaultProps = defaultProps

export default ImageRow
