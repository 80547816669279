// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-footer-style__bottom_wrapper--1TlpA {\n  position: unset;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  padding: 20px;\n  background-color: #fff;\n}\n\n.src-components-layouts-footer-style__info--1AgXb {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  padding-bottom: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"bottom_wrapper": "src-components-layouts-footer-style__bottom_wrapper--1TlpA",
	"info": "src-components-layouts-footer-style__info--1AgXb"
};
module.exports = exports;
