import moment from 'moment'
import authentication from '../../authentication'

export const currentShift = {
  Mutation: {
    updateShiftStartDate: (_, { startDate }, { cache }) => {
      const data = {
        currentShift: {
          startDate,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateCurrentPage: (_, { currentPage }, { cache }) => {
      const data = {
        currentShift: {
          currentPage,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateLocation: (_, { id, name, message, alias }, { cache }) => {
      const data = {
        currentShift: {
          location: {
            id,
            name,
            message,
            alias,
            __typename: 'ShiftLocation'
          },
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    resetShiftProgress: (_, variables, { cache }) => {
      const data = {
        currentShift: {
          shiftId: null,
          restartPhoneTree: false,
          __typename: 'CurrentShift',
          position: {
            id: '',
            name: 'cashier',
            color: '0',
            alias: '',
            __typename: 'ShiftPosition'
          },
          location: {
            id: '',
            name: '',
            message: '',
            alias: '',
            __typename: 'ShiftLocation'
          },
          employeeCount: 1,
          currentPage: 1,
          notes: '',
          spanishNotes: '',
          startTime: moment().format('h:mma'),
          endTime: moment().format('h:mma'),
          startDate: moment().format('MMMM D, YYYY'),
          initialTime: true,
          replacedUsers: '',
          callOutUserId: '',
          assignedUserCount: 0,
          minBetweenCall: 'Fast',
          assignedUsers: [],
          lastPhoneStart: moment().format('MMMM D, h:mma'),
          initialCallTime: true,
          notifyManagers: [],
          startedEditing: false,
          noteChanged: false,
          spanNoteChanged: false,
          notifyManagerChanged: false
        }
      }
      cache.writeData({ data })
      return null
    },

    updateCurrentShift: (_, variables, { cache }) => {
      const data = {
        currentShift: {
          __typename: 'CurrentShift',
          position: {
            id: variables?.position?.id,
            name: variables?.position?.name,
            alias: variables?.position?.alias,
            color: variables?.position?.color,
            __typename: 'ShiftPosition'
          },
          location: {
            id: variables?.location?.id,
            name: variables?.location?.name,
            alias: variables?.location?.alias,
            message: variables?.location?.message,
            __typename: 'ShiftLocation'
          },
          employeeCount: variables.countUser,
          currentPage: 1,
          startTime: variables.startTime,
          endTime: variables.endTime,
          startDate: variables.startDate,
          initialTime: false,
          replacedUsers: variables.replacedUsers,
          lastPhoneStart: variables.lastPhoneStart,
          assignedUsers: variables.assignedUsers,
          callOutUserId: '',
          shiftId: variables.shiftId,
          restartPhoneTree: variables.restartPhoneTree,
          assignedUserCount: variables.assignedUserCount,
          notes: '',
          spanishNotes: '',
          minBetweenCall: variables.minBetweenCall,
          initialCallTime: false,
          notifyManagers: [],
          noteChanged: false,
          spanNoteChanged: false,
          notifyManagerChanged: false
        }
      }
      cache.writeData({ data })
      return null
    },

    updateShiftPosition: (_, { id, name, color, alias }, { cache }) => {
      const data = {
        currentShift: {
          position: {
            id,
            name,
            color,
            alias,
            __typename: 'ShiftPosition'
          },
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateShiftTime: (_, { startTime, endTime }, { cache }) => {
      const data = {
        currentShift: {
          startTime,
          endTime,
          initialTime: false,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateShiftWorkplace: (
      _,
      { id, name, phone, timezone, corporationId, corporationName, settings },
      { cache }
    ) => {
      const data = {
        currentShift: {
          workplace: {
            id,
            name,
            phone,
            timezone,
            corporation: {
              id: corporationId,
              name: corporationName,
              settings,
              __typename: 'ShiftCorporation'
            },
            __typename: 'ShiftWorkplace'
          },
          __typename: 'CurrentShift'
        }
      }
      authentication.setSelectedCorporationId(corporationId)
      cache.writeData({ data })
      return data
    },
    updateShiftManager: (
      _,
      { id, firstName, lastName, phoneNumber },
      { cache }
    ) => {
      const data = {
        currentShift: {
          manager: {
            id,
            firstName,
            lastName,
            phoneNumber,
            __typename: 'CurrentManager'
          },
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateEmployeeCount: (_, { employeeCount }, { cache }) => {
      const data = {
        currentShift: {
          employeeCount,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateReplacedUsers: (_, { replacedUsers, callOutUserId }, { cache }) => {
      const data = {
        currentShift: {
          replacedUsers,
          callOutUserId,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateNotes: (_, { notes, noteChanged }, { cache }) => {
      const data = {
        currentShift: {
          notes,
          noteChanged,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateSpanishNotes: (_, { spanishNotes, spanNoteChanged }, { cache }) => {
      const data = {
        currentShift: {
          spanishNotes,
          spanNoteChanged,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateMinBetweenCall: (_, { minBetweenCall }, { cache }) => {
      const data = {
        currentShift: {
          minBetweenCall,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateNotifyManagers: (
      _,
      { notifyManagers, notifyManagerChanged },
      { cache }
    ) => {
      const data = {
        currentShift: {
          notifyManagers,
          notifyManagerChanged,
          __typename: 'CurrentShift'
        }
      }
      cache.writeData({ data })
      return data
    },

    updateShiftEditing: (_, variables, { cache }) => {
      const data = {
        currentShift: {
          __typename: 'CurrentShift',
          startedEditing: true
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

export const defaultShift = {
  currentShift: {
    __typename: 'CurrentShift',
    workplace: {
      id: '',
      name: '',
      phone: '',
      timezone: '',
      corporation: {
        id: null,
        name: '',
        settings: '',
        __typename: 'ShiftCorporation'
      },
      __typename: 'ShiftWorkplace'
    },
    position: {
      id: '',
      name: 'cashier',
      color: '0',
      alias: '',
      __typename: 'ShiftPosition'
    },
    location: {
      id: '',
      name: '',
      message: '',
      alias: '',
      __typename: 'ShiftLocation'
    },
    employeeCount: 1,
    currentPage: 1,
    manager: {
      id: null,
      firstName: '',
      lastName: '',
      phoneNumber: 0,
      __typename: 'CurrentManager'
    },
    shiftId: '',
    notes: '',
    spanishNotes: '',
    startTime: moment().format('h:mma'),
    endTime: moment().format('h:mma'),
    startDate: moment().format('MMMM D, YYYY'),
    initialTime: true,
    restartPhoneTree: false,
    startedEditing: false,
    replacedUsers: '',
    callOutUserId: '',
    assignedUserCount: 0,
    minBetweenCall: 'Fast',
    lastPhoneStart: moment().format('MMMM D, h:mma'),
    assignedUsers: [],
    initialCallTime: true,
    notifyManagers: [],
    noteChanged: false,
    spanNoteChanged: false,
    notifyManagerChanged: false
  }
}
