// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-login-header-style__logo_container--3htpe {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-positive: 2;\n      flex-grow: 2;\n}\n", ""]);
// Exports
exports.locals = {
	"logo_container": "src-components-layouts-login-header-style__logo_container--3htpe"
};
module.exports = exports;
