// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: ADD BORDERLESS DROPDOWN TO DESIGN KIT */\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D fieldset {\n  border: none;\n}\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n}\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D .MuiFormHelperText-filled {\n  display: none;\n}\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D .fa-chevron-down {\n  color: #2a53ba;\n  font-size: 14px;\n  margin: 4px 0 0 0;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D .MuiSelect-selectMenu {\n  font-weight: bold;\n  color: #333c47;\n  background: transparent;\n  width: auto;\n  padding-right: 5px;\n}\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D .MuiInputBase-root svg {\n  z-index: unset;\n  position: unset;\n  margin: 0;\n}\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D .MuiSelect-selectMenu:focus {\n  font-weight: bold;\n  color: #333c47;\n  background: transparent;\n  width: auto;\n  padding-right: 5px;\n}\n\n.src-components-theme-select-workplace-header-style__select_workplace--3pv0D .MuiSelect-outlined.MuiSelect-outlined {\n  padding-right: 5px;\n}\n\n@media screen and (max-width: 320px) {\n  .src-components-theme-select-workplace-header-style__select_workplace--3pv0D .MuiInputBase-root {\n    max-width: 90px;\n  }\n}\n\n@media screen and (max-width: 375px) {\n  .src-components-theme-select-workplace-header-style__select_workplace--3pv0D .MuiInputBase-root {\n    max-width: 110px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"select_workplace": "src-components-theme-select-workplace-header-style__select_workplace--3pv0D"
};
module.exports = exports;
