export const advanceTemplate = (
  positionName,
  corporationName,
  startDay,
  startTime,
  endTime,
  workplaceName,
  Department,
  isSpanish
) => {
  return isSpanish
    ? `Este mensaje confirma que usted está asignado para trabajar como ${positionName} con ${corporationName} el ${startDay.format(
        'dddd'
      )}, ${startDay.format('MMMM')} ${startDay.format(
        'Do'
      )} desde las ${startTime.toUpperCase()} horas hasta las ${endTime.toUpperCase()} horas en ${workplaceName} ${JSON.parse(
        Department?.addressJson
      )?.street || ''}, ${JSON.parse(Department?.addressJson)?.city ||
        ''}. El supervisor a cargo es ${Department?.contactUser?.firstName} ${
        Department?.contactUser?.lastName
      } y puede ser contactado al ${Department?.contactUser?.phoneNumber}. ${
        Department?.message ? Department?.message : ''
      }`
    : `You are confirmed for your ${positionName} shift with ${corporationName} which starts ${startDay.format(
        'dddd'
      )}, ${startDay.format('MMMM')} ${startDay.format(
        'Do'
      )} from ${startTime.toUpperCase()} to ${endTime.toUpperCase()} at ${workplaceName} ${JSON.parse(
        Department?.addressJson
      )?.street || ''}, ${JSON.parse(Department?.addressJson)?.city ||
        ''}. The supervisor is ${Department?.contactUser?.firstName} ${
        Department?.contactUser?.lastName
      } and can be reached at the following phone number ${
        Department?.contactUser?.phoneNumber
      }. ${Department?.message ? Department?.message : ''}`
}
export default advanceTemplate
