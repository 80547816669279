import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'syrg-design-kit'
import style from './style.css'
import { Footer } from '../footer'

// TODO - retrieve design kit loader

const FooterButton = props => {
  const { children, onClick, isDisabled, buttonInfo, isLink, isLoading } = props
  return (
    <Footer isLink={isLink} buttonInfo={buttonInfo}>
      <div className={style.footer_btn_wrapper}>
        <Button
          data-id="footer-button"
          fullWidth
          primary
          onClick={onClick}
          disabled={isDisabled}
        >
          {isLoading ? 'loading...' : children}
        </Button>
      </div>
    </Footer>
  )
}

FooterButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLink: PropTypes.bool,
  buttonInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.bool])
}

FooterButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  buttonInfo: false,
  isLink: false
}

export default FooterButton
