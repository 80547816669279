import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropdownMenu, MenuItem, IconButton } from 'syrg-design-kit'
import style from './style.css'
import NavigationDrawerCont from '../../../containers/navigation-drawer'
import { LogoHeader } from '../../layouts/logo-header'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isWorkplaceDropdown: PropTypes.bool,
  selectedWorkplace: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  changeWorkPlace: PropTypes.func,
  allWorkplaces: PropTypes.arrayOf(PropTypes.object)
}

const defaultProps = {
  isWorkplaceDropdown: true,
  history: { push: () => {} },
  selectedWorkplace: {
    id: '',
    name: ''
  },
  changeWorkPlace: () => {},
  allWorkplaces: []
}

export class SelectWorkplaceHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDrawerOpen: false
    }
  }

  onDrawerClick = () => {
    const { isDrawerOpen } = this.state
    this.setState({ isDrawerOpen: !isDrawerOpen })
  }

  render() {
    const {
      history,
      selectedWorkplace,
      changeWorkPlace,
      allWorkplaces,
      isWorkplaceDropdown
    } = this.props
    const { isDrawerOpen } = this.state
    return (
      <>
        <LogoHeader
          leftInfo={<IconButton onClick={this.onDrawerClick}>menu</IconButton>}
          rightInfo={
            isWorkplaceDropdown && (
              <div className={style.select_workplace}>
                <DropdownMenu
                  data-id="work_drop"
                  value={selectedWorkplace?.id || ''}
                  onChange={e => changeWorkPlace(e.target.value)}
                  isMaxWidth
                >
                  {allWorkplaces?.map(workPlace => (
                    <MenuItem
                      key={workPlace.id}
                      name={workPlace.name}
                      value={workPlace.id}
                      isCustomWidth
                    >
                      {workPlace.name}
                    </MenuItem>
                  ))}
                </DropdownMenu>
              </div>
            )
          }
        />
        <NavigationDrawerCont
          isNavigationDrawer={isDrawerOpen}
          onDrawerClick={this.onDrawerClick}
          history={history}
        />
      </>
    )
  }
}
SelectWorkplaceHeader.propTypes = propTypes
SelectWorkplaceHeader.defaultProps = defaultProps

export default SelectWorkplaceHeader
