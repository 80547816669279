// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-add-supervisor-style__location_content--1rYPD {\n  padding: 0 20px;\n}\n\n.src-components-add-supervisor-style__block--2kGuw > div {\n  margin-top: 0;\n  width: 100%;\n}\n\n.src-components-add-supervisor-style__location_content--1rYPD > div {\n  margin-top: 32px;\n  margin-bottom: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"location_content": "src-components-add-supervisor-style__location_content--1rYPD",
	"block": "src-components-add-supervisor-style__block--2kGuw"
};
module.exports = exports;
