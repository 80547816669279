// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-add-member-position-style__add_member_pos--1OKTt h4 {\n  margin-bottom: 15px;\n  padding: 0 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"add_member_pos": "src-components-add-member-position-style__add_member_pos--1OKTt"
};
module.exports = exports;
