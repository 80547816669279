import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import { AddLocationWizardComponent } from '../../components/layouts/add-location-wizard'
import { getLocation } from '../../apollo/apollo-cache-query.gql'
import { clearLocation } from '../../apollo/apollo-cache-mutation.gql'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getLocationData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  clearLocationData: PropTypes.func
}

const defaultProps = {
  getLocationData: {},
  clearLocationData: () => {}
}

export class AddLocationWizard extends Component {
  onDeleteCurrentLocation = () => {
    const { history, clearLocationData } = this.props
    clearLocationData()
    history.push('/locations')
  }

  render() {
    const { getLocationData } = this.props
    const { page, startedEditing } = getLocationData?.location
    return (
      <AddLocationWizardComponent
        page={page || 1}
        onDeleteCurrentLocation={this.onDeleteCurrentLocation}
        startedEditing={startedEditing}
      />
    )
  }
}
const AddLocationWizardContainer = compose(
  withApollo,
  graphql(getLocation, { name: 'getLocationData' }),
  graphql(clearLocation, { name: 'clearLocationData' })
)(AddLocationWizard)

AddLocationWizard.propTypes = propTypes
AddLocationWizard.defaultProps = defaultProps

export default AddLocationWizardContainer
