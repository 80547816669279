// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-layouts-search-table-header-style__search_head--2vJuu {\n  padding: 40px 20px 15px 20px;\n}\n\n.src-components-layouts-search-table-header-style__table_head--1kQIV {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n}\n\n.src-components-layouts-search-table-header-style__table_head--1kQIV button {\n  max-width: 131px;\n}\n\n.src-components-layouts-search-table-header-style__search_position--2TzR_ {\n  margin: 10px 0 15px;\n}\n\n.src-components-layouts-search-table-header-style__search_position--2TzR_ span:first-child {\n  min-width: 109px;\n  max-width: -webkit-fit-content;\n  max-width: -moz-fit-content;\n  max-width: fit-content;\n  width: auto;\n}\n\n.src-components-layouts-search-table-header-style__rename--12z82 {\n  cursor: pointer;\n  margin-left: 7px;\n  color: #2a53ba;\n}\n", ""]);
// Exports
exports.locals = {
	"search_head": "src-components-layouts-search-table-header-style__search_head--2vJuu",
	"table_head": "src-components-layouts-search-table-header-style__table_head--1kQIV",
	"search_position": "src-components-layouts-search-table-header-style__search_position--2TzR_",
	"rename": "src-components-layouts-search-table-header-style__rename--12z82"
};
module.exports = exports;
