import gql from 'graphql-tag'

export const getUnavailability = gql`
  query getCorporationUserUnavailability($corporationUserId: ID!) {
    getCorporationUserUnavailability(corporationUserId: $corporationUserId) {
      id
      reason
      startTime
      endTime
      corporationUserId
    }
  }
`

export const createUserUnavailability = gql`
  mutation createCorporationUserUnavailability(
    $input: CreateUserUnavailabilityInput!
  ) {
    createCorporationUserUnavailability(input: $input) {
      id
      reason
      startTime
      endTime
      corporationUserId
    }
  }
`

export const deleteUnavailability = gql`
  mutation deleteCorporationUserUnavailability($id: ID!) {
    deleteCorporationUserUnavailability(id: $id) {
      id
      reason
      reason
      endTime
      corporationUserId
    }
  }
`

export const updateUnavailabilityMutation = gql`
  mutation updateCorporationUserUnavailability(
    $input: UpdateUserUnavailabilityInput!
  ) {
    updateCorporationUserUnavailability(input: $input) {
      id
      reason
      startTime
      endTime
      corporationUserId
    }
  }
`

export default getUnavailability
