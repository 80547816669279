import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { graphql, withApollo } from 'react-apollo'
import { uniqBy, flowRight as compose } from 'lodash'
import { ShiftRequest } from '../../components/shift-request'
import { getPastShift } from './past-shifts.gql'

const propTypes = {
  client: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  workplaceId: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onCreateShift: PropTypes.func.isRequired,
  restartPhoneTree: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  isSynced: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getPastShifts: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

const defaultProps = {
  client: { cache: { query: () => {} } },
  getPastShifts: { ShiftHistory: [] }
}

export class PastShifts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shifts: [],
      isFetching: false,
      offset: 1,
      isEnd: false,
      isLoading: false,
      workId: ''
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { getPastShifts, workplaceId } = nextProps
    const { offset, workId } = state
    const { loading, error } = getPastShifts
    if (workId !== workplaceId) {
      return {
        isLoading: true,
        workId: workplaceId,
        offset: 1
      }
    }
    if ((loading || error) && offset === 1) {
      return {
        isLoading: true
      }
    }
    if (!loading && getPastShifts?.ShiftHistory && offset === 1) {
      return {
        isLoading: false,
        shifts: getPastShifts.ShiftHistory
      }
    }
    return null
  }

  getShifts = () => {
    const { workplaceId, client } = this.props
    const { offset, shifts } = this.state
    client
      .query({
        query: getPastShift,
        fetchPolicy: 'network-only',
        variables: {
          workplaceId,
          offset: offset * 10
        }
      })
      .then(res => {
        if (
          res?.data?.ShiftHistory === null ||
          res?.data?.ShiftHistory?.length === 0
        ) {
          this.setState({ isFetching: false, isEnd: true })
        } else {
          this.setState({
            isFetching: false,
            offset: offset + 1,
            shifts: shifts.concat(res.data.ShiftHistory)
          })
        }
      })
  }

  // determines when to lazy load
  loadOnScroll = e => {
    const { isFetching, isEnd } = this.state
    const element = e.target
    if (element.scrollHeight - element.scrollTop - 10 < element.clientHeight) {
      if (!isFetching && !isEnd) {
        this.setState({ isFetching: true })
        this.getShifts()
      }
    }
  }

  processShiftsData = (shiftsQueryData, tz) => {
    if (shiftsQueryData?.length) {
      const rawShifts = shiftsQueryData
      return rawShifts.map(shift => {
        const { startTime, endTime } = shift
        if (!startTime.includes('m')) {
          return {
            ...shift,
            startDate: moment
              .tz(moment.unix(startTime), tz)
              .format('MMMM D, YYYY'),
            startTime: moment.tz(moment.unix(startTime), tz).format('h:mma'),
            endTime: moment.tz(moment.unix(endTime), tz).format('h:mma')
          }
        }
        return shift
      })
    }
    return []
  }

  render() {
    const {
      timezone,
      onCreateShift,
      onTabChange,
      restartPhoneTree,
      isSynced,
      history
    } = this.props
    const { isFetching, shifts, isLoading } = this.state
    const props = {
      tabId: 2,
      restartPhoneTree,
      onTabChange,
      onCreateShift,
      isSynced,
      history
    }
    if (isLoading) return <ShiftRequest isLoading {...props} />
    const shiftsData = uniqBy(this.processShiftsData(shifts, timezone), 'id')
    return (
      <ShiftRequest
        shifts={shiftsData}
        isFetching={isFetching}
        onShiftScroll={this.loadOnScroll}
        {...props}
      />
    )
  }
}

export const PastShiftsContainer = compose(
  withApollo,
  graphql(getPastShift, {
    skip: ownProps => !ownProps.workplaceId,
    options: ownProps => ({
      variables: {
        workplaceId: ownProps.workplaceId,
        offset: 0
      },
      fetchPolicy: 'network-only'
    }),
    name: 'getPastShifts'
  })
)(PastShifts)

PastShifts.propTypes = propTypes
PastShifts.defaultProps = defaultProps

export default PastShiftsContainer
