import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button } from 'syrg-design-kit'
import style from './style.css'

const propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  isRestricted: PropTypes.bool,
  isHeaderLayout: PropTypes.bool
}

const defaultProps = {
  info: '',
  onClick: () => {},
  isRestricted: false,
  isHeaderLayout: true
}

export class SearchTableHeader extends Component {
  // TODO: removed Edit props when rename-position mutation ready
  render() {
    const { header, info, onClick, isRestricted, isHeaderLayout } = this.props
    return (
      <div className={style.search_head}>
        {isHeaderLayout ? (
          <div className={style.table_head}>
            <Typography variant="h1">{header}</Typography>
            {!isRestricted && (
              <Button onClick={onClick} primary size="small">
                Add New
              </Button>
            )}
          </div>
        ) : (
          header
        )}
        {/* {positionName?.length > 0 && ( */}
        {/*  <div className={style.search_position}> */}
        {/*    <Tag name={positionName} color={color} /> */}
        {/*    {!isEdit && ( */}
        {/*      <span */}
        {/*        role="presentation" */}
        {/*        className={style.rename} */}
        {/*        onClick={onRenameClick} */}
        {/*      > */}
        {/*        Rename */}
        {/*      </span> */}
        {/*    )} */}
        {/*  </div> */}
        {/* )} */}
        {info && info}
      </div>
    )
  }
}

SearchTableHeader.propTypes = propTypes
SearchTableHeader.defaultProps = defaultProps

export default SearchTableHeader
