import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, Link as LinkButton } from 'syrg-design-kit'
import style from './style.css'

const propTypes = {
  isSynced: PropTypes.bool,
  isRestricted: PropTypes.bool,
  onEditLocation: PropTypes.func,
  sortedEligibleMember: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  ),
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  )
}

const defaultProps = {
  isSynced: false,
  isRestricted: false,
  onEditLocation: () => {},
  sortedEligibleMember: [],
  employees: []
}
class EligibleMemberSectionComponent extends Component {
  render() {
    const {
      isSynced,
      isRestricted,
      onEditLocation,
      sortedEligibleMember,
      employees
    } = this.props

    const eligibleMemberCount =
      employees.length > 20 ? employees.length - 20 : ''
    return (
      <>
        <div className={style.content_head}>
          <Typography variant="h4" color="primary">
            Eligible Employees
          </Typography>
          {!isSynced && !isRestricted && (
            <LinkButton
              data-id="edit_team_member"
              isButton
              onClick={onEditLocation}
            >
              Edit
            </LinkButton>
          )}
        </div>
        <div id="eligible-members" className={style.eligible_team_member}>
          {sortedEligibleMember?.length ? (
            sortedEligibleMember.slice(0, 20).map(emp => (
              <div key={emp.id}>
                <Typography variant="body1">
                  {emp.name || `${emp.firstName || ''} ${emp.lastName || ''}`}
                </Typography>
              </div>
            ))
          ) : (
            <Typography variant="body1">No eligible team members</Typography>
          )}

          {employees && employees.length > 20 && (
            <div className={style.view_all}>
              <Typography variant="h4">{`and (${eligibleMemberCount} more)`}</Typography>
              {!isSynced && !isRestricted && (
                <LinkButton
                  data-id="btnSeeAll"
                  isButton
                  onClick={onEditLocation}
                >
                  View All
                </LinkButton>
              )}
            </div>
          )}
        </div>
      </>
    )
  }
}

EligibleMemberSectionComponent.propTypes = propTypes
EligibleMemberSectionComponent.defaultProps = defaultProps

export default EligibleMemberSectionComponent
