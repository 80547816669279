import React, { Component } from 'react'
import { Snackbar } from 'syrg-design-kit'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { sortBy, flowRight as compose } from 'lodash'
import { AddSuperVisorComponent } from '../../components/add-supervisor'
import { addSupervisorData } from '../../apollo/apollo-cache-mutation.gql'
import {
  getCurrentShift,
  getLocation
} from '../../apollo/apollo-cache-query.gql'
import { getSupervisor, addLocationMutation } from './add-supervisor.gql'
import { fetchWorkplaceLocations } from '../location-table/location-table.gql'

const propTypes = {
  onDeleteCurrentLocation: PropTypes.func,
  addSupervisor: PropTypes.func,
  getSupervisorData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  getLocationData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  addLocation: PropTypes.func
}

const defaultProps = {
  onDeleteCurrentLocation: () => {},
  addSupervisor: () => {},
  getSupervisorData: {},
  getLocationData: {},
  addLocation: () => {}
}

export class AddSupervisor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      supervisorId: props?.getLocationData?.location?.supervisorId,
      locationMessage: props?.getLocationData?.location?.message,
      primaryArea: props?.getLocationData?.location?.primaryArea,
      isLoading: false,
      error: {
        supervisorId: false,
        primaryArea: false
      }
    }
  }

  onSelectWorkplaceError = (value, id) => {
    const { error } = this.state

    this.setState({
      primaryArea: id,
      error: {
        ...error,
        primaryArea: value
      }
    })
  }

  onLocationChange = (value, key) => {
    const { error: stateError } = this.state
    let error = false
    if (value === 'Select Supervisor' || value === 'Select area') {
      error = true
    }
    this.setState({
      [key]: value,
      error: {
        ...stateError,
        [key]: error
      }
    })
  }

  onAddLocationClick = () => {
    const { supervisorId, locationMessage, primaryArea } = this.state
    const { getLocationData, addLocation, onDeleteCurrentLocation } = this.props
    const supervisor = supervisorId === ''
    const primary = primaryArea === ''
    if (supervisor || primary) {
      this.setState({
        error: {
          supervisorId: supervisor,
          primaryArea: primary
        }
      })
    }
    if (supervisor) {
      Snackbar('Please Select Supervisor', 'error')
    } else if (primary) {
      Snackbar('Please Select Area', 'error')
    } else {
      this.setState({ isLoading: true })
      const { location } = getLocationData
      addLocation({
        variables: {
          name: location?.name?.trim(),
          workplaceId: primaryArea,
          contactUserId: supervisorId,
          alias: '',
          addressJson: JSON.stringify({
            street: location?.street,
            city: location?.city,
            postalCode: location?.zipcode,
            state: location?.state
          }),
          message: locationMessage
        },
        update: (cache, { data: { createDepartment: newLocation } }) => {
          const locationData = {
            id: newLocation?.id,
            name: newLocation?.name,
            addressJson: newLocation.addressJson,
            __typename: 'Department'
          }
          try {
            const { getDepartmentsByWorkplaceId } = cache.readQuery({
              query: fetchWorkplaceLocations,
              variables: { id: newLocation.workplaceId }
            })
            let newLocations = getDepartmentsByWorkplaceId
            if (!newLocations) {
              newLocations = []
            }
            if (newLocations.filter(e => e.id === location.id).length === 0) {
              newLocations.push(locationData)
            }
            cache.writeQuery({
              query: fetchWorkplaceLocations,
              variables: { id: newLocation.workplaceId },
              data: { getDepartmentsByWorkplaceId: newLocations }
            })
          } catch (error) {
            console.info('error', error)
          }
        }
      }).then(() => {
        this.setState({ isLoading: false })
        onDeleteCurrentLocation()
      })
    }
  }

  onBackClick = () => {
    const { addSupervisor } = this.props
    const { supervisorId, locationMessage, primaryArea } = this.state
    addSupervisor({
      variables: {
        page: 1,
        supervisorId,
        primaryArea,
        message: locationMessage
      }
    })
  }

  render() {
    const { isLoading, error } = this.state
    const { getSupervisorData } = this.props
    const supervisors = sortBy(
      getSupervisorData?.getManagersByCorporationId,
      x => x.firstName
    )
    return (
      <AddSuperVisorComponent
        onLocationChange={this.onLocationChange}
        supervisors={supervisors}
        onAddLocationClick={this.onAddLocationClick}
        locationData={this.state}
        onSelectWorkplaceError={this.onSelectWorkplaceError}
        isLoading={isLoading}
        onBackClick={this.onBackClick}
        error={error}
      />
    )
  }
}
const AddSupervisorContainer = compose(
  withApollo,
  graphql(getCurrentShift, { name: 'getShift' }),
  graphql(addSupervisorData, { name: 'addSupervisor' }),
  graphql(getLocation, { name: 'getLocationData' }),
  graphql(getSupervisor, {
    skip: ({ getShift }) =>
      !getShift.currentShift || !getShift.currentShift.workplace.corporation.id,
    options: ({ getShift }) => ({
      variables: {
        corporationId: getShift?.currentShift?.workplace?.corporation?.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    name: 'getSupervisorData'
  }),
  graphql(addLocationMutation, { name: 'addLocation' })
)(AddSupervisor)

AddSupervisor.propTypes = propTypes
AddSupervisor.defaultProps = defaultProps

export default AddSupervisorContainer
